// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#our_services_divs::-webkit-scrollbar{
    display: none;
}
@media (max-width:768px)  {

    
}
@media (max-width:600px)  {
    #urban_tickets{
        flex-direction: column;
    }
    #urban_ticket_text{
        width: 100% !important;
    }
    #urban_ticket_pic{
        width: 100% !important;
        height: auto !important;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Views/Experience/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;;;AAGA;AACA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;;AAEJ","sourcesContent":["#our_services_divs::-webkit-scrollbar{\r\n    display: none;\r\n}\r\n@media (max-width:768px)  {\r\n\r\n    \r\n}\r\n@media (max-width:600px)  {\r\n    #urban_tickets{\r\n        flex-direction: column;\r\n    }\r\n    #urban_ticket_text{\r\n        width: 100% !important;\r\n    }\r\n    #urban_ticket_pic{\r\n        width: 100% !important;\r\n        height: auto !important;\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
