import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMethod } from '../../helpers';
import SideMenuBar from '../../Components/SideMenuBar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceHolderImage from '../../assets/images/placeholder-image.png';

const MyPostListView = ({screenName}) => {
    const [myPostList, setMyPostList] = useState([]);
    const [displayCount, setDisplayCount] = useState(8); // Initial number of posts to display
    const navigate = useNavigate();
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [postCategories, setPostCategories] = useState([]);
    const [categoryIndex, setCatgoryIndex] = useState(0);
   





    const getHubTypes = () => {
        let json = localStorage.getItem("@USER");
        let User = JSON.parse(json);
        if (User?.hubUser?.Student) {
            return 'Student';
        } else if (User?.hubUser?.Farmer) {
            return 'Farmer';
        } else {
            return 'MarketPlace'; // Ensure 'MarketPlace' is used as a fallback
        }
    };
    

    useEffect(() => {
        makeMyPostApiCall();
    }, []);
    useEffect(() => {
        fetchCategories();
    }, []);
    
    const makeMyPostApiCall = async () => {
        try {
            let hubType = getHubTypes(); // Determine hub type
            let url = `/customers/secure/posts/byme/${hubType}`; // Fetch posts based on hub type
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log("API Response:", response); // Debug response
    
            if (response.success) {
                setMyPostList(response.data);
            } else {
                console.log("Error:", response.message);
            }
        } catch (e) {
            console.log("API call error:", e);
        }
    };
    


    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting geolocation:', error);
                }
            );
        } else {
            console.error('Geolocation not supported by this browser.');
        }
    }, []);
    
    const [categoryMap, setCategoryMap] = useState({});

    const fetchCategories = async () => {
        try {
            const url = `customers/posts/categories/${getHubTypes()}`;
            const token = localStorage.getItem("@token");
            const response = await getMethod({ url, token });
    
            if (response?.data && response?.data?.length > 0) {
                const categoryMapObj = {};
                response.data.forEach(category => {
                    categoryMapObj[category._id] = category.title;
                    if (category.children) {
                        category.children.forEach(subCategory => {
                            categoryMapObj[subCategory._id] = subCategory.title;
                            if (subCategory.children) {
                                subCategory.children.forEach(subSubCategory => {
                                    categoryMapObj[subSubCategory._id] = subSubCategory.title;
                                });
                            }
                        });
                    }
                });
                setCategoryMap(categoryMapObj);
                setPostCategories(response.data);
            }
        } catch (e) {
            console.error('Error fetching categories:', e);
        }
    };
    const getColorText = (status) => {
        if (status === 'Pending') {
            return '#FFA500'
        } else if (status === 'Approved') {
            return '#2db04e'
        } else if (status === 'Decline') {
            return '#FF0000'
        } else if (status === 'Requested') {
            return '#23c2f2'
        }
    };

    const handleCardClick = (item) => {
        navigate(`/hub/${screenName}/hub-details`, { state: { isFromMyPost: true, data: item } });
    };

    const handleLoadMore = () => {
        setDisplayCount(prevCount => prevCount + 8);
    };

    return (
        <SideMenuBar>
            <div style={{ margin: '16px' }}>
                <div style={{
                    fontWeight: 'bold',
                    marginBottom: '16px',
                    marginLeft: 'auto',
                    marginRight: '600px',
                    textAlign: 'Center',
                    fontSize: '24px',
                }}>
                    My Posts
                </div>
                
                {myPostList && myPostList.length > 0 ? (
                    <>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '20px',
                            justifyContent: 'start',
                            padding: '20px',
                        }}>
                            {myPostList.slice(0, displayCount).map((item, i) => (
                              <Card
                              key={i}
                              style={{
                                  width: '235px',
                                  height: 'auto',
                                  border: '1px solid #e0e0e0',
                                  borderRadius: '10px 0px 0px 0px',
                                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                  transition: 'transform 0.2s ease',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflow: 'hidden',
                                  position: 'relative',
                              }}
                              onClick={() => handleCardClick(item)}
                              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
                              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                          >
                              <div style={{
                                  width: '127px',
                                  height: '141px',
                                  position: 'absolute',
                                  top: '20px',
                                  left: '54px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  overflow: 'hidden',
                                  borderRadius: '10px 0px 0px 0px',
                              }}>
                                  <img
                                      src={item?.image?.[0] ? item.image[0] : PlaceHolderImage}
                                      alt={item?.title}
                                      style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover',
                                      }}
                                  />
                              </div>
                              <div style={{
                                  padding: '16px',
                                  textAlign: 'left',
                                  marginTop: '161px', // Push content below the image
                              }}>
                                  <div style={{
                                    fontSize: '12px',
                                    color: '#908C8C',
                                    fontWeight: 600,
                                    marginBottom: '4px',
                                    textTransform: 'uppercase',
                                }}>
                                    {categoryMap[item.category?._id] || 
                                    categoryMap[item.subcategory?._id] || 
                                    categoryMap[item.subsubcategory?._id] || 
                                    'N/A'}
                                </div>
                                  <div style={{
                                      fontSize: '18px',
                                      fontWeight: 700,
                                      color: '#323232',
                                      marginBottom: '8px',
                                  }}>
                                      {item?.title}
                                  </div>
                                  <div style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                  }}>
                                      {item?.price && item?.discountPrice && item?.price !== item?.discountPrice && (
                                          <div style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                          }}>
                                              <div style={{
                                                  fontSize: '14px',
                                                  fontWeight: 700,
                                                  color: 'gray',
                                                  marginRight: '8px',
                                                  textDecoration: 'line-through',
                                              }}>
                                                  ₹ {item?.price}
                                              </div>
                                              <div style={{
                                                  fontSize: '20px',
                                                  fontWeight: 700,
                                                  color: '#323232',
                                              }}>
                                                  ₹ {item?.discountPrice}
                                              </div>
                                          </div>
                                      )}
                                  </div>
                                  <div style={{
                                      fontSize: '12px',
                                      color: '#666',
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                  }}>
                                      <LocationOnIcon style={{ width:'18', height:'22', fontSize: '16px', marginRight: '4px', color: '#00b5ff' }} />
                                      {item?.location?.address?.city || item?.location?.city || 'N/A'}
                                  </div>
                                  <div style={{
                                      padding: '4px 8px',
                                      borderRadius: '4px',
                                      fontSize: '12px',
                                      fontWeight: 600,
                                      color: '#fff',
                                      backgroundColor: getColorText(item?.status),
                                      textAlign: 'center',
                                      margin: '0 auto',
                                      display: 'block',
                                  }}>
                                      {item?.status}
                                  </div>
                              </div>
                          </Card>
                          
                            ))}
                        </div>

                        {myPostList.length > displayCount && (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px'
                            }}>
                                <button
                                    onClick={handleLoadMore}
                                    style={{
                                        backgroundColor: '#f0f0f0',
                                        border: '1px solid #000000',
                                        padding: '10px 20px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#333',
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.3s'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                >
                                    Load More
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <div style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        display: 'flex',
                        height: '200px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        No Data Found
                    </div>
                )}
            </div>
        </SideMenuBar>
    );
};

export default MyPostListView;
