import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate, useLocation } from "react-router-dom";
import { getOrderListApiAction } from '../Views/Order/redux/OrderAction';
import { useDispatch, useSelector } from 'react-redux';
import { getMethod } from '../helpers';
import logoY from '../assets/AppLogo/logoY.svg';
import logoG from '../assets/AppLogo/logoG.svg';
import logoV from '../assets/AppLogo/logoV.svg';

const SideMenuBar = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    let splittedArray = location?.pathname?.split('/');
    let path = splittedArray[1];

    const [selectedPortalMenu, setSelectedPortalMenu] = useState('');

    const orderList = useSelector(state => state.orderState.orderDataList);
    const complainList = useSelector(state => state.complaintState.complaintDataList);
    const wishlist = useSelector(state => state.wishlistState.wishlistData);


    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    useEffect(() => {
        getOrderList();
    }, []);

    const getOrderList = async () => {
        try {
            let url = 'customers/secure/myorders';
            let token = localStorage.getItem('@token');
            let response = await getMethod({ url, token });
            if (response.success) {
                dispatch(getOrderListApiAction(response.data))
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                let url = 'customers/secure/profile';
                let token = localStorage.getItem('@token');
                let response = await getMethod({ url, token });
                if (response.success) {
                    const userData = response.data.customer;
                    localStorage.setItem("@USER", JSON.stringify(userData));
                    // Update your user state here if you're using one
                }
            } catch (e) {
                console.log(e);
            }
        };
    
        fetchUserProfile();
    }, []);
    
    const getSelectedTabColor = (key, secondaryColor) => {
        return key === path ? '#23c2f2' : secondaryColor
    }

    const getSelectedPortalMenuColor = (key, secondaryColor) => {
        return key === selectedPortalMenu ? '#23c2f2' : secondaryColor
    }

    const renderMenu = () => {
        const dashboardMenu = [
            {
                key: 'orders',
                icon: <ShoppingBagOutlinedIcon style={{ width: '0.8em', height: '0.8em', color: getSelectedTabColor('orders', '#7D879C') }} />,
                menuName: 'Orders',
                menuValue: orderList?.length || '0',
                onClick: () => navigate('/orders')
            },
            {
                key: 'profile-wishlist',
                icon: <FavoriteBorderIcon style={{ width: '0.8em', height: '0.8em', color: getSelectedTabColor('profile-wishlist', '#7D879C') }} />,
                menuName: 'Wishlist',
                menuValue: wishlist?.length || '0',
                onClick: () => navigate('/profile-wishlist')
            },
            {
                key: 'support-ticket',
                icon: <HeadsetMicIcon style={{ width: '0.8em', height: '0.8em', color: getSelectedTabColor('support-ticket', '#7D879C') }} />,
                menuName: 'Support Tickets',
                menuValue: complainList?.length || '0',
                onClick: () => navigate('/support-ticket')
            },
        ];
        return (
            dashboardMenu?.map((item) => {
                return (
                    <div
                        className='d-flex justify-content-between'
                        style={{
                            paddingLeft: '1.5rem',
                            paddingRight: '1.5rem',
                            marginBottom: '1.25rem',
                            ...(item.key === path
                                ? {
                                    borderLeftColor: '#23c2f2',
                                    borderLeftWidth: '4px',
                                    borderLeftStyle: 'solid',
                                    paddingLeft: '1.3rem',
                                }
                                : {}),
                            cursor: 'pointer'
                        }}
                        onClick={item?.onClick}
                    >
                        <div className='d-flex align-items-center' style={{ gap: '8px' }}>
                            {item?.icon}
                            <div style={{ fontSize: '14px', color: getSelectedTabColor(item?.key, '#2B3445') }}>{item?.menuName}</div>
                        </div>
                        <div style={{ fontSize: '14px', color: getSelectedTabColor(item?.key, '#2B3445') }}>{item?.menuValue}</div>
                    </div >
                )
            })
        )
    }

    const renderAccountMenu = () => {
        const AccountsMenu = [
            {
                key: 'profile',
                icon: <PersonIcon style={{ width: '0.8em', height: '0.8em', color: getSelectedTabColor('profile', '#7D879C') }} />,
                menuName: 'Profile Info',
                onClick: () => navigate('/profile')
            },
            {
                key: 'addresses',
                icon: <PlaceIcon style={{ width: '0.8em', height: '0.8em', color: '#7D879C' }} />,
                menuName: 'Addresses',
                onClick: () => {navigate('/addresses') }
            },
            {
                key: 'payment-methods',
                icon: <PaymentIcon style={{ width: '0.8em', height: '0.8em', color: '#7D879C' }} />,
                menuName: 'Payment Methods',
                onClick: () => {navigate('/payment-methods') }
            }
        ];
        return (
            AccountsMenu?.map((item) => {
                return (
                    <div
                        className='d-flex justify-content-between'
                        style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem', marginBottom: '1.25rem', cursor: 'pointer' }}
                        onClick={item?.onClick}
                    >
                        <div className='d-flex align-items-center' style={{ gap: '8px' }}>
                            {item?.icon}
                            <div style={{ fontSize: '14px', color: getSelectedTabColor(item?.key, '#2B3445') }}>{item?.menuName}</div>
                        </div>
                        <div style={{ fontSize: '14px', color: '#2B3445' }}>{item?.menuValue}</div>
                    </div>
                )
            })
        )
    }

    const renderPortalData = () => {
        const PortalData = [
            {
                key: 'brain-box',
                icon: <img src={logoY} style={{ width: '20px', height: '20px' }} />,
                menuName: 'Brain Box',
                onClick: () => {
                    if (User && User.isHub && User.hubUser && 'Student' in User.hubUser) {
                        navigate('/my-post')
                    } else {
                        navigate('/hub-register', { state: { affiliateIcon: 'logoY' } });
                    }
                }
            },
            {
                key: 'agri-sure',
                icon: <img src={logoG} style={{ width: '20px', height: '20px' }} />,
                menuName: 'Agri Sure',
                onClick: () => {
                    if (User && User.isHub && User.hubUser && 'Farmer' in User.hubUser) {
                        navigate('/my-post')
                    } else {
                        navigate('/hub-register', { state: { affiliateIcon: 'logoG' } });
                    }
                }
            },
            {
                key: 'xbo',
                icon: <img src={logoV} style={{ width: '20px', height: '20px' }} />,
                menuName: 'XBO',
                onClick: () => {

                    if (User && User.isHub && User.hubUser && 'Market-place' in User.hubUser) {      
                  navigate('/my-post')
                    } else {
                        navigate('/hub-register', { state: { affiliateIcon: 'logoV' } });
                    }
                }
            }
        ];

        return (
            PortalData?.map((item) => {
                return (
                    <div
                        className='d-flex justify-content-between'
                        style={{ paddingLeft: '1.8rem', paddingRight: '1.5rem', marginBottom: '1.25rem', cursor: 'pointer' }}
                        onClick={() => {
                            setSelectedPortalMenu(item?.key);
                            item?.onClick();
                        }}
                    >
                        <div className='d-flex align-items-center' style={{ gap: '8px' }}>
                            {item?.icon}
                            <div style={{ fontSize: '14px', color: getSelectedPortalMenuColor(item?.key, '#2B3445') }}>{item?.menuName}</div>
                        </div>
                        <div style={{ fontSize: '14px', color: '#2B3445' }}>{item?.menuValue}</div>
                    </div>
                )
            })
        )
    }
    return (
        <div style={{ backgroundColor: '#F6F9FC', paddingBottom: '2rem', paddingTop: '2rem' }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={2}>
                    <div style={{
                        backgroundColor: '#ffffff',
                        color: '#2B3445',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: '#03004717 0px 1px 3px',
                        overflow: 'hidden',
                        borderRadius: '8px',
                        paddingBottom: '1.5rem'
                    }}>
                        <Typography style={{ fontSize: '12px', color: '#7D879C', padding: '26px 30px 1rem' }}>DASHBOARD</Typography>
                        {renderMenu()}
                        <Typography style={{ fontSize: '12px', color: '#7D879C', padding: '26px 30px 1rem' }}>ACCOUNT SETTINGS</Typography>
                        {renderAccountMenu()}
                        <Typography style={{ fontSize: '12px', color: '#7D879C', padding: '26px 30px 1rem' }}>URBAN WALLAH PORTAL</Typography>
                        {renderPortalData()}
                    </div>
                </Grid>
                <Grid item xs={8}>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}

export default SideMenuBar;