import React, { useEffect } from 'react';
import SecurityIcon from '@mui/icons-material/Security';

const ReturnPolicy = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className='container mt-5'>
            <div>
                <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '10px' }}>
                    Return Policy
                </div>
                <>
                    <p className="p1">1. Introduction</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p3">
                        1.1. This Return Policy governs the process and conditions for returning
                        products purchased from Bharat UrbanWallah Pvt Ltd ("we", "our", or "us")
                        through our website <span className="s1">www.urbanwallah.com</span>
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        1.2. By purchasing Products from us, you agree to be bound by this Return
                        Policy. If you do not agree with any part of this Return Policy, do not
                        purchase Products from us.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">2. Eligibility for Returns</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        2.1. You may be eligible to return a Product if you meet one or more of the
                        following criteria:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        defective or damaged upon receipt.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The wrong
                        Product was delivered.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product
                        received does not match the description on our Website.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- You change
                        your mind or are not satisfied with the Product (subject to the conditions
                        outlined in Section 3).
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">2.2. To be eligible for a return, you must:</p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Notify us of
                        your intent to return the Product within [number] days of receiving it.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Return the
                        Product to us in its original condition and packaging, along with proof of
                        purchase.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">3. Non-Returnable Items</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">3.1. The following items are not eligible for returns:</p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Products that
                        have been used, altered, or damaged after receipt.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Products that
                        are not in their original condition or packaging.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Products that
                        are personalized or made-to-order.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Digital
                        products, such as downloadable software or electronic gift cards.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Perishable
                        goods, such as food or flowers.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">4. Return Process</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.1. To initiate a return, please contact us at [Your Contact Information]
                        and provide the following information:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Order number
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Reason for the
                        return
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Details of the
                        Product to be returned
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.2. Once your return request is received and approved, we will provide you
                        with instructions for returning the Product to us.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.3. You are responsible for the cost of return shipping, unless the return
                        is due to our error or the Product is defective or damaged upon receipt.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.4. Returns must be shipped back to us within [number] days of receiving
                        authorization for the return.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">5. Refund or Exchange</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        5.1. Upon receiving the returned Product and verifying its condition, we
                        will process your return according to your preference:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Refund: We
                        will issue a refund for the purchase price of the returned Product,
                        excluding any shipping charges, to the original payment method used for the
                        purchase.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Exchange: We
                        will provide you with a replacement Product of equal value, subject to
                        availability.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        5.2. Refunds will be processed within [number] days of receiving the
                        returned Product. The refund will be issued to the original payment method
                        used for the purchase, unless otherwise agreed upon.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">6. Return Exceptions</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        6.1. We reserve the right to refuse returns in the following situations:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned after the specified return period has expired.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        not returned in its original condition or packaging.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned without proof of purchase.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned without prior authorization from us.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">7. Contact Us</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p3">
                        7.1. If you have any questions or concerns about our Return Policy, please
                        contact us at{" "}
                        <a href="mailto:info@urbanwallah.com">
                            <span className="s1">info@urbanwallah.com</span>
                        </a>
                    </p>
                </>
            </div>
        </div>
    )
}

export default ReturnPolicy;