import React, { useEffect, useState } from 'react';
import { Typography, Paper, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const Addresses = () => {
  const [addresses, setAddresses] = useState({
    shippingAddresses: [],
    affiliateAddress: '',
    studentAddress: '',
    farmerAddress: '',
    marketplaceAddress: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      const token = localStorage.getItem('@token');
      setLoading(true);

      try {
        const response = await axios.get('http://localhost:5002/api/v1/customers/secure/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data?.success) {
          const customerData = response.data.data.customer;

          // Extracting the necessary addresses
          const shippingAddresses = customerData.addresses.map((addr) => addr.formatted_address_shipping);
          const affiliateAddress = customerData.affiliate
            ? `${customerData.affiliate.addressLine1}, ${customerData.affiliate.addressLine2}, ${customerData.affiliate.district}, ${customerData.affiliate.state}, ${customerData.affiliate.pincode}`
            : '';

          const studentAddress = customerData.hubUser?.Student
            ? `Student Address: ${customerData.hubUser.Student}`
            : '';
          const farmerAddress = customerData.hubUser?.Farmer
            ? `Farmer Address: ${customerData.hubUser.Farmer}`
            : '';
          const marketplaceAddress = customerData.hub
            ? `${customerData.hub.addressLine1}, ${customerData.hub.addressLine2}, ${customerData.hub.district}, ${customerData.hub.state}, ${customerData.hub.pincode}`
            : '';

          setAddresses({
            shippingAddresses,
            affiliateAddress,
            studentAddress,
            farmerAddress,
            marketplaceAddress,
          });
        } else {
          setError('Failed to fetch addresses');
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
        setError('An error occurred while fetching addresses');
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;


  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Addresses
      </Typography>

      {addresses.shippingAddresses.length > 0 ? (
        addresses.shippingAddresses.map((address, index) => (
          <Paper elevation={3} key={index} style={{ marginBottom: '1rem' }}>
            <Box p={2}>
              <Typography variant="body1">{address}</Typography>
            </Box>
          </Paper>
        ))
      ) : (
        <Typography>No addresses found.</Typography>
      )}

{/* 
      {addresses.affiliateAddress && (
        <Paper elevation={3} style={{ marginBottom: '1rem' }}>
          <Box p={2}>
            <Typography variant="h6">Affiliate Address</Typography>
            <Typography variant="body1">{addresses.affiliateAddress}</Typography>
          </Box>
        </Paper>
      )}

      {addresses.studentAddress && (
        <Paper elevation={3} style={{ marginBottom: '1rem' }}>
          <Box p={2}>
            <Typography variant="h6">Student Address</Typography>
            
            <Typography variant="body1">{addresses.studentAddress}</Typography>
          </Box>
        </Paper>
      )}

      {addresses.farmerAddress && (
        <Paper elevation={3} style={{ marginBottom: '1rem' }}>
          <Box p={2}>
            <Typography variant="h6">Farmer Address</Typography>
            <Typography variant="body1">{addresses.farmerAddress}</Typography>
          </Box>
        </Paper>
      )}

      {addresses.marketplaceAddress && (
        <Paper elevation={3} style={{ marginBottom: '1rem' }}>
          <Box p={2}>
            <Typography variant="h6">Marketplace Address</Typography>
            <Typography variant="body1">{addresses.marketplaceAddress}</Typography>
          </Box>
        </Paper>
      )} */}
    </div>
  );
};

export default Addresses;
