import { Box, Modal, TextField } from '@mui/material';
import React, { useState } from 'react';
import { makeActivateCouponsApi, makeGetOfflineAffiliateApi, makeSettlement } from './redux/AffiliateAction';
import { useDispatch } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '2rem',
    borderRadius: '10px'
};

const NewSettlementForm = ({ openModal, modalOnClose }) => {
    const [hoverButton, setHoverButton] = useState(false);
    const [amount, setAmount] = useState('0');

    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        setAmount(e.target.value)
    }

    return (
        <Modal
            open={openModal}
            onClose={modalOnClose}
        >
            <Box sx={style}>
                <div style={{ fontSize: '20px', color: '#23c2f2', fontWeight: 700 }}>
                    New Settlement
                </div>
                <div className='my-4'>
                    <TextField
                        label="Enter Amount to settle"
                        variant="outlined"
                        name='amount'
                        type="number"
                        fullWidth
                        className='mb-4'
                        value={amount}
                        onChange={handleOnChange}
                    />
                    <div className='text-center'>
                        <button
                            className="btn py-2 mt-4"
                            style={{
                                backgroundColor: hoverButton ? '#23c2f2' : '#fff',
                                color: hoverButton ? '#fff' : '#23c2f2',
                                border: '1px solid #23c2f2',
                                borderRadius: '4px',
                                width: 200,
                                fontWeight: 600
                            }}
                            onMouseEnter={() => setHoverButton(true)}
                            onMouseLeave={() => setHoverButton(false)}
                            onClick={() =>{ makeSettlement(dispatch, amount)
                                modalOnClose();
                            }
                                
                            }
                        >
                            Make Payment
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default NewSettlementForm;