import React from 'react';
import { Avatar, Card, Grid } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
    const navigate = useNavigate();

    const cardData = [
        {
            bgImg: 'https://blog.myntra.com/wp-content/uploads/2024/03/Banner-002.jpg',
            date: 'May 2, 2024',
            title: "Taking a Trip Down EORS Memory Lane",
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            bgImg: 'https://blog.myntra.com/wp-content/uploads/2024/04/Myntra-Trend-Index-Banner-image.jpg',
            date: 'Apr 21, 2024',
            title: "Spotlight on Style: Trend Index Report Maps India’s Latest Fashion Trends",
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            bgImg: 'https://blog.myntra.com/wp-content/uploads/2024/05/EORS-20-banner-1.jpg',
            date: 'Aug 19, 2024',
            title: 'Beat the Heat in Style with Trendy Summer Outfit Ideas',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            bgImg: 'https://blog.myntra.com/wp-content/uploads/2024/04/Banner-6.jpg',
            date: 'Jun 11, 2024',
            title: 'Sneakerheads at Share Their Go-To Sneakers for Every Scene!',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            bgImg: 'https://blog.myntra.com/wp-content/uploads/2024/03/myntra-banner.jpg',
            date: 'Jul 12, 2024',
            title: 'Replenish Your Glow: Pre & Post Holi Skincare & Haircare Tips',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            bgImg: 'https://blog.myntra.com/wp-content/uploads/2024/05/EORS-20-banner-1.jpg',
            date: 'May 6, 2024',
            title: 'Beat the Heat in Style with Trendy Summer Outfit Ideas',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
    ];

    const renderBanner = () => {
        return (
            <div>
                <img src={require('../../assets/banner/blog-hero-img.png')} style={{ width: '100%' }} />
            </div>
        )
    }

    const renderBlogCard = () => {
        return (
            <Grid container spacing={5}>
                {cardData.map(item => {
                    return (
                        <Grid item xs={6}>
                            <Card 
                                style={{ borderRadius: '6px', cursor: 'pointer' }}
                                onClick={() => { navigate(`/blog/${item.title}`, { state: { data: item } }) }}
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${item.bgImg})`,
                                        backgroundSize: 'cover', // Ensures the image covers the entire element
                                        backgroundPosition: 'center', // Centers the image
                                        height: '30vh',
                                        width: '100%',
                                    }}
                                />
                                <div className='px-4' style={{height: '200px',overflow:'scroll'}}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-3'>
                                        <div style={{ fontSize: '18px', fontWeight: 600, color: '#23c2f2', lineHeight: 1.33 }}>
                                            {item.date}
                                        </div>
                                        <div>
                                            <Avatar style={{ backgroundColor: '#22BA8D', width: '30px', height: '30px' }}>
                                                <ShareIcon style={{ color: '#fff', width: '20px', height: '20px' }} />
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div style={{ margin: '15px 0 15px' }}>
                                        <h4 style={{ fontSize: '18px', fontWeight: '700', color: '#353a50' }}>{item.title}</h4>
                                    </div>
                                    <div style={{ margin: '10px 0 20px' }}>
                                        <div style={{ fontSize: '15px', fontWeight: '400', color: '#353a50' }}>{item.description}</div>
                                    </div>
                                </div>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    return (
        <div className='px-5 pt-5'>
            <div className='mb-5'>
                {renderBanner()}
            </div>
            {renderBlogCard()}
            <div className='mt-5' style={{ textAlign: 'center' }}>
                <button className='px-3 py-2' style={{ border: 'none', backgroundColor: '#2ABDF0', fontWeight: 600, fontWeight: '14px', borderRadius: '5px' }}>
                    View More
                </button>
            </div>
        </div>
    )
}

export default Blog