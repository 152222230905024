import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { getMethod } from "../helpers";
import LoginModal from "./LoginModal";
import PurchaseCouponForm from "../Views/Coupons/PurchaseCouponForm";
import { Badge, Menu, MenuItem, Stack, AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, Divider, Dialog, DialogTitle, DialogContent } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { setScreenTypeReducer } from "../Views/Home/redux/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import menuDotIcon from '../assets/icons/9dots.svg';
import { makeGetCartApi } from "../Views/Cart/redux/CartAction";
import SearchComponent from "./SearchComponent";
import { useLocation } from 'react-router-dom';
import SearchDropdownComponent from "./SearchDropDown";

import ExperienceIcon from '../assets/icons/exp.svg';
import UrbanTicketIcon from '../assets/icons/UT.svg';
import AccountHeaderIcon from '../assets/icons/account1.svg';
import CartHeaderIcon from '../assets/icons/cartHeaderIcon.svg';

import CommerceIcon from '../assets/AppLogo/logoB.svg';
import BrainBoxIcon from '../assets/AppLogo/logoY.svg';
import AgriSureIcon from '../assets/AppLogo/logoG.svg';
import XBOIcon from '../assets/AppLogo/logoV.svg';
import AffiliateIcon from '../assets/AppLogo/logoO.svg';
import SellerIcon from '../assets/AppLogo/logoDB.svg';
import OffAffiliateIcon from '../assets/AppLogo/logoO.svg';
import CashIcon from '../assets/icons/walletIcon.svg';
import WalletIcon from '../assets/icons/safe.svg';
import WinnersIcon from '../assets/icons/winnerListIcon.svg';
import SupportIcon from '../assets/icons/customer-service.svg';
import MerchandiseIcon from '../assets/icons/cartIcon.svg';

import { styled } from '@mui/material/styles';
import { storeProductSearchData } from "../Views/Products/redux/ProductAction";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: -10,
        backgroundColor: '#2ABDF0',
        fontWeight: 'bolder',
        fontSize: '10px',
        color: '#FFFFFF'
    },
}));

const NewHeader = ({ screenName, children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    const [anchorEl, setAnchorEl] = useState(null);
    const [searchAnchorEl, setSearchAnchorEl] = useState(null);
    const [profileEl, setProfileEl] = useState(null);
    const [categoryEl, setCategoryEl] = useState(null);
    const [vendorEl, setVendorEl] = useState(null);
    const [showSignin, setShowSignin] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [isTabsVisible, setIsTabsVisible] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const [searchDataList, setSearchDataList] = useState([]);
    const [searchData, setSearchData] = useState(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const searchRef = useRef(null);

    const handleClose = () => setShowSignin(false);
    const handleShow = () => setShowSignin(true);

    const [showPurchaseCoupon, setShowPurchaseCoupon] = useState(false)
    const [value, setValue] = useState(0);

    const [screenType, setScreenType] = useState('Home');
    const cartData = useSelector(state => state.cartState.cartData);

    useEffect(() => {
        makeGetCartApi(dispatch);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const headerHeight = document.getElementById('sticky-header')?.clientHeight || 0;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            setIsTabsVisible(scrollTop <= headerHeight);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (searchData) {
            const fetchSearchResult = async () => {
                try {
                    let url = `products/list_products?search=${searchData}`;
                    let token = localStorage.getItem("@token");
                    let response = await getMethod({ url, token });
                    if (response.success) {
                        if (response.data.length > 0) {
                            setSearchDataList(response.data);
                            dispatch(storeProductSearchData(response.data));
                            setDropdownVisible(true);
                        } else {
                            setSearchDataList([]);
                            dispatch(storeProductSearchData([]));
                            setDropdownVisible(false);
                        }
                    } else {
                        setSearchDataList([])
                    }
                } catch (error) {
                    console.log("Error:", error);
                }
            }
            const timeoutId = setTimeout(fetchSearchResult, 100);
            return () => clearTimeout(timeoutId);
        } else {
            setSearchDataList([]);
            setDropdownVisible(false);
        }
    }, [searchData])


        const getHeaderBackgroundColor = () => {
            let gradient = 'linear-gradient(to bottom, #2DBDEE 0%, #70DBFF 100%);'; // Default gradient
            if (location.pathname.includes('farmer')) {
                gradient = 'linear-gradient(to top, #99e0b8 0%, #20E492 100%)';
            } else if (location.pathname.includes('student')) {
                gradient = 'linear-gradient(to bottom, #F5DE54 0%, #FDF2AF 100%)';
            } else if (location.pathname.includes('market-place')) {
                gradient = 'linear-gradient(to top, #d9bcf5 0%, #AB68FE 100%)';
            } else if (location.pathname.includes('affiliate')) {
                gradient = 'linear-gradient(to bottom, #FA8A5C 0%, #FEB090 100%)';
            }
            return gradient;
        };

    const handleChange = (event, index) => {
        setValue(index);
        if (index === 0) {
          navigate("/urban-ticket");
        } else if (index === 1) {
          navigate("/cart");
        } else if (index === 2) {
          if (!User) {
            handleShow(); // Show login modal
          } else {
            navigate("/profile");
          }
        } else if (index === 3) {
          setIsClicked(true);
          setAnchorEl(event.currentTarget);
          if (window.innerWidth < 600) {
            setDrawerOpen(true);
          } else {
            setOpenModal(true);
          }
        }
      }
    
    useEffect(() => {
        getCategoriesList();
    }, []);
    

    const getCategoriesList = async () => {
        try {
            let url = "common/categories";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                setCategoriesList(response.data);
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const headerContent = [
        {
            label: "Today's Winners List",
            onNavigate: () => navigate('/today-winners')
        },
        {
            label: "Become a Affiliate",
            onNavigate: () => navigate('/affiliate')
        },
        {
            label: "Become a Hub",
            onNavigate: () => navigate('/hub')
        },
    ];

    const profileData = [
        {
            label: 'Profile',
            onNavigate: () => {
                navigate('/profile')
                handleProfileClose();
            }
        },
        {
            label: 'Logout',
            onNavigate: () => {
                localStorage.clear();
                navigate("/");
                window.location.reload();
                handleProfileClose();
            }
        }
    ];

const handleMenuClick = (event) => {
        setIsClicked(true);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuOnChange = (event) => {
        setSearchData(event.target.value);
    }

    const handleSearchMenuClick = async (event) => {
        if (event.currentTarget) {
            setSearchAnchorEl(event.currentTarget);
        }
        if (searchData) {
            try {
                let url = `products/list_products?search=${searchData}`;
                let token = localStorage.getItem("@token");
                let response = await getMethod({ url, token });
                if (response.success) {
                    setSearchDataList(response.data);
                } else {
                    setSearchDataList([])
                }
            } catch (error) {
                console.log("Error:", error);
            }
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setIsClicked(false);
        setOpenModal(false);
    };

    const handleCartClick = () => {

    }

    const handleProfileClose = () => {
        setProfileEl(null);
    }

    const serviceData = [
        {
            title: 'Commerce',
            icon: CommerceIcon,
            onClick: () => navigate('/')
        },
        {
            title: 'Brain Box',
            icon: BrainBoxIcon,
            onClick: () => navigate('/hub/student')
        },
        {
            title: 'Agri Sure',
            icon: AgriSureIcon,
            onClick: () => navigate('/hub/farmer')
        },
        {
            title: 'XBO',
            icon: XBOIcon,
            onClick: () => navigate('/hub/market-place')
        },
        {
            title: 'Affiliate',
            icon: AffiliateIcon,
            onClick: () => navigate('/affiliate', { state: { affiliateType: 'Online' } })
        },
        {
            title: 'Seller',
            icon: SellerIcon,
            onClick: () => navigate('/')
        },
        {
            title: 'Off Affiliate',
            icon: OffAffiliateIcon,
            onClick: () => navigate('/affiliate', { state: { affiliateType: 'Offline' } })
        },
    ];

    const appsData = [
        {
            title: 'Cash',
            icon: CashIcon,
            onClick: () => { }
        },
        {
            title: 'Wallet',
            icon: WalletIcon,
            onClick: () => { }
        },
        {
            title: 'Winners',
            icon: WinnersIcon,
            onClick: () => navigate('/today-winners')
        },
        {
            title: 'Support',
            icon: SupportIcon,
            onClick: () => { }
        },
        {
            title: 'Merchandise',
            icon: MerchandiseIcon,
            onClick: () => { }
        }
    ];

    const tabData = [
        {
            label: '',  
            iconName: UrbanTicketIcon,
            key: 'UB',
          },
        {
          label: '',
          iconName: CartHeaderIcon,
          key: 'Cart',
        },
        {
          label: User ? User?.firstName : 'Login',
          key: 'Login',
        },
        {
          label: '',
          iconName: menuDotIcon,
          key: 'Menu'
        }
      ];

    const headerTabData = [
        {
            label: 'Who we are',
        },
        {
            label: 'Our business',
        },
        {
            label: 'Announcements',
        },
        {
            label: 'Join Us',
        },
        {
            label: 'Contact us',
        }
    ];

    const handleScreenType = (type) => {
        setScreenType(type);
        setScreenTypeReducer(type, dispatch);
    }

    const handleSearchListOnClick = (item) => {
        if (item) {
            navigate(`/productDetails/${item?.subcategoryUuid}/${item.productUuid}`)
        }
        setSearchAnchorEl(null);
        setSearchData(null);
    };

    const handleDropdownClose = () => {
        setDropdownVisible(false);
    }

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {/* Profile Section */}
            <List>
                {profileData.filter(item => item.label !== 'Logout').map((item, index) => (
                    <ListItem button key={index} onClick={() => item.onNavigate()}>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            
            {/* Service Data Section */}
            <List>
                {serviceData.map((item, index) => (
                    <ListItem button key={index} onClick={() => item.onClick()}>
                        <img src={item.icon} style={{ width: '25px', height: '25px', marginRight: 10 }} alt="icon" />
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            
            {/* Apps Data Section */}
            <List>
                {appsData.map((item, index) => (
                    <ListItem button key={index} onClick={() => item.onClick()}>
                        <img src={item.icon} style={{ width: '25px', height: '25px', marginRight: 10 }} alt="icon" />
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            
            {/* Logout Section */}
            <List>
                {profileData.filter(item => item.label === 'Logout').map((item, index) => (
                    <ListItem button key={index} onClick={() => item.onNavigate()}>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    
    
    <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
    >
        {drawerList()}
    </Drawer>
    

    return (
        <div>
            <style>
                {`
                    .css-1bbl089 {justify-content: space-evenly;}
                `}
            </style>
            <AppBar 
  position="sticky" 
  sx={{ 
    background: getHeaderBackgroundColor(),
    boxShadow: 'none'
  }}
>
  <Toolbar className="header-toolbar" sx={{ minHeight: { xs: 56, md: 80 }, flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'space-between' }}>
    {/* Mobile view */}
    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', width: '100%' }}>
      {/* Top row with hamburger menu, logo, and cart */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <IconButton
          edge="start"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon sx={{ color: 'black' }} />
        </IconButton>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={require("../assets/AppLogo/Logo_UW.png")}
            alt="app-logo"
            style={{ width: '140px', height: '36px', cursor: 'pointer', marginTop: '10px' }}
            className="header-logo"
            onClick={() => navigate("/")}
          />
        </Box>

        <IconButton
          color="inherit"
          onClick={(e) => handleChange(e, 3)}
          className="header-cart"
        >
          {cartData.orderItems?.length > 0 ? (
            <StyledBadge badgeContent={cartData.orderItems?.length} color="error">
              <img src={CartHeaderIcon} style={{ width: '25px', height: '25px' }} alt="cart-icon" />
            </StyledBadge>
          ) : (
            <img src={CartHeaderIcon} style={{ width: '25px', height: '25px' }} alt="cart-icon" />
          )}
        </IconButton>
      </Box>


      {/* Search bar below logo */}
      {screenName !== 'Login' && screenName !== 'Register' && (
        <Box 
          sx={{ 
            position: 'relative', 
            width: '100%',
            padding: '8px 16px',
            marginTop: '8px',
          }} 
          ref={searchRef} 
          className="header-search"
        >
          <SearchComponent searchData={searchData} handleMenuOnChange={handleMenuOnChange} />
        </Box>
      )}
    </Box>

    {/* Desktop view - Keep exactly as it was */}
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',

        marginRight: '65px', 
        marginLeft: '50px',

        marginRight: '50px', 
        marginLeft: '30px',

        
      }}
    >
      {/* Logo on the left */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={require("../assets/AppLogo/Logo_UW.png")}
          alt="app-logo"
          style={{ width: '140px', height: '36px', cursor: 'pointer' }}
          className="header-logo"
          onClick={() => navigate("/")}
        />
      </Box>

      {/* Search bar in the center */}
      {screenName !== 'Login' && screenName !== 'Register' && (
        <Box 
          sx={{ 
            position: 'relative', 
            width: { md: '450px', lg: '500px', xl: '600px' },
            borderRadius: '100px',
            marginLeft: '-130px', 
            
          }} 
          ref={searchRef} 
          className="header-search"
        >
          <SearchComponent searchData={searchData} handleMenuOnChange={handleMenuOnChange} />
        </Box>
      )}


<Stack direction={'row'} spacing={4} alignItems={'center'} gap={'15px'}>
  {tabData.map((item, index) => (
    <Stack key={index} spacing={2} direction={'row'} style={{ cursor: 'pointer' }} alignItems={'center'} onClick={(e) => handleChange(e, index)}>
      {item.iconName && (
        item.key === 'Cart' && cartData.orderItems?.length > 0 ? (
          <Badge badgeContent={cartData.orderItems?.length} color="error">
            <img src={item.iconName} style={{ width: '25px', height: '22px' }} alt={`${item.key}-icon`} />
          </Badge>
        ) : (
          <img 
            src={item.iconName} 
            style={{ 
              opacity: item.key === 'Menu' && isClicked ? 0.3 : 1, 
              width: item.key === 'Menu' ? '22px' : '25px', 
              height: item.key === 'Menu' ? '20px' : '28px' 
            }} 
            alt={`${item.key}-icon`}
          />
        )
      )}
      {item.label && (
        <div style={{ fontWeight: 600, textTransform: 'capitalize', fontSize: '15px', color: '#000' }}>
          {item.label}
        </div>
      )}
    </Stack>
  ))}
</Stack>
</Box>
</Toolbar>

</AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {drawerList()}
            </Drawer>
            <Dialog
                open={openModal}
                onClose={handleMenuClose}
                sx={{ position: 'absolute', top: 0, right: 0 }}
                PaperProps={{
                    style: { margin: 0, maxHeight: '90vh', width: '300px', position: 'fixed', top: '10px', right: '10px' },
                }}
            >
                <DialogTitle>Menu</DialogTitle>
                <DialogContent>
                    <div className="px-2 py-4">
                        <div>
                            <div style={{ fontSize: '12px', fontWeight: '600' }} className="px-2">
                                Services
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }} className="mt-2 px-2">
                                {serviceData.map((item, i) => {
                                    return (
                                        <MenuItem key={i} onClick={() => {
                                            handleMenuClose();
                                            item.onClick()
                                        }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={item.icon} style={{ width: '42px', height: '42px' }} />
                                                <div className="mt-3" style={{ fontSize: '10px', fontWeight: '600' }}>{item.title}</div>
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mt-3">
                            <div style={{ fontSize: '12px', fontWeight: '600' }} className="px-2">
                                Apps
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }} className="mt-2">
                                {appsData.map((item, i) => {
                                    return (
                                        <MenuItem key={i} onClick={() => {
                                            handleMenuClose();
                                            item.onClick()
                                        }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={item.icon} style={{ width: '60px', height: '40px' }} />
                                                <div className="mt-3" style={{ fontSize: '10px', fontWeight: '600' }}>{item.title}</div>
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {isDropdownVisible && ReactDOM.createPortal(
    <div
        style={{
            position: 'absolute',
            top: searchRef.current?.getBoundingClientRect().bottom + 'px',
            left: searchRef.current?.getBoundingClientRect().left + 'px',
            width: searchRef.current?.offsetWidth + 'px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 101,
            borderRadius: '4px',
            // Adjustments for mobile view
            maxWidth: window.innerWidth <= 768 ? '100vw' : 'auto',
            left: window.innerWidth <= 768 ? '0' : searchRef.current?.getBoundingClientRect().left + 'px',
            right: window.innerWidth <= 768 ? '0' : 'auto',
            width: window.innerWidth <= 768 ? '100vw' : searchRef.current?.offsetWidth + 'px',
        }}
        className="header-dropdown"
    >
        <SearchDropdownComponent
            searchDataList={searchDataList}
            isDropdownVisible={isDropdownVisible}
            searchData={searchData}
            resetSearchData={() => setSearchData('')}
            handleDropdownClose={handleDropdownClose}
        />
    </div>,
    document.body
)}

            {children}
            <PurchaseCouponForm modalVisible={showPurchaseCoupon} modalOnClose={() => setShowPurchaseCoupon(false)} />
            <LoginModal
                showSignin={showSignin}
                setShowSignin={setShowSignin}
                handleClose={handleClose}
                handleShow={handleShow}
            />
        </div>
    );
}
export default NewHeader;
//mobile//2 