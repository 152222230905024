import { ComplaintActionTypes } from "./ComplaintActionType";

const INITIAL_STATE = {
  complaintDataList: [],
  updatedComplaintData: {}
};

export const ComplaintReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ComplaintActionTypes.GET_ALL_COMPLAINT_LIST:
      return { 
        ...state, 
        complaintDataList: action.payload 
      };
    case ComplaintActionTypes.UPDATE_COMPLAINT_LIST:
      return { 
        ...state, 
        updatedComplaintData: action.payload 
      };
    default:
      return state;
  }
};