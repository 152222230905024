import React, { useEffect } from 'react';
import SecurityIcon from '@mui/icons-material/Security';

const ShippingPolicy = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className='container mt-5'>
            <div>
                <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '10px' }}>
                    Shipping Policy
                </div>
                <>
                    <p className="p1">
                        Thank you for your interest in our products! This shipping policy outlines
                        the details of how we get your orders to you.
                    </p>
                    <p className="p1">
                        <strong>Shipping Methods and Costs</strong>
                    </p>
                    <p className="p1">
                        We offer a variety of shipping methods to meet your needs and budget. You
                        will see the available shipping options and their associated costs during
                        checkout. These costs are based on the weight and size of your order, as
                        well as your delivery destination.
                    </p>
                    <ul className="ul1">
                        <li className="li1">
                            <strong>Standard Shipping:</strong> This is our most economical option,
                            with delivery within 4 to 6 business days.
                        </li>
                        <li className="li1">
                            <strong>Expedited Shipping:</strong> For a faster delivery, choose
                            expedited shipping with delivery within 2 to 3 business days.
                        </li>
                        <li className="li1">
                            <strong>Premium Shipping Option:</strong> Our fastest shipping option,
                            with delivery within 1 to 2 business days.
                        </li>
                    </ul>
                    <p className="p1">
                        <strong>Order Processing</strong>
                    </p>
                    <p className="p1">
                        Orders are typically processed within 2 to 3 business days. Once your order
                        is shipped, you will receive a notification with a tracking number so you
                        can follow your package's progress.
                    </p>
                    <p className="p1">
                        <strong>Delivery Times</strong>
                    </p>
                    <p className="p1">
                        The delivery times listed above are estimates and may vary depending on your
                        location and other factors. We are not responsible for delays caused by
                        carriers or unforeseen circumstances.
                    </p>
                    <p className="p1">
                        <strong>Free Shipping</strong>
                    </p>
                    <p className="p1">
                        We may offer free shipping promotions from time to time. These promotions
                        will be clearly advertised on our website and will have specific terms and
                        conditions.
                    </p>
                    <p className="p1">
                        <strong>International Shipping</strong>
                    </p>
                    <p className="p1">
                        We currently only ship to domestic addresses within India.
                    </p>
                    <p className="p1">
                        <strong>Shipping Restrictions</strong>
                    </p>
                    <p className="p1">
                        There may be certain restrictions on shipping some items to certain
                        destinations. We will notify you of any restrictions at checkout.
                    </p>
                    <p className="p1">
                        <strong>Missing or Lost Packages</strong>
                    </p>
                    <p className="p1">
                        If your package is marked as delivered but you have not received it, please
                        contact us immediately. We will work with the carrier to locate your
                        package.
                    </p>
                    <p className="p1">
                        <strong>Changes to Shipping Policy</strong>
                    </p>
                    <p className="p1">
                        We reserve the right to modify this shipping policy at any time. We will
                        post any changes on our website.
                    </p>
                    <p className="p1">
                        <strong>Contact Us</strong>
                    </p>
                    <p className="p1">
                        If you have any questions about our shipping policy, please contact us at
                        +91 9567805030 or info@urbanwallah.com.
                    </p>
                    <p className="p1">
                        <strong>Additional Notes</strong>
                    </p>
                    <ul className="ul1">
                        <li className="li1">
                            You can customize this policy to include specific details about your
                            business, such as your carriers and any special handling instructions.
                        </li>
                        <li className="li1">
                            Be sure to keep your shipping policy up-to-date, especially if you change
                            your shipping rates or methods.
                        </li>
                        <li className="li1">
                            Consider offering a visual representation of your shipping rates and
                            delivery times, such as a shipping chart.
                        </li>
                    </ul>
                </>
            </div>
        </div>
    )
}

export default ShippingPolicy;