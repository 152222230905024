import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from 'antd';
import { getMethod, postMethod } from '../../helpers';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '2rem 3rem',
    borderRadius: '10px'
};

const JoinPoolForm = ({
    modalVisible,
    modalOnClose,
    poolId,
    handleTimer
}) => {
    const [hoverButton, setHoverButton] = useState(false);
    const [couponCode, setCouponCode] = useState("");

    const handleOnChange = (e) => {
        setCouponCode(e.target && e?.target?.value ? e.target.value : '');
    }

    const onSubmit = async () => {
        let payload = {
            couponCode
        };
        try {
            let url = `customers/secure/pool/join/${poolId}`;
            const token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload });
            if (response.success) {
                toast.success(response.message);
                handleTimer(true);
                modalOnClose();
            } else {
                handleTimer(false);
                toast.error(response.message);
            }
        } catch (e) {
            handleTimer(false);
            toast.error(e.response.message);
        }
    }

    return (
        <>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: "green",
                            secondary: "#000",
                        },
                    },
                    error: {
                        duration: 3000,
                        theme: {
                            primary: "red",
                            secondary: "#fff",
                        },
                    }
                }}
            />
            <Modal
                open={modalVisible}
                onClose={modalOnClose}
            >
                <Box sx={style}>
                    <div style={{ fontSize: '20px', color: '#23c2f2', fontWeight: 700 }}>
                        Join the Pool
                    </div>
                    <div className='my-4'>
                        <TextField
                            label="Coupon Code"
                            variant="outlined"
                            name='couponCode'
                            fullWidth
                            className='mb-4'
                            value={couponCode}
                            onChange={handleOnChange}
                        />
                        <button
                            className="btn py-2"
                            style={{
                                backgroundColor: hoverButton ? '#23c2f2' : '#fff',
                                color: hoverButton ? '#fff' : '#23c2f2',
                                border: '1px solid #23c2f2',
                                borderRadius: '4px',
                                width: 150,
                                fontWeight: 600
                            }}
                            onMouseEnter={() => setHoverButton(true)}
                            onMouseLeave={() => setHoverButton(false)}
                            onClick={onSubmit}
                        >
                            Join Pool
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    )
};

export default JoinPoolForm;