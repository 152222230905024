import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchDropdownComponent = ({ isDropdownVisible, searchDataList, resetSearchData = () => {}, searchData = '', handleDropdownClose }) => {

    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && searchData) {
                navigate('/product-search', { state: { productItem: { productName: searchData } } });
                handleDropdownClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleSearchListOnClick = (item) => {
        if (item) {
            navigate(`/productDetails/${item?.subcategoryUuid}/${item.productUuid}`)
        }
        resetSearchData();
    };

    return (
        <div>
            {isDropdownVisible && searchDataList?.length > 0 && (
                <div
                    className="mt-2"
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        padding: '0px',
                        borderRadius: '10px'
                    }}>
                    {searchDataList.map(result => (
                        <div
                            key={result.id}
                            className="search-item"
                            onClick={() => handleSearchListOnClick(result)}
                            style={{ padding: '6px 15px', margin: '8px 0px', cursor: 'pointer', fontSize: '14px', fontWeight: '600' }}
                        >
                            {result.productName}
                        </div>
                    ))}
                </div>
            )}
            {!isDropdownVisible && searchData && searchDataList?.length === 0
                && <div
                    className="mt-2"
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        padding: '5px',
                        borderRadius: '10px'
                    }}>
                    <div style={{ padding: '6px', cursor: 'pointer', fontSize: '14px', fontWeight: '600', textAlign: 'center' }}>
                        No Result Found
                    </div>
                </div>
            }
        </div>
    )
}

export default SearchDropdownComponent;