export const boxData = [
    {
        title: 'Brain Box',
        color: '#F5DE54',
        icon: 'icon-1-brainBox.svg',
        image: 'brainbox.png',
        screenName: '/hub/student'
    },
    {

        title: 'Agri Sure',
        color: '#3EC87F',
        icon: 'icon-2-agriSure.svg',
        image: 'agrisure.png',
        screenName: '/hub/farmer'
    },
    {

        title: 'XBO',
        color: '#A49CFA',
        icon: 'icon-3-xbo.svg',
        image: 'xbo.png',
        screenName: '/hub/market-place'
    },
    {

        title: 'Affiliate',
        color: '#F69776',
        icon: 'icon-4-affiliate.svg',
        image: 'affiliate.png',
        screenName: '/affiliate'
    }
];

export const boxImgData = [
    {
        logo: 'logoY.svg',
        screenName: '/hub/student'
    },
    {
        logo: 'logoG.svg',
        screenName: 'hub/farmer'
    }, 
    {
        logo: 'logoV.svg',
        screenName: 'hub/market-place'
    },
    {
        logo: 'logoO.svg',
        screenName: '/affiliate'
    }
];