import { TextField } from '@mui/material';
import React, { useMemo } from 'react';

const PaymentCardView = ({ cartInfo, handleNextMove = () => { }, isFromCart }) => {

    const chargesDataArray = useMemo(() => {
        if (cartInfo && cartInfo?.charges && cartInfo?.charges?.length > 0) {
            return cartInfo?.charges;
        }
        return [];
    }, [cartInfo]);

    const renderCharges = () => {
        return chargesDataArray && chargesDataArray?.length > 0 && chargesDataArray?.map((item) => {
            return (
                <div className="cartFormHeader">
                    <span className="total">{item.chargeLabel || ''}</span>
                    <span className="totalAmt">₹{item?.value || 0}</span>
                </div>
            )
        });
    };

    return (
        <div className="col-4 checkoutSummary ms-5" >
            <div style={{ borderBottom: '1px solid rgb(125, 135, 156,0.1)' }} className="mb-2">
                <div className="cartFormHeader">
                    <span className="total">Subtotal:</span>
                    <span className="totalAmt">₹{cartInfo?.subTotal}</span>
                </div>
                {renderCharges()}
                <div className="cartFormHeader">
                    <span className="total">Discount:</span>
                    <span className="totalAmt">-</span>
                </div>
            </div>

            
            <div className="d-flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <h5>Total:</h5>
                <h5>₹{cartInfo?.total}</h5>
            </div>
            {isFromCart
                ? <div className='mt-2'>
                    <div className="d-flex mb-2" style={{ alignItems: 'center' }}>
                        <div style={{ fontSize: '16px', fontWeight: 600 }}>Additional Comments</div>
                        <span className="noteSpan">Note</span>
                    </div>
                    <textarea id="textarea" name="textarea" rows="5" cols="40" style={{ width: '100%' }}></textarea>
                    <div className="pb-4 mt-2" style={{ borderBottom: '1px solid rgb(125, 135, 156,0.1)' }}>
                        <TextField fullWidth label="Voucher" id="voucher" size="small" />
                        <button
                            onClick={null}
                            className="apply_voucher_btn mt-3"style={{borderRadius:'4px'}}
                        >
                            Apply Voucher
                        </button>
                    </div>
                    <button
                        onClick={handleNextMove}
                        className="checkout_red_btn"style={{borderRadius:'4px'}}
                    >
                        Next
                    </button>
                </div>
                : null
            }
        </div>
    )
}

export default PaymentCardView;