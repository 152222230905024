import { DashboardActionTypes } from "./DashboardActionType";

const INITIAL_STATE = {
  screenType: 'Home'
};

export const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.SET_SCREEN_TYPE:
      return { ...state, screenType: action.payload };
    default:
      return state;
  }
};