import { ComplaintActionTypes } from "./ComplaintActionType";

export const getAllComplaintApiAction = (data) => {
    return {
        type: ComplaintActionTypes.GET_ALL_COMPLAINT_LIST,
        payload: data
    }
};

export const updateComplaintAction = (data) => {
    return {
        type: ComplaintActionTypes.UPDATE_COMPLAINT_LIST,
        payload: data
    }
}