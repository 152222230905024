import { getMethod } from "../../../helpers";
import { ProductActionTypes } from "./ProductActionTypes";

export const makeGetCategoryApi = async (dispatch) => {
    try {
        let url = "common/categories";
        const token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        console.log(response);
        if (response.success) {
            dispatch({
                type: ProductActionTypes.SET_CATEGORY_DATA,
                payload: response.data
            });
        } else {
            dispatch({
                type: ProductActionTypes.SET_CATEGORY_DATA,
                payload: []
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const makeGetSubCategoryApi = async (dispatch) => {
    try {
        let url = "subcategories/get_subcategories";
        const token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        if (response.success) {
            dispatch({
                type: ProductActionTypes.SET_SUBCATEGORY_DATA,
                payload: response.data
            });
        } else {
            dispatch({
                type: ProductActionTypes.SET_SUBCATEGORY_DATA,
                payload: []
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export const makeGetProductApi = async (dispatch) => {
    try {
        let url = "products/list_products";
        let response = await getMethod({ url });
        if (response.success) {
            dispatch({
                type: ProductActionTypes.SET_PRODUCT_DATA,
                payload: response.data
            })
        } else {
            dispatch({
                type: ProductActionTypes.SET_PRODUCT_DATA,
                payload: []
            })
        }
    } catch (e) {
        console.log(e);
    }
}

export const storeProductSearchData = (data) => {
    return {
        type: ProductActionTypes.SET_PRODUCT_SEARCH_DATA,
        payload: data
    }
}

export const makeGetAllListApi = async (dispatch) => {
    try {
        let url = "products/listalltypes";
        let response = await getMethod({ url });
        if (response.success) {
            dispatch({
                type: ProductActionTypes.MAKE_ALL_LIST_DATA,
                payload: response.data
            })
        } else {
            dispatch({
                type: ProductActionTypes.MAKE_ALL_LIST_DATA,
                payload: []
            })
        }
    } catch (e) {
        console.log(e);
    }
}