import { OrderActionTypes, ProfileActionTypes } from "./ProfileActionType";

const INITIAL_STATE = {
  profileData: {}
};

export const ProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProfileActionTypes.GET_PROFILE_LIST:
      return { ...state, profileData: action.payload };
    default:
      return state;
  }
};