import { WishlistActionTypes } from './WishlistActionTypes';

const INITIAL_STATE = {
    wishlistData: [],
    wishlistedProductIdList: []
};

export const WishlistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WishlistActionTypes.SET_WISHLIST_DATA:
            return {
                ...state,
                wishlistData: action.payload
            };
        case WishlistActionTypes.STORE_WISHLISTED_PRODUCT_IDS:
            return {
                ...state,
                wishlistedProductIdList: updateWishlistProductBasedById(state.wishlistedProductIdList, action.payload)
            }
        case WishlistActionTypes.REMOVE_WISHLISTED_PRODUCT_BY_ID:
            return {
                ...state,
                wishlistedProductIdList: removeWishlistProductBasedById(state.wishlistedProductIdList, action.payload)
            }
        default:
            return state;
    }
};

const updateWishlistProductBasedById = (wishlistedProductIdList, productUuid) => {
    let newWishlistData = [...wishlistedProductIdList, productUuid];
    localStorage.setItem('localWishlistProductList', JSON.stringify(newWishlistData));
    return newWishlistData;
}

const removeWishlistProductBasedById = (wishlistedProductIdList, payload) => {
    const { productUuid, wishlistIdData } = payload;
    let newWishlistData = [];
    if (wishlistedProductIdList && wishlistedProductIdList.length > 0) {
        newWishlistData = wishlistedProductIdList.filter(productid => productid !== productUuid);
    } else {
        newWishlistData = wishlistIdData.filter(productid => productid !== productUuid);
    }
    localStorage.setItem('localWishlistProductList', JSON.stringify(newWishlistData));
    return newWishlistData;
};