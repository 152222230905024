import { Avatar, Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';

const BlogDetails = () => {
    const location = useLocation();
    const { bgImg, title, date, description } = location.state.data

    useEffect(() => {
        window.scroll(0,0)
    },[]);

    return (
        <div style={{ paddingBottom: '40%' }}>
            <div
                style={{
                    backgroundImage: `url(${bgImg})`,
                    backgroundSize: 'cover', // Ensures the image covers the entire element
                    backgroundPosition: 'center', // Centers the image
                    height: '50vh',
                    width: '100%',
                    position: 'relative'
                }}
            />
            <div style={{ position: 'absolute', bottom: '-40%', width: '100%', display: 'flex', justifyContent: 'center', borderRadius: '10px' }}>
                <Card style={{ padding: '0rem 5rem', width: '80%', borderRadius: '10px',height:'700px', overflow:'scroll' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ fontSize: '32px', width: '50%', color: '#353a50', fontWeight: 700 }}>
                            {title}
                        </div>
                        <div>
                            <Avatar style={{ backgroundColor: '#22BA8D', width: '40px', height: '40px' }}>
                                <ShareIcon style={{ color: '#fff', width: '30px', height: '30px' }} />
                            </Avatar>
                        </div>
                    </div>
                    <div className='my-3' style={{ display: 'flex', color: '#353a50', fontSize: '16px', fontWeight: '600', alignItems: 'center' }}>
                        <div>
                            By Sabarish
                        </div>
                        <div style={{ color: '#23c2f2' }} className='ms-2'>
                            {date}
                        </div>
                    </div>
                    <div style={{ fontSize: '16px', color: '#353a50' }}>
                        {description}
                    </div>
                    <div style={{ fontSize: '16px', color: '#353a50' }} className='mt-2'>
                        Lorem Ipsum is simply dummy text of the Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, 
                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                        Lorem Ipsum is simply dummy text of the Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        Lorem Ipsum is simply dummy text of the Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, 
                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </div>
                    <div className='mt-5'>
                        <img src={bgImg} style={{ width: '100%', height: '40vh' }} />
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default BlogDetails;