import React from 'react';
import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import Check from '@mui/icons-material/Check';

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: ownerState.completed ? '#2DBDEE' : ownerState.active ? '#2DBDEE' : '#fff',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    border: '1px solid #7F7B7B',
    padding: 0
}));

const useStyles = makeStyles((theme) => ({
    stepper: {
        backgroundColor: 'transparent'
    },
    root: {
        marginBottom: '20px',
        padding: 0
    }
}));

const StepperComponent = ({
    stepsData,
    activeStep,
    children
}) => {

    const classes = useStyles();

    const CustomStepIcon = ({ active, completed, className }) => {
        return (
            <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
                {completed ? <Check style={{ color: '#fff', fontSize: '14px' }} /> : null}
            </CustomStepIconRoot>
        );
    };

    return (
        <Grid container spacing={2} className='my-5' justifyContent="center">
            <Grid item xs={12} md={3}>
                <Stepper
                    className={[classes.stepper, classes.root]}
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{
                        '& .MuiStepConnector-line': { minHeight: '45px' },
                        '& .MuiStepLabel-label': { fontSize: '14px' },
                        '& .MuiStepConnector-root': { marginLeft: '9px' },
                        '& .MuiStepLabel-root': { padding: 0 }
                    }}
                >
                    {stepsData.map((label, index) => (
                        <Step key={label} className={classes.step}>
                            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item xs={12} md={4}>
                {children}
            </Grid>
        </Grid>
    )
};

export default StepperComponent;