import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../helpers";

const PoolsList = () => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [poolData, setPoolData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        makePoolListApi()
    }, []);

    const makePoolListApi = async () => {
        try {
            let url = "common/pools";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                setPoolData(response.data)
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }

    let mockObjData = {
        "_id": "65808770a06eb7956c7f83fc",
        "imageUrl": "https://boxappdocuments.s3.ap-south-1.amazonaws.com/product/1702922023250-kurthis.jpg",
    }

    const naviagteToPoolsDetails = (item) => {
        navigate(`/pools/${item._id}`)
    }

    return (
        <Grid container spacing={{ md: 4 }} className="mt-4 pe-5 ps-5 pb-5" style={{ backgroundColor: '#F6F9FC' }}>
            {poolData?.map((item, index) => {
                return (
                    <Grid item md={3}>
                        <Card>
                            <div className='p-4'>
                                <div
                                    style={{
                                        backgroundImage: `url(${item?.poolImage})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        height: '200px',
                                        width: '100%',
                                        marginTop: '20px'
                                    }}
                                />
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ fontSize: '16px', color: '#373F50', fontWeight: 600 }} className='mt-1'>
                                        {item?.poolName}
                                    </div>
                                    <div>
                                        <button
                                            className="btn mt-3"
                                            style={{
                                                backgroundColor: hoverIndex === index ? '#23c2f2' : '#fff',
                                                color: hoverIndex === index ? '#fff' : '#23c2f2',
                                                border: '1px solid #23c2f2',
                                                borderRadius: '4px',
                                                width: 150,
                                                fontWeight: 600
                                            }}
                                            onMouseEnter={() => setHoverIndex(index)}
                                            onMouseLeave={() => setHoverIndex(null)}
                                            onClick={() => naviagteToPoolsDetails(item)}
                                        >
                                            Join Pool
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default PoolsList;