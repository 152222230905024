import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { postMethod } from "../helpers/index";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { storeLoginData } from "../Views/auth/redux/AuthAction";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import NewHeader from "../Components/NewHeaderView";
import NewFooter from "../Components/NewFooter";
import CommonTextField from "../Components/CommonTextField";

function Login() {
  const [eyechange, setEyechange] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const onLogin = async (e) => {
    e.preventDefault();
    try {
      let url = "users/login";
      var payload = {
        emailOrPhoneNumber: email,
        password: password,
        userType: "Customer",
      };
      let response = await postMethod({ url, payload });
      if (response?.success) {
        dispatch(storeLoginData(response?.data));
        localStorage.setItem("@token", response.data.token);
        let user = JSON.stringify(response?.data?.user);
        localStorage.setItem("@USER", user);
        toast.success(response?.message);
        if (location?.state?.isFromPurchaseCouponForm) {
          navigate("/coupon/purchase/" + location?.state?.affiliateCode);
        } else {
          navigate("/");
        }
        setEmail("");
        setPassword("");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error(e.response.message);
      setEmail("");
      setPassword("");
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <NewHeader screenName={'Login'}>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "#000",
              },
            },
            error: {
              duration: 3000,
              theme: {
                primary: "red",
                secondary: "#fff",
              },
            }
          }}
        />
        <div style={{ height: '90vh', display: 'flex' }}>
          <Grid container justifyContent="center" alignItems={'center'}>
            <Grid item md={0.5}  sx={{
              display: {
                xs: 'none'
              }
            }} />
            <Grid item md={4}>
              <Card className="loginSideCard" sx={{ p: 3, px: 5 }}>
                <Col>
                  <h5 style={{ textAlign: 'center' }} className="mb-4">Welcome back</h5>
                  <div>
                    <h6 style={{ color: '#A8A5A5', fontSize: '15px' }}>Let's log you in</h6>
                    <div className="mt-3">
                      <CommonTextField
                        id='email'
                        labelName='Email'
                        name='email'
                        value={email}
                        handleChange={(e) => setEmail(e.target.value)}
                        style={{ marginBottom: '1rem' }}
                      />
                    </div>
                    <CommonTextField
                      id='password'
                      labelName='Password'
                      name='password'
                      value={password}
                      handleChange={(e) => setPassword(e.target.value)}
                      handleClickShowPassword={handleClickShowPassword}
                      handleMouseDownPassword={handleMouseDownPassword}
                      showPassword={showPassword}
                      type='password'
                    />
                    <div className="text-center mt-4">
                      <button onClick={onLogin} className="common_lightbluebtn">
                        Login
                      </button>
                      <div className="mt-3 text-center">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <div style={{ color: '#00000033', marginRight: '5px', fontSize: '13px' }}>Don't have an account yet?</div><span onClick={() => navigate('/register')} style={{ color: '#2DBDEE', fontSize: '13px', cursor: 'pointer' }}>Sign Up</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Card>
            </Grid>
            <Grid item md={1}  sx={{
              display: {
                xs: 'none'
              }
            }}/>
            <Grid item md={4} sx={{
              display: {
                xs: 'none'
              }
            }}>
              <div>
                <div style={{ textAlign: 'center', fontSize: '20px', color: '#3A3A3A' }}>One UrbanWallah Account</div>
                <img alt="loginBanner" src={require('../assets/images/loginBanner.png')} style={{ width: '100%' }} />
              </div>
            </Grid>
            <Grid item md={0.5} />
          </Grid>
        </div>
      </NewHeader>
      <div style={{ height: '40vh' }} />
      <NewFooter screenName={'Login'} />
    </>
  );
}

export default Login;
