import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import { getMethod } from '../../helpers';
import { useParams } from 'react-router-dom';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

const useStyles = makeStyles((theme) => ({
    customSelect: {
        width: '140px',
        height: '30px',
        color: '#000000',
        fontSize: '12px'
    },
}));

const FilterProductsCard = ({ subCategoryList, getViewType }) => {
    const [filterType, setFilterType] = useState('Relevance');
    const [viewType, setViewType] = useState('grid');
    const classes = useStyles();
    const { id } = useParams();
    const [productList, setProductList] = useState([]);

    const getProductList = async () => {
        try {
            let url = "products/list_products";
            let response = await getMethod({ url });
            if (response.success) {
                let filtered = response.data.filter(
                    (item) => item.subcategoryUuid === id
                );
                setProductList(filtered);
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getProductList();
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
                <div style={{ fontSize: '16px', fontWeight: 600 }}>
                    Products
                </div>
                <div style={{ fontSize: '12px', fontWeight: 600, color: '#7D879C' }}>
                    Showing 1-30 of 2200 results
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '12px', color: '#7D879C', fontWeight: 600, }}>
                    Sort by
                </div>
                <div className='mx-3' style={{ color: '#23c2f2', fontSize: '12px', fontWeight: 600 }}>
                    Popularity
                    {/* <Select
                        className={classes.customSelect}
                        labelId="sort"
                        id="sort"
                        value={filterType}
                        label=""
                        onChange={(e) => { setFilterType(e.target.value) }}
                    >
                        <MenuItem value={'Relevance'}>Relevance</MenuItem>
                        <MenuItem value={'Date'}>Date</MenuItem>
                        <MenuItem value={'Price Low to High'}>Price Low to High</MenuItem>
                        <MenuItem value={'Price High to Low'}>Price High to Low</MenuItem>
                    </Select> */}
                </div>
                <div style={{ height: '20px', width: '1px', backgroundColor: '#000', margin: '0 10px' }} className="mx-3"></div>
                <div style={{ fontSize: '12px', color: '#7D879C', fontWeight: 600, }}>
                    Price
                </div>
                <div className='mx-3' style={{ fontSize: '12px', color: '#23c2f2', fontWeight: 600 }}>
                    Low to High
                </div>
                <div style={{ height: '20px', width: '1px', backgroundColor: '#000', margin: '0 10px' }} className="mx-3"></div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <div className='ms-3 me-2'>
                        <GridViewIcon
                            style={{ width: '1em', height: '1em', color: viewType === 'grid' ? '#23c2f2' : '#7D879C' }}
                            onClick={() => {
                                setViewType('grid')
                                getViewType('grid')
                            }}
                        />
                    </div>
                    <div>
                        <ViewHeadlineIcon
                            style={{ width: '1em', height: '1em', color: viewType === 'list' ? '#23c2f2' : '#7D879C' }}
                            onClick={() => {
                                getViewType('list')
                                setViewType('list')
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterProductsCard;