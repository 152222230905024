import React from 'react';
import NewHeader from './NewHeaderView';
import NewFooter from './NewFooter';

const ContainerWrapper = ({ children, screenName }) => {
    return (
        <div>
            <NewHeader screenName={screenName}>
                <div style={{ backgroundColor: '#E9F0F6' }}>
                    {children}
                </div>
            </NewHeader>
            <NewFooter screenName={screenName} />
        </div>
    )
}

export default ContainerWrapper;