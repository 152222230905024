import React, { useCallback, useEffect, useState } from 'react';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Card, Divider, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import SideMenuBar from '../../Components/SideMenuBar';
import NewHeader from '../../Components/NewHeader';
import { getMethod, putMethod } from '../../helpers';
import toast, { Toaster } from 'react-hot-toast';
import { updateComplaintAction } from './redux/ComplaintAction';

const SupportDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [message, setMessage] = useState('');

    const updatedComplaintData = useSelector((state) => state.complaintState.updatedComplaintData);

    useEffect(() => {
        getSelectedComplaintData();
        const intervalId = setInterval(() => {
            getSelectedComplaintData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, [location?.state?.id]);

    const getSelectedComplaintData = async () => {
        try {
            let url = `customers/secure/complaint/${location?.state?.id}`;
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                dispatch(updateComplaintAction(response.data))
            } else {
                toast.error(response.message);
            }
        } catch (e) {
            toast.error(e.response.message);
        }
    }

    const getDateFormat = useCallback((chatOnDate) => {
        if (chatOnDate) {
            const dateObject = new Date(chatOnDate);

            const formattedTime = dateObject.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            const formattedDate = dateObject.toLocaleDateString('en-US', options);

            return { formattedTime, formattedDate };
        }
        return;
    }, []);

    const renderTicketView = () => {
        return updatedComplaintData?.chats?.map((item) => {
            return (
                <div className='mb-4'>
                    <div className='d-flex align-items-center'>
                        <div>
                            {item?.messageByAdmin
                                ? <Avatar
                                    src={require(`../../assets/images/app_logo.png`)}
                                    style={{ backgroundColor: '#000' }} />
                                : <Avatar src="/broken-image.jpg" />
                            }
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                            <div style={{ fontSize: '16px', color: '#2b3445', fontWeight: '600' }}>
                                {item?.messageByAdmin ? 'UrbanWallah' : item?.user?.userName}
                            </div>
                            <div style={{ fontSize: '14px', color: '#7d879c' }}>
                                {`${getDateFormat(item?.chatOn)?.formattedTime} | ${getDateFormat(item?.chatOn)?.formattedDate}`}
                            </div>
                        </div>
                    </div>
                    <Card style={{ fontSize: '14px', padding: '18px', marginLeft: '3.5rem', backgroundColor: '#f3f5f9', boxShadow: 'none', color: '#2B3445', borderRadius: '10px' }} className='mt-3'>
                        {item?.message ? item?.message : ''}
                    </Card>
                </div>
            )
        })
    }

    const handleOnChange = (e) => {
        if (e?.target && e?.target?.value) {
            setMessage(e.target.value)
        }
    };

    const handlePostData = async () => {
        try {
            let url = `customers/secure/complaint/${updatedComplaintData?._id}`;
            const token = localStorage.getItem("@token");
            const params = {
                message: message
            };
            let response = await putMethod({ url, token, params });
            if (response.success) {
                toast.success(response.message);
                setMessage('')
                dispatch(updateComplaintAction(response.data))
            } else {
                toast.error(response.message);
            }
        } catch (e) {
            toast.error(e.response.message);
        }
    }

    return (
        <SideMenuBar>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: "green",
                            secondary: "#000",
                        },
                    },
                    error: {
                        duration: 3000,
                        theme: {
                            primary: "red",
                            secondary: "#fff",
                        },
                    }
                }}
            />
            <NewHeader
                title={'Support Tickets'}
                icon={<HeadsetMicIcon style={{ width: '1.2em', height: '1.2em', color: '#23c2f2' }} />}
                isShowButton={true}
                screenName='Support Ticket'
                onPress={() => navigate('/support-ticket')}
            />
            <div className='mt-4 ms-2' style={{ height: '500px', overflow: 'hidden' }}>
                <div style={{ height: '100%', overflowY: 'scroll' }}>
                    {renderTicketView()}
                </div>
            </div>
            <Divider style={{ borderColor: '#e3e9ef', borderWidth: '0px 0px thin', borderStyle: 'solid' }} component={'h1'} />
            <div className='mt-4'>
                <TextField
                    id="filled-multiline-static"
                    multiline
                    value={message}
                    rows={5}
                    placeholder='Write your message here...'
                    fullWidth
                    style={{ background: 'none', borderRadius: '4px' }}
                    onChange={handleOnChange}
                />
            </div>
            <div className='d-flex mt-4' style={{ justifyContent: 'flex-end' }}>
                <Button
                    variant='contained'
                    style={{ backgroundColor: '#23c2f2', color: '#ffffff', fontWeight: '600' }}
                    onClick={handlePostData}
                >
                    Post Message
                </Button>
            </div>
        </SideMenuBar>
    )
}

export default SupportDetails;