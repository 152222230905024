import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../helpers";
import { Toaster } from "react-hot-toast";
import LoginModal from "./LoginModal";
import PurchaseCouponForm from "../Views/Coupons/PurchaseCouponForm";
import { AppBar, Avatar, IconButton, InputBase, Menu, MenuItem, Paper, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InterestsIcon from '@mui/icons-material/Interests';
import { setScreenTypeReducer } from "../Views/Home/redux/DashboardAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  iconDefault: {
    color: '#ffffff'
  },
  iconHover: {
    '&:hover': {
      color: '#23c2f2'
    },
  },
}));

const RenderIcon = ({ children }) => {
  return (
    <IconButton>
      {children}
    </IconButton>
  )
}

function Header() {

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  let json = localStorage.getItem("@USER");
  let User = JSON.parse(json);

  const [anchorEl, setAnchorEl] = useState(null);
  const [profileEl, setProfileEl] = useState(null);
  const [categoryEl, setCategoryEl] = useState(null);
  const [vendorEl, setVendorEl] = useState(null);
  const [showSignin, setShowSignin] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);

  const handleClose = () => setShowSignin(false);
  const handleShow = () => setShowSignin(true);

  const [showPurchaseCoupon, setShowPurchaseCoupon] = useState(false)
  const [value, setValue] = React.useState(0);

  const [screenType, setScreenType] = useState('Home');

  const handleChange = (event, index) => {
    setValue(index);
    if (index === 0) {
      navigate("/")
    } else if (index === 1) {
      if (!User) {
        handleShow();
      } else {
        navigate("/wishlist", {
          state: {
            TabKey: "Wishlist",
          },
        });
      }
    } else if (index === 2) {
      navigate("/products")
    } else if (index === 3) {
      setShowPurchaseCoupon(true)
    } else if (index === 4) {
      navigate("/pools", { state: 'All' })
    } else if (index === 5) {
      setVendorEl(event.currentTarget)
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  const getCategoriesList = async () => {
    try {
      let url = "common/categories";
      const token = localStorage.getItem("@token");
      let response = await getMethod({ url, token });
      if (response.success) {
        setCategoriesList(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headerContent = [
    {
      label: "Today's Winners List",
      onNavigate: () => navigate('/today-winners')
    },
    {
      label: "Become a Affiliate",
      onNavigate: () => navigate('/affiliate')
    },
    {
      label: "Become a Hub",
      onNavigate: () => navigate('/hub')
    },
  ];

  const profileData = [
    {
      label: 'Profile',
      onNavigate: () => {
        navigate('/profile')
        handleProfileClose();
      }
    },
    {
      label: 'Logout',
      onNavigate: () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
        handleProfileClose();
      }
    }
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItem = (item) => {
    navigate("/subcategory", { state: item?.categoryName });
    setAnchorEl(null);
  }

  const handleCategoryList = (item) => {
    navigate("/subcategory", { state: item?.categoryName });
    setCategoryEl(null);
  }

  const handleCartClick = () => {
    if (!User) {
      handleShow();
    } else {
      navigate("/cart");
    }
  }

  const getFirstLetterFromUserName = (name = 'U') => {
    return name?.charAt(0).toLocaleUpperCase();
  }

  const handleProfileOpen = (event) => {
    setProfileEl(event.currentTarget);
  }

  const handleProfileClose = () => {
    setProfileEl(null);
  }

  const vendorData = [
    {
      label: 'Action'
    },
    {
      label: 'Another Action'
    },
    {
      label: 'Something Else'
    }
  ];

  const tabData = [
    {
      label: 'Home',
    },
    {
      label: 'Wishlist',
    },
    {
      label: 'Products',
    },
    {
      label: 'Buy Coupons',
    },
    {
      label: 'Pools',
    },
    {
      label: 'Become A Vendor',
    }
  ];

  const contentData = [
    { title: 'Home' },
    { title: 'Hub' },
    { title: 'On Affiliate' },
    { title: 'Off Affiliate' }
  ];

  const handleScreenType = (type) => {
    setScreenType(type);
    setScreenTypeReducer(type, dispatch);
  }

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <AppBar position="static" style={{ backgroundColor: '#2b3445' }}>
        <Toolbar variant="dense" style={{ justifyContent: 'flex-end' }}>
          {headerContent.map((item, index) => {
            return (
              <Typography
                component="div"
                style={{ fontSize: '14px', marginLeft: index === 0 ? '1rem' : '2rem', cursor: 'pointer' }}
                onClick={item.onNavigate}
              >
                {item.label}
              </Typography>
            )
          })}
          <div style={{ marginLeft: 'auto' }}>
            <RenderIcon>
              <TwitterIcon className={`${classes.iconDefault} ${classes.iconHover}`} />
            </RenderIcon>
            <RenderIcon>
              <FacebookIcon className={`${classes.iconDefault} ${classes.iconHover}`} />
            </RenderIcon>
            <RenderIcon>
              <InstagramIcon className={`${classes.iconDefault} ${classes.iconHover}`} />
            </RenderIcon>
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ display: 'flex', alignItems: 'center' }} className="my-3">
        <div style={{ flex: 1.5, display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
          <img
            onClick={() => navigate("/")}
            style={{ width: '160px', height: '100px', cursor: 'pointer' }}
            src={require("../assets/AppLogo/UW-Logo.png")}
            alt="app-logo"
          />
        </div>
        <div style={{ flex: 5, alignItems: 'center', justifyContent: 'center' }}>
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '2rem' }}
          >
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <div
              style={{
                backgroundColor: '#f6f9fc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '1.5rem',
                borderRadius: '2rem'
              }}
            >
              <Typography
                component="div"
                style={{ fontSize: '15px', color: '#4b566b', cursor: 'pointer' }}>
                All Categories
              </Typography>
              <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={handleClick}>
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {categoriesList?.map((item) => {
                  return (
                    <MenuItem onClick={() => handleMenuItem(item)}>{item?.categoryName}</MenuItem>
                  )
                })}
              </Menu>
            </div>
          </Paper>
        </div>
        <div style={{ flex: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {User
            ? <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Avatar sx={{ bgcolor: '#23c2f2', cursor: 'pointer' }} onClick={handleProfileOpen}>
                <Typography component="div" style={{ fontSize: '20px', color: '#4b566b', fontWeight: '700' }}>
                  {getFirstLetterFromUserName(User?.firstName)}
                </Typography>
              </Avatar>
              <ArrowDropDownIcon style={{ cursor: 'pointer' }} onClick={handleProfileOpen} />
              <Menu
                anchorEl={profileEl}
                open={Boolean(profileEl)}
                onClose={handleProfileClose}
              >
                {profileData?.map((item) => {
                  return (
                    <MenuItem onClick={item.onNavigate}>{item?.label}</MenuItem>
                  )
                })}
              </Menu>
            </div>
            : <Typography
              component="div"
              onClick={() => navigate("/login")}
              style={{ fontSize: '16px', color: '#4b566b', cursor: 'pointer', fontWeight: '700' }}>
              Login
            </Typography>
          }
          <div className={`${User ? 'ms-4' : 'ms-5'}`}>
            <Avatar sx={{ bgcolor: '#23c2f2', cursor: 'pointer' }} onClick={handleCartClick}>
              <ShoppingBagIcon style={{ fill: '#fff' }} fontSize="10px" />
            </Avatar>
          </div>
        </div>
      </div>
      <div className="px-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex'} }>
          {contentData.map((item, i) => {
            return (
              <div 
                key={i} 
                style={{ 
                  minWidth: '125px', 
                  backgroundColor: screenType === item.title ? '#23C2F2' : '#fff', 
                  border: '1px solid #000', 
                  borderRadius: '6px', 
                  textAlign: 'center',
                  cursor: 'pointer',
                  color: screenType === item.title ? '#fff' : '#000',
                  fontWeight: 700
                }}
                className="p-1 ms-3"
                onClick={() => handleScreenType(item.title)}
              >
                {item.title}
              </div>
            )
          })}
        </div>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor=""
            textColor=""
          >
            {tabData.map((item, index) => {
              return (
                <Tab
                  label={item?.label}
                  icon={item.label == 'Become A Vendor' ? <ArrowDropDownIcon /> : null}
                  iconPosition="end"
                  disableRipple
                  style={{ fontWeight: value === index ? 700 : 'normal', textTransform: 'capitalize', fontSize: '16px' }}
                />
              )
            })}
            <Menu
              anchorEl={vendorEl}
              open={Boolean(vendorEl)}
              onClose={() => setVendorEl(null)}
            >
              {vendorData.map(item => {
                return <MenuItem onClick={() => setVendorEl(null)} >{item.label}</MenuItem>
              })}
            </Menu>
          </Tabs>
        </div>
      </div>
      {/* start of signin popup */}
      <PurchaseCouponForm modalVisible={showPurchaseCoupon} modalOnClose={() => {
        setShowPurchaseCoupon(false)
      }} />
      <LoginModal
        showSignin={showSignin}
        setShowSignin={setShowSignin}
        handleClose={handleClose}
        handleShow={handleShow}
      />
      {/* end of signin popup */}
    </>
  );
}

export default Header;
