import React, { useEffect } from 'react';
import SecurityIcon from '@mui/icons-material/Security';

const Privacy = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className='container mt-5'>
            <div>
                <div style={{ textAlign: 'center' }}>
                    <SecurityIcon style={{ width: '2em', height: '2em' }} />
                </div>
                <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '10px' }}>
                    Privacy Policy
                </div>
                <>
                    <p className="p1">
                        <strong>Effective Date: 21-May-2024</strong>
                    </p>
                    <p className="p1">
                        This Privacy Policy describes how Bharat UrbanWallah private limited ("we,"
                        "us," or "our") collects, uses, and discloses your personal information when
                        you use our mobile application, UrbanWallah (the "App").
                    </p>
                    <p className="p1">
                        <strong>Information We Collect</strong>
                    </p>
                    <p className="p1">
                        We collect several different types of information for various purposes to
                        improve our services to you.
                    </p>
                    <ul className="ul1">
                        <li className="li2">
                            <strong>Information You Provide Directly:</strong>
                        </li>
                        <ul className="ul1">
                            <li className="li2">
                                Account Information: When you create an account, we collect your name,
                                email address, phone number, and password (hashed and salted).
                            </li>
                            <li className="li2">
                                Profile Information: You may optionally provide additional information
                                to your profile, such as billing and shipping addresses, date of birth
                                (for age verification purposes only), and preferences.
                            </li>
                            <li className="li2">
                                Payment Information: We use a secure third-party payment processor to
                                collect your payment information, such as credit card details. We do not
                                store your full payment information on our servers.
                            </li>
                            <li className="li2">
                                Communication Information: When you contact us or participate in
                                surveys, we may collect your name, email address, and the content of
                                your communication.
                            </li>
                        </ul>
                        <li className="li2">
                            <strong>Information Collected Automatically:</strong>
                        </li>
                        <ul className="ul1">
                            <li className="li2">
                                Device Information: We collect information about the device you use to
                                access the App, such as your device type, operating system, IP address,
                                browser type, and unique device identifiers.
                            </li>
                            <li className="li2">
                                Usage Information: We collect information about your activity on the
                                App, such as your browsing history, search queries, and products viewed
                                or purchased.
                            </li>
                        </ul>
                    </ul>
                    <p className="p3">&nbsp;</p>
                    <p className="p3">&nbsp;</p>
                    <p className="p1">
                        <strong>Use of Your Information</strong>
                    </p>
                    <p className="p1">
                        We use the information we collect for various purposes, including:
                    </p>
                    <ul className="ul1">
                        <li className="li2">To provide and operate the App</li>
                        <li className="li2">To process your orders and deliver products</li>
                        <li className="li2">
                            To communicate with you about your account, orders, and promotional offers
                        </li>
                        <li className="li2">To personalize your experience on the App</li>
                        <li className="li2">To improve the App and our services</li>
                        <li className="li2">
                            To prevent fraud and maintain the security of the App
                        </li>
                        <li className="li2">To comply with legal and regulatory requirements</li>
                    </ul>
                    <p className="p1">
                        <strong>Sharing Your Information</strong>
                    </p>
                    <p className="p1">
                        We may share your information with third-party service providers who help us
                        operate the App and provide our services. These third-party service
                        providers are obligated to protect your information and use it only for the
                        purposes we disclose.
                    </p>
                    <p className="p1">
                        We may also disclose your information if required by law or to protect the
                        rights, property, or safety of ourselves or others.
                    </p>
                    <p className="p1">
                        <strong>Your Choices</strong>
                    </p>
                    <p className="p1">You have several choices regarding your information:</p>
                    <ul className="ul1">
                        <li className="li2">
                            You can access and update your account information at any time.
                        </li>
                        <li className="li2">
                            You can unsubscribe from marketing communications by following the
                            instructions in the emails we send you.
                        </li>
                        <li className="li2">
                            You can choose to delete your account by contacting us.
                        </li>
                    </ul>
                    <p className="p2">
                        <strong>Data Security</strong>
                    </p>
                    <p className="p2">
                        We take reasonable steps to protect your information from unauthorized
                        access, disclosure, alteration, or destruction. However, no internet
                        transmission or electronic storage is 100% secure.
                    </p>
                    <p className="p4">
                        <strong>Children's Privacy</strong>
                    </p>
                    <p className="p1">
                        Our App is not intended for children under the age of 18. We do not
                        knowingly collect personal information from children.
                    </p>
                    <p className="p2">
                        <strong>Changes to this Privacy Policy</strong>
                    </p>
                    <p className="p2">
                        We may update this Privacy Policy from time to time. We will notify you of
                        any changes by posting the new Privacy Policy on the App.
                    </p>
                    <p className="p2">
                        <strong>Contact Us</strong>
                    </p>
                    <p className="p2">
                        If you have any questions about this Privacy Policy, please contact us at
                        info@urbanwallah.com
                    </p>
                    <p className="p1">
                        <strong>Important Note:</strong>
                    </p>
                    <p className="p1">
                        This is a sample privacy policy and may need to be modified to comply with
                        all applicable laws and regulations in India. It is recommended that you
                        consult with a lawyer to ensure your privacy policy is compliant with the
                        Information Technology Act, 2000 and any other relevant Indian laws.
                    </p>
                </>
            </div>
        </div>
    )
}

export default Privacy;