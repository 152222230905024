import React, { useMemo } from "react";
import { Grid, Stack, useMediaQuery, useTheme, Container, Typography, Box } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import fIcon from '../assets/SocialIcons/f.svg';
import iIcon from '../assets/SocialIcons/i.svg';
import yIcon from '../assets/SocialIcons/y.png';
import xIcon from '../assets/SocialIcons/x.svg';
import wIcon from '../assets/SocialIcons/W.svg';
import googleIcon from '../assets/SocialIcons/google.svg';
import appleIcon from '../assets/SocialIcons/apple.svg';
import { useNavigate } from "react-router-dom";
import { navigateToScreen } from "../Views/helper";
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function NewFooter({ screenName }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));


    const isLoginScreen = useMemo(() => {
        return screenName === 'Login' || screenName === 'Register'
    }, [screenName]);

    const getFooterBackgroundColor = () => {
        let gradient = 'linear-gradient(to top, #2DBDEE 0%, #70DBFF 100%)';
        if (screenName === 'farmer') {
            gradient = 'linear-gradient(to bottom, #99e0b8 0%, #20E492 100%)';
        } else if (screenName === 'student') {
            gradient = 'linear-gradient(to top, #F5DE54 0%, #FDF2AF 100%)';
        } else if (screenName === 'market-place') {
            gradient = 'linear-gradient(to bottom, #d9bcf5 0%, #AB68FE 100%)';
        } else if (screenName === 'affiliate') {
            gradient = 'linear-gradient(to top, #FA8A5C 0%, #FEB090 100%)';
        }
        return { background: gradient };
    };

   

    const loginFooterContent = ['Experience', 'Terms & Conditions', 'Privacy Policy', 'Shipping Policy', 'Return Policy', 'Refund policy', 'Contact us'];

    const companyData = [
        {
            label: 'Who we are',
            screenName: '/experience'
        },
        {
            label: 'Our business',
            screenName: '/'
        },
        {
            label: 'Announcements',
            screenName: '/'
        },
        {
            label: 'Join Us',
            screenName: '/'
        }
    ];

    const portalData = [
        {
            label: 'Students Portal',
            screenName: '/hub/student'
        },
        {
            label: 'Farmers Portal',
            screenName: '/hub/farmer'
        },
        {
            label: 'Marketplace',
            screenName: '/hub/market-place'
        },
        {
            label: 'Affiliate portal',
            screenName: '/affiliate'
        },
        {
            label: 'Pools',
            screenName: '/pools'
        }
    ];

    const legalData = [
        {
            label: 'Terms & Conditions',
            screenName: '/terms-and-condition'
        },
        {
            label: 'Privacy Policy',
            screenName: '/privacy'
        },
        {
            label: 'Refund Policy',
            screenName: '/refund'
        },
        {
            label: 'Return Policy',
            screenName: '/return'
        },
        {
            label: 'Shipping Policy',
            screenName: '/shipping'
        }
    ];

    const contactUsData = [
        {
            label: 'Support tickets',
            screenName: '/support-ticket'
        },
        {
            label: 'Talk to us',
            screenName: '/support-ticket'
        }
    ];

    const footerImg = ['Mastercard', 'Visa', 'UPI', 'AmazonPay', 'Vector', 'bank'];

    const FooterSection = ({ title, data }) => (
        <Box mb={isMobile ? 3 : 0}>
            <Typography variant="h6" fontWeight={600} mb={2}>
                {title}
            </Typography>
            <Stack direction="column" spacing={1}>
                {data.map((item, index) => (
                    <Typography
                        key={index}
                        variant="body2"
                        fontWeight={600}
                        color="#908C8C"
                        className="hover-underline"
                        onClick={() => navigateToScreen(navigate, item.screenName)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {item.label}
                    </Typography>
                ))}
            </Stack>
        </Box>
    );

    return (
        <>
            {!isLoginScreen ? (
                <>
  <Box bgcolor={getFooterBackgroundColor()} py={6} mt={5} position="relative" marginTop='4rem' minHeight={isMobile ? "325px" : "180px"}>
    <Container maxWidth="lg">
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    marginLeft={isMobile ? 0 : '-150px'}
                    textAlign={isMobile ? "center" : "left"}
                    mr={isMobile ? 0 : 'auto'}
                    margin={isMobile ? 'auto' : 0}
                >
                    <Typography variant="h5" fontWeight={700}>
                        Download UrbanWallah app
                    </Typography>
                    <Typography variant="body1" fontWeight={500} mt={isMobile ? 1 : 2}>
                        To experience the reinvented market platform
                    </Typography>
                </Box>
                <Box
                    id="app_scanner"
                    position="absolute"
                    bottom={isMobile ? 0 : -53}
                    right={isMobile ? 30 : '250px'}
                    width="max-content"
                    display="flex"
                    justifyContent="center"
                    sx={{ zIndex: 1, padding: isMobile ? '0 16px' : 0 }}
                >
                    <style>
                        {`
                            @media (min-width:601px) and (max-width:1200px){
                                #app_scanner{
                                    right:17px;
                                }
                            }
                        `}
                    </style>
                    <img
                        src={require('../assets/images/mobile-scan.png')}
                        style={{
                            width: isMobile ? '100%' : '320px',
                            maxWidth: isMobile ? '320px' : 'none',
                            height: isMobile ? 'auto' : '320px',
                            top: isMobile ? '52px' : 'auto',
                            marginRight: isMobile ? '0' : '150px',
                            marginBottom: isMobile ? '-52px' : '0'
                        }}
                        alt="Mobile scan"
                    />
                </Box>
            </Grid>
        </Grid>
    </Container>
</Box>

                    <Container maxWidth="lg">
                        <Grid container spacing={4} py={5} paddingTop={'20px !important'}>
                            <Grid item xs={12} md={3.5} >
                                <Box display="flex" flexDirection="column" alignItems={isMobile ? "center" : "flex-start"}>
                                    <img
                                        style={{ width: '160px', height: '100px', cursor: 'pointer' }}
                                        src={require("../assets/AppLogo/UW-Logo.png")}
                                        alt="app-logo"
                                    />
                                    <Typography variant="body2" color="#908C8C" mt={2} textAlign={isMobile ? "center" : "left"} width={isMobile ? "100%" : "50%"} ml={isMobile ? 0 : "1.5rem"}>
                                        34/1, KVR Nagar, Kannampalayam, Sulur, Coimbatore, Tamil Nadu, 641402
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8.5}>
                                <Grid container spacing={isMobile ? 3 : 4}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FooterSection title="Company" data={companyData} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FooterSection title="Our Portals" data={portalData} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FooterSection title="Legal" data={legalData} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FooterSection title="Contact Us" data={contactUsData} />
                                        <Box mt={3}>
                                            <Typography variant="h6" fontWeight={600} mb={5}>
                                                Social Links
                                            </Typography>
                                            <Stack direction="row" spacing={2}>
                                                <img src={fIcon} style={{ width: '20px', height: '20px' }} alt="Facebook" />
                                                <img src={iIcon} style={{ width: '20px', height: '20px' }} alt="Instagram" />
                                                <img src={yIcon} style={{ width: '20px', height: '20px' }} alt="YouTube" />
                                                <img src={xIcon} style={{ width: '20px', height: '20px' }} alt="Twitter" />
                                                <img src={wIcon} style={{ width: '20px', height: '20px' }} alt="WhatsApp" />
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg">
                        <Stack direction="row" justifyContent="center" flexWrap="wrap" spacing={4} py={6}>
                            {footerImg.map((imgName, index) => (
                                <img key={index} src={require(`../assets/icons/${imgName}.svg`)} style={{ width: '50px', height: '60px' }} alt={imgName} />
                            ))}
                        </Stack>
                    </Container>
                </>
            ) : (
                <Container maxWidth="lg">
                    <Grid container justifyContent="center" alignItems="center">
                        {loginFooterContent.map((item, index) => (
                            <Grid item key={index} className="ms-4" style={{ height: '38px' }}>
                                <Typography variant="body2" color="#908C8C">
                                    {item}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            )}
            <Box bgcolor={getFooterBackgroundColor()} py={1}>
                <Typography variant="body2" textAlign="center">
                    © 2024 Bharat UrbanWallah Private Limited
                </Typography>
            </Box>
        </>
    );
}

export default NewFooter;
