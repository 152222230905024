import toast from "react-hot-toast";
import { deleteMethod, getMethod, postMethod } from "../../../helpers";
import { WishlistActionTypes } from "./WishlistActionTypes";
import { makeRemoveFromCartApi } from "../../Cart/redux/CartAction";


export const makeGetWishlistApi = async (dispatch) => {
    try {
        let url = "customers/secure/wishlist";
        let token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        if (response?.success) {
            dispatch({
                type: WishlistActionTypes.SET_WISHLIST_DATA,
                payload: response?.data
            })
        } else {
            dispatch({
                type: WishlistActionTypes.SET_WISHLIST_DATA,
                payload: []
            })
        }
    } catch (e) {
        console.log(e);
    }
}

export const makeAddToWishlistApi = async (product, dispatch, isFromCart = false) => {
    try {
        let url = `customers/secure/wishlist/${product?.productUuid}?quantity=1`;
        var payload = {
            productId: product?.productUuid,
            quantity: 1,
        };
        let token = localStorage.getItem("@token");
        let response = await postMethod({ url, payload, token });
        if (response.success) {
            toast.success(response.message);
            if (isFromCart) {
                makeRemoveFromCartApi(product, dispatch);
            }
            dispatch({
                type: WishlistActionTypes.STORE_WISHLISTED_PRODUCT_IDS,
                payload: product?.productUuid
            });
        } else {
            toast.error(response.message);
        }
    } catch (e) {
        console.log(e);
    }
};

export const makeRemoveFromWishlistApi = async (product, dispatch) => {
    try {
        let url = `customers/secure/wishlist/${product?.productUuid}?`;
        var payload = {
            productId: product?.productUuid,
        };
        let token = localStorage.getItem("@token");
        const localWishlistProductList = localStorage.getItem('localWishlistProductList');
        let wishlistIdData = JSON.parse(localWishlistProductList);
        let response = await deleteMethod({ url, payload, token });
        if (response.success) {
            toast.success(response.message, {
                icon: false,
                style: { color: '#fff', backgroundColor: '#000' }
            });
            makeGetWishlistApi(dispatch);
            dispatch({
                type: WishlistActionTypes.REMOVE_WISHLISTED_PRODUCT_BY_ID,
                payload: {
                    productUuid: product?.productUuid,
                    wishlistIdData
                }
            });
        } else {
            toast.error(response.message);
        }
    } catch (e) {
        console.log(e);
    }
}