import { AuthActionTypes } from './AuthActionType';

export const storeLoginData = (data) => {
    return {
        type: AuthActionTypes.LOGIN_DATA_SUCCESS,
        payload: data
    }
};

export const setIsHubUser = (value) => {
    return {
        type: AuthActionTypes.SET_HUB_USER,
        payload: value
    }
}

// export const storeLoginData = (data) => ({
//     type: 'LOGIN_DATA_SUCCESS',
//     payload: data
//   });
  
//   export const setIsHubUser = (value) => ({
//     type: 'SET_HUB_USER',
//     payload: value
//   });