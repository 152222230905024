import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiDatePicker: {
            styleOverrides: {
                root: {
                    minWidth: '100%'
                },
            },
        },
    },
});

export default theme;