import React, { useState, useEffect, Fragment, useMemo, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Card,
  Grid,
  InputBase,
  Paper,
  Rating,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import assureIcon from "../../assets/icons/assure.svg";
import icon1Icon from "../../assets/icons/icon1.svg";
import authenticIcon from "../../assets/icons/authentic.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { makeAddToCartApi, makeGetCartApi } from "../Cart/redux/CartAction";
import {
  makeAddToWishlistApi,
  makeRemoveFromWishlistApi,
} from "../Wishlist/redux/WishlistAction";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../Components/LoginModal";
import { boxData, boxImgData } from "./Utils";
import CheckIcon from "../../assets/icons/checkIcon.svg";
import { navigateToScreen } from "../helper";
import { topCategoryData } from "../Utils";
import './homestyle.css'
import { putMethod } from "../../helpers";
import StarRatingComponent from "../../Components/StartRatingComponent";
import "../../css/fonts.css"

const NewHomeList = ({
  discountData,
  SubCategoryList,
  ProductList,
  LatestProducts,
  FeaturedProducts,
  MostViewedProducts
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const [featureCardhoverIndex, setFeatureCardhoverIndex] = useState(null);
  const [dailyDealsCardhoverIndex, setDailyDealsCardhoverIndex] =
    useState(null);
  const [catImgCardhoverIndex, setCatImgCardhoverIndex] = useState(null);
  const [trendingCardhoverIndex, setTrendingCardhoverIndex] = useState(null);
  const [showSignin, setShowSignin] = useState(false);

  const wishlistedProductIdList = useSelector(
    (state) => state.wishlistState.wishlistedProductIdList
  );
  const cartProductIdList = useSelector(
    (state) => state.cartState.cartProductIdList
  );

  const handleClose = () => setShowSignin(false);
  const handleShow = () => setShowSignin(true);

  let json = localStorage.getItem("@USER");
  let User = JSON.parse(json);

  const localWishlistProductList = localStorage.getItem(
    "localWishlistProductList"
  );
  let wishlistIdData = JSON.parse(localWishlistProductList);

  const wishlistIdList = useMemo(() => {
    if (wishlistedProductIdList && wishlistedProductIdList?.length > 0) {
      return wishlistedProductIdList;
    } else {
      return wishlistIdData;
    }
  }, [wishlistedProductIdList, wishlistIdData]);

  const localCartProductList = localStorage.getItem("localCartProductList");
  let cartlistData = JSON.parse(localCartProductList);

  const [position, setPosition] = useState({ latitude: null, longitude: null });

  console.log('position', position);

  useEffect(() => {
    makeGetCartApi(dispatch);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const handleWishlistApi = (e, item) => {
    e.stopPropagation();
    if (User) {
      makeAddToWishlistApi(item, dispatch);
    } else {
      handleShow();
    }
  };

  const handleRemoveWishlistApi = (e, item) => {
    e.stopPropagation();
    if (User) {
      makeRemoveFromWishlistApi(item, dispatch);
    } else {
      handleShow();
    }
  };

  const tilesData = [
    {
      icon: authenticIcon,
      title: "Authentic",
      subTitle: "Our products are aunthentic",
    },
    {
      icon: assureIcon,
      title: "Assured",
      subTitle: "Our products are assured",
    },
    {
      icon: icon1Icon,
      title: "Adulteration Free",
      subTitle: "Our products are organic",
    },
    {
      icon: assureIcon,
      title: "Assured",
      subTitle: "Our products are assured",
    },
  ];

  const handleFeaturedCardHover = (index) => {
    setFeatureCardhoverIndex(index);
  };

  const handleDailyDealsCardHover = (index) => {
    setDailyDealsCardhoverIndex(index);
  };

  const handleCatImgCardHover = (index) => {
    setCatImgCardhoverIndex(index);
  };

  const handleTrendingCardHover = (index) => {
    setTrendingCardhoverIndex(index);
  };

  const updateProductMostView = async (productId) => {
    try {
      let url = `products/visit/${productId}`;
      let response = await putMethod({ url });
      console.log(response)
      if (response.success) {
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const naviagteToProductDetails = (item) => {
    navigate(`/productDetails/${item?.subcategoryUuid}/${item.productUuid}`);
    updateProductMostView(item?._id);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderTitleContainer = (title) => {
    return (
      <div
        style={{
          fontWeight: 600,
          // color: '#FEFEFE',
          fontSize: "20px",
          fontFamily:'Gabarito'
        }}
      >
        {title}
      </div>
    );
  };

  const renderTrendingProductSection = (plainBackgroundCard = false) => {
    return (
      <div className="my-4">
        {renderTitleContainer("Trending Now")}
        <div className="mt-4">
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              // justifyContent: "space-between",
              justifyContent: "start",
              gap: "15px"
            }}
          > */}
          <Grid container spacing={ProductList?.length > 3 ? 0 : 40} justifyContent={ProductList?.length > 3 ? 'space-between' : 'flex-start'}>
            {ProductList.map((item, i) => {
              if (i < 5) {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={i}>
                    <Card
                      style={{
                        marginBottom: "10px",
                        width: "254px",
                        height: "350px",
                        
                        border:
                          trendingCardhoverIndex === i
                            ? "1px solid #D3D3D3"
                            : "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        backgroundColor: plainBackgroundCard ? "#fff" : "#F3F0F0",
                      }}
                      onClick={() => naviagteToProductDetails(item)}
                      onMouseEnter={() => handleTrendingCardHover(i)}
                      onMouseLeave={() => handleTrendingCardHover(null)}
                    >
                      <div className="py-5" >
                        <div
                          style={{
                            transition: "transform 0.2s ease",  
                            transform:
                              trendingCardhoverIndex === i
                                ? "scale(1.1)"
                                : "scale(1)",  
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={
                                item?.imageUrl
                                  ? item?.imageUrl
                                  : require("../../assets/images/placeholder-image.png")
                              }
                              style={{ height: "150px", width: "140px" ,borderRadius: '15px'}}
                            />
                            <div
                              className="mt-3"
                              style={{
                                textAlign: "start",
                                paddingLeft: "2.5rem",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontFamily:'Gabarito',
                                  color: 'rgba(144, 140, 140, 1)',
                                  lineHeight:'16.8px',
                                  textTransform: 'uppercase',
                                }}
                              >
                                {item?.category?.categoryName}
                              </div>
                              <div
                                className="mt-2"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color:'rgba(50, 50, 50, 1)',
                                  lineHeight:'19.2px',
                                  // color:
                                  //   trendingCardhoverIndex === i
                                  //     ? "#23c2f2"
                                  //     : "#000",
                                  // textDecoration:
                                  //   trendingCardhoverIndex === i
                                  //     ? "underline"
                                  //     : "none",
                                }}
                              >
                                {item?.productName}
                              </div>
                              <div className="mt-2 mb-1">
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={2}
                                >
                                  <StarRatingComponent
                                    starRating={item?.overallRating}
                                    size="medium"
                                    readOnly={true}
                                  />
                                  {item?.ratings && item?.ratings?.length > 0
                                    ? <div
                                      style={{
                                        fontSize: "14px",
                                        color: "#000",
                                        fontWeight: 500,
                                        lineHeight:'16.8px',
                                        fontFamily:'Gabarito',
                                      }}
                                    >
                                      {item?.ratings?.length > 1
                                        ? `( ${item?.ratings?.length} Ratings )`
                                        : `( ${item?.ratings?.length} Rating )`
                                      }
                                    </div>
                                    : null
                                  }
                                </Stack>
                              </div>
                              <div
                                className="mt-2"
                                style={{ fontSize: '22px', lineHeight:'26.4px', fontWeight: 600,}}
                              >
                                ₹ {item?.price}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                );
              }
            })}
          </Grid>
          {/* </div> */}
        </div>
      </div>
    );
  };
  // const discountData = [
  //   { image: 'https://png.pngtree.com/png-vector/20190221/ourlarge/pngtree-discount-90--off-vector-template-design-illustration-png-image_693402.jpg', id: 1 },
  //   { image: 'https://png.pngtree.com/png-vector/20190221/ourlarge/pngtree-discount-90--off-vector-template-design-illustration-png-image_693402.jpg', id: 2 },
  //   { image: 'https://png.pngtree.com/png-vector/20190221/ourlarge/pngtree-discount-90--off-vector-template-design-illustration-png-image_693402.jpg', id: 3 },
  //   { image: 'https://png.pngtree.com/png-vector/20190221/ourlarge/pngtree-discount-90--off-vector-template-design-illustration-png-image_693402.jpg', id: 4 },
  // ];
  const renderSwiperBannerImage = () => {
    return (
      <div className="swiper-banner-container" style={{marginTop:'0rem !important'}}>
        <Swiper
          navigation={true}
          modules={[Navigation, Pagination]}
          className="mySwiper custom-swiper"
          breakpoints={{
            360: { slidesPerView: 1, spaceBetween: 10 },
            576: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 1, spaceBetween: 10 },
            992: { slidesPerView: 1, spaceBetween: 10 },
            1200: { slidesPerView: 1, spaceBetween: 10 },
          }}
          pagination={{ clickable: true }}
        >
          {discountData.map((item) => (
            <SwiperSlide key={item.id}>
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '339px',
                  borderRadius: '10px',
                  ...(window.innerWidth <= 576 && { maxHeight: '500px' }), // Adjust size for mobile view
                }}
                src={item.image}
                alt={`Discount ${item.id}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };
  
  
  const renderMainSection = () => {
    return (
      <Grid
        container
        spacing={5}
        direction="row"
        alignItems="center"
        

      >
        <Grid
        paddingTop={'0px !important'}
          item
          xs={12}
          md={9}
          style={{
            ...(window.innerWidth > 576 && { marginRight: '0px' }), // Shift only the main section's content to the left in desktop view
          }}
        >
          {renderSwiperBannerImage()}
        </Grid>
        <Grid item xs={12} md={3} paddingTop={'0px !important'}>
          <div>
            <a href="/product-search" target="_blank" rel="noopener noreferrer">
              <img
                src={require('../../assets/banner/left-side 1.png')}
                style={{
                  width: '289px',
                  height: '165px',
                  borderRadius: '10px',
                  ...(window.innerWidth <= 576 && { width: '100%', height: 'auto' }),
                }}
                className="mb-2"
                alt="First Banner"
              />
            </a>
            <a href="/product-search" target="_blank" rel="noopener noreferrer">
              <img
                src={require('../../assets/banner/left-side.banner-down.png')}
                style={{
                  width: '289px',
                  height: '165px',
                  borderRadius: '10px',
                  ...(window.innerWidth <= 576 && { width: '100%', height: 'auto' }),
                }}
                alt="Second Banner"
              />
            </a>
          </div>
        </Grid>
      </Grid>
    );
  };
  
  
  

  const renderDailySectionWithBanner = () => {
    return (
      <Grid container spacing={5} style={{ alignItems: 'center' ,flexWrap:'wrap'}}>
        <Grid item xs={12} md={7}>
          <div style={{ position: 'relative' }}>
            {renderTitleContainer('New Arrivals')}
            <div className="mt-4">
              <Grid container spacing={2}>
                {ProductList.slice(0, 3).map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <Card
                      style={{
                        width: '100%',
                        height: 'auto',
                        border: dailyDealsCardhoverIndex === i ? '1px solid #D3D3D3' : 'none',
                        cursor: 'pointer',
                        boxShadow: 'none', 
                        borderRadius: '10px',
                      }}
                      onClick={() => naviagteToProductDetails(item)}
                      onMouseEnter={() => handleDailyDealsCardHover(i)}
                      onMouseLeave={() => handleDailyDealsCardHover(null)}
                    >
                      <div style={{ padding: '1rem' }}>
                        <div id="daily_deals"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transition: 'transform 0.2s ease',
                            transform: dailyDealsCardhoverIndex === i ? 'scale(1.1)' : 'scale(1)',
                          }}
                        >
                          <img
                            src={item.imageUrl ? item.imageUrl : require('../../assets/images/placeholder-image.png')}
                            style={{ height: '150px', width: '40%', objectFit: 'contain' ,borderRadius: '15px'}}
                            alt={item.productName}
                          />
                          <div className="ps-3">
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                marginTop: '6px',
                                fontFamily: 'Gabarito',
                                color: 'rgba(144, 140, 140, 1)',
                                lineHeight: '16.8px',
                                textTransform: 'uppercase',
                              }}
                            >
                              {item?.category?.categoryName}
                            </div>
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                color: 'rgba(50, 50, 50, 1)',
                                lineHeight: '19.2px',
                                textDecoration: dailyDealsCardhoverIndex === i ? 'underline' : 'none',
                              }}
                              className="mt-3"
                            >
                              {item.productName}
                            </div>
                            <div className="mt-2 mb-1" style={{ display: 'flex', alignItems: 'center',flexWrap:'wrap', }}>
                              <StarRatingComponent
                                starCount={5} /* Replace with your StarRatingComponent props */
                                value={item.overallRating} /* Replace with your StarRatingComponent props */
                                size="medium"
                                readOnly={true}
                              />
                              {item.ratings && item.ratings.length > 0 && (
                                <div
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: 500,
                                    lineHeight: '16.8px',
                                    
                                  }}
                                >
                                  {item.ratings.length > 1 ? `( ${item.ratings.length} Ratings )` : `( ${item.ratings.length} Rating )`}
                                </div>
                              )}
                            </div>
                            <div style={{ fontSize: '22px', lineHeight: '26.4px', fontFamily: 'Gabarito', fontWeight: 600 }} className="mt-2">
                              ₹ {item.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} style={{ marginTop: '53px', marginRight: '0px' }}>
        <a href="/product-search" target="_blank" rel="noopener noreferrer">
          <img id="daily_deals_beside_pic"
            src={require('../../assets/banner/new arrival.png')}
            style={{ width: '100%', height: '290px', borderRadius: '15px' }}
            alt="Hero Banner"
    />
  </a>
</Grid>
</Grid>
    );
  };


  // const renderLatestProductSection = () => {
  //   return (
  //     <Grid container spacing={5} alignItems={"center"}>
  //       <Grid item xs={12} md={3}>
  //         {renderTitleContainer("Latest Product")}
  //         <div className="mt-3">
  //           {LatestProducts.map((item, i) => {
  //             if (i < 4) {
  //               return (
  //                 <Card  
  //                   style={{
  //                     width: "90%",
  //                     height: "auto",
  //                     border:
  //                       catImgCardhoverIndex === i
  //                         ? "1px solid #D3D3D3"
  //                         : "none",
  //                     cursor: "pointer",
  //                     boxShadow: "none",
                   
  //                   }}
  //                   onClick={() => naviagteToProductDetails(item)}
  //                   onMouseEnter={() => handleCatImgCardHover(i)}
  //                   onMouseLeave={() => handleCatImgCardHover(null)}
  //                 >
  //                   <div style={{ padding: "0.8rem" }}>
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         flexDirection: "row",
  //                         alignItems: "center",
  //                         transition: "transform 0.2s ease", // Add transition for smooth zoom
  //                         transform:
  //                           catImgCardhoverIndex === i
  //                             ? "scale(1.1)"
  //                             : "scale(1)", // Zoom effect on hover
  //                       }}
  //                     >
  //                       <img
  //                         src={
  //                           item?.imageUrl
  //                             ? item?.imageUrl
  //                             : require("../../assets/images/placeholder-image.png")
  //                         }
  //                         style={{ height: "70px", width: "70px" }}
  //                       />
  //                       <div className="ps-3">
  //                         <div
  //                           style={{
  //                             fontSize: "14px",
  //                             fontWeight: 400,
  //                             fontFamily:'Gabarito',
  //                             color:
  //                               catImgCardhoverIndex === i ? "#23c2f2" : "#000",
  //                             textDecoration:
  //                               catImgCardhoverIndex === i
  //                                 ? "underline"
  //                                 : "none",
  //                           }}
  //                           className="mt-3"
  //                         >
  //                           {item?.productName}
  //                         </div>
  //                         <div
  //                           style={{ fontSize: "15px",fontFamily:'Gabarito', fontWeight: 600 }}
  //                           className="mt-2"
  //                         >
  //                           ₹ {item?.price}
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </Card>
  //               );
  //             }
  //           })}
  //         </div>
  //       </Grid>
  //       <Grid item xs={12} md={9}>
  //         <Grid container spacing={4} style={{marginTop:'10px'}}>
  //           <Grid item xs={3}>
  //             <img
  //               src={require("../../assets/banner/banner4.webp")}
  //               style={{ width: "100%" }}
  //             />
  //             <div className="mt-4">
  //               <img
  //                 src={require("../../assets/banner/banner4.webp")}
  //                 style={{ width: "100%" }}
  //               />
  //             </div>
  //           </Grid>
  //           <Grid item xs={6}>
  //             <img
  //               src={require("../../assets/banner/heroBanner-2.png")}
  //               style={{ width: "100%", height: "100%" }}
  //             />
  //           </Grid>
  //           <Grid item xs={3}>
  //             <img
  //               src={require("../../assets/banner/banner4.webp")}
  //               style={{ width: "100%" }}
  //             />
  //             <div className="mt-4">
  //               <img
  //                 src={require("../../assets/banner/banner4.webp")}
  //                 style={{ width: "100%" }}
  //               />
  //             </div>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const renderMostViewedProductSection = (title = "Winter Sale") => {
    return (
      <div className="my-4">
        {renderTitleContainer(title)}
        <div className="mt-4">
          <Swiper
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper custom-swiper"
            breakpoints={{
              360: { slidesPerView: 1, spaceBetween: 10 },
              576: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 3, spaceBetween: 10 },
              992: { slidesPerView: 4, spaceBetween: 10 },
              1200: { slidesPerView: 5, spaceBetween: 10 }, // Updated to 5 slides per view
            }}
          >
            {ProductList.map((item, i) => (
              <SwiperSlide key={i}>
                <Card
                  style={{
                    width: "100%",
                    height: "auto",
                    border: catImgCardhoverIndex === i ? "1px solid #D3D3D3" : "none",
                    cursor: "pointer",
                    boxShadow: "none",
                    justifyContent: "center", // Updated to center content
                    display: "flex",
                    flexWrap: "wrap",
                    padding: '0px',
                    borderRadius: '10px',
                  }}
                  onClick={() => naviagteToProductDetails(item)}
                  onMouseEnter={() => handleCatImgCardHover(i)}
                  onMouseLeave={() => handleCatImgCardHover(null)}
                >
                  <div style={{ padding: "1.5rem 2rem" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        transition: "transform 0.2s ease",
                        transform: catImgCardhoverIndex === i ? "scale(1.1)" : "scale(1)",
                      }}
                    >
                      <img
                        src={item?.imageUrl ? item?.imageUrl : require("../../assets/images/placeholder-image.png")}
                        style={{ height: "140px", width: "150px", objectFit: "contain", borderRadius: '15px' }}
                        alt={item?.productName}
                      />
                      <div className="ms-3 text-center">
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: 'Gabarito',
                            color: 'rgba(144, 140, 140, 1)',
                            lineHeight: '16.8px',
                            textTransform: 'uppercase',
                          }}
                        >
                          {item?.category?.categoryName}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: 'rgba(50, 50, 50, 1)',
                            lineHeight: '19.2px',
                            textDecoration: catImgCardhoverIndex === i ? "underline" : "none",
                          }}
                          className="mt-3"
                        >
                          {item?.productName}
                        </div>
                        <div className="mt-2 mb-1">
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={2}
                            justifyContent="center"
                          >
                            <StarRatingComponent
                              starRating={item?.overallRating}
                              size="small"
                              readOnly={true}
                            />
                            {item?.ratings && item?.ratings?.length > 0 && (
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: 500,
                                  lineHeight: '16.8px',
                                }}
                              >
                                {item?.ratings?.length > 1
                                  ? `( ${item?.ratings?.length} Ratings )`
                                  : `( ${item?.ratings?.length} Rating )`
                                }
                              </div>
                            )}
                          </Stack>
                        </div>
                        <div style={{ fontSize: '22px', lineHeight: '26.4px', fontWeight: 600 }} className="mt-2">
                          ₹ {item?.price}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  };
  
  const renderSubscriptionButton = () => {
    return (
      <Grid container justifyContent="space-around">
        <Grid item xs={5}>
          <div>
            <div
              style={{ fontWeight: 400,fontFamily:'Gabarito', fontSize: "20px", textAlign: "center" }}
            >
              Subscribe to our Newsletter
            </div>
            <div className="mt-4" style={{ textAlign: "center" }}>
              <Paper
                style={{
                  border: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "20px",
                }}
              >
                <InputBase
                  value={""}
                  sx={{
                    ml: 1,
                    flex: 1,
                    paddingLeft: "25px",
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily:'Gabarito',
                    color: "#908C8C",
                  }}
                  placeholder="Enter you email address here"
                />
                <div
                  style={{
                    backgroundColor: "#F3F0F0",
                    display: "flex",
                    alignItems: "center",
                    padding: "0.7rem 3rem",
                    borderRadius: "20px",
                    justifyContent: "center",
                    borderLeftWidth: "1.5px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#000000",
                  }}
                >
                  <Typography
                    component="div"
                    className="ps-0"
                    style={{
                      fontSize: "14px",
                      color: "#000",
                      cursor: "pointer",
                      fontFamily:'Gabarito',
                      fontWeight: 400,
                    }}
                  >
                    Subscribe
                  </Typography>
                </div>
              </Paper>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ fontSize: "14px", color: "#908C8C" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
        </Grid>
      </Grid>
    );
  };

  const boxData = [
    { image: 'brainbox.png', link: '/hub/student' },
    { image: 'agrisure.png', link: '/hub/farmer' },
    { image: 'xbo.png', link: '/hub/market-place' },
    { image: 'affiliate.png', link: '/affiliate' },
  ];
  
  const handleImageClick = (link) => {
    window.location.href = link; // Navigate to the link
  };
  
  const renderCheckThisOut = () => {
    return (
      <div>
        {/* Added marginTop to move the "Check this out" title down */}
        <div style={{ marginTop: '20px' , fontWeight:'600'}}> 
          {renderTitleContainer("Check this out")}
        </div>
        <div className="py-4" style={{ marginTop: '-25px',marginRight:'30px' }}>
          <Stack
            direction={{
              xs: 'column',
              md: 'row'
            }}
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            flexWrap={'wrap'}
          >
            {boxData?.map((item, index) => {
              return (
                <div 
                  key={index} 
                  onClick={() => handleImageClick(item.link)} 
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={require(`../../assets/checkthisout/${item.image}`)}
                    alt={`Image ${index + 1}`}
                    style={{marginTop :'15px', width:'332px', borderRadius: '15px'}}
                  />
                </div>
              );
            })}
          </Stack>
        </div>
      </div>
    );
  };
  
  
  const renderTopCategories = () => {
    return (
      <div style={{fontFamily:"Gabarito, sans-serif"}}>
        {renderTitleContainer("Top Categories to Expolre")}
        <div className="py-4">
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={"20px"}
          >
            {topCategoryData?.map((item) => {
              return (<Stack direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}>
                <Avatar
                sx={{ bgcolor:'#F3F0F0', width: 120, height: 120 }}
                  
                >

               <img
                    src={require(`../../assets/topCategories/${item.icon}`)}
                    alt="topCat"
                  />
                   
                </Avatar>
                <Typography sx={{fontFamily:'Gabarito'}}>{item?.categoryName}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </div>
      </div>
    );
  };

  const renderProductData = (product, vertical) => {
    return (<Card
      onClick={() => naviagteToProductDetails(product)}
    // onMouseEnter={() => handleTrendingCardHover(i)}
    // onMouseLeave={() => handleTrendingCardHover(null)}
    >
    <Stack
            direction={vertical ? 'row' : 'column'}
            // direction={"column"}
            justifyContent="center"

            alignItems="center"
            sx={{
              padding: '20px',
              '@media (max-width: 600px)': {
                flexDirection: 'column',  
                padding:'0px !important',
              },
            }}>
      <img
        alt="productImage"
        src={product?.imageUrl ? product.imageUrl : require('../../assets/images/placeholder-image.png')}
        style={{ height: '140px', width: '140px', objectFit: 'contain', padding: '20px' ,borderRadius: '15px'}}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"

        spacing={1}
        alignSelf={vertical ? '' : 'center'}
        sx={{
          padding: '20px',
          textAlign: 'left',  
          '@media (max-width: 600px)': {
            alignItems: 'center',  
          },
        }}
      >
        <Typography variant="caption" sx={{ color: '#908C8C' , textTransform: 'uppercase',  fontSize: "14px",  fontWeight: 600,color: 'rgba(144, 140, 140, 1)',lineHeight:'16.8px',}}>
          {product?.category?.categoryName}
        </Typography>
        <Typography variant="body2" noWrap style={{fontSize: "16px",
                                  fontWeight: 400,
                                  color:'rgba(50, 50, 50, 1)',
                                  lineHeight:'19.2px',}}>
          {product?.productName}
        </Typography>
        <Stack direction="row" alignItems="center"  flexWrap =" wrap" spacing={2}>
          <StarRatingComponent starRating={product?.overallRating} size="medium" readOnly={true} />
          {product?.ratings && product?.ratings?.length > 0 ? (
            <div style={{ fontSize: "14px",
              color: "#000",
              fontWeight: 500,
              lineHeight:'16.8px', }}>
              {product.ratings.length > 1 ? `( ${product.ratings.length}  )` : `( ${product.ratings.length}  )`}
            </div>
          ) : null}
        </Stack>
        <Typography variant="body1" sx={{ fontSize: '22px', lineHeight:'26.4px', fontWeight: 600,}}>{`Rs. ${product?.price}`}</Typography>
      </Stack>
    </Stack>

    </Card>)
  }

  const renderProductsSliderSection = (title, productsData, verical = true) => {
    return (<div className="my-4">{renderTitleContainer(title)}
    <div className="mt-4">

    <Swiper
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper custom-swiper"
        breakpoints={{
          360: { slidesPerView: 1, spaceBetween: 10 },
          576: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 3, spaceBetween: 10 },
          992: { slidesPerView: verical? 4 : 5, spaceBetween: 10 },
          1200: { slidesPerView: verical? 4 : 5, spaceBetween: 10 },
        }}
      >
        {productsData?.map((item, i) => (
          <SwiperSlide key={i}>
            {renderProductData(item, verical)}
          </SwiperSlide>
        ))}
      </Swiper>

    </div>
    </div>)
  }

  const renderProductSection = (title, productMap, plainBackgroundCard = false) => {
    return (
      <div className="my-4">
        {renderTitleContainer(title)}
        <div className="mt-4">
          <Swiper
            navigation={true}
            pagination={{ clickable: true }}
            modules={[Pagination, Navigation]}
            className="mySwiper custom-swiper"
            breakpoints={{
              360: { slidesPerView: 1, spaceBetween: 10 },
              576: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 3, spaceBetween: 10 },
              992: { slidesPerView: 4, spaceBetween: 10 },
              1200: { slidesPerView: 5, spaceBetween: 10 },
            }}
          >
            {productMap?.map((item, i) => {
              if (i < 5) {
                return (
                  <SwiperSlide key={i}>
                    <Card
                      style={{
                        marginBottom: "10px",
                        width: "254px",
                        height: "350px",
                        boxShadow: "none",
                        cursor: "pointer",
                        borderRadius: '10px',
                        backgroundColor: plainBackgroundCard ? "#fff" : "#F3F0F0",
                      }}
                      className="productcard"
                      onClick={() => naviagteToProductDetails(item)}
                    >
                      <div className="py-5" >
                        <div
                          style={{
                            transition: "transform 0.2s ease", // Add transition for smooth zoom
                          }}
                        >
                          <div style={{ textAlign: "center", fontFamily: 'Gabarito' }}>
                            <img
                              src={
                                item?.imageUrl
                                  ? item?.imageUrl
                                  : require("../../assets/images/placeholder-image.png")
                              }
                              style={{ height: "150px", width: "140px"  ,borderRadius: '15px'}}
                            />
                            <div
                              className="mt-3"
                              style={{
                                textAlign: "start",
                                paddingLeft: "2.5rem",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: 'rgba(144, 140, 140, 1)',
                                  lineHeight:'16.8px',
                                  textTransform: 'uppercase',
                                }}
                              >
                                {item?.category?.categoryName}
                              </div>
                              <div
                                className="mt-2"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  color:'rgba(50, 50, 50, 1)',
                                  lineHeight:'19.2px',
                                }}
                              >
                                {item?.productName}
                              </div>
                              <div className="mt-2 mb-1">
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={2}
                                >
                                  <StarRatingComponent
                                    starRating={item?.overallRating}
                                    size="medium"
                                    readOnly={true}
                                  />
                                  {item?.ratings && item?.ratings?.length > 0
                                    ? <div
                                      style={{
                                        fontSize: "14px",
                                        color: "#000",
                                        fontWeight: 500,
                                        lineHeight:'16.8px',
                                      }}
                                    >
                                      {item?.ratings?.length > 1
                                        ? `( ${item?.ratings?.length} Ratings )`
                                        : `( ${item?.ratings?.length} Rating )`
                                      }
                                    </div>
                                    : null
                                  }
                                </Stack>
                              </div>
                              <div
                                className="mt-2"
                                style={{ fontSize: '22px', lineHeight:'26.4px', fontWeight: 600,}}
                              >
                                ₹ {item?.price}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </SwiperSlide>
                );
              }
              return null;
            })}
          </Swiper>
        </div>
      </div >
    );
};


  const renderDailySection = () => {
    return (
      <div>
        {renderTitleContainer("Featured")}
        <div className="p-4">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "1rem",
            }}
          >
            {FeaturedProducts.slice(0, 6).map((item, i) => (
              <Card
                key={i}
                style={{
                  // width: "100%",
                  height: "auto",
                  border:
                    dailyDealsCardhoverIndex === i
                      ? "1px solid #D3D3D3"
                      : "none",
                  cursor: "pointer",
                  boxShadow: "none",
                }}
                onClick={() => naviagteToProductDetails(item)}
                onMouseEnter={() => handleDailyDealsCardHover(i)}
                onMouseLeave={() => handleDailyDealsCardHover(null)}
              >
                <div style={{ padding: "1rem" }}>
                  <div
                    style={{
                      marginLeft: dailyDealsCardhoverIndex === i ? "20px" : 0,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      transition: "transform 0.2s ease", // Add transition for smooth zoom
                      transform:
                        dailyDealsCardhoverIndex === i
                          ? "scale(1.1)"
                          : "scale(1)", // Zoom effect on hover
                    }}
                  >
                    <img
                      src={
                        item?.imageUrl
                          ? item?.imageUrl
                          : require("../../assets/images/placeholder-image.png")
                      }
                      style={{ height: "120px", width: "30%", minWidth: "80px"  ,borderRadius: '15px'}}
                    />
                    <div className="ps-3">
                      <div
                        style={{
                          fontSize: "16px",
                                  fontWeight: 400,
                                  color:'rgba(50, 50, 50, 1)',
                                  lineHeight:'19.2px',
                        }}
                        className="mt-3"
                      >
                        {item?.productName}
                      </div>
                      <div
                        className="mt-2 mb-1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <StarRatingComponent
                          starRating={item?.overallRating}
                          size="medium"
                          readOnly={true}
                        />
                        {item?.ratings && item?.ratings?.length > 0
                          ? <div
                            style={{
                            
                              marginLeft: '5px',
                              fontSize: "14px",
                                        color: "#000",
                                        fontWeight: 500,
                                        lineHeight:'16.8px',
                            }}
                          >
                            {item?.ratings?.length > 1
                              ? `( ${item?.ratings?.length} Ratings )`
                              : `( ${item?.ratings?.length} Rating )`
                            }
                          </div>
                          : null
                        }
                      </div>
                      <div
                        style={{ fontSize: '22px', lineHeight:'26.4px', fontWeight: 600,}}
                        className="mt-2"
                      >
                        ₹ {item?.price}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderFashionContainer = (type) => {
    
  
    const handleViewMore = () => {
      // Navigate to the product search page
      navigate('/product-search', { state: { category: type } });
    };
  
    return (
      <div >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          {renderTitleContainer(`Best of ${type}`)}
          <Button 
  variant="text" 
  onClick={handleViewMore}
  style={{
    color: '#23c2f2',
    fontFamily: 'Gabarito',
    textTransform: 'none',
    marginRight:'10px',
    border: 'none', // Ensure no border is displayed
    backgroundColor: 'transparent' // Optional: ensure no background color
  }}
>
  View More
</Button>

        </div>
        <div className="py-4 px-3">
  <Grid
    container
    spacing={3}
    direction={{ xs: 'column', md: 'row' }}
    alignItems="center"
    justifyContent="space-between"

  >
    <Grid item xs={12} md={6}>
  <a href="/product-search" target="_blank" rel="noopener noreferrer">
    <img
      id="best_of_home_pic"
      src={require(`../../assets/banner/best of fashion.png`)}
      style={{
        width: "100%",
        height: "387px",
        borderRadius: "10px",
      }}
      alt="Hero Banner 1"
    />
  </a>
</Grid>
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        {ProductList.slice(0, 4).map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'none',
                cursor: 'pointer',
              }}
              onClick={() => naviagteToProductDetails(item)}
            >
              <div style={{ padding: '1rem', display: 'flex', gap:'20px', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'start' } }}>
                <img
                  src={item.imageUrl ? item.imageUrl : require('../../assets/images/placeholder-image.png')}
                  alt={item.productName}
                  style={{
                    width: { xs: '100%', md: '80%' },
                    height: '150px',
                    objectFit: 'contain',
                    borderRadius: '10px',
                  }}
                />
                <div style={{ padding: '0.5rem 0', textAlign: { xs: 'center', md: 'left' } }}>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: 'Gabarito',
                      color: 'rgba(144, 140, 140, 1)',
                      lineHeight: '16.8px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {item?.category?.categoryName}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                                  fontWeight: 400,
                                  color:'rgba(50, 50, 50, 1)',
                                  lineHeight:'19.2px',
                    }}
                  >
                    {item.productName}
                  </div>
                  <div className="mt-2 mb-1" style={{ display: "flex", alignItems: "center", flexWrap:'wrap', justifyContent: { xs: 'center', md: 'start' } }}>
                    <StarRatingComponent
                      starRating={item?.overallRating}
                      size="medium"
                      readOnly={true}
                    />
                    {item?.ratings && item?.ratings?.length > 0
                      ? <div
                        style={{
                        
                          marginLeft: '5px',
                          fontSize: "14px",
                                        color: "#000",
                                        fontWeight: 500,
                                        lineHeight:'16.8px',
                        }}
                      >
                        {item?.ratings?.length > 1
                          ? `( ${item?.ratings?.length} )`
                          : `( ${item?.ratings?.length} )`
                        }
                      </div>
                      : null
                    }
                  </div>
                  <div style={{ fontSize: '22px', lineHeight:'26.4px', fontWeight: 600, fontFamily: 'Gabarito', marginTop: '0.5rem', textAlign: { xs: 'center', md: 'left' } }}>
                    ₹ {item.price}
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
</div>
      </div>
    );
  };

  const renderPortalCards = () => {
    return (
      <div>
        <div className="mb-4" style={{fontWeight:'600'}}>{renderTitleContainer("Check this out")}</div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <a href="/hub/student" target="_blank" rel="noopener noreferrer">
              <img className="chechout_images"
                src={require(`../../assets/banner/Hero 2.png`)}
                style={{ width: "100%",  borderRadius: 5, objectFit: 'contain' }}
                alt="First Image"
              />
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a href="/hub/farmer" target="_blank" rel="noopener noreferrer">
              <img className="chechout_images"
                src={require(`../../assets/banner/Hero 3.png`)}
                style={{ width: "100%",  borderRadius: 5, objectFit: 'contain' }}
                alt="Second Image"
              />
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            <a href="/hub/market-place" target="_blank" rel="noopener noreferrer">
              <img className="chechout_images"
                src={require(`../../assets/banner/Hero 5.png`)}
                style={{ width: "100%",  borderRadius: 5, objectFit: 'contain' }}
                alt="Third Image"
              />
            </a>
          </Grid>
        </Grid>
      </div>
    );
  };
  

  const renderPortalCard = () => {
    return (
      <div>
        <div className="mb-4">
          {renderTitleContainer(
            <>
              Consider registering as an{' '}
              <a href="/affiliate" style={{ color: 'skyblue', textDecoration: 'underline' }}>
                Affiliate
              </a>
            </>
          )}
        </div>
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <a href="/affiliate" target="_blank" rel="noopener noreferrer">
            <img
              className="chechout_images"
              id="footer_hero_image"
              src={require(`../../assets/banner/affiliate.png`)}
              style={{
                borderRadius: 5,
                width: '100%',
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
              alt="Footer Hero Image"
            />
          </a>
        </Grid>

        </Grid>
      </div>
    );
  };


  const homeRenderData = [
    { component: renderMainSection, marginTop: "mt-3" },
    { component: renderCheckThisOut, marginTop: "mt-5" },
    { component: renderTopCategories, marginTop: "mt-5" },
    { component: () => renderProductsSliderSection("Featured Items", FeaturedProducts), marginTop: "mt-5" },
    { component: () => renderProductsSliderSection("Trending Now", ProductList, false), marginTop: "mt-5" },

    { component: () => renderProductsSliderSection("Recently Viewed", ProductList), marginTop: "mt-5" },

    
    // { component: renderMostViewedProductSection, marginTop: "mt-5" },
    { component: renderDailySectionWithBanner, marginTop: "mt-5" },
    { component: () => renderPortalCards(), marginTop: "mt-5" },
    // { component: renderLatestProductSection, marginTop: "mt-5" },
    { component: () => renderProductSection("Most Viewed", MostViewedProducts, true), marginTop: "mt-5" },
    // {
    //   component: () => renderFashionContainer("Home and Living"),
    //   marginTop: "mt-5",
    // },
    {
      component: () => renderFashionContainer("Fashion"),
      marginTop: "mt-5",
    },

    {
      component: () => renderMostViewedProductSection("Top Selling"),
      marginTop: "mt-5",
    },
    
    { component: () => renderPortalCard(), marginTop: "mt-5" },


  ];

  return (
    <Fragment>
      <div className="px-5" >
        {homeRenderData?.map((section, index) => (
          <div className={section.marginTop} key={index}>
            {section.component()}
          </div>
        ))}
      </div>
      <LoginModal
        showSignin={showSignin}
        setShowSignin={setShowSignin}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </Fragment>
  );
};

export default memo(NewHomeList);
