import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, Checkbox, Grid, Stack, Typography } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { postMethod } from '../../helpers/index';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useDispatch } from 'react-redux';
import { setIsHubUser } from '../auth/redux/AuthAction';
import StepperComponent from '../../Components/StepperComponent';
import PhoneInput from 'react-phone-input-2';
import NewHeader from '../../Components/NewHeaderView';
import NewFooter from '../../Components/NewFooter';
import CommonTextField from '../../Components/CommonTextField';
import { storeLoginData } from '../auth/redux/AuthAction';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    datePickerOverride: {
        width: '100%',
        '& .MuiInputBase-root': {
            height: '40px',
            padding: '0 14px',
            borderRadius: '10px'
        },
        '& .MuiInputLabel-root': {
            top: '0px',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px 14px'
        },
    },
    root: {
        marginBottom: '20px'
    },
    selectField: {
        height: '45px',
        '& .MuiSelect-select': {
            padding: '8px 14px',
            borderRadius: '10px',
        },
        '& .MuiInputBase-root': {
            height: '45px',
            borderRadius: '10px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
        },
    },
}));

const HubFormComponent = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectType, setSelectType] = useState('Farmer');
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    const steps = ['Login Info', 'Address Details'];

    const [formData, setFormData] = useState({
        hubType: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        dob: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        district: "",
        pincode: "",
        classgrade: "",
        institute: "",
        instituteCity: ""
    })

    const stepList = useMemo(() => {
        if (selectType === 'Student') {
            return [...steps, 'Educational Details']
        }
        return steps;
    }, [selectType]);

    useEffect(() => {
        if (User) {
            const { firstName, lastName, email, phoneNumber } = User;
            setFormData({
                ...formData,
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phoneNumber
            })
        }
    }, []);

    const handleSelectTypeOnChange = (e) => {
        setSelectType(e.target.value);
        setFormData({
            ...formData,
            hubType: e.target.value
        })
        setActiveStep(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateOnChange = (date) => {
        setFormData({
            ...formData,
            dob: dayjs(date.$d).format('YYYY-MM-DD')
        });
    }

    const handleSubmit = async () => {
        try {
            let url = "customers/secure/hub/signup";
            let token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload: formData });
            if (response.data) {
                dispatch(storeLoginData(response.data));
                console.log(response);
                dispatch(setIsHubUser(true));
                navigateToPortal(response.data.hubUser);
            } else {
                console.log("err", response);
            }
        } catch (e) {
            console.log(e);
        }
    };
    
    const navigateToPortal = (hubUser) => {
        if (hubUser.Student) {
            navigate('/hub/student');
        } else if (hubUser.Farmer) {
            navigate('/hub/farmer');
        } else if (hubUser.MarketPlace) {
            navigate('/hub/market-place');
        }else{
            navigate('/hub');
        }
    };

    const handleNext = () => {
        if (activeStep === stepList?.length - 1) {
            handleSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePhoneChange = (phone) => {
        setFormData({
            ...formData,
            phone_number: phone
        })
    }

    return (
        <div>
        <NewHeader screenName={'Login'}>
          <Grid container className={isMobile ? 'my-2' : 'my-5'}>
            <Grid item xs={12}>
              <Stack
                spacing={isMobile ? 1 : 2}
                direction={isMobile ? 'column' : 'row'}
                alignItems={'center'}
                justifyContent={'space-around'}
              >
                <div
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    color: '#5C5B5B',
                  }}
                >
                  Complete Your UrbanWallah Hub Registration
                </div>
                <RadioGroup
                row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
                
                name="row-radio-buttons-group"
                value={selectType}
                onChange={handleSelectTypeOnChange}
              >
                                <FormControlLabel
                                    value="Farmer"
                                    control={<Radio
                                        sx={{
                                            transform: 'scale(0.8)',
                                            color: '#2DBDEE',
                                            '&.Mui-checked': {
                                                color: '#2DBDEE',
                                            },
                                        }} />}
                                    label={<span style={{ fontSize: '0.9em' }}>Farmer</span>}
                                />
                                <FormControlLabel
                                    value="Market Place"
                                    control={<Radio
                                        sx={{
                                            transform: 'scale(0.8)',
                                            color: '#2DBDEE',
                                            '&.Mui-checked': {
                                                color: '#2DBDEE',
                                            },
                                        }} />}
                                    label={<span style={{ fontSize: '0.9em' }}>Market Place</span>}
                                />
                                <FormControlLabel
                                    value="Student"
                                    control={<Radio
                                        sx={{
                                            transform: 'scale(0.8)',
                                            color: '#2DBDEE',
                                            '&.Mui-checked': {
                                                color: '#2DBDEE',
                                            },
                                        }} />}
                                    label={<span style={{ fontSize: '0.9em' }}>Student</span>}
                                />
                            </RadioGroup>
                        </Stack>
                    </Grid>
                </Grid>
                <StepperComponent activeStep={activeStep} stepsData={stepList}>
          <Box component="form">
            {activeStep === 0 && (
              <div>
                <Stack
                  style={{ marginBottom: isMobile ? '0.4rem' : '1rem' }}
                  spacing={isMobile ? 1 : 2}
                  direction={isMobile ? 'column' : 'row'}
                  alignItems={'center'}
                >
                                    <CommonTextField
                                        id='first-name'
                                        labelName='First Name'
                                        name='first_name'
                                        value={formData.first_name}
                                        handleChange={handleChange}
                                        disabled={formData?.first_name}
                                    />
                                    <CommonTextField
                                        id='last-name'
                                        labelName='Last Name'
                                        name='last_name'
                                        value={formData.last_name}
                                        handleChange={handleChange}
                                        disabled={formData?.last_name}
                                    />
                                </Stack>
                                <CommonTextField
                                    id='email'
                                    labelName='Email'
                                    name='email'
                                    value={formData.email}
                                    handleChange={handleChange}
                                    disabled={formData?.email}
                                    style={{ marginBottom: '1rem' }}
                                />
                                    <FormControl
                                    fullWidth
                                    variant="outlined"
                                    style={{ marginBottom: isMobile ? '0.5rem' : '1rem' }}>                                    <InputLabel
                                        htmlFor="phone-input"
                                        style={{
                                            fontSize: '15px',
                                            position: 'relative',
                                            transform: 'none',
                                            color: '#000'
                                        }}
                                    >
                                        Phone Number
                                    </InputLabel>
                                    <PhoneInput
                                        country={'in'}
                                        preferredCountries={['in']}
                                        value={`91${formData?.phone_number}`}
                                        onChange={handlePhoneChange}
                                        disabled={`91${formData?.phone_number}`}
                                        placeholder="Enter phone number"
                                        inputProps={{
                                            id: 'phone-input',
                                            style: {
                                                height: '40px',
                                                padding: '0 3rem',
                                                borderRadius: '10px',
                                                width: '100%',
                                            },
                                        }}
                                        containerStyle={{ marginTop: '10px' }} // Adjust the margin to position below the label
                                    />
                                </FormControl>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <InputLabel
                    htmlFor="dob"
                    style={{
                      fontSize: '15px',
                      position: 'relative',
                      transform: 'none',
                      color: '#000',
                    }}
                  >
                    Date Of Birth
                  </InputLabel>
                  <DatePicker
                    value={formData?.dob}
                    onChange={handleDateOnChange}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        name="dob"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    className={clsx(
                      isMobile ? 'mb-2 mt-1' : 'mb-3 mt-2',
                      classes.datePickerOverride
                    )}
                  />
                </LocalizationProvider>
                                <FormControl variant="outlined" fullWidth>
                                    <Typography variant="body1" className={classes.selectLabel}>
                                        Gender
                                    </Typography>
                                    <Select
                                        id="demo-simple-select-helper"
                                        value={formData?.gender}
                                        name="gender"
                                        onChange={handleChange}
                                        className={`${classes.selectField} mt-2`}
                                    >
                                        <MenuItem value={"Male"}>Male</MenuItem>
                                        <MenuItem value={"Female"}>Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {activeStep === 1 && (
                            <div>
                                <CommonTextField
                                    id='addressLine1'
                                    labelName='Address Line 1'
                                    name='addressLine1'
                                    value={formData.addressLine1}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='addressLine2'
                                    labelName='Address Line 2'
                                    name='addressLine2'
                                    value={formData.addressLine2}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                    className='my-2'
                                />
                                <Stack className='my-2' spacing={2} direction={'row'} alignItems={'center'}>
                                    <CommonTextField
                                        id='city'
                                        labelName='City'
                                        name='city'
                                        value={formData.city}
                                        handleChange={handleChange}
                                    />
                                    <CommonTextField
                                        id='district'
                                        labelName='District'
                                        name='district'
                                        value={formData.district}
                                        handleChange={handleChange}
                                    />
                                </Stack>
                                <Stack className='my-2' spacing={2} direction={'row'} alignItems={'center'}>
                                    <CommonTextField
                                        id='state'
                                        labelName='State'
                                        name='state'
                                        value={formData.state}
                                        handleChange={handleChange}
                                    />
                                    <CommonTextField
                                        id='pincode'
                                        labelName='Pincode'
                                        name='pincode'
                                        value={formData.pincode}
                                        handleChange={handleChange}
                                    />
                                </Stack>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div>
                                <CommonTextField
                                    id='classgrade'
                                    labelName='Class/Grade'
                                    name='classgrade'
                                    value={formData.classgrade}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='institute'
                                    labelName='Institute Name'
                                    name='institute'
                                    value={formData.institute}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='instituteCity'
                                    labelName='Institute City'
                                    name='instituteCity'
                                    value={formData.instituteCity}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                            </div>
                        )}
                    </Box>
                    {activeStep === stepList?.length - 1 && (
            <div
              className={`mt-${isMobile ? '1' : '3'} d-flex align-items-center justify-content-center`}
            >
              <Checkbox
                sx={{
                  transform: 'scale(1.2)',
                  '& .MuiSvgIcon-root': { fontSize: 18 },
                  '&.Mui-checked': {
                    color: '#2DBDEE',
                  },
                  '&.MuiCheckbox-root': {
                    color: '#2DBDEE',
                  },
                }}
              />
              <div style={{ fontSize: isMobile ? '10px' : '13px' }}>
                Accept Terms and Conditions & Privacy policy
              </div>
            </div>
          )}
                    <Stack
            className={`mt-${activeStep === stepList?.length - 1 ? '3' : '4'}`}
            direction={'row'}
            spacing={activeStep !== 0 ? (isMobile ? 1 : 3) : 0}
            alignItems={'center'}
            justifyContent={'center'}
          >
                        {activeStep !== 0 && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#2DBDEE',
                  width: isMobile ? '120px' : '150px',
                }}
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Back
              </Button>
            )}
                       <Button
              variant="contained"
              style={{
                backgroundColor: '#2DBDEE',
                width: isMobile ? '120px' : '150px',
              }}
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}
              disabled={activeStep === stepList?.length}
            > {activeStep === stepList?.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Stack>
        </StepperComponent>
      </NewHeader>
      <div style={{ height: isMobile ? '25vh' : '50vh' }} />
      <NewFooter screenName={'Login'} />
    </div>
    )
}

export default HubFormComponent;
