import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { postMethod } from "../helpers";
import Card from '@mui/material/Card';

function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();

  const sendOTP = async (e) => {
    e.preventDefault();
    try {
      let url = "users/send-otp";
      var payload = {
        email: email,
        userType: "Customer",
      };
      let response = await postMethod({ url, payload });
      if (response.success) {
        localStorage.setItem("tempid", response?.data?._id);
        toast.success(response.message);
        navigate("/otpverification");
        setEmail("");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.log("api err", e);
      toast.error(e.response.message);
      setEmail("");
    }
  };
  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "#000",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "#fff",
            },
          }
        }}
      />
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ backgroundColor: '#FFFFFF', width: '500px', height: '100vh' }} className="d-flex align-items-center">
            <Col>
              <div className="login_form_details">
                <div className="log_details">
                  <img
                    style={{ width: '160px', height: '100px', cursor: 'pointer' }}
                    src={require("../assets/AppLogo/UW-Logo.png")}
                    alt="app-logo"
                  />
                  <h4 className="welcome_txt mt-3">Forgot Passowrd</h4>
                  <p className="hint_txt">Reset your password</p>
                  <div className="login_input">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                    <i className="fa-solid fa-envelope login_icons"></i>
                  </div>

                  {/* <Link className='signup_txt' to='/otpverification'> */}
                  <button onClick={sendOTP} className="common_blubtn mt-4">
                    Send OTP
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </Col>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default ForgotPassword;
