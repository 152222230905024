import React from 'react';

import { Rating } from '@mui/material';
import StarIcon from "@mui/icons-material/Star";

const StarRatingComponent = ({ starRating = 0, size = "medium", readOnly = false }) => {
    return (
        <Rating
            name="rating"
            value={starRating}
            size={size}
            readOnly={readOnly}
            style={{ fontSize: "15px", color: "#FDDA04" }}
            emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55 }}
                  fontSize="inherit"
                />
              }
        />
    )
};

export default StarRatingComponent;