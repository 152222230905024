import { Card, Divider, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMethod } from '../../helpers';
import { getProfileAPIAction } from '../Profile/redux/ProfileAction';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import OnlineAffiliateList from './OnlineAffiliateList';
import { Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import LandingOfflineAffiliate from './LandingOfflineAffiliate';
import LandingAffiliate from './LandingAffiliate';
import AffiliateLogo from '../../assets/AppLogo/logoO.svg';

const dashboardData = [
    {
        title: 'Affilate Settlement',
        value: '₹ 48,000',
        icon: <AccountBalanceIcon style={{ width: '0.8em', height: '0.8em' }} />,
        value2: '+2,000'
    },
    {
        title: 'Tickets Sold',
        value: '8,433',
        icon: <ConfirmationNumberIcon style={{ width: '0.8em', height: '0.8em' }} />,
        value2: '+135'
    },
    {
        title: 'Product Count',
        value: '754',
        icon: <AccountBalanceIcon style={{ width: '0.8em', height: '0.8em' }} />,
        value2: '+20'
    },
    {
        title: 'Total Revenue',
        value: '₹ 3,00,000',
        icon: <AccountBalanceIcon style={{ width: '0.8em', height: '0.8em' }} />,
        value2: '+42,000'
    },
    {
        title: 'Seller"s Settlement',
        value: '₹ 48,000',
        icon: <AccountBalanceIcon style={{ width: '0.8em', height: '0.8em' }} />,
        value2: '+2,000'
    },
    {
        title: 'Customer Count',
        value: '₹ 6,400',
        icon: <AccountBalanceIcon style={{ width: '0.8em', height: '0.8em' }} />,
        value2: '+1,200'
    },
]
const CommonAffiliate = ({ }) => {

    const profileData = useSelector(state => state.profileState.profileData)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const affiliateType = location?.state?.affiliateType;

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);
    let affiliateData = localStorage.getItem("affiliateData");
    let affiliateObj = JSON.parse(affiliateData);

    useEffect(() => {
        if (!User) {
            navigate('/login');
        }
    }, [User]);

    useEffect(() => {
        makeGetProfileAPI();
    }, []);

    const capitalizeName = (fullName) => {
        const parts = fullName?.split(' ');
        const capitalizedParts = parts?.map(part => {
            return part?.charAt(0)?.toUpperCase() + part?.slice(1)?.toLowerCase();
        });
        return capitalizedParts?.join(' ');
    }

    const makeGetProfileAPI = async () => {
        try {
            let url = "/customers/secure/profile";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                dispatch(getProfileAPIAction(response.data))
            } else {
            }
        } catch (e) {
        }
    }

    return (
        affiliateType === 'Offline' && !User?.isOfflineAffiliate
            ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                <img src={AffiliateLogo} style={{ width: '60px', height: '60px' }} className='mb-3' />
                <div style={{ fontSize: '18px', fontWeight: 600 }}>
                    Please Contact Admin To Register As Offline Affiliate
                </div>
            </div>
            : <div className="px-5">
                <Divider sx={{ borderBottom: '1px solid #000' }} />
                <div style={{ fontSize: '14px', fontWeight: 400 }} className='my-3'>
                    Welcome {capitalizeName(profileData?.customer?.userName)},
                </div>
                <style>
                    {`
                        @media (max-width : 600px){
                         #common-affilate-mobile-design{
                         flex-direction:column;
                         }
                         #hr_line{
                         display:none
                         }
                        }
                    `}
                </style>
                <Grid container className='mt-4' id="common-affilate-mobile-design">
                    <Grid item xs={4.5}>
                        {affiliateType === 'Online'
                            ? <LandingAffiliate />
                            : <LandingOfflineAffiliate />
                        }
                    </Grid>
                    <Grid item xs={0.5} id="hr_line">
                        <div style={{ height: '250px', width: '1px', backgroundColor: '#AEADAD', margin: '0 10px' }} className="mx-3"></div>
                    </Grid>
                    <Grid item xs={7}>
                        <div className='mb-5'>
                            <div style={{ color: '#AEADAD', fontSize: '15px', marginLeft: '1.5rem' }}>
                                Dashboard
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }} className='mt-3'>
                                {dashboardData.map(item => {
                                    return (
                                        <div
                                            style={{ border: '1px solid #AEADAD', color: '#AEADAD', fontSize: '12px', borderRadius: '10px', minWidth: '200px', fontWeight: 600 }}
                                            className='px-3 py-3 mb-4 ms-4'
                                        >
                                            {item.title}
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ color: '#000', fontWeight: 600, fontSize: '16px' }}>
                                                    {item.value}
                                                </div>
                                                <div style={{ color: '#000' }}>
                                                    {item.icon}
                                                </div>
                                            </div>
                                            <div style={{ color: '#6EB35F', fontSize: '10px' }}>
                                                {item.value2}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            Urban ticket insights
                        </div>
                        <div>
                            <button style={{ backgroundColor: '#FA895B', color: '#fff', border: 'none', fontSize: '10px', fontWeight: '600' }} className='px-4 py-2'>
                                Request new tickets
                            </button>
                        </div>
                    </div>
                    <div className='my-4'>
                        <Card style={{ border: '1px solid #AEADAD', boxShadow: 'none' }}>
                            <OnlineAffiliateList />
                        </Card>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            Commission Settlements
                        </div>
                        <div>
                            <button style={{ backgroundColor: '#FA895B', color: '#fff', border: 'none', fontSize: '10px', fontWeight: '600' }} className='px-4 py-2'>
                                Request Payment
                            </button>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <Card style={{ border: '1px solid #AEADAD', boxShadow: 'none' }}>
                            <Table />
                        </Card>
                    </div>
                </div>
            </div>
    )
}

export default CommonAffiliate;