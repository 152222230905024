import React, { useEffect, useState } from 'react';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { Card } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import SideMenuBar from '../../Components/SideMenuBar';
import NewHeader from '../../Components/NewHeader';
import { getMethod } from '../../helpers';
import ComplaintRaiseModal from './ComplaintModal';
import { getAllComplaintApiAction } from './redux/ComplaintAction';

const SupportTicket = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [complaintModalVisible, setComplaintModalVisible] = useState(false);
    const [complaintList, setComplaintList] = useState([]);
    const orderDataList = useSelector((state) => state.orderState.orderDataList);

    useEffect(() => {
        makeAllComplaintsApi();
    }, []);

    const OpenComplaintModal = (modalVisible) => {
        setComplaintModalVisible(modalVisible);
    }

    const handleComplaintModalClose = () => {
        setComplaintModalVisible(false);
    }

    const makeAllComplaintsApi = async () => {
        try {
            let url = "/customers/secure/complaints";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                dispatch(getAllComplaintApiAction(response.data))
                setComplaintList(response.data)
            } else {
            }
        } catch (e) {
        }
    }

    // const handlePageChange = (event, page) => {
    //     setCurrentPage(page);
    //     const startIndex = (page - 1) * 1;
    //     const endIndex = startIndex + 1;
    //     const slicedComplaintList = complaintList?.slice(startIndex, endIndex);
    //     setComplaintList(slicedComplaintList);
    // };

    const getDateFormat = (date) => {
        const dateObject = new Date(date);
        const formattedDate = dateObject.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        return formattedDate;
    }

    return (
        <SideMenuBar>
            <NewHeader
                title={'Support Tickets'}
                icon={<HeadsetMicIcon style={{ width: '1.2em', height: '1.2em', color: '#23c2f2' }} />}
                isAddIcon={true}
                handleAddButtonClick={OpenComplaintModal}
            />
            <div className='mt-2'>
                {complaintList?.map((item) => {
                    return (
                        <Card
                            style={{
                                backgroundColor: '#ffffff',
                                color: '#2b3445',
                                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                boxShadow: '#03004717 0px 1px 3px',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                padding: '15px 24px',
                            }}
                            className='d-flex justify-content-between align-items-center mt-4 mb-2'
                            onClick={() => navigate(`/support-ticket/${item?.complaintNo}`, { state: { id: item?._id } })}
                        >
                            <div style={{ color: '#2b3445', fontSize: '16px' }}>
                                <div style={{ fontWeight: 600 }}>{`#${item?.complaintNo}`}</div>
                                <div className='d-flex align-items-center mt-2' style={{ gap: '15px', color: '#7d879c' }}>
                                    <Chip label={item?.complaintStatus} style={{ color: '#33d067', backgroundColor: '#e7f9ed', fontSize: '0.8125rem' }} />
                                    <span>{getDateFormat(item?.createdAt)}</span>
                                    <span>{item?.subject || 'Subject'}</span>
                                </div>
                            </div>
                            <div>
                                <ArrowForwardOutlinedIcon style={{ color: '#7D879C' }} />
                            </div>
                        </Card>
                    )
                })}
                <div style={{ display: 'flex', justifyContent: 'center' }} className='mt-5'>
                    <Pagination
                        // count={4}
                        // page={currentPage}
                        variant="outlined"
                        color='secondary'
                        // onChange={handlePageChange}
                    />
                </div>
            </div>
            {complaintModalVisible &&
                <ComplaintRaiseModal
                    modalVisible={complaintModalVisible}
                    modalOnClose={handleComplaintModalClose}
                    orderData={orderDataList}
                />}
        </SideMenuBar>
    )
}

export default SupportTicket;