import React, { useEffect } from "react";
import ArticleIcon from '@mui/icons-material/Article';
const TermsAndCondition = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className='container mt-5'>
            <div>
                <div style={{ textAlign: 'center' }}>
                    <ArticleIcon style={{ width: '2em', height: '2em' }} />
                </div>
                <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '10px' }}>
                    Terms And Condition
                </div>
            </div>
            <>
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                {/* <meta charSet="utf-8" />
                <title></title> */}
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p className="p1">1. Introduction</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    1.1. Welcome to Bharat UrbanWallah Pvt Ltd ! These Terms and Conditions
                    govern the sale of products and the use of services ("ecommerce") offered by
                    UrbanWallah ("we", "our", or "us") through our website www.urbanwallah.com.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    1.2. By accessing or using our website and/or purchasing products from us,
                    you agree to be bound by these Terms and Conditions. If you do not agree
                    with all the provisions of these Terms and Conditions, do not access the
                    Website or purchase products from us.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>2. Product Information</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    2.1. We make every effort to ensure that the products displayed on our
                    Website are accurately described and depicted. However, we do not warrant
                    that the product descriptions or other content on our Website are accurate,
                    complete, reliable, current, or error-free.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    2.2. All products are subject to availability. We reserve the right to
                    discontinue any product at any time.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    2.3. Product images are for illustrative purposes only. Actual products may
                    vary slightly from the images displayed on our Website.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    2.4. We reserve the right to limit the quantities of any products that we
                    offer. All descriptions of products or product pricing are subject to change
                    at any time without notice, at our sole discretion.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>3. Ordering Process</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    3.1. To place an order, you must follow the instructions on our Website.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    3.2. By placing an order, you warrant that you are legally capable of
                    entering into a binding contract and that you are at least 18 years old.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    3.3. After placing an order, you will receive an email acknowledging receipt
                    of your order. This email does not constitute acceptance of your order.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    3.4. We reserve the right to refuse any order you place with us. We may, in
                    our sole discretion, limit or cancel quantities purchased per person, per
                    household, or per order.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>4. Price and Payment</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    4.1. The prices of products are as stated on our Website and are subject to
                    change without notice.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    4.2. Payment must be made in full at the time of placing your order.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    4.3. We accept payment by credit card, debit card, PayPal, and other payment
                    methods as indicated on our Website.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    4.4. All payments are processed securely. However, we are not responsible
                    for any errors or delays in payment processing by third-party payment
                    processors.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>5. Delivery</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    5.1. We will deliver the products to the address specified in your order.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    5.2. Delivery timescales are estimates only and are not guaranteed. We will
                    make every effort to deliver the products within the estimated timeframe,
                    but we shall not be liable for any delays in delivery.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    5.3. Risk of loss and damage to the products passes to you upon delivery.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    5.4. If you refuse delivery of any products, you are responsible for all
                    shipping charges incurred by us in relation to the refused delivery.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>6. Returns and Refunds</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    6.1. Our Returns Policy, available on our Website, sets out the terms and
                    conditions governing returns and refunds.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    6.2. To be eligible for a refund, products must be returned in their
                    original condition and packaging within [number] days of delivery.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    6.3. We reserve the right to refuse returns or refunds for products that
                    have been used, damaged, or altered in any way.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    6.4. Refunds will be processed within [number] days of receiving the
                    returned products. The refund will be issued to the original payment method
                    used for the purchase.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>7. Limitation of Liability</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    7.1. To the fullest extent permitted by law, we shall not be liable for any
                    direct, indirect, incidental, special, consequential, or punitive damages
                    arising out of or relating to your use of our Website or the purchase or use
                    of our products.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    7.2. In no event shall our total liability to you for all damages exceed the
                    amount paid by you for the products purchased from us.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>8. Intellectual Property</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    8.1. All content on our Website, including but not limited to text,
                    graphics, logos, images, and software, is the property of [Your Company
                    Name] and is protected by copyright and other intellectual property laws.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    8.2. You may not reproduce, distribute, modify, display, perform, publish,
                    license, create derivative works from, or sell any content on our Website
                    without our prior written consent.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>9. Governing Law and Jurisdiction</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    9.1. These Terms and Conditions shall be governed by and construed in
                    accordance with the laws of [Your Country].
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    9.2. Any disputes relating to these Terms and Conditions shall be subject to
                    the exclusive jurisdiction of the courts of [Your Country].
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>10. Amendments</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    10.1. We reserve the right to modify or amend these Terms and Conditions at
                    any time. Any changes will be effective immediately upon posting on our
                    Website.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    10.2. It is your responsibility to review these Terms and Conditions
                    periodically for changes. Your continued use of our Website after any such
                    changes constitutes acceptance of the updated Terms and Conditions.
                </p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>11. Contact Us</p>
                <p style={{ lineHeight: "normal" }}>&nbsp;</p>
                <p style={{ lineHeight: "normal" }}>
                    11.1. If you have any questions or concerns about these Terms and
                    Conditions, please contact us at info@urbanwallah.com
                </p>
                <p style={{ bottom: 10, right: 10, position: "absolute" }}>
                    <a
                        href="https://wordtohtml.net"
                        target="_blank"
                        style={{ fontSize: 11, color: "#d0d0d0" }}
                    >
                        Converted to HTML with WordToHTML.net
                    </a>
                </p>
            </>
        </div>
    )
}

export default TermsAndCondition;