import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import LoginModal from "../Components/LoginModal";
import { Avatar, Rating, Chip, Divider, Badge, Breadcrumbs, Stack, Paper, InputBase, Typography, RadioGroup, FormControlLabel, TextField, Radio, LinearProgress, linearProgressClasses, CircularProgress, Box, Modal, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { makeAddToCartApi } from "./Cart/redux/CartAction";
import { makeAddToWishlistApi, makeRemoveFromWishlistApi } from "./Wishlist/redux/WishlistAction";
import { useDispatch, useSelector } from "react-redux";
import assureIcon from '../assets/icons/assure.svg';
import icon1Icon from '../assets/icons/icon1.svg';
import authenticIcon from '../assets/icons/authentic.svg';
import { Pagination, Navigation } from "swiper/modules";
import { Grid } from '@mui/material';
import { makeGetCategoryApi, makeGetProductApi, makeGetSubCategoryApi } from "./Products/redux/ProductAction";
import ReactImageMagnify from "react-image-magnify";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UWAssuredIcon from '../assets/icons/UWAssuredIcon.svg';
import 'swiper/swiper-bundle.css';
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";

import { Link } from 'react-router-dom'; 
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import deliveryIcon from '../assets/icons/delivery.svg';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import varientIcon from '../assets/icons/icon-varient.svg';
import offerIcon from '../assets/icons/icon-offer.svg';
import chartIcon from '../assets/icons/sizeIcon.svg';
import tagIcon from '../assets/icons/tag.svg';
import RatingStartIcon from '../assets/icons/raingStar.svg';
import PersonIcon from '@mui/icons-material/Person';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CloseModal from '../assets/icons/closeModal.svg';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { makeStyles, styled, useTheme } from "@mui/styles";
import StarRatingComponent from "../Components/StartRatingComponent";
import userAvatar from '../assets/images/review_user_avatar.png'; 
// import ReactImageMagnify from 'react-image-magnify';
const getShareUrl = (platform, url) => {
  const encodedUrl = encodeURIComponent(url);
  switch (platform) {
    case 'f.svg':
      return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    case 'W.svg':
      return `https://api.whatsapp.com/send?text=${encodedUrl}`;
    case 'TG.svg':
      return `https://t.me/share/url?url=${encodedUrl}`;
    case 'X1.svg':
      return `https://twitter.com/intent/tweet?url=${encodedUrl}`;
    case 'Messenger.svg':
      return `fb-messenger://share/?link=${encodedUrl}`;
    default:
      return '#';
  }
};
const handleIconClick = (platform, url) => {
  const shareUrl = getShareUrl(platform, url);
  if (platform === 'messenger') {
    window.location.href = shareUrl;
  } else {
    window.open(shareUrl, '_blank', 'noopener,noreferrer');
  }
};

// Function to shorten the URL (for display purposes)
const shortUrl = (url) => {
  return url.length > 30 ? url.slice(0, 27) + '...' : url;
};
const useStyles = makeStyles(() => ({
  label: {
    fontSize: '10px',
  },
  radio: {
    '& svg': {
      fontSize: '18px', // Adjust the size of the radio button itself
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '5', // Adjust the padding to reduce height
      '& .MuiInputBase-input': {
        padding: '12px 14px', // Adjust the input padding
        fontSize: '14px', // Adjust the font size if needed
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #00000080',
        borderRadius: '13px'
      },
    },
  },
}));
 
//for caterogy display 

const ProductBreadcrumbs = ({ category, subcategory, productName }) => {
  return (
    <Breadcrumbs 
      separator={<NavigateNextIcon fontSize="small" />} 
      aria-label="breadcrumb"
      sx={{ padding: '10px 0', fontSize: '14px' }}
    >
      <Link color="inherit" href="/" style={{textDecoration:'none',color:'black'}}>
        Home
      </Link>
      {category && (
        <Link color="inherit" href={`/category/${category}`} style={{ textDecoration: 'none' , color:'black'}}>
          {category}
        </Link>
      )}
      {subcategory && (
        <Link color="inherit" href={`/subcategory/${subcategory}`} style={{ textDecoration: 'none', color:'black' }}>
          {subcategory}
        </Link>
      )}
      <Typography color="text.primary">{productName}</Typography>
    </Breadcrumbs>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 2,
  padding: '5px',
  borderRadius: 5,
  width: '70%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FDD901' : '#F3F0F0',
  },
}));

const CircularProgressWithLabel = ({ ratings }) => {
  let starValue = ratings * 20
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={starValue} style={{ color: '#5c9ad5' }} thickness={6} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography style={{ fontSize: '10px' }} variant="caption" component="div" color="text.secondary">
          {`${Math.round(starValue)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 250,
  bgcolor: 'background.paper',
  borderRadius: 5,
  p: '0.5rem 1rem',
};

const ratingModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  height: 550,
  bgcolor: 'background.paper',
  borderRadius: 5,
  p: '0.5rem 1rem',
};


function InfoDetails() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { subcatId, productId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let json = localStorage.getItem("@USER");
  let User = JSON.parse(json);

  const [inCart, setInCart] = useState(false);
  const [inWish, setInWish] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [attributeObj, setAttributeObj] = useState({});
  const [ProductList, setProductList] = useState([]);
  const [Product, setProduct] = useState([]);
  const [featureCardhoverIndex, setFeatureCardhoverIndex] = useState(null);
  const [selectedAttrKey, setSelectedAttrKey] = useState('');
  const [selectedAttrValue, setSelectedAttrValue] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [value, setValue] = useState('no');
  const [isScrollable, setIsScrollable] = useState(false);
  const [isQtyBtnShow, setIsQtyBtnShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const [hoveredImage, setHoveredImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? Product?.images?.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === Product?.images?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const url = window?.location?.href

  const mainSwiperRef = useRef(null);

  const dispatch = useDispatch();

  const allProductList = useSelector((state) => state.productState.productData);
  const productSearchData = useSelector((state) => state.productState.productSearchData);
  const wishlistedProductIdList = useSelector((state) => state.wishlistState.wishlistedProductIdList);
  const cartLoading = useSelector(state => state.cartState.cartLoading);

  console.log('cartLoading', cartLoading);
  useEffect(() => {
    if (copied) {
      toast.success('Copied to clipboard!');
    }
  }, [copied]);

  const getProductList = useCallback(async () => {
    if (allProductList && allProductList.length > 0) {
      let filteredProductData = allProductList?.filter((item) => item.productUuid === productId);
      let filtered = allProductList?.filter((item) => item.subcategoryUuid === subcatId);
      setProductList(filtered);
      setProduct(filteredProductData[0]);
      if (Object.keys(attributeObj)?.length === 0) {
        setAttributeObj(filteredProductData[0]?.formattedAttributes);
      }
    }
  }, [allProductList, productId, subcatId, setProductList, setProduct, setAttributeObj, attributeObj]);

  const localWishlistProductList = localStorage.getItem('localWishlistProductList');
  let wishlistIdData = JSON.parse(localWishlistProductList);

  const wishlistIdList = useMemo(() => {
    if (wishlistedProductIdList && wishlistedProductIdList?.length > 0) {
      return wishlistedProductIdList
    } else {
      return wishlistIdData;
    }
  }, [wishlistedProductIdList, wishlistIdData]);

  useEffect(() => {
    getProductList();
  }, [location, getProductList, productId, subcatId]);

  useEffect(() => {
    makeGetCategoryApi(dispatch);
    makeGetSubCategoryApi(dispatch);
    makeGetProductApi(dispatch);
    getProductList();
  }, []);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleRatingModalOpen = () => setRatingModalOpen(true);
  const handleRatingModalClose = () => setRatingModalOpen(false);

  const getDateFormat = (date) => {
    const dateObject = new Date(date);
    const formattedDate = dateObject.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    return formattedDate;
  }

  const socialIcon = ['f.svg', 'W.svg', 'TG.svg', 'X1.svg', 'Messenger.svg', ];

  const breadcrumbs = [
    <div style={{ fontSize: '12px', fontWeight: 400, color: '#000' ,fontFamily:'Gabarito' }}>
      {Product?.category?.categoryName}
    </div>,
    <div style={{ fontSize: '12px', fontWeight: 400, color: '#000' ,fontFamily:'Gabarito' }}>
      {Product?.subcategory?.subcategoryName}
    </div>,
    <div style={{ fontSize: '12px', fontWeight: 400, color: '#000' ,fontFamily:'Gabarito' }}>
      {Product?.productName}
    </div>
  ];

  const handleAttributeData = (attrKey, attrVal) => {
    setSelectedAttrKey(attrKey);
    setSelectedAttrValue(attrVal);
    setAttributeObj((prevAttributeObj) => {
      const updatedAttributes = {
        ...prevAttributeObj,
        [attrKey]: prevAttributeObj[attrKey].map((item) => {
          if (item.productUuid === attrVal?.productUuid) {
            return { ...item, highLight: false };
          }
          return { ...item, highLight: false };
        }),
      };
      return updatedAttributes;
    });
    naviagteToProductDetails(attrVal);
  };

  const handleClose = () => setShowSignin(false);
  const handleShow = () => setShowSignin(true);

  const naviagteToProductDetails = (item) => {
    navigate(`/productDetails/${item?.subcategoryUuid}/${item.productUuid}`)
  }

  const tilesData = [
    {
      icon: authenticIcon,
      title: 'Authentic',
      subTitle: 'Our products are aunthentic'
    },
    {
      icon: assureIcon,
      title: 'Assured',
      subTitle: 'Our products are assured'
    },
    {
      icon: icon1Icon,
      title: 'Adulteration Free',
      subTitle: 'Our products are organic'
    }
  ];

  const handleFeaturedCardHover = (index) => {
    setFeatureCardhoverIndex(index);
  };

  const renderTileImages = () => {
    return tilesData.map(item => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img src={item?.icon} style={{ width: '20px', height: '20px' }} />
          <div style={{ marginLeft: '15px' }}>
            <div style={{ fontSize: '12px', fontWeight: 600  ,fontFamily:'Gabarito'}}>{item.title}</div>
            <div style={{ fontSize: '10px', color: '#7D879C'  ,fontFamily:'Gabarito'}}>{item.subTitle}</div>
          </div>
        </div>
      )
    });
  }

  const renderDescription = () => {
    return (
      <div style={{ border: '0.5px solid #AEADAD', fontSize: '12px', color: '#AEADAD' }} className="px-2 py-4">
        {Product?.description}
      </div>
    )
  };

  const renderReview = () => {
    return Product?.ratings && Product?.ratings?.length > 0
      ? Product?.ratings?.map((item) => {
        return item?.isActive
          ? <div className={`${item?.isActive ? 'mb-3' : 'mb-2'}`}>
            <div className='d-flex align-items-center'>
              <Avatar style={{ width: '50px', height: '50px' }} src='/broken.jpeg' />
              <div style={{ marginLeft: '15px' }} className="d-flex">
                <div>
                  <div style={{ fontSize: '16px', color: '#2b3445', fontWeight: '600' ,fontFamily:'Gabarito' }}>
                    {item?.reviewByName}
                  </div>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <StarRatingComponent
                        starRating={item?.starRating}
                        size="medium"
                        readOnly={true}
                      />
                      {item?.starRating && (
                        <div className='ms-2' style={{ fontSize: '14px' }}>{`${item?.starRating}`}</div>
                      )}
                      <div style={{ color: '#7d879c', fontSize: '14px' }} className="ms-2">
                        {getDateFormat(item?.reviewedOn)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ fontSize: '14px', color: '#4b566b', fontWeight: '400', marginTop: '15px' ,fontFamily:'Gabarito' }}>
              {item?.description}
            </div>
          </div>
          : null
      }) : <div style={{ fontSize: '15px', fontWeight: '400', color: '#7d879c' ,fontFamily:'Gabarito' }}>No Reviews Found</div>
  }

  const items = [
    {
      key: '1',
      label: 'Description',
      children: renderDescription(),
    },
    {
      key: '2',
      label: 'Specifications',
      children: <div style={{ fontSize: '15px', fontWeight: '400', color: '#7d879c' ,fontFamily:'Gabarito'}}>No Specifications Found</div>,
    },
    {
      key: '3',
      label: 'Reviews',
      children: renderReview(),
    },
  ];

  const handleMouseEnter = (item) => {
    setHoveredImage(item);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    mainSwiperRef?.current?.swiper?.slideTo(index);
  };

  const renderProductImageSet = () => {
    return (
      <Swiper
        // navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          360: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
      >
        {Product?.images?.map((item, i) => {
          return (
            <SwiperSlide key={i} onClick={() => handleThumbnailClick(i)}>
              <div style={{ width: '100px', height: '80px', cursor: 'pointer' }}>
                <img
                  style={{ width: '100%', objectFit: 'contain', height: '100px' }}
                  src={item}
                  alt={`Thumbnail Image ${i}`}
                />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }
  const handleQuantity = (type) => {
    if (type === 'add') {
      setQuantity(quantity + 1);
    } else {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    }
  }

  const handleRemoveWishlistApi = (e, item) => {
    e.stopPropagation();
    if (User) {
      makeRemoveFromWishlistApi(item, dispatch)
    } else {
      handleShow()
    }
  }

  const data = [
    {
      label: Product?.vendor?.legalName,
      icon: 'seller'
    },
    {
      label: '14 days exchange',
      icon: 'exchange'
    },
    {
      label: 'AAA certified',
      icon: 'certified'
    },
    {
      label: Product?.stock < 10 ? `${Product?.stock} in Stock` : Product?.stock > 10 ? 'Stock Available' : 'Stock not Available',
      icon: 'stock'
    },
    {
      label: 'Secure transaction',
      icon: 'secure'
    },
    {
      label: 'Apply UrbanTicket',
      icon: 'ubTicket'
    }
  ];

  const productData = [
    {
      label: 'Share',
      color: '#3EC87F',
      icon: <ShareIcon style={{ fill: '#000', width: '25px', height: '25px' }} />,
      onClick: () => {
        handleModalOpen();
      }
    },
    {
      label: wishlistIdList?.includes(Product?.productUuid) ? 'Wishlisted' : 'Wishlist',
      color: '#F69776',
      icon: wishlistIdList?.includes(Product?.productUuid)
        ? <FavoriteIcon
          className="me-1"
          style={{ fill: '#000', width: '25px', height: '25px' }}
          onClick={(e) => handleRemoveWishlistApi(e, Product)}
        />
        : <FavoriteBorderIcon style={{ fill: '#000', width: '25px', height: '25px' }} />,
      onClick: () => makeAddToWishlistApi(Product, dispatch)
    },
    {
      label: 'Buy now',
      color: '#F5DE54',
      icon: cartLoading
        ? <CircularProgress size={18} style={{ color: '#000' }} />
        : <ShoppingCartOutlinedIcon style={{ fill: '#000', width: '25px', height: '25px' }} />,
      onClick: () => {
        makeAddToCartApi(Product, dispatch, false, quantity, navigate, true);
      }
    },
    // {
    //   label: 'Add to cart',
    //   color: '#2DBDEE',
    //   icon: <AddShoppingCartIcon style={{ fill: '#000', width: '25px', height: '25px' }} />,
    //   onClick: () => {
    //     setIsQtyBtnShow(true);
    //   }
    // }
  ];

  const offerData = [
    {
      label: `Got a `,
      highlight: `coupon`,
      suffix: `? More discounts during the checkout.`,
    },
    {
      label: '10% Instant Discount on SBI Credit Card and Credit Card EMI',
    },
    {
      label: 'Get Upto ₹250 cashback for your first RuPay Credit Card transaction',
    }
  ];

  const ratingArray = [
    {
      userName: 'UserName',
      date: '24-may-2024',
      ratingInfo: 'Wonderful product. Very good value for money',
      description: 'Up to 36GB (M3 Pro) or up to 128GB (M3 Max) of unified memory makes everything you do fast and fluid. Up to 4TB (M3 Pro) or up to 8TB (M3 Max) of superfast SSD storage launches apps and opens files in an instant'
    },
    {
      userName: 'UserName',
      date: '24-may-2024',
      ratingInfo: 'Wonderful product. Very good value for money',
      description: 'Up to 36GB (M3 Pro) or up to 128GB (M3 Max) of unified memory makes everything you do fast and fluid. Up to 4TB (M3 Pro) or up to 8TB (M3 Max) of superfast SSD storage launches apps and opens files in an instant'
    },
    {
      userName: 'UserName',
      date: '24-may-2024',
      ratingInfo: 'Wonderful product. Very good value for money',
      description: 'Up to 36GB (M3 Pro) or up to 128GB (M3 Max) of unified memory makes everything you do fast and fluid. Up to 4TB (M3 Pro) or up to 8TB (M3 Max) of superfast SSD storage launches apps and opens files in an instant'
    },
    {
      userName: 'UserName',
      date: '24-may-2024',
      ratingInfo: 'Wonderful product. Very good value for money',
      description: 'Up to 36GB (M3 Pro) or up to 128GB (M3 Max) of unified memory makes everything you do fast and fluid. Up to 4TB (M3 Pro) or up to 8TB (M3 Max) of superfast SSD storage launches apps and opens files in an instant'
    }
  ]

  const shortUrl = (url) => {
    const urlParts = url.split('/');
    const baseUrl = urlParts.slice(0, 5).join('/');
    return `${baseUrl.substring(0, 60)}...`;
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const specificationData =
  {
    brand: Product?.specifications
  };

  const renderSwiperImage = () => {
    return (
      <Swiper
        navigation={true}
        modules={[Pagination, Navigation]}
        ref={mainSwiperRef}
        className="mySwiper custom-swiper"
        breakpoints={{
          360: { slidesPerView: 1, spaceBetween: 10 },
          576: { slidesPerView: 1, spaceBetween: 10 },
          768: { slidesPerView: 1, spaceBetween: 10 },
          992: { slidesPerView: 1, spaceBetween: 10 },
          1200: { slidesPerView: 1, spaceBetween: 10 },
        }}
        initialSlide={activeIndex}
      >
        {Product?.images?.map((item, i) => (
          <SwiperSlide key={i}>
            <img
              style={{ width: '100%', objectFit: 'contain', height: '200px' }}
              src={item}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const renderImageMagnifierView = () => {
    return (
      <div style={{ border: '1px solid #ccc', display: 'flex', padding: 20, flexDirection: 'row', alignItems: 'center', borderRadius: '10px', justifyContent: 'space-between' }}>
        <div onClick={handlePrev}>
          <ArrowBackIosIcon />
        </div>
        {Product?.images?.map((item, index) => {
          return (
            <div>
              {currentIndex === index
                ? <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: "Image",
                      src: item,
                      width: 220,
                      height: 300,
                    },
                    largeImage: {
                      alt: "",
                      src: item,
                      width: 600,
                      height: 900,
                    },
                    isHintEnabled: true,
                  }}
                />
                : null
              }
            </div>
          )
        })}
        <div onClick={handleNext}>
          <ArrowForwardIosIcon />
        </div>
      </div>
    )
  }

  const renderQuantityButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }}>
          <div onClick={() => handleQuantity('minus')}>
            <RemoveOutlinedIcon />
          </div>
          <div className="mx-3">
            {quantity}
          </div>
          <div onClick={() => handleQuantity('add')}>
            <AddOutlinedIcon />
          </div>
        </div>
      </div>
    )
  }

  const handleCopy = () => {
    const copyLink = window.location?.href;
    if (copyLink) {
      navigator.clipboard.writeText(copyLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }
  };

  const convertDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date?.toLocaleDateString('en-GB', options)?.replace(',', '')?.replace(' ', '-');
    return formattedDate
  };

  const getRatingsArray = () => {
    const ratingCounts = Array(5).fill(0);

    Product?.ratings?.forEach(rating => {
      ratingCounts[rating.starRating - 1]++;
    });

    const maxCount = Math?.max(...ratingCounts);

    const ratingPercentages = ratingCounts.map(count => (maxCount > 0 ? (count / maxCount) * 100 : 0));

    return ratingPercentages;
  };

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "#000",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "#fff",
            },
          }
        }}
      />
      <div className='px-5 py-4'>
        <ProductBreadcrumbs  
          category={Product?.category?.categoryName}
          subcategory={Product?.subcategory?.subcategoryName}
          productName={Product?.productName}
        />
        <div className="mt-4">
       <style>
        {`
        .hover-scale:hover {
  transform: scale(1.05); /* Scale up the image by 5% */
}
  .zoom-image {
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
}

.zoom-image:hover {
  transform: scale(1.1); /* Scale up the image by 10% */
}

`}
       </style>
          <Grid container alignItems={'center'} spacing={3} justifyContent={'space-between'}>
            <Grid item xs={12} md={3.8}>
              <div
                style={{
                  backgroundColor:'white',
                  width: '100%',
                  height: '350px',
                  border: '1px solid #00000033',
                  borderRadius: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                }}
                className="hover-scale"
              >
                <div style={{ width: '100%', height: '250px' }}>
                  {Product?.images && Product?.images?.length > 1
                    ? renderSwiperImage()
                    : <img src={Product?.imageUrl} style={{ width: '100%', objectFit: 'contain', height: '250px' }}  className="zoom-image" />
                  }
                </div>
                {Product?.aaacertified
                    ? <div style={{ position: 'absolute', top: -25, right: -50 }}>
                        <img
                          src={UWAssuredIcon}
                          alt="assured"
                          style={{ width: '100px', height: '50px' }}
                          
                        />
                      </div>
                    : null
                  }
              </div>
            </Grid>
            <Grid item xs={12} md={8.2}>
              <div style={{ width: '100%', minHeight: '350px', border: '1px solid #00000033',backgroundColor:'white' ,borderRadius: '15px' }} className="p-4">
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <div style={{ fontSize: '12px', fontWeight: 600, color: '#000',fontFamily:'Gabarito' }}>
                      {Product?.category?.categoryName}
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: 600, color: '#000',fontFamily:'Gabarito' }} className="mt-2">
                      {Product?.productName}
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: 500, color: '#000',fontFamily:'Gabarito', maxHeight: '4.5em', overflow: 'auto' }} className="mt-2">
                      {Product?.description}
                    </div>
                    <style>
                      {`
                        @media (max-width:345px){
                            #reviews-tags{
                            flex-wrap:wrap;
                           
                            }
                            
                        }
                            @media (max-width:385px){
                            #share-butons{
                              flex-wrap:wrap;
                             gap:10px;
                            }
                            }
                            @media (max-width:600px){
                            #rating-review{
                            flex-wrap:wrap;
                            }
                            }
                      `}
                    </style>
                    <Stack direction={'row'} alignItems={'center'} spacing={1} className="mt-2" id="reviews-tags">
  <Rating
    name="rating"
    value={Product?.overallRating || 0}  // Default to 0 if overallRating is undefined or null
    readOnly
    style={{ color: '#FDD901' }}
    size="medium"
    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
  />
  <div style={{ fontSize: '13px', fontWeight: 400, color: '#000', fontFamily: 'Gabarito' }}>
    {Product?.overallRating ? `${Product.overallRating} Ratings` : 'No Ratings'}
  </div>
  <div style={{ fontSize: '13px', fontWeight: 400, color: '#000', fontFamily: 'Gabarito' }}>
    {Product?.ratings?.length > 0 ? `${Product.ratings.length} Review${Product.ratings.length > 1 ? 's' : ''}` : 'No Review'}
  </div>
</Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={3} className="mt-3">
                      <div style={{fontSize: '24px', lineHeight:'28.8px', fontWeight: 500, color: '#000' }}>
                        ₹{Product?.price}
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item md={1} />
                  <Grid item xs={12} md={3}>
                    <div>
                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <img src={deliveryIcon} style={{ width: '22px', height: '22px' }} />
                        <div style={{ fontSize: '12px', fontWeight: 400, color: '#000' ,fontFamily:'Gabarito'}}>
                          COD available
                        </div>
                      </Stack>
                      <Paper
                        className="mt-2"
                        style={{ border: '1px solid #000', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '5px' }}
                      >
                        <InputBase
                          value={''}
                          sx={{ ml: 1, flex: 1, paddingLeft: '10px', fontSize: '14px', fontWeight: 400, color: '#908C8C' ,fontFamily:'Gabarito'}}
                          placeholder="Enter pincode"
                          style={{ color: '#908C8C', fontSize: '11px' }}
                        />
                        <div
                          style={{
                            backgroundColor: '#D9D9D9',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '5px',
                            borderRadius: '0px',
                            borderLeftWidth: '1px',
                            borderLeftColor: '#000',
                            borderLeftStyle: 'solid',
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px'
                          }}
                          className="px-3"
                        >
                          <Typography
                            component="div"
                            style={{ fontSize: '12px', color: '#000', cursor: 'pointer', fontWeight: 400 ,fontFamily:'Gabarito'}}>
                            Check
                          </Typography>
                        </div>
                      </Paper>
                    </div>
                  </Grid>
                </Grid>
                <Divider className="my-3" sx={{ border: '0.5px solid #000000' }} />
                <Grid container spacing={3} justifyContent='center'  marginLeft= {'30px'} alignItems={'center'}>
                  {data?.map((item, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img
                          src={require(`../assets/icons/icon-${item.icon}.svg`)}
                          style={{ width: '22px', height: '22px', color: Product?.stock === 0 ? 'red' : '#000' }}
                          alt={item.label}
                        />
                        <div
                          style={{ fontSize: '15px', color: '#000', cursor: 'pointer',  lineHeight:'18px', fontWeight: 400,fontFamily:'Gabarito' }}>
                          {item.label}
                        </div>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} spacing={3}>
            <Grid item md={3.5} sx={{
    display: { xs: "none", lg: "block" }
  }}>
              {Product?.images && Product?.images?.length > 0
                ? <div className="mt-3 px-4">
                  {renderProductImageSet()}
                </div>
                : null
              }
            </Grid>
            <Grid item xs={12} md={8.5}>

              <Stack className="mt-3" sx={{
                padding: {
                  xs: '0',
                  md: '0rem 7rem'
                }
              }}  direction={'row'} alignItems={'center'} justifyContent={'space-evenly'} gap={'10px'} id="share-butons">
                {productData?.map(item => {
                  return item.label === 'Add to cart' && isQtyBtnShow
                    ?
                    <div
                      style={{
                        backgroundColor: item.color,
                        color: '#000',
                        fontSize: '16px',
                        fontWeight: 400,
                        width: '150px',
                        height: '40px',
                        borderRadius: '30px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >{renderQuantityButton()}</div>
                    : (
                      <Chip
                        label={item.label}
                        variant="outlined"
                        style={{
                          backgroundColor: item.color,
                          color: '#000',
                          fontSize: '16px',
                          fontWeight: 400,
                          width: '150px',
                          height: '40px',
                          borderRadius: '30px',
                          cursor: 'pointer'
                          ,fontFamily:'Gabarito'
                        }}
                        onDelete={() => { }}
                        deleteIcon={item?.icon}
                        onClick={item?.onClick}
                      />
                    )
                })}
              </Stack>
            </Grid>
          </Grid>
        </div>
        <div className='mt-5'>
          <div style={{ fontSize: '24px', lineHeight:'28.8px', fontWeight: 400, color: '#000',fontFamily:'Gabarito',  paddingBottom:'15px'}}>
            We’ve got choices for you
          </div>
          <Grid container sx={{
            padding: {
              xs: '0',
              
            }
          }}>
            <Grid item xs={12} md={6} style={{ height: '230px', overflowY: 'auto' }}>
              <div style={{ border: '1px solid #00000033', borderRightWidth: '0px', borderBottomWidth: '0px' }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'} className="py-2" style={{ backgroundColor: 'rgba(41, 189, 238, 0.1)' }}>
                  <img src={varientIcon} style={{ width: '20px', height: '20px' }} />
                  <div style={{ fontSize: '16px', fontWeight: 400 ,fontFamily:'Gabarito'}}>
                    Variants
                  </div>
                </Stack>
              </div>
              <div style={{ border: '1px solid #00000033', height: 'calc(100% - 42px)',borderRadius: '0px 0px 0px 10px', overflowY: 'auto', borderRightWidth: '0px' ,backgroundColor:'white'}}>
                {Object.keys(attributeObj)?.length > 0 ? Object.keys(attributeObj)?.map((attrKey) => {
                  return (
                    <div className="p-4" key={attrKey}>
                      <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                          <div className="mb-2" style={{ fontSize: '14px', fontWeight: 400 ,fontFamily:'Gabarito'}}>{attrKey}</div>
                          {attributeObj[attrKey]?.map((attrVal) => {
                            return (
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} key={attrVal.Label}>
                                <Chip
                                  label={attrVal?.Label}
                                  sx={{
                                    maxWidth: '100%',
                                    minWidth: '80px',
                                    color: '#000',
                                    borderRadius: '5px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #000',
                                    marginRight: '10px',
                                    fontSize: '12px',
                                    fontWeight: 600
                                  }}
                                  onClick={() => handleAttributeData(attrKey, attrVal)}
                                />
                              </Stack>
                            )
                          })}
                        </Stack>
                      </Stack>
                    </div>
                  )
                }) : <div style={{ fontSize: '16px', fontWeight: 600, fontFamily:'Gabarito', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                  No Variants Found
                </div>}
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ height: '230px', overflowY: 'auto' }}>
              <div style={{ border: '1px solid #00000033', borderBottomWidth: '0px' }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'} className="py-2" style={{ backgroundColor: 'rgba(41, 189, 238, 0.1)' }}>
                  <img src={offerIcon} style={{ width: '20px', height: '20px' }} />
                  <div style={{ fontSize: '16px', fontWeight: 400 ,fontFamily:'Gabarito'}}>
                    Offers
                  </div>
                </Stack>
              </div>
              <div style={{ border: '1px solid #00000033', height: 'calc(100% - 42px)',borderRadius: '0px 0px 10px 0px', overflowY: 'auto' ,backgroundColor:'white'}}>
                {offerData.length > 0 ? (
                  offerData?.map((item, index) => (
                    <div className="p-3" key={index}>
                      <Stack direction="row" spacing={3} alignItems="center" className="ps-5">
                        <img src={tagIcon} style={{ width: '20px', height: '20px' }} />
                        <div style={{ fontSize: '14px', fontWeight: 400,fontFamily:'Gabarito' }}>
                          {item.label}
                          {item.highlight && <span style={{ color: '#2DBDEE' }}>{item.highlight}</span>}
                          {item.suffix}
                        </div>
                      </Stack>
                    </div>
                  ))
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                    No Offers Found
                  </div>
                )}  
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mt-5" id="product_description " style={{backgroundColor:'white',padding:'15px',borderRadius:'15px',marginLeft:'15px'}}>
          <div style={{ fontSize: '24px', fontWeight: 400, color: '#000' ,lineHeight:'28.8px',fontFamily:'Gabarito'}}>
            Product Description
          </div>
          <div className="mt-3" style={{marginLeft:'15px'}}>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
              <li style={{ fontSize: '16px', fontWeight: 400, color: '#979797', marginBottom: '10px' ,fontFamily:'Gabarito'}}>
                {Product?.description}
              </li>
            </ul>
          </div>
        </div>
        <div>
             <div className='mt-5'>
          
            <Grid container spacing={15}>
              <Grid item xs={12} md={4}>
                <div style={{ height: '330px',backgroundColor:'white', borderRadius: '15px', textAlign: 'center' }} className="px-5 pt-3">
                  <div style={{ fontSize: '20px',  lineHeight:'24px',color: '#000', fontWeight: 400,fontFamily:'Gabarito' }}>
                    Rating overview
                  </div>
            <div style={{ fontSize: '28px', color: '#000', fontWeight: 400, fontFamily: 'Gabarito' }}>
              {Product?.overallRating}
            </div>
            <div>
              <Rating
                name="rating"
                value={Product?.overallRating || 0}
                readOnly
                style={{ color: '#FDD901' }}
                size='medium'
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
            </div>
            <div style={{ fontSize: '13px', color: '#000', fontWeight: 400, fontFamily: 'Gabarito' }}>
              {Product?.overallRating} Ratings
            </div>
            <div className="mt-3">
              {getRatingsArray().reverse().map((item, index) => (
                <Stack key={index} direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={5} className="mb-3">
                  <img src={RatingStartIcon} style={{ width: '20px', height: '20px' }} alt="Rating Star Icon" />
                  <BorderLinearProgress variant="determinate" value={item} />
                </Stack>
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ backgroundColor: 'white', borderRadius: '10px', border: '1px solid #ddd',height:'330px',overflow:'auto' }}>
            {Product?.specifications?.length > 0
              ? <div style={{ fontSize: '24px', lineHeight: '28.8px', marginLeft:'30px', marginTop:'15px', fontWeight: 400, color: '#000', fontFamily: 'Gabarito' }}>
                Product Specifications
              </div>
              : null
            }
            {Product?.specifications?.length > 0 ? (
              <div className="px-5 mt-4">
                {Product.specifications.map((item, index) => (
                  <Stack key={index} direction={'row'} justifyContent={'flex-start'} alignItems={'center'} style={{ marginBottom: '12px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <div style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Gabarito' }}>{item.title}</div>
                      </Grid>
                      <Grid item xs={8}>
                        <div style={{ fontSize: '16px', fontWeight: 600, color: '#979797', fontFamily: 'Gabarito' }}>{item.description}</div>
                      </Grid>
                    </Grid>
                  </Stack>
                ))}
              </div>
            ) : <div style={{ fontSize: '24px', fontWeight: 600, lineHeight: '28.8px', color: '#979797', textAlign: 'center', paddingTop: '7rem', fontFamily: 'Gabarito' }}>
              Product Specifications Not Available
            </div>}
          </div>
        </Grid>
      </Grid>
    </div>
 
          <div className="mt-5">
          {Product?.ratings?.length > 1
              ? <div style={{ fontSize: '24px',  lineHeight:'28.8px',fontWeight: 400, color: '#000', fontFamily: 'Gabarito' }}>
                  <span style={{ color: '#2DBDEE', cursor: 'pointer' }} onClick={() => handleRatingModalOpen(true)}>
                    Click here
                  </span> to read other {Product.ratings.length} reviews
                </div>
              : <div style={{ fontSize: '24px', fontWeight: 400, color: '#000', fontFamily: 'Gabarito' }}>
                  Ratings and Reviews
                </div>
            }
            {Product?.ratings?.map((item, index) => {
              if (index < 1) {
                return (
                  <Stack id="rating-review"
                    direction={'row'}
                    alignItems={'center'}
                    className="px-4 py-3 mb-3 mt-3"
                    style={{ border: '1px solid #00000033', backgroundColor:'white', borderRadius: '15px' }}
                    justifyContent={'space-around'}  
                  >
                    <div>
                      <Avatar style={{ width: '70px', height: '70px' }}>
                      <img 
                      src={userAvatar} 
                      alt="User Avatar" 
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    />
                      </Avatar>
                      <div style={{ fontSize: '15px', fontWeight: 400, color: '#000',fontFamily:'Gabarito' }}>
                        {item?.reviewByName}
                      </div>
                      <div style={{ fontSize: '11px', fontWeight: 400, color: '#908C8C' ,fontFamily:'Gabarito'}}>
                        {convertDate(item?.createdAt)}
                      </div>
                    </div>
                    <div className="px-5">
                      <Rating
                        name="rating"
                        value={item?.starRating}
                        readOnly
                        style={{ color: '#FDD901' }}
                        size='medium'
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                      <div style={{ fontSize: '15px', fontWeight: 400, color: '#000',fontFamily:'Gabarito' }}>
                        {item?.ratingInfo || ''}
                      </div>
                      <div style={{ fontSize: '16px', fontWeight: 400, color: '#979797',fontFamily:'Gabarito' }}>
                        {item?.description}
                      </div>
                    </div>
                    <div>
                      <CircularProgressWithLabel ratings={item?.starRating} />
                    </div>
                  </Stack>
                )
              }
            })}
          </div>
          {/* <div className='mt-5'>
            <div style={{ fontSize: '24px', lineHeight:'28.8px', fontWeight: 400, color: '#000',fontFamily:'Gabarito' }}>
              Recommended products
            </div>
            <div style={{ display: 'flex', flexDirection: 'row',flexWrap: 'wrap', justifyContent: 'space-evenly' }} className="mt-4">
              {ProductList.map((item, i) => {
                if (i < 4) {
                  return (
                    <Card
                      style={{
                        marginBottom: '10px',
                        width: '254px',
                        height: '350px',
                        border: 'none',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        backgroundColor: 'white'
                      }}
                    >
                      <div className='py-5'>
                        <div
                          style={{
                            transition: 'transform 0.2s ease', // Add transition for smooth zoom
                            transform: 'scale(1)', // Zoom effect on hover
                          }}
                        >

                          <div style={{ textAlign: 'center' }}>
                            <img
                              src={item?.imageUrl ? item?.imageUrl : require('../assets/images/placeholder-image.png')}
                              style={{ height: '150px', width: '140px' }}
                            />
                            <div className="mt-3" style={{ fontSize: '12px', fontWeight: 600, color: '#000',fontFamily:'Gabarito' }}>
                              {item?.category?.categoryName}
                            </div>
                            <div className="mt-1" style={{ textAlign: 'center' }}>
                              <div style={{ fontSize: '14px', fontWeight: 600, color: '#000' ,fontFamily:'Gabarito'}}>
                                {item?.productName}
                              </div>
                              <div className='mt-2 mb-1'>
                                <Rating
                                  name="rating"
                                  value={item?.overallRating}
                                  readOnly
                                  style={{ fontSize: '15px' }}
                                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                              </div>
                            </div>
                            <div style={{ fontSize: '16px', fontWeight: 600 ,fontFamily:'Gabarito'}}>
                              ₹ {item?.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  )
                }
              })}
            </div>
          </div>
           */}
          {/* <div className="mt-3" style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '16px' }}>
            {productSearchData.map((item, i) => {
              return (
                <Card
                  style={{
                    width: '100%',
                    height: 'auto',
                    border: 'none',
                    cursor: 'pointer',
                    boxShadow: 'none',
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}
                >
                  <div style={{ padding: '1.5rem 3rem' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        transition: 'transform 0.2s ease', // Add transition for smooth zoom
                        transform: 'scale(1)', // Zoom effect on hover
                      }}>
                      <img src={item?.imageUrl ? item?.imageUrl : require('../assets/images/placeholder-image.png')} style={{ height: '100px', width: '100px' }} />
                      <div className="ms-3">
                        <div style={{ fontSize: '12px', fontWeight: 600, color: '#000' ,fontFamily:'Gabarito'}} className='mt-3'>
                          {item?.productName}
                        </div>
                        <div style={{ fontSize: '24px', lineHeight:'28.8px', fontWeight: 500 ,fontFamily:'Gabarito'}} className="mt-2">
                          ₹ {item?.price}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              )
            })}
          </div> */}
        </div>
        <style>
          {`
          @media(max-width:518px){
            #shared-div{
            width:92%;
            }
          }
          `}
        </style>
        
        <LoginModal
          showSignin={showSignin}
          setShowSignin={setShowSignin}
          handleClose={handleClose}
          handleShow={handleShow}
        />
        <Modal
          open={modalOpen}
          onClose={handleModalClose} 
        >
          <Box sx={modalStyle} id="shared-div" >
            <div style={{ textAlign: 'end', cursor: 'pointer' }} onClick={handleModalClose}>
              <img src={CloseModal} style={{ width: '25px', height: '25px' }} />
            </div>
            <div className="px-4 py-2">
              <div style={{ fontSize: '17px' }}>
                Share
              </div>
              <div className="mt-3 px-3">
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                  {socialIcon.map(icon => {
                    return <img src={require(`../assets/SocialIcons/${icon}`)} style={{ width: '30px', height: '30px',cursor:'pointer' }} onClick={() => handleIconClick(icon, url)} className="me-3" />
                  })}
                </Stack>
              </div>
              <div className="my-4">
                <div style={{ fontSize: '12px' }} className="mb-3">
                  Copy Link
                </div>
                <div className="px-3 py-1" style={{ height: '40px', width: '100%', backgroundColor: '#F3F0F0', borderRadius: '8px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ fontSize: '12px' }}>
                    {shortUrl(url)}
                  </div>
                  <ContentCopyOutlinedIcon style={{ width: '0.7em', height: '0.7em', cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(url)} />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={ratingModalOpen}
          onClose={handleRatingModalClose}
        >
          <Box sx={ratingModalStyle}>
            <div style={{ textAlign: 'end', cursor: 'pointer' }} onClick={handleRatingModalClose}>
              <img src={CloseModal} style={{ width: '25px', height: '25px' }} />
            </div>
            <div className="px-4 py-2">
              <div style={{ fontSize: '24px', fontWeight: 600  ,fontFamily:'Gabarito'}}>
                Reviews
              </div>
              <div className="mt-3" style={{ maxHeight: '380px', overflowY: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                {Product?.ratings?.map(item => {
                  return (
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      className="px-4 py-3 mb-3 mt-3"
                      style={{ border: '1px solid #00000033', borderRadius: '15px' }}
                      justifyContent={'space-around'}
                    >
                      <div>
                      <Avatar style={{ width: '70px', height: '70px' }}>
                      <img 
                      src={userAvatar} 
                      alt="User Avatar" 
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    />
                      </Avatar>
                        <div style={{ fontSize: '15px', fontWeight: 400, color: '#000'  ,fontFamily:'Gabarito'}}>
                          {item?.reviewByName}
                        </div>
                        <div style={{ fontSize: '11px', fontWeight: 400, color: '#908C8C' ,fontFamily:'Gabarito'}}>
                        {convertDate(item?.createdAt)}
                      </div>
                      </div>
                      <div className="px-5">
                        <Rating
                          name="rating"
                          value={item?.starRating}
                          readOnly
                          style={{ color: '#FDD901' }}
                          size='medium'
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        <div style={{ fontSize: '15px', fontWeight: 400, color: '#000'  ,fontFamily:'Gabarito'}}>
                          {item?.ratingInfo || ''}
                        </div>
                        <div style={{ fontSize: '12px', fontWeight: 400, color: '#979797'  ,fontFamily:'Gabarito'}}>
                          {item?.description}
                        </div>
                      </div>
                      <div>
                        <CircularProgressWithLabel />
                      </div>
                    </Stack>
                  )
                })}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default InfoDetails;
