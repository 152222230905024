import React, { useState, useEffect, useMemo, useCallback } from 'react';
import FilterProductsCard from './FilterProductsCard';
import { Card, Grid, Rating, Avatar, Checkbox, Pagination } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMethod, postMethod } from '../../helpers';
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { makeGetCategoryApi, makeGetProductApi, makeGetSubCategoryApi } from './redux/ProductAction';
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

const NewProductsView = () => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [viewType, setViewType] = useState('grid');
    const [isWishListed, setIsWishListed] = useState();
    const [selectedSubcatName, setSelectedSubcatName] = useState();
    const [ProductList, setProductList] = useState([]);
    const [trendingCardhoverIndex, setTrendingCardhoverIndex] = useState(null);

    const dispatch = useDispatch();

    const categortList = useSelector((state) => state.productState.categoryData);
    const subCategoryList = useSelector((state) => state.productState.subCategoryData);
    const allProductList = useSelector((state) => state.productState.productData);

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        makeGetCategoryApi(dispatch);
        makeGetSubCategoryApi(dispatch);
        makeGetProductApi(dispatch);
        getProductList();
    }, []);

    const getProductList = async () => {
        try {
            let url = "products/list_products";
            let response = await getMethod({ url });
            if (response.success) {
                setProductList(response.data);
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleTrendingCardHover = (index) => {
        setTrendingCardhoverIndex(index);
    };
    const addToWishlist = async (item) => {
        if (!User) {
        } else {
            try {
                let url = `customers/secure/wishlist/${item?.productUuid}?quantity=1`;
                var payload = {
                    productId: item?.productUuid,
                    quantity: 1,
                };
                const token = localStorage.getItem("@token");
                let response = await postMethod({ url, payload, token });
                if (response) {
                    setIsWishListed(true)
                } else {
                    setIsWishListed(false)
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    const constructedData = useMemo(() => {
        const resData = categortList?.map(category => {
            const matchingSubcategories = subCategoryList?.filter(subcategory => subcategory.categoryUuid === category.categoryUuid);

            return {
                ...category,
                subCat: matchingSubcategories
            };
        });
        return resData
    }, [categortList, subCategoryList])

    useEffect(() => {
        if (location?.state !== 'All') {
            if (constructedData && constructedData?.length > 0 && constructedData[0]?.subCat && constructedData[0]?.subCat?.length > 0) {
                let res = [];
                allProductList?.forEach(item => {
                    if (item?.subcategory?.subcategoryUuid === constructedData[0]?.subCat[0]?.subcategoryUuid) {
                        res = [...res, { ...item, isHovered: false }]
                    }
                });
                setSelectedSubcatName(constructedData[0]?.subCat[0]?.subcategoryName)
                setConstructedProductData(res);
            }
        } else {
            setConstructedProductData([...allProductList]);
        }
    }, [constructedData, allProductList, location]);

    const [constructedProductData, setConstructedProductData] = useState([]);

    const handleSubCategoryClick = (subCatItem) => {
        let res = []
        allProductList?.forEach(item => {
            if (item?.subcategory?.subcategoryUuid === subCatItem?.subcategoryUuid) {
                res = [...res, { ...item, isHovered: false }]
            }
        });
        setSelectedSubcatName(subCatItem?.subcategoryName)
        setConstructedProductData(res);
    }

    const naviagteToProductDetails = (item) => {
        navigate(`/productDetails/${item?.subcategoryUuid}/${item.productUuid}`)
    }

    const handleCardHover = (index) => {
        setHoverIndex(index);
    };

    const getViewType = useCallback((type) => {
        setViewType(type);
    }, [setViewType]);

    const renderProducts = () => {
        return (
            <div className="px-4 py-5 mt-4" style={{ backgroundColor: '#F3F3F3' }}>
                <div>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ flex: 4, marginLeft: '10px', position: 'relative' }}>
                            <img
                                src={require('../../assets/banner/banner4.webp')}
                                style={{ width: '100%', borderRadius: '10px', height: '350px' }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    textAlign: 'center',
                                    bottom: '0%',
                                    right: '23%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            >
                                <button
                                    className="btn mt-3"
                                    style={{
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        padding: '10px 20px',
                                        fontWeight: 600,
                                        borderRadius: '5px'
                                    }}
                                >
                                    Show Now
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 6, alignItems: 'center', marginLeft: '2rem' }}>
                            {ProductList.map((item, i) => {
                                if (i < 3) {
                                    return (
                                        <Card
                                            style={{
                                                width: '260px',
                                                height: 'auto',
                                                border: trendingCardhoverIndex === i ? '1px solid #D3D3D3' : 'none',
                                                cursor: 'pointer',
                                                boxShadow: 'none',
                                                backgroundColor: '#f3f3f3'
                                            }}
                                            onClick={() => naviagteToProductDetails(item)}
                                            onMouseEnter={() => handleTrendingCardHover(i)}
                                            onMouseLeave={() => handleTrendingCardHover(null)}
                                        >
                                            <div style={{ paddingLeft: '2.2rem', paddingRight: '2.2rem', paddingTop: '2.5rem', paddingBottom: '2rem' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        transition: 'transform 0.2s ease', // Add transition for smooth zoom
                                                        transform: trendingCardhoverIndex === i ? 'scale(1.1)' : 'scale(1)', // Zoom effect on hover
                                                    }}>
                                                    <img
                                                        src={item?.imageUrl}
                                                        style={{ height: '120px', width: '100%', objectFit: 'contain', backgroundColor: '' }}
                                                    />
                                                    <div>
                                                        <div style={{ fontSize: '12px', fontWeight: 600, color: trendingCardhoverIndex === i ? '#23c2f2' : '#000', textDecoration: trendingCardhoverIndex === i ? 'underline' : 'none' }} className='mt-3'>
                                                            {item?.productName}
                                                        </div>
                                                        <div className='mt-2 mb-1' style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Rating
                                                                name="rating"
                                                                value={item?.overallRating}
                                                                style={{ fontSize: '15px', color: '#FBD731' }}
                                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                            />
                                                            {item?.ratings && item?.ratings?.length > 0
                                                                ? <div
                                                                    style={{
                                                                        fontSize: "10px",
                                                                        marginLeft: '5px',
                                                                        color: "#000",
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {item?.ratings?.length > 1
                                                                        ? `( ${item?.ratings?.length} Ratings )`
                                                                        : `( ${item?.ratings?.length} Rating )`
                                                                    }
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '16px', fontWeight: 600 }} className="mt-2">
                                                        ₹ {item?.price}
                                                    </div>
                                                </div>
                                                <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <FavoriteIcon className="me-1" style={{ color: '#EE5350' }} />
                                                    <button
                                                        className="px-2"
                                                        style={{
                                                            backgroundColor: '#23c2f2',
                                                            color: '#fff',
                                                            border: trendingCardhoverIndex === i ? 'none' : '1px solid #23c2f2',
                                                            fontSize: '12px',
                                                            fontWeight: 600,
                                                            width: '120px',
                                                            padding: '4px 0px'
                                                        }}
                                                    >
                                                        Add To Cart
                                                    </button>
                                                    <div className="ms-1">
                                                        <Avatar style={{ backgroundColor: '#22BA8D', width: '25px', height: '25px' }}>
                                                            <ShareIcon style={{ color: '#fff', width: '15px', height: '15px' }} />
                                                        </Avatar>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className='mt-5 px-5'>
            <Grid container spacing={10}>
                <Grid item xs={3}>
                    <div style={{
                        backgroundColor: '#ffffff',
                        color: '#2B3445',
                        borderRadius: '8px',
                        border: '1px solid #DCDCDC'
                    }}>
                        <div style={{ padding: '1.5rem' }}>
                            <div style={{ paddingLeft: '10px', paddingTop: '5px' }}>
                                {constructedData?.map((item) => {
                                    return (
                                        <div className='pb-2 pt-2'>
                                            <div style={{ fontSize: '15px', fontWeight: 600, color: '#2B3445' }}>{item?.categoryName}</div>
                                            {item.subCat.length > 0 ? item.subCat?.map((subItem) => {
                                                return (
                                                    <div className='d-flex align-items-center'>
                                                        <div>
                                                            <Checkbox size='small' />
                                                        </div>
                                                        <div
                                                            onClick={() => handleSubCategoryClick(subItem)}
                                                            style={{
                                                                fontSize: '12px',
                                                                paddingTop: '5px',
                                                                paddingBottom: '5px',
                                                                cursor: 'pointer',
                                                                color: selectedSubcatName === subItem?.subcategoryName ? '#23c2f2' : '#000',
                                                                fontWeight: 400
                                                            }}
                                                        >
                                                            {subItem?.subcategoryName}
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div style={{ fontSize: '13px', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '15px' }}>
                                                No Subcategory Found
                                            </div>}
                                        </div>
                                    )
                                })}
                                
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <FilterProductsCard subCategoryList={subCategoryList} getViewType={getViewType} />
                    {constructedProductData && constructedProductData?.length > 0
                        ? <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: viewType === 'grid' ? 'row' : 'column',
                            }}
                            className='mt-4 px-4'
                        >
                            {constructedProductData?.map((item, i) => {
                                return (
                                    <Card
                                        style={{
                                            marginBottom: '10px',
                                            width: viewType === 'grid' ? '290px' : '100%',
                                            height: 'auto',
                                            border: hoverIndex === i ? '1px solid #D3D3D3' : 'none',
                                            cursor: 'pointer',
                                            boxShadow: 'none'
                                        }}
                                        onClick={() => naviagteToProductDetails(item)}
                                        onMouseEnter={() => handleCardHover(i)}
                                        onMouseLeave={() => handleCardHover(null)}
                                    >
                                        <div style={{ paddingLeft: '2.2rem', paddingRight: '2.2rem', paddingTop: '2.5rem', paddingBottom: '2rem' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: viewType === 'list' ? 'row' : 'column',
                                                    transition: 'transform 0.2s ease',
                                                    transform: hoverIndex === i ? 'scale(1.1)' : 'scale(1)'
                                                }}>
                                                <img
                                                    src={item?.imageUrl ? item?.imageUrl : require('../../assets/images/placeholder-image.png')}
                                                    style={{ height: '120px', width: viewType === 'list' ? '10%' : '100%', objectFit: item?.imageUrl ? 'contain' : 'cover' }}
                                                />
                                                <div style={viewType === 'list' ? { marginLeft: '2rem' } : {}}>
                                                    <div style={{ fontSize: '12px', fontWeight: 600, color: hoverIndex === i ? '#23c2f2' : '#000', textDecoration: hoverIndex === i ? 'underline' : 'none' }} className='mt-3'>
                                                        {item?.productName}
                                                    </div>
                                                    <div className='mt-2 mb-1' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Rating
                                                            name="rating"
                                                            value={item?.overallRating}
                                                            style={{ fontSize: '15px', color: '#FBD731' }}
                                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                        />
                                                        {item?.ratings && item?.ratings?.length > 0
                                                            ? <div
                                                                style={{
                                                                    fontSize: "10px",
                                                                    marginLeft: '5px',
                                                                    color: "#000",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {item?.ratings?.length > 1
                                                                    ? `( ${item?.ratings?.length} Ratings )`
                                                                    : `( ${item?.ratings?.length} Rating )`
                                                                }
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                    <div style={{ fontSize: '16px', fontWeight: 600 }} className="mt-2">
                                                        ₹ {item?.price}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                        : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', fontSize: '20px', fontWeight: 600 }}>
                            No Data Found
                        </div>}
                </Grid>
            </Grid>
            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }} className='mt-3' >
                <Pagination count={1} color="primary" />
            </div>
            <div className='py-3'>
                {renderProducts()}
            </div>
        </div>
    )
}

export default NewProductsView;