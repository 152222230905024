import React, { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import CartCard from "../../Components/CartCard";
import { getMethod, postMethod, putMethod } from "../../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Checkbox, Stack, Avatar, Grid, Modal, Card, CardContent, Button, RadioGroup } from "@mui/material";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { blueGrey } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import PaymentCardView from "../PaymentCardView";
import { makeGetCartApi, updateProductQuantityInCart } from "./redux/CartAction";
import { useDispatch, useSelector } from "react-redux";
import { topCategoryData } from "../Utils";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseModal from '../../assets/icons/closeModal.svg';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '0.5rem 0.5rem',
  borderRadius: '15px'
};

const Cartpage = () => {
  const [cartList, setCartList] = useState([]);
  const [paymentType, setPaymentType] = useState('')
  const [cart, setCart] = useState(true)
  const [details, setDetails] = useState(false)
  const [payment, setPayment] = useState(false)
  const [cartInfo, setCartInfo] = useState({});
  const [address, setAddress] = useState({
    shipping_addressLine1: "",
    shipping_addressLine2: "",
    shipping_state: "",
    shipping_district: "",
    shipping_pincode: "",
    billing_addressLine1: "",
    billing_addressLine2: "",
    billing_state: "",
    billing_district: "",
    billing_pincode: ""
  });
  const [couponCode, setcouponCode] = useState("");

  const [billingAddressEnable, setBillingAddressEnable] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    district: '',
    pincode: '',
    state: ''
  });
  const [billingAddress, setBillingAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    district: '',
    pincode: '',
    state: ''
  });
  const [addressData, setAddressData] = useState([]);
  const [addressModal, setAddressModal] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState('0');
  const [fieldValidationStatus, setFieldValidationStatus] = useState({
    isShippingAddress1Error: false,
    isShippingAddress2Error: false,
    isShippingDistrictError: false,
    isShippingStateError: false,
    isShippingPincodeError: false,
    isBillingAddress1Error: false,
    isBillingAddress2Error: false,
    isBillingDistrictError: false,
    isBillingStateError: false,
    isBillingPincodeError: false
  })

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartData = useSelector(state => state.cartState.cartData);

  let json = localStorage.getItem("@USER");
  let User = JSON.parse(json);

  useEffect(() => {
    if (!User) {
      navigate('/login');
    }
  }, [User]);

  useEffect(() => {
    makeGetProfileAPI();
    makeGetCartApi(dispatch);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const makeGetProfileAPI = async () => {
    try {
      let url = "/customers/secure/profile";
      const token = localStorage.getItem("@token");
      let response = await getMethod({ url, token });
      if (response?.success && response?.data?.customer && response?.data?.customer?.addresses && response?.data?.customer?.addresses?.length > 0) {
        setAddressData(response?.data?.customer?.addresses);
      }
    } catch (e) {
    }
  }

  const handleShippingAddress = (name, value, errorKey) => {
    value !== '' && setFieldValidationStatus({ ...fieldValidationStatus, [errorKey]: false });
    setShippingAddress({ ...shippingAddress, [name]: value })
  };

  const handleBillingAddress = (name, value, errorKey) => {
    value !== '' && setFieldValidationStatus({ ...fieldValidationStatus, [errorKey]: false });
    setBillingAddress({ ...billingAddress, [name]: value })
  };

  const handleAddressChange = (event) => {
    setSelectedAddressIndex(event?.target?.value);
    setBillingAddressEnable(false);
  };


  useEffect(() => {
    if (billingAddressEnable && shippingAddress !== undefined && shippingAddress !== null) {
      setBillingAddress(shippingAddress);
      setFieldValidationStatus({
        ...fieldValidationStatus,
        isBillingAddress1Error: !shippingAddress?.addressLine1,
        isBillingAddress2Error: !shippingAddress?.addressLine2,
        isBillingDistrictError: !shippingAddress?.district,
        isBillingStateError: !shippingAddress?.state,
        isBillingPincodeError: !shippingAddress?.pincode
      })
    } else {
      setBillingAddress({
        addressLine1: '',
        addressLine2: '',
        district: '',
        pincode: '',
        state: ''
      });
    }
  }, [billingAddressEnable]);

  const memoizedAddress = useMemo(() => {
    if (shippingAddress && billingAddress) {
      return {
        shipping_addressLine1: shippingAddress?.addressLine1 || '',
        shipping_addressLine2: shippingAddress?.addressLine2 || '',
        shipping_state: shippingAddress?.state || '',
        shipping_district: shippingAddress?.district || '',
        shipping_pincode: shippingAddress?.pincode || '',
        billing_addressLine1: billingAddress?.addressLine1 || '',
        billing_addressLine2: billingAddress?.addressLine2 || '',
        billing_state: billingAddress?.state || '',
        billing_district: billingAddress?.district || '',
        billing_pincode: billingAddress?.pincode || ''
      };
    }
    return {};
  }, [shippingAddress, billingAddress]);

  useEffect(() => {
    setAddress(memoizedAddress);
  }, [memoizedAddress]);

  const memoizedInitalShippingAddress = useMemo(() => {
    if (addressData && addressData[0]) {
      const {
        shipping_addressLine1 = '',
        shipping_addressLine2 = '',
        shipping_district = '',
        shipping_pincode = '',
        shipping_state = ''
      } = addressData[0];
      return {
        addressLine1: shipping_addressLine1,
        addressLine2: shipping_addressLine2,
        state: shipping_state,
        district: shipping_district,
        pincode: shipping_pincode
      };
    }
    return {};
  }, [addressData]);

  useEffect(() => {
    setShippingAddress(memoizedInitalShippingAddress)
  }, [memoizedInitalShippingAddress]);

  const applyCoupon = async () => {
    try {
      let url = "customers/secure/cart/apply-coupon/" + couponCode;
      let token = localStorage.getItem("@token");
      let response = await postMethod({ url, token, payload: {} });
      if (response.success) {
        setCartList(response.data.orderItems);
        setCartInfo(response.data);
      } else {
        toast.error(response.message);
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addAddress = async () => {
    try {
      let url = "customers/secure/cart/addAddress";
      let token = localStorage.getItem("@token");
      let response = await postMethod({ url, token, payload: address });
      if (response.success) {
        setCartList(response.data.orderItems);
        setCartInfo(response.data);
        toast.success("Address has been set");
      } else {
        toast.error(response.message);
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePaymentMethodChange = async () => {
    try {
      let url = "customers/secure/cart/paymentmode?mode=" + paymentType;
      let token = localStorage.getItem("@token");
      let response = await putMethod({ url, token, payload: address });
      if (response.success) {
        setCartList(response.data.orderItems);
        setCartInfo(response.data);
      } else {
        toast.error(response.message);
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const placeOrder = async () => {
    try {
      let url = "customers/secure/cart/placeorder";
      let token = localStorage.getItem("@token");
      let response = await postMethod({ url, token, payload: {} });
      if (response.success) {
        setCartList(response.data.orderItems);
        setCartInfo(response.data);
        toast.success("Order Placed");
        navigate("/orders");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error(e.response.message);
    }
  };

  const handleQuantityOnchange = (quantity, productUuid) => {
    updateProductQuantityInCart(productUuid, quantity, dispatch)
  }

  const renderTopCategories = () => {
    return (
      <div className="px-5" >
        <div
          style={{
            fontWeight: 600,
            fontSize: '16px'
          }}
        >
          TOP CATEGORIES
        </div>
        <div className="py-4">
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} gap={"38px"} flexWrap={'wrap'}>
            {topCategoryData?.map(item => {
              return (
                <Avatar style={{ backgroundColor: '#F3F0F0', width: '140px', height: '140px' }}>
                  <img
                    src={require(`../../assets/topCategories/${item.icon}`)}
                    alt="topCat"
                    style={{ width: '80px', height: '80px' }}
                  />
                </Avatar>
              )
            })}
          </Stack>
        </div>
      </div>
    )
  };

  const handleAddressOpenModal = () => {
    setAddressModal(true)
  };

  const handleAddressModalClose = () => {
    setAddressModal(false);
  }

  const constructAddressList = (address) => {
    if (address?.formatted_address_shipping) {
      return address?.formatted_address_shipping;
    } else {
      return `${address?.shipping_addressLine1}, ${address?.shipping_addressLine2}, ${address?.shipping_district}, ${address?.shipping_state}, ${address?.shipping_pincode}`;
    }
  };

  const onSubmitAddressData = () => {
    if (addressData && selectedAddressIndex && addressData[selectedAddressIndex]) {
      const {
        shipping_addressLine1 = '',
        shipping_addressLine2 = '',
        shipping_district = '',
        shipping_pincode = '',
        shipping_state = ''
      } = addressData[selectedAddressIndex];
      setShippingAddress({
        addressLine1: shipping_addressLine1,
        addressLine2: shipping_addressLine2,
        state: shipping_state,
        district: shipping_district,
        pincode: shipping_pincode
      })
      setFieldValidationStatus({
        ...fieldValidationStatus,
        isShippingAddress1Error: !shipping_addressLine1,
        isShippingAddress2Error: !shipping_addressLine2,
        isShippingDistrictError: !shipping_state,
        isShippingStateError: !shipping_district,
        isShippingPincodeError: !shipping_pincode
      })
      handleAddressModalClose();
    }
  }

  const renderStepper = () => {
    return (
      cartData && cartData?.orderItems && cartData?.orderItems?.length > 0 && <div className="stepperComponent">
        <Chip
          label="1. Cart"
          sx={{ backgroundColor: cart ? '#23c2f2' : '#FCE9EC', color: cart ? 'white' : 'rgb(210, 63, 87)', fontWeight: 'bold', height: '32px', padding: '8px 16px' }}
        />
        <Box sx={{ backgroundColor: details ? '#23c2f2' : '#9ee5ff', width: '50px', height: '4px' }} />
        <Chip
          label="2. Details"
          sx={{ backgroundColor: details ? '#23c2f2' : '#fff', color: details ? 'white' : '#23c2f2', fontWeight: 'bold', height: '32px', padding: '8px 16px', border: 'solid #23c2f2' }}
        />
        <Box sx={{ backgroundColor: payment ? '#23c2f2' : '#9ee5ff', width: '50px', height: '4px' }} />
        <Chip
          label="3. Payment"
          sx={{ backgroundColor: payment ? '#23c2f2' : '#fff', color: payment ? 'white' : '#23c2f2', fontWeight: 'bold', height: '32px', padding: '8px 16px', border: 'solid #23c2f2' }}
        />
      </div>
    )
  }

  const checkValidFields = () => {
    let isAllFieldsAreNotValid = false;
    let fieldValidationStatus = {
      isShippingAddress1Error: false,
      isShippingAddress2Error: false,
      isShippingDistrictError: false,
      isShippingStateError: false,
      isShippingPincodeError: false,
      isBillingAddress1Error: false,
      isBillingAddress2Error: false,
      isBillingDistrictError: false,
      isBillingStateError: false,
      isBillingPincodeError: false
    }
    if (!shippingAddress?.addressLine1) {
      fieldValidationStatus.isShippingAddress1Error = true;
      isAllFieldsAreNotValid = true;
    }
    if (!shippingAddress?.addressLine2) {
      fieldValidationStatus.isShippingAddress2Error = true;
      isAllFieldsAreNotValid = true;
    }
    if (!shippingAddress?.district) {
      fieldValidationStatus.isShippingDistrictError = true;
      isAllFieldsAreNotValid = true;
    }
    if (!shippingAddress?.state) {
      fieldValidationStatus.isShippingStateError = true;
      isAllFieldsAreNotValid = true;
    }
    if (!shippingAddress?.pincode) {
      fieldValidationStatus.isShippingPincodeError = true;
      isAllFieldsAreNotValid = true;
    }
    if (!billingAddress?.addressLine1) {
      fieldValidationStatus.isBillingAddress1Error = true;
      isAllFieldsAreNotValid = true;
    }
    if (!billingAddress?.addressLine2) {
      fieldValidationStatus.isBillingAddress2Error = true;
      isAllFieldsAreNotValid = true;
    }
    if (!billingAddress?.district) {
      fieldValidationStatus.isBillingDistrictError = true;
      isAllFieldsAreNotValid = true;
    }
    if (!billingAddress?.state) {
      fieldValidationStatus.isBillingStateError = true;
      isAllFieldsAreNotValid = true;
    }
    if (!billingAddress?.pincode) {
      fieldValidationStatus.isBillingPincodeError = true;
      isAllFieldsAreNotValid = true;
    }
    setFieldValidationStatus(fieldValidationStatus);
    return isAllFieldsAreNotValid;
  };

  const isAddressPresent = (addressList, targetAddress) => {
    return addressList.some(address =>
      address.shipping_addressLine1 === targetAddress.addressLine1 &&
      address.shipping_addressLine2 === targetAddress.addressLine2 &&
      address.shipping_state === targetAddress.state &&
      address.shipping_district === targetAddress.district &&
      address.shipping_pincode === targetAddress.pincode
    );
  };

  const onProceedToPayment = () => {
    const inValidFields = checkValidFields();
    if (!inValidFields) {
      navigate('/payment');
      setDetails(true);
      setPayment(true);
      if (!isAddressPresent(addressData, shippingAddress)) {
        addAddress();
      }
    }
  };

  const handleNextMove = () => {
    setDetails(true)
    navigate('/checkout')
  }

  const renderCheckoutView = () => {
    return (
      <div style={{ margin: 'auto' }} className="mt-3 container">
         <style>{`
                            @media (max-width: 589px) {
                                #address_column{
                                    flex-direction: column;
                                    text-align: center;
                                }
                                .col-8{
                                    width:100%;
                                }
                                .col-4{
                                width:100% !important;
                                }
                                .ms-5{
                                margin-left:0px !important;
                                }
                               
                            }
                        `}</style>
        <div className="d-flex" id="address_column">
          <div className="addressSection col-8">
            {addressData && addressData?.length > 0
              ? (
                <div
                  className="mb-3"
                  style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', alignItems: 'end', justifyContent: 'flex-end' }}
                  onClick={() => handleAddressOpenModal()}
                >
                  <div>
                    <AddCircleOutlineIcon style={{ color: '#2abdf0', fontSize: 20 }} />
                  </div>
                  <div style={{ fontSize: '14px', fontWeight: 600, color: '#2abdf0' }} className="ms-1">
                    Select Address
                  </div>
                </div>
              )
              : null
            }

            <style>
              {`
                    .checkout_red_btn{
                      border-radius: 25px!important;
                    }
                  @media (max-width:600px){
                   #payment_button{
                        display: block !important;
                    }
                               
                  }
              `}
            </style>
            <div className="shippingAddress mb-3" id="shipping_address">
              <p>Shipping Address</p>
              <div className="d-flex">
                <div className="me-5">
                  <TextField
                    fullWidth
                    label="Address 1"
                    id="address1"
                    size="small"
                    className="mb-3"
                    value={shippingAddress.addressLine1}
                    onChange={(e) => handleShippingAddress('addressLine1', e.target.value, 'isShippingAddress1Error')}
                    required={true}
                    error={fieldValidationStatus.isShippingAddress1Error && 'Enter the address'}
                  />
                  <TextField
                    fullWidth
                    label="District"
                    id="district"
                    size="small"
                    className="mb-3"
                    value={shippingAddress.district}
                    onChange={(e) => handleShippingAddress('district', e.target.value, 'isShippingDistrictError')}
                    required={true}
                    error={fieldValidationStatus.isShippingDistrictError && 'Enter the district'}
                  />
                  <TextField
                    fullWidth
                    label="Pincode"
                    id="pincode"
                    size="small"
                    className="mb-3"
                    value={shippingAddress?.pincode}
                    onChange={(e) => handleShippingAddress('pincode', e.target.value, 'isShippingPincodeError')}
                    required={true}
                    error={fieldValidationStatus.isShippingPincodeError && 'Enter the pincode'}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Address 2"
                    id="addressLine2"
                    size="small"
                    className="mb-3"
                    value={shippingAddress?.addressLine2}
                    onChange={(e) => handleShippingAddress('addressLine2', e.target.value, 'isShippingAddress2Error')}
                    required={true}
                    error={fieldValidationStatus.isShippingAddress2Error && 'Enter the address'}
                  />
                  <TextField
                    fullWidth
                    label="State"
                    id="state"
                    size="small"
                    className="mb-3"
                    value={shippingAddress?.state}
                    onChange={(e) => handleShippingAddress('state', e.target.value, 'isShippingStateError')}
                    required={true}
                    error={fieldValidationStatus.isShippingStateError && 'Enter the address'}
                  />
                </div>
              </div>
            </div>
            <div className="shippingAddress mt-4">
              <p>Billing Address</p>
              <FormControlLabel
                label="Same as shipping address"
                control={
                  <Checkbox
                    size="small"
                    checked={billingAddressEnable}
                    onChange={(e) => setBillingAddressEnable(e.target.checked)}
                    sx={{
                      color: '#2DBDEE',
                      '&.Mui-checked': {
                        color: '#2DBDEE'
                      },
                    }}

                  />}
                className="mb-3"
              />
              <div className="d-flex">
                <div className="me-5">
                  <TextField
                    fullWidth
                    label="Address 1"
                    id="address1"
                    size="small"
                    className="mb-3"
                    value={billingAddress?.addressLine1}
                    onChange={(e) => handleBillingAddress('addressLine1', e.target.value, 'isBillingAddress1Error')}
                    required={true}
                    error={fieldValidationStatus.isBillingAddress1Error && 'Enter the address'}
                  />
                  <TextField
                    fullWidth
                    label="District"
                    id="district"
                    size="small"
                    className="mb-3"
                    value={billingAddress?.district}
                    onChange={(e) => handleBillingAddress('district', e.target.value, 'isBillingDistrictError')}
                    required={true}
                    error={fieldValidationStatus.isBillingDistrictError && 'Enter the district'}
                  />
                  <TextField
                    fullWidth
                    label="Pincode"
                    id="pincode"
                    size="small"
                    className="mb-3"
                    value={billingAddress?.pincode}
                    onChange={(e) => handleBillingAddress('pincode', e.target.value, 'isBillingPincodeError')}
                    required={true}
                    error={fieldValidationStatus.isBillingPincodeError && 'Enter the pincode'}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Address 2"
                    id="addressLine1"
                    size="small"
                    className="mb-3"
                    value={billingAddress?.addressLine2}
                    onChange={(e) => handleBillingAddress('addressLine1', e.target.value, 'isBillingAddress1Error')}
                    required={true}
                    error={fieldValidationStatus.isBillingAddress2Error && 'Enter the address'}
                  />
                  <TextField
                    fullWidth
                    label="State"
                    id="state"
                    size="small"
                    className="mb-3"
                    value={billingAddress?.state}
                    onChange={(e) => handleBillingAddress('state', e.target.value, 'isBillingStateError')}
                    required={true}
                    error={fieldValidationStatus.isBillingStateError && 'Enter the state'}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button style={{borderRadius:'4px'}}
                onClick={() => {
                  navigate('/cart');
                  setDetails(false);
                }}
                className="apply_voucher_btn mt-3 me-5"
              >
                Back To Cart
              </button>
              <button style={{borderRadius:'4px'}}
                onClick={onProceedToPayment}
                className="checkout_red_btn mt-3 ms-1 ps-5"  
              >
                Proceed To Payment 
              </button>
            </div>
          </div>
          <PaymentCardView isFromCart={location.pathname === '/cart'} cartInfo={cartData} />
        </div>
      </div>
    )
  };

  const renderPaymentView = () => {
    return (
        <div style={{ margin: 'auto' }} className="mt-3 container">
        <div className="d-flex">
          <div className="col-8">
            <div className="cardDetails">
              <div className="cardAccSec">
                <div style={{ borderBottom: '1px solid rgb(125, 135, 156,0.1)' }} className="px-5 py-2">
                  <FormControlLabel value="Pay with credit card" control={
                    <Radio
                      sx={{
                        color: blueGrey[800],
                        '&.Mui-checked': {
                          color: '#23c2f2',
                        },
                      }}
                      onChange={() => setPaymentType('Card')}
                      checked={paymentType === 'Card' ? true : false} />}
                    label="Pay With Credit Card"
                  />
                </div>
                {paymentType === 'Card' ?
                  <div className="d-flex px-5 py-3" style={{ borderBottom: '1px solid rgb(125, 135, 156,0.1)' }}>
                    <div className="me-5">
                      <TextField fullWidth label="Card Number" id="cardnum" size="small" className="mb-3" />
                      <TextField fullWidth label="Name on Card" id="fname" size="small" className="mb-3" />
                      <button
                        onClick={handlePaymentMethodChange}
                        className="apply_voucher_btn mt-3"
                        sx={{ width: '150px !important' ,borderRadius:'25px'}}
                      >
                        Submit
                      </button>
                    </div>
                    <div>
                      <TextField fullWidth label="Exp Date" id="fname" size="small" className="mb-3" />
                      <TextField fullWidth label="Name on Card" id="fname" size="small" className="mb-3" />
                    </div>
                  </div>
                  : ''
                }
              </div>
              <div className="cardAccSec">
                <div style={{ borderBottom: '1px solid rgb(125, 135, 156,0.1)' }} className="px-5 py-2">
                  <FormControlLabel value="cod" control={
                    <Radio sx={{
                      color: blueGrey[800],
                      '&.Mui-checked': {
                        color: '#23c2f2',
                      },
                    }} onChange={() => setPaymentType('Cash')} checked={paymentType === 'Cash' ? true : false} />} label="Cash On Delivery" />
                </div>
              </div>
            </div>
            <div className="d-flex" id="payment_button">
              <button style={{borderRadius:'25px'}}
                onClick={() => {
                  navigate('/checkout');
                  setPayment(false);
                }}
                className="apply_voucher_btn mt-3 me-5"
              >
                Back To Checkout Details
              </button>
              <button style={{borderRadius:'25px'}}
                onClick={placeOrder}
                className="checkout_red_btn mt-3"
              >
                Confirm
              </button>
            </div>
          </div>
          <PaymentCardView isFromCart={location.pathname === '/cart'} cartInfo={cartData} />
        </div>
      </div> 
    )
  };

  const renderCartView = () => {
    return (
      <div style={{ margin: 'auto' }} className="mt-3">
         <style jsx>{`

         
            @media (max-width: 769px) {
                #payment_details{
                   flex-direction:column;
                   align-items:center; 
                }
                .cartSection.col-8{
                width:90%;
                }
                .col-4{
                width:90%;
                }
                .ms-5{
                margin-left:0px !important;
                }
            }
            @media(max-width:503px){
                .cart_cart_whole.mb-4{
                    flex-direction:column;
                    align-items: center;
                }
                .cart_closer.d-flex{
                    justify-content: normal;
                    height:72px !important;
                }
            }
            @media(max-width:369px){
                .cart_dtls_only{
                align-items: center;
                text-align: center !important;
                text-align:center;
                }
            }
        `}</style>
        <div className="d-flex" id="payment_details">
          <div className="cartSection col-8">
            {cartData?.orderItems?.map((item, i) => {
              return (
                <CartCard
                  key={i}
                  item={item}
                  handleQuantityOnchange={handleQuantityOnchange}
                />
              )
            })}
          </div>
          <PaymentCardView isFromCart={location.pathname === '/cart'} handleNextMove={handleNextMove} cartInfo={cartData} />
        </div>
      </div>
      
    )
  };

  const renderEmptyCartView = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} className="container py-5">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <img
              src={require("../../assets/images/empty-cart.png")}
              alt="Empty Cart"
              className="img-fluid"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Grid>
        </Grid>
        <div style={{ margin: "54px 400px" }} >
          <div style={{
            fontSize: "20px", fontWeight: 400, lineHeight: "22px", letterSpacing: " 0.03em", textAlign: "center", color: "#00000080"
          }}>Looks like you have not made your choice yet</div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "45px" }} >
            <button
              onClick={() => navigate('/')}
              className="checkout_red_btn"
              style={{ width: "317px", height: "45px", borderRadius: "10px" }}
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="my-4">
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "#000",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "#fff",
            },
          }
        }}
      />
      <div>
        {renderStepper()}
      </div>
      {cartData && cartData?.orderItems && cartData?.orderItems?.length > 0
        ? <div className="dynamicComponent container pt-3">
          {location.pathname === '/checkout'
            ? renderCheckoutView()
            : location.pathname === '/payment'
              ? renderPaymentView()
              : renderCartView()
          }
          <Modal open={addressModal} onClose={handleAddressModalClose}>
            <Box sx={{ ...modalStyle, height: '450px', position: 'relative',width:'60%' }}>
              <div style={{ textAlign: 'end', cursor: 'pointer' }} onClick={handleAddressModalClose}>
                <img src={CloseModal} alt="Close" style={{ width: '25px', height: '25px' }} />
              </div>
              <div className="mt-1 px-2" style={{ fontSize: '18px', fontWeight: 600, marginBottom: '12px' }}>Address List</div>
              <div className="py-2 px-2" style={{ overflowY: 'auto', maxHeight: '300px' }}>
                <RadioGroup value={selectedAddressIndex} onClick={handleAddressChange} >
                  {addressData?.map((item, index) => (
                    <Card key={index} variant="outlined" style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', borderRadius: '10px' }}>
                      <CardContent className="px-3 py-3" style={{ flexGrow: 1, fontSize: '15px' }}>
                        {constructAddressList(item)}
                      </CardContent>
                      <FormControlLabel
                        value={index}
                        control={
                          <Radio
                            sx={{
                              color: '#2abdf0',
                              '&.Mui-checked': {
                                color: '#2abdf0'
                              },
                            }}
                          />
                        }
                        label=""
                        style={{ marginRight: '5px' }}
                      />
                    </Card>
                  ))}
                </RadioGroup>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'absolute', bottom: 20, right: 10 }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#2DBDEE', width: '150px',  borderRadius:'25px'}}
                  onClick={onSubmitAddressData}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Apply
                </Button>
              </div>
            </Box>
          </Modal>
        </div >
        : <>
          {renderEmptyCartView()}
          {renderTopCategories()}
        </>
      }
    </div>
  );
};

export default Cartpage;