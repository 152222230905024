import { combineReducers } from 'redux';
import { OrderReducer } from '../../Views/Order/redux/OrderReducer';
import { ComplaintReducer } from '../../Views/SupportTicket/redux/ComplaintReducer';
import { AuthReducer } from '../../Views/auth/redux/AuthReducer';
import { CartReducer } from '../../Views/Cart/redux/CartReducer';
import { WishlistReducer } from '../../Views/Wishlist/redux/WishlistReducer';
import { ProductReducer } from '../../Views/Products/redux/ProductReducer';
import { DashboardReducer } from '../../Views/Home/redux/DashboardReducer';
import { AffiliateReducer } from '../../Views/Affiliate/redux/AffiliateReducer';
import { ProfileReducer } from '../../Views/Profile/redux/ProfileReducer';

const rootReducer = combineReducers({
  dashboardState: DashboardReducer,
  authState: AuthReducer,
  orderState: OrderReducer,
  complaintState: ComplaintReducer,
  cartState: CartReducer,
  wishlistState: WishlistReducer,
  productState: ProductReducer,
  affiliateState: AffiliateReducer,
  profileState: ProfileReducer
});

export default rootReducer;
