import React, { useEffect, useState } from 'react'
import NewHeader from '../../Components/NewHeader';
import { AppBar, Tab, Tabs, Typography, Box, Container } from '@mui/material';
import { Table } from 'antd';
import ActivateCouponsForm from './ActivateCouponsForm';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeGetOffAffiliateSettlementApi, makeGetOfflineAffiliateApi } from './redux/AffiliateAction';
import NewSettlementForm from './NewSettlementForm';

const OfflineAffiliateList = () => {
    const [value, setValue] = useState(0);
    const [openModal, setModalOpen] = useState(false);
    const [openSettlementModal, setSettlementModal] = useState(false);

    const [floatingBalance, setFloatingBalance] = useState(0);
    const dispatch = useDispatch();

    const affiliateData = useSelector(state => state.affiliateState.offlineAffiliateData);
    const settlementData = useSelector(state => state.affiliateState.offlineAffiliateSettlement);

    useEffect(() => {
        let balance = 0;
        if(settlementData != null && settlementData?.length) {
            
            if(settlementData.length > 0) {
                settlementData.sort((a, b) => new Date(b.transactionOn) - new Date(a.transactionOn));

for (const transaction of settlementData) {
    balance += transaction.credit - transaction.debit;
    console.log(`Transaction ID: ${transaction._id}, Balance: ${balance}`);
}
            }
        }
        setFloatingBalance(balance)
    },[settlementData])

    console.log("affiliateData",affiliateData)

    useEffect(() => {
        makeGetOfflineAffiliateApi(dispatch);
        makeGetOffAffiliateSettlementApi(dispatch);
    },[]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const tabData = [
        {
            label: 'Coupons',
        },
        {
            label: 'Settlements',
        }
    ];

    const columns = [
        {
            title: <div style={{ textAlign: 'center' }}>Coupon Name</div>,
            dataIndex: 'couponName',
            key: 'couponName',
            render: (serialNo) => {
                return (
                    <div style={{ textAlign: 'center' }}>{serialNo}</div>
                )
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>No of Coupons</div>,
            key: 'noOfCoupons',
            dataIndex: 'noOfCoupons',
            render: (noOfCoupons) => {
                return (
                    <div style={{ textAlign: 'center' }}>{noOfCoupons}</div>
                )
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>Available Coupons</div>,
            dataIndex: 'availableCoupons',
            key: 'availableCoupons',
            render: (availableCoupons) => {
                return (
                    <div style={{ textAlign: 'center' }}>{availableCoupons}</div>
                )
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Expire On</div>,
            dataIndex: 'expiryOn',
            key: 'expiryOn',
            render: (expiryOn) => {
                return (
                    <div style={{ textAlign: 'center' }}>{moment(expiryOn).format("DD-MM-yyyy")}</div>
                )
            }
        }
    ];

    const settlementColumns = [
        {
            title: <div style={{ textAlign: 'center' }}>Date</div>,
            dataIndex: 'transactionOn',
            key: 'transactionOn',
            render: (transactionOn) => {
                return (
                    <div style={{ textAlign: 'center' }}>{moment(transactionOn).format("DD-MM-yyyy")}</div>
                )
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>Credit</div>,
            key: 'credit',
            dataIndex: 'credit',
            render: (credit) => {
                return (
                    <div style={{ textAlign: 'center' }}>{credit}</div>
                )
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>Debit</div>,
            dataIndex: 'debit',
            key: 'debit',
            render: (debit) => {
                return (
                    <div style={{ textAlign: 'center' }}>{debit}</div>
                )
            }
        }
    ];


    return (
        <div>
            <div className='mt-4 d-flex'>
                <NewHeader title={'Offline Affiliate'} />
                <div
                    className='ms-4'
                    style={{
                        fontSize: '18px',
                        color: '#23c2f2',
                        padding: '0.5rem 1rem',
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        borderColor: '#23c2f2',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        fontWeight: '500',
                        width: '200px',
                        textAlign: 'center'
                    }}
                >
                    Floating Cash: <span style={{ fontWeight: 'bold' }}>{floatingBalance}</span>
                </div>
            </div>
            <div className='my-4'>
                <div style={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { backgroundColor: '#23C2F2' } }}
                        centered
                    >
                        {tabData.map((item, index) => {
                            return <Tab
                                label={item.label}
                                sx={{ marginX: 10 }}
                                style={{ fontSize: '19px', fontWeight: 600, color: value === index ? '#23C2F2' : '' }}
                            />
                        })}
                    </Tabs>
                    <div className='mt-3'>
                        {value === 0 && <div>
                            <div className='text-end mb-4'>
                                <button
                                    className="btn mt-3 text-center py-2"
                                    style={{
                                        backgroundColor: '#23c2f2',
                                        color: '#fff',
                                        border: '1px solid #23c2f2',
                                        borderRadius: '6px',
                                        width: 150,
                                        fontWeight: 600,
                                        fontSize: '14px'
                                    }}
                                onClick={() => setModalOpen(true)}
                                >
                                    Activate Coupon
                                </button>
                            </div>
                            <Table
                                columns={columns}
                                dataSource={affiliateData}
                                className="custom-table"
                            />
                        </div>}
                        {value === 1 && (
                            <div>
                                <div className='text-end mb-4'>
                                    <button
                                        className="btn mt-3 text-center py-2"
                                        style={{
                                            backgroundColor: '#23c2f2',
                                            color: '#fff',
                                            border: '1px solid #23c2f2',
                                            borderRadius: '6px',
                                            width: 150,
                                            fontWeight: 600,
                                            fontSize: '14px'
                                        }}
                                    onClick={() => setSettlementModal(true)}
                                    >
                                        New Settlement
                                    </button>
                                </div>
                                <Table
                                    columns={settlementColumns}
                                    dataSource={settlementData}
                                    className="custom-table"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ActivateCouponsForm openModal={openModal} modalOnClose={() => setModalOpen(false)} />
            <NewSettlementForm openModal={openSettlementModal} modalOnClose={() => setSettlementModal(false)} />
        </div>
    )
}

export default OfflineAffiliateList;