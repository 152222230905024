import React, { useEffect, useState } from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { getMethod } from '../helpers';

const PaymentMethods = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const fetchPaymentMethod = async () => {
    try {
      let url = 'customers/secure/last-order'; // Adjust this URL to fetch the last order
      let token = localStorage.getItem('@token');
      let response = await getMethod({ url, token });
      if (response.success) {
        setPaymentMethod(response.data.paymentMethod);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full height of the viewport
        textAlign: 'center',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Your Payment Method
      </Typography>

      <div>
        Coming Soon
      </div>
    </div>
  );
}

export default PaymentMethods;