import { AffiliateActionTypes } from './AffiliateActionType';

const INITIAL_STATE = {
    onlineAffiliateData: [],
    offlineAffiliateData: [],
    offlineAffiliateSettlement: []
};

export const AffiliateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AffiliateActionTypes.GET_ONLINE_AFFILIATE:
            return {
                ...state,
                onlineAffiliateData: action.payload
            };
        case AffiliateActionTypes.GET_OFFLINE_AFFILIATE:
            return {
                ...state,
                offlineAffiliateData: action.payload
            };
        case AffiliateActionTypes.GET_OFFLINE_AFFILIATE_SETTLEMENT:
            return {
                ...state,
                offlineAffiliateSettlement: action.payload
            };
        default:
            return state;
    }
};