import React, { useEffect, useState } from "react";
import { Card, Grid, Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { makeGetWishlistApi } from "./redux/WishlistAction";
import { useDispatch, useSelector } from "react-redux";
import { makeAddToCartApi } from "../Cart/redux/CartAction";

function WishlistCard({ isFromProfile = false }) {

  const [hoverIndex, setHoverIndex] = useState(null);

  const dispatch = useDispatch();
  const wishlistData = useSelector((state) => state.wishlistState.wishlistData);

  useEffect(() => {
    makeGetWishlistApi(dispatch);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleCardHover = (index) => {
    setHoverIndex(index);
  };

  return (
    <Grid
      container
      rowSpacing={isFromProfile ? 0 : 5} columnSpacing={isFromProfile ? 3 : 7}
      className={`${isFromProfile ? 'mt-0' : 'mt-2' } px-5 pb-5`}  id= {`${isFromProfile ? 'mt-0' : 'mt-2' }  profile_wishlis`}
      style={{ backgroundColor: '#F6F9FC' }}
    >
      {wishlistData?.map((item, index) => {
        return (
          <Grid item key={index}>
            <Card
              style={{ width: isFromProfile ? '270px' : '290px', marginBottom: isFromProfile ? '1.5rem' : 0 }}
              onMouseEnter={() => handleCardHover(index)}
              onMouseLeave={() => handleCardHover(null)}
            >
              <div className='p-4' style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <img src={item?.imageUrl} style={{ height: '200px', width: '100%', objectFit: 'contain' }} />
                </div>
                <div className="pt-1">
                  <div style={{ fontSize: isFromProfile ? '12px' : '14px', color: '#373F50', fontWeight: 600 }} className='mt-3'>
                    {item?.productName}
                  </div>
                  <div className='my-2'>
                    <Rating
                      name="rating"
                      value={item?.overallRating}
                      size='small'
                      readOnly
                      sx={{
                        '& .MuiRating-iconFilled': {
                          color: '#ffc107', // Gold color
                        },
                        '& .MuiRating-iconHover': {
                          color: '#ffc107', // Ensure no hover effect
                        },
                      }}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                  </div>
                  {item?.price
                    ? <div style={{ color: '#23c2f2', fontSize: isFromProfile ? '12px' : '14px', fontWeight: 600 }}>
                      ₹{item?.price}.00
                    </div>
                    : null
                  }
                </div>
                <button
                  className="btn mt-3 text-center"
                  style={{
                    backgroundColor: hoverIndex === index ? '#23c2f2' : '#fff',
                    color: hoverIndex === index ? '#fff' : '#23c2f2',
                    border: '1px solid #23c2f2',
                    borderRadius: '4px',
                    width: 150,
                    fontWeight: 600,
                    fontSize: isFromProfile ? '12px' : '14px'
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => makeAddToCartApi(item, dispatch, true)}
                >
                  Move To Cart
                </button>
                <style>
                  {`

                  .css-zdvz49-MuiGrid-root>.MuiGrid-item {
    padding-left: 15px; 
}
                    @media (max-width: 600px) {
                    #profile_wishlis{
                    padding-left:0rem !important;
                    }
                    .px-5 {
    padding-right: 3rem !important;
    padding-left: 0rem !important;
}
                    }
                  `}
                </style>
              </div>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  );
}

export default WishlistCard;
