import { Box, Modal, Button } from '@mui/material';
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    minHeight: 120,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '2rem 3rem',
    borderRadius: '10px'
};

const ContactModal = ({ modalVisible, modalOnClose, data }) => {
    return (
        <Modal
            open={modalVisible}
            onClose={modalOnClose}
        >
            <Box sx={style}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '24px', fontWeight: '400', lineHeight: '28.8px' }}>
                    Details
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '22px', fontWeight: 'bold' }} className='mt-4'>
                    <div style={{ marginLeft: '15px', fontSize: '16px', fontWeight: '400', lineHeight: '19.2px' }}>
                        Contact Name : <span className='ps-3' style={{ color: 'rgba(50, 50, 50, 1)', fontWeight: '500' }}>{data?.contactName}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '22px', fontWeight: 'bold' }} className='mt-4'>
                    <div style={{ marginLeft: '15px', fontSize: '16px', fontWeight: '400', lineHeight: '19.2px' }}>
                        Contact Number : <span className='ps-3' style={{ color: 'rgba(50, 50, 50, 1)', fontWeight: '500' }}>{data?.phoneNumber}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '22px', fontWeight: 'bold' }} className='mt-4'>
                    <div style={{ marginLeft: '15px', fontSize: '16px', fontWeight: '400', lineHeight: '19.2px' }}>
                        Email : <span className='ps-3' style={{ color: 'rgba(50, 50, 50, 1)', fontWeight: '500' }}>{data?.email}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={modalOnClose}
                    >
                        OK
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ContactModal;
