import { ProductActionTypes } from './ProductActionTypes';

const INITIAL_STATE = {
    categoryData: [],
    subCategoryData: [],
    productData: [],
    productSearchData: [],
    productAllList: []
};

export const ProductReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.SET_CATEGORY_DATA:
            return {
                ...state,
                categoryData: action.payload
            };
        case ProductActionTypes.SET_SUBCATEGORY_DATA:
            return {
                ...state,
                subCategoryData: action.payload
            };
        case ProductActionTypes.SET_PRODUCT_DATA:
            return {
                ...state,
                productData: action.payload
            };
        case ProductActionTypes.SET_PRODUCT_SEARCH_DATA:
            return {
                ...state,
                productSearchData: action.payload
            }
        case ProductActionTypes.MAKE_ALL_LIST_DATA:
            return {
                ...state,
                productAllList: action.payload
            }
        default:
            return state;
    }
};