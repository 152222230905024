import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { postMethod } from "../helpers";
import toast from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Google from "../Views/auth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import FacebookIcon from '@mui/icons-material/Facebook';
import { useDispatch } from "react-redux";
import { storeLoginData } from "../Views/auth/redux/AuthAction";

const LoginModal = ({ showSignin, setShowSignin, handleClose, handleShow }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  // const [user,setUser]= useState({});
  // let json = localStorage.getItem("@USER");
  // let User = JSON.parse(json);
  // const [showSignin, setShowSignin] = useState(false);
  const [eyechange, setEyechange] = useState("password");

  // const handleClose = () => setShowSignin(false);
  // const handleShow = () => setShowSignin(true);

  const dispatch = useDispatch();

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      let url = "users/login";
      var payload = {
        emailOrPhoneNumber: email,
        password: password,
        userType: "Customer",
      };
      let response = await postMethod({ url, payload });
      if (response.success) {
        dispatch(storeLoginData(response.data));
        toast.success(response.message);
        localStorage.setItem("@token", response.data.token);
        let user = JSON.stringify(response.data.user);
        localStorage.setItem("@USER", user);
        handleClose();
        setEmail("");
        setPassword("");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error(e.response.error);
      setEmail("");
      setPassword("");
    }
  };

  const onSuccessHandler = (response) => {
    // setIsBeginLoader(true);
    if (response.accessToken) {
      // Extract access token and expiration time from response
      const accessToken = response.accessToken;
      const expTime = response.expiresIn;
      // Calculate expiration date
      let expDate = new Date();
      expDate.setSeconds(expDate.getSeconds() + expTime);
      // Create request data object with access token and expiration date
      const reqData = {
        FBAccessToken: accessToken,
        FBExpirationDate: expDate.toUTCString(),
      };

      console.log('1111 reqData', reqData);
      // Call the SocialLoginAction function with the request data object
      // const formData = jsonToFormData(reqData);
      // dispatch(SocialLoginAction(formData))
      //   .then(() => {
      //     setIsBeginLoader(false);
      //     const isNew = socialLoginData?.Session_CreateFromFacebook?.User?.New;
      //     // Redirect to the desired page after successful login
      //     if (isNew) {
      //       history.push("/profile");
      //     } else {
      //       history.push("/");
      //     }
      //   })
      //   .catch((error) => {
      //     // Handle the error here
      //     setIsBeginLoader(false);
      //     toast.error("Login failed. Please try again.");
      //   });
    }
  };

  return (
    <Modal
      show={showSignin}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body className="mb-3">
        <div className="modal_closing">
          <i onClick={handleClose} className="fa-solid fa-xmark fa-shake"></i>
        </div>
        <div className="whole_signin_popup">
          <img
            style={{ width: '160px', height: '100px' }}
            src={require("../assets/AppLogo/UW-Logo.png")}
            alt="app-logo"
          />
          <div className="login_title_txt">Welcome To UrbanWallah</div>

          <div className=" mt-4" style={{ width: '90%' }}>
            <label className="login_label">Email</label>
            <div className="login_input">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <i className="fa-solid fa-envelope login_icons"></i>
            </div>
            <div className=" mt-3">
              <label className="login_label">Password</label>
              <div className="login_input">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={eyechange === "password" ? "password" : "text"}
                  placeholder="Password"
                />
                <i
                  className={
                    eyechange === "password"
                      ? "fa-solid fa-eye login_icons"
                      : "fa-solid fa-eye-slash login_icons"
                  }
                  onClick={() =>
                    setEyechange(eyechange === "password" ? "text" : "password")
                  }
                ></i>
              </div>
            </div>
            <button onClick={onLogin} className="common_blubtn mt-4">
              Login
            </button>
            <div className="login_opt_details my-4">
              <hr className="login_hr" />
              <p style={{ fontSize: '15px' }}>or login with</p>
            </div>
            {/* <div className="d-flex justify-content-around align-items-center w-100 my-3">
              <div className="w-50">
                <LoginSocialFacebook
                  appId="701626371815919"
                  onResolve={(response) => {
                    console.log("fb res=", response);
                  }}  
                  onReject={(error) => {
                    console.log(error);
                  }}
                >
                  <FacebookLoginButton style={{fontSize:'14px', height:'40px'}}/>
                </LoginSocialFacebook>
                <FacebookLogin
                  appId="1060192688025761"
                  appId='701626371815919'
                  onSuccess={(response) => onSuccessHandler(response)}
                  onFail={(error) => {
                    console.log("Login Failed!", error);
                  }}
                  onProfileSuccess={(response) => {
                    console.log("Get Profile Success!", response);
                  }}
                  style={{
                    padding: "8px",
                    backgroundColor: "#4267b2",
                    color: "#fff",
                    fontSize: "16px",
                    border: "none",
                    borderRadius: "2px",
                    width: "100%"
                  }}
                  initParams={{
                    xfbml: true,
                    version: "v15.0",
                  }}
                  loginOptions={{
                    auth_type: "reauthenticate",
                  }}
                >
                  <FacebookIcon />
                  <span style={{ fontSize: '13px', paddingLeft: '5px', whiteSpace: 'nowrap' }}>
                    Sign in With Facebook
                  </span>

                  <FacebookLoginButton style={{fontSize:'14px', height:'40px'}}/>
                </FacebookLogin>
              </div>
              <div className="w-50 d-flex justify-content-end">
                <GoogleOAuthProvider clientId="208119880030-qbtfoiblif77keiqk36nlmu3lsfr4ck4.apps.googleusercontent.com">
                  <Google handleClose={handleClose} />
                </GoogleOAuthProvider>
              </div>
            </div> */}

            <div className="login_bottom_txt">
              Don't have account?
              <Link
                to="/register"
                onClick={handleClose}
                className="signup_txt ms-1"
              >
                Sign Up
              </Link>
            </div>

            <div className="gray_content mt-2">
              <p className="login_bottom_txt m-0">
                Forgot your password?
                <Link
                  onClick={handleClose}
                  to="/forgotpw"
                  className="signup_txt ms-1"
                >
                  Reset it
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
