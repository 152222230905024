import { CartActionTypes } from './CartActionTypes';

const INITIAL_STATE = {
    cartData: {},
    cartProductIdList: [],
    cartLoading: false
};

export const CartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CartActionTypes.SET_CART_DATA:
            return {
                ...state,
                cartData: action.payload
            };
        case CartActionTypes.REMOVE_FROM_CART:
            return {
                ...state,
                cartData: action.payload
            };
        case CartActionTypes.STORE_CART_PRODUCT_IDS:
            return {
                ...state,
                cartProductIdList: updateCartProductBasedById(state.cartProductIdList, action.payload)
            };
        case CartActionTypes.REMOVE_CART_PRODUCT_ID:
            return {
                ...state,
                cartProductIdList: removeCartProductBasedById(state.cartProductIdList, action.payload)
            };
        case CartActionTypes.CART_LOADING:
            return {
                ...state,
                cartLoading: action.payload
            };
        default:
            return state;
    }
};

const updateCartProductBasedById = (cartProductIdList, productUuid) => {
    let newCartData = [...cartProductIdList, productUuid];
    localStorage.setItem('localCartProductList', JSON.stringify(newCartData));
    return newCartData;
}

const removeCartProductBasedById = (cartProductIdList, productUuid) => {
    let newCartData = [];
    if (cartProductIdList && cartProductIdList.length > 0) {
        newCartData = cartProductIdList.filter(productid => productid !== productUuid);
    }
    localStorage.setItem('localCartProductList', JSON.stringify(newCartData));
    return newCartData;
};