import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from 'antd';
import { getMethod, postMethod } from '../../helpers';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    // Define your custom styles here
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '2rem',
    borderRadius: '10px'
};

const AffiliatePurchaseCouponForm = () => {

    const { affiliateCode } = useParams();

    const [isLoading, setIsLoading] = useState(false)


    const modalVisible = true;
    const modalOnClose = () => {

    }

    const classes = useStyles();
    const [couponPrices, setCouponPrices] = useState([]);
    const [noOfCoupons, setNoOfCoupons] = useState(1)
    const [hoverButton, setHoverButton] = useState(false);
    const navigate = useNavigate();

    const initialCouponPriceId = useMemo(() => {
        return couponPrices && couponPrices.length > 0 && couponPrices[0]._id;
    }, [couponPrices]);

    useEffect(() => {
        getCouponPriceListApi()
    }, []);

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    useEffect(() => {
        if (!User) {
            navigate('/login', { state: { isFromPurchaseCouponForm: true, affiliateCode  } });
        }
    }, [User]);

    const getCouponPriceListApi = async () => {
        try {
            let url = "common/couponprice";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                setCouponPrices(response.data)
                
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }


    const [selectedCouponPrice, setSelectedCouponPrice] = useState(initialCouponPriceId);

    const handleCouponPriceChange = (e) => {
        setSelectedCouponPrice(e.target && e?.target?.value ? e.target.value : '');
    }

    const handleOnChange = (e) => {
        setNoOfCoupons(e?.target?.value);
    }

    const totalPrice = useMemo(() => {
        if (noOfCoupons && couponPrices && couponPrices?.length > 0 && selectedCouponPrice) {
            let filteredCouponPrice = couponPrices?.filter(item => item?._id === selectedCouponPrice)[0];
            return noOfCoupons * filteredCouponPrice?.priceValue;
        }
    },[noOfCoupons, couponPrices, selectedCouponPrice]);

    const onSubmit = async () => {
        setIsLoading(true);
        let payload = {
            noOfCoupons,
            selectedCouponPrice
        };
        try {
            let url = `customers/secure/coupon/purchase/${affiliateCode}`;
            const token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload });
            if (response.success) {
                toast.success(response.message);
                // modalOnClose();
                setIsLoading(false);
            } else {
                toast.error(response.message);
                setIsLoading(false);
            }
        } catch (e) {
            toast.error(e.response.message);
            setIsLoading(false);
        }
    }

    return (<>
        <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                className: "",
                duration: 5000,
                style: {
                    background: "#363636",
                    color: "#fff",
                },
                success: {
                    duration: 3000,
                    theme: {
                        primary: "green",
                        secondary: "#000",
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: "red",
                        secondary: "#fff",
                    },
                }
            }}
        />
        <Modal
            open={modalVisible}
            onClose={modalOnClose}
        >
            <Box sx={style}>
                <div style={{ fontSize: '20px', color: '#23c2f2', fontWeight: 700 }}>
                    Purchase Online Coupon
                </div>
                <div className='my-4'>
                    <TextField
                        label="No Of Coupons"
                        variant="outlined"
                        name='noOfCoupons'
                        fullWidth
                        className='mb-4'
                        value={noOfCoupons}
                        onChange={handleOnChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel>Select Coupon Price</InputLabel>
                        <Select
                            value={selectedCouponPrice}
                            name="selectedCouponPrice"
                            label="Select Coupon Price"
                            onChange={handleCouponPriceChange}
                            className={`${classes.customSelect} mb-4`}
                        >
                            {couponPrices?.map((item, index) => {
                                return <MenuItem key={`coupon_price_list_${index}`} value={item._id}>{item.priceName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Typography component={'div'} style={{ fontWeight: 600 }}>Total Price: {totalPrice || '-'}</Typography>
                    <button
                        className="btn py-2 mt-4"
                        disabled={isLoading}
                        style={{
                            backgroundColor: hoverButton ? '#23c2f2' : '#fff',
                            color: hoverButton ? '#fff' : '#23c2f2',
                            border: '1px solid #23c2f2',
                            borderRadius: '4px',
                            width: 200,
                            fontWeight: 600
                        }}
                        onMouseEnter={() => setHoverButton(true)}
                        onMouseLeave={() => setHoverButton(false)}
                        onClick={onSubmit}
                    >
                        {isLoading ? 'Purchasing....' : 'Purchase Coupon'}
                    </button>
                </div>
            </Box>
        </Modal>
    </>)

}

export default AffiliatePurchaseCouponForm;