import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 60000, // Set a timeout for requests (optional)
});

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const upload = async (formdata, folder = "subcategory") => {
  try {
    const token = localStorage.getItem("@token");
    if (token) setAuthToken(token);
    const uploadHeader = {
      "content-type": "multipart/form-data",
    };
    return api.post(`common/upload/s3/${folder}`, formdata, {
      headers: uploadHeader,
    });
  } catch (error) {
    // return handleTokenExpiration(error);
  }
};
//get request
export const getMethod = async (item) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + item.url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${item.token}`,
      },
    });
    const json = await response.json();
    if (json.success === false) {
      if (json.message === "Token Expired") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    return json;
  } catch (error) {
    return false;
  }
};

//post request
export const postMethod = async (item) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + item.url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${item.token}`,
      },
      body: JSON.stringify(item.payload),
    });
    const json = await response.json();
    if (json.success === false) {
      if (json.message === "Token Expired") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    return json;
  } catch (error) {
    return false;
  }
};

//put request
export const putMethod = async (item) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + item.url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${item.token}`,
      },
      body: JSON.stringify(item.params),
    });
    const json = await response.json();
    if (json.success === false) {
      if (json.message === "Token Expired") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    return json;
  } catch (error) {
    return false;
  }
};

export const deleteMethod = async (item) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + item.url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${item.token}`,
      },
      body: JSON.stringify(item.params),
    });
    const json = await response.json();
    if (json.success === false) {
      if (json.message === "Token Expired") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
    return json;
  } catch (error) {
    return false;
  }
};
