import { getMethod, putMethod } from "../../../helpers";
import { AffiliateActionTypes } from "./AffiliateActionType";
import toast from 'react-hot-toast';

export const makeGetOnlineAffiliateApi = async (dispatch) => {
    try {
        let url = 'customers/secure/poolcoupons/online';
        let token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        if (response.success) {
            dispatch({
                type: AffiliateActionTypes.GET_ONLINE_AFFILIATE,
                payload: response.data
            });
        } else {
            dispatch({
                type: AffiliateActionTypes.GET_ONLINE_AFFILIATE,
                payload: []
            });
        }
    } catch (error) {
        console.log("Error:", error);
    }
};

export const makeGetOfflineAffiliateApi = async (dispatch) => {
    try {
        let url = 'customers/secure/poolcoupons/offline';
        let token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        if (response.success) {
            dispatch({
                type: AffiliateActionTypes.GET_OFFLINE_AFFILIATE,
                payload: response.data
            });
        } else {
            dispatch({
                type: AffiliateActionTypes.GET_OFFLINE_AFFILIATE,
                payload: []
            });
        }
    } catch (error) {
        console.log("Error:", error);
    }
};

export const makeGetOffAffiliateSettlementApi = async (dispatch) => {
    try {
        let url = 'customers/secure/offlineaffiliate/settlements';
        let token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        if (response.success) {
            dispatch({
                type: AffiliateActionTypes.GET_OFFLINE_AFFILIATE_SETTLEMENT,
                payload: response.data
            });
        } else {
            dispatch({
                type: AffiliateActionTypes.GET_OFFLINE_AFFILIATE_SETTLEMENT,
                payload: []
            });
        }
    } catch (error) {
        console.log("Error:", error);
    }
};

export const makeActivateCouponsApi = async (dispatch, activationCode) => {
    try {
        let url = `customers/secure/poolcoupons/activate/${activationCode}`;
        let token = localStorage.getItem("@token");
        let response = await putMethod({ url, token });
        if (response.success) {
            toast.success(response.message);
            dispatch({
                type: AffiliateActionTypes.ACTIVATE_COUPON,
                payload: response.data
            });
            makeGetOfflineAffiliateApi(dispatch);
        makeGetOffAffiliateSettlementApi(dispatch)
        } else {
            toast.error(response.message);
            dispatch({
                type: AffiliateActionTypes.ACTIVATE_COUPON,
                payload: []
            });
        }
    } catch (error) {
        console.log("Error:", error);
    }
};

export const makeSettlement = async (dispatch, amount) => {
    try {
        let url = `customers/secure/offlineaffiliate/settlements`;
        let token = localStorage.getItem("@token");
        let response = await putMethod({ url, token, params: {
            amount
        } });
        if (response.success) {
            toast.success(response.message);
            dispatch({
                type: AffiliateActionTypes.ACTIVATE_COUPON,
                payload: response.data
            });
            makeGetOfflineAffiliateApi(dispatch);
        makeGetOffAffiliateSettlementApi(dispatch)
        } else {
            toast.error(response.message);
            dispatch({
                type: AffiliateActionTypes.ACTIVATE_COUPON,
                payload: []
            });
        }
    } catch (error) {
        console.log("Error:", error);
    }
};