import React from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LandingOfflineAffiliate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Fetch user and affiliate data from localStorage
    const json = localStorage.getItem("@USER");
    const User = JSON.parse(json);
    const affiliateData = localStorage.getItem("affiliateData");
    const affiliateObj = JSON.parse(affiliateData);

    return (
        <div className='pe-4' id="pe-4-affiliate">
            <div style={{ color: '#AEADAD', fontSize: '15px' }}>
                Your Floating Cash
            </div>
            <style>
                {` 
                        @media (max-width:600px){
                       .css-uwxp2l-MuiGrid-root{
                             max-width:100% !important;
                        }
                        .ticket_sale{
                        flex-Wrap:wrap;
                        gap:5px;
                        }
                        }

                `}
            </style>
            <div className='mt-3'>
                <div className='mb-3' style={{ display: 'flex', alignItems: 'center'}}>
                    <button
                        className='px-4 py-1'
                        style={{ backgroundColor: '#FA895B', color: '#000', border: 'none', fontSize: '14px', fontWeight: '600', borderRadius: '6px' }}
                    >
                        ₹ 1,500
                    </button>
                    <div className='ms-5' style={{ fontSize: '13px', fontWeight: 500, marginLeft: '0px !important' }}>
                        ₹ 500 Remaining
                    </div>
                </div>
                <div  className='mb-3  ticket_sale' style={{ display: 'flex', alignItems: 'center'}}>
                    <TextField
                        id="settle-amount"
                        variant="outlined"
                        placeholder='Enter an amount to settle'
                        name='settle'
                        fullWidth
                        InputProps={{
                            style: { height: '40px', fontSize: '12px', fontWeight: 600 }
                        }}
                    />
                    <button
                        className='ms-5'
                        style={{ backgroundColor: '#FA895B', color: '#000', border: 'none', fontSize: '14px', fontWeight: '600', borderRadius: '6px', minWidth: '100px', height: '38px' }}
                    >
                        Settle
                    </button>
                </div>
                <div className='mb-3' style={{ color: '#AEADAD', fontSize: '15px' }}>
                    You can also activate a ticket here
                </div>
                <div class="ticket_sale" style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        id="activate-ticket-id"
                        variant="outlined"
                        placeholder='Enter UrbanTicket ID to activate'
                        name='activate'
                        fullWidth
                        InputProps={{
                            style: { height: '40px', fontSize: '12px', fontWeight: 600 }
                        }}
                    />
                    <button
                        className='ms-5'
                        style={{ backgroundColor: '#FA895B', color: '#000', border: 'none', fontSize: '14px', fontWeight: '600', borderRadius: '6px', minWidth: '100px', height: '38px' }}
                    >
                        Activate
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LandingOfflineAffiliate;
