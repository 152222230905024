import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import SideMenuBar from '../../Components/SideMenuBar';
import NewHeader from '../../Components/NewHeader';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const OrderList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderList = useSelector(state => state.orderState.orderDataList)

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    useEffect(() => {
        if (!User) {
            navigate('/login');
        }
    }, [User]);

    const getTagStyle = (tag) => {
        let tagStyle = {};
        if (tag === 'Placed' || tag === 'Processing' || tag === 'Incart') {
            tagStyle.color = '#0c0e30';
            tagStyle.backgroundColor = '#e8e8ee';
        } else if (tag === 'Completed') {
            tagStyle.color = '#0b7724';
            tagStyle.backgroundColor = '#E7F9ED';
        } else if (tag === 'Cancelled' || tag === 'Rejected') {
            tagStyle.color = '#FF2929';
            tagStyle.backgroundColor = '#FFEAEA';
        }
        return tagStyle;
    }

    const getDateFormat = (date) => {
        const dateObject = new Date(date);
        const formattedDate = dateObject.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        return formattedDate;
    }
    const columns = [
        {
            title: 'Order #',
            dataIndex: 'orderNo',
            key: 'order',
            render: (orderNo, { orderUuid }) => {
                return (
                    <a>{orderNo ? orderNo : orderUuid}</a>
                )
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'orderStatus',
            render: (status) => {
                let tagStyle = getTagStyle(status);
                return (
                    <Tag
                        color={tagStyle?.color}
                        key={status}
                        className='px-3'
                        style={{
                            borderRadius: '12px',
                            height: '24px',
                            fontSize: '12px',
                            backgroundColor: tagStyle?.backgroundColor,
                            color: tagStyle?.color
                        }}
                    >
                        {status}
                    </Tag>
                )
            }
        },
        {
            title: 'Date purchased',
            dataIndex: 'createdAt',
            key: 'date',
            render: (_, { createdAt }) => {
                const formattedDate = getDateFormat(createdAt);
                return (
                    <div>{formattedDate}</div>
                )
            }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (total) => {
                return (
                    <div>
                        ₹{total?.toFixed(2)}
                    </div>
                )
            }
        },
        {
            title: '',
            dataIndex: 'icon',
            key: 'icon',
            render: () => {
                return (
                    <ArrowForwardOutlinedIcon style={{ color: '#7D879C' }} />
                )
            }
        },
    ];

    const data = [
        {
            key: '1',
            order: 'f0ba538b',
            status: ['Pending'],
            date: 'Nov 10, 2022',
            total: '₹350.00'
        },
        {
            key: '2',
            order: '1f10985b',
            status: ['Processing'],
            date: 'Nov 10, 2022',
            total: '₹500.00'
        },
        {
            key: '3',
            order: '6d54d506',
            status: ['Delivered'],
            date: 'Dec 22, 2020',
            total: '₹700.00'
        },
        {
            key: '4',
            order: '63d35462',
            status: ['Delivered'],
            date: 'Nov 20, 2020',
            total: '₹700.00'
        },
        {
            key: '5',
            order: '753deee0',
            status: ['Cancelled'],
            date: 'Dec 14, 2020',
            total: '₹300.00'
        }
    ];

    // const customPagination = {
    // showSizeChanger: true, // Allow the user to change the page size
    // pageSizeOptions: ['5', '10', '20'], // Available page sizes
    // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, // Display total items text
    // You can add more pagination settings here if needed
    // };

    return (
        <SideMenuBar>
            <NewHeader title={'My Orders'} icon={<ShoppingBag style={{ width: '1.2em', height: '1.2em', color: '#23c2f2' }} />} />
            <div className='mt-2' style={{overflow:"scroll"}}>
                <Table
                    columns={columns}
                    dataSource={orderList}
                    className="custom-table"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                navigate(`/orders/${record._id}`)
                            },
                        };
                    }}
                />
            </div>
        </SideMenuBar>
    )
}

export default OrderList;