import React, { useEffect, useState } from "react";
import CircleTimer from "./PoolTimer";
import { useParams } from "react-router-dom";
import { getMethod } from "../../helpers";
import moment from 'moment';
import JoinPoolForm from "../Coupons/JoinPoolForm";

const PoolsDetails = () => {
    const [hoverButton, setHoverButton] = useState(false);
    const [poolData, setPoolData] = useState({});
    const { id } = useParams();
    const [endDate, setEndDate] = useState(moment().unix())
    const [showTimer, setShowTimer] = useState(false);

    const [showJoinPool, setShowJoinPool] = useState(false)

    useEffect(() => {
        makePoolSelectedApi()
    }, []);

    const makePoolSelectedApi = async () => {
        try {
            let url = `common/pool/${id}`;
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                setPoolData(response.data)
                setEndDate(moment(response.data.endDate).toDate() / 1000);
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleTimer = (data) => {
        setShowTimer(data);
    }

    return (
        <div style={{ backgroundColor: '#F6F9FC' }}>
            <div
                style={{
                    marginTop: '1rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '85%',
                    border: '1px solid #fff',
                    borderRadius: '5px',
                    boxShadow: '0px 1px 3px rgba(3, 0, 71, 0.09)',
                    borderRadius: '8px',
                    backgroundColor: '#fff'
                }}
            >
                <div
                    style={{
                        backgroundImage: `url('${poolData?.poolImage}')`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '220px',
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        display: 'flex',
                        alignItems: 'flex-end'
                    }}
                />
                <div className="d-flex justify-content-between align-items-center" style={{ padding: '2rem 2rem' }}>
                    <div>
                        <div style={{ fontSize: '20px', color: '#000', fontWeight: '700' }} className="pb-2">
                            {poolData?.poolName}
                        </div>
                        <div style={{ fontSize: '16px', color: 'grey', fontWeight: '600' }}>
                            Total Slots: {poolData?.noOfUsersCanJoin}
                        </div>
                        <div style={{ fontSize: '16px', color: 'grey', fontWeight: '600', marginTop: '10px' }}>
                            Slots Available: {poolData?.noOfUsersCanJoin - poolData?.joinedUsers?.length}
                        </div>
                    </div>
                    {showTimer ? <CircleTimer endTime={endDate} /> : null}
                    <div>
                        <button
                            className="btn"
                            style={{
                                backgroundColor: hoverButton ? '#23c2f2' : '#fff',
                                color: hoverButton ? '#fff' : '#23c2f2',
                                border: '1px solid #23c2f2',
                                borderRadius: '4px',
                                width: 150,
                                fontWeight: 600
                            }}
                            onMouseEnter={() => setHoverButton(true)}
                            onMouseLeave={() => setHoverButton(false)}
                            onClick={() => {
                                setShowJoinPool(true)
                            }}
                        >
                            Join Pool
                        </button>
                    </div>
                </div>
            </div>
            <JoinPoolForm modalVisible={showJoinPool} modalOnClose={() => {
                setShowJoinPool(false);
            }} poolId={id} handleTimer={handleTimer} />
        </div>
    )
};

export default PoolsDetails;