import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getMethod, putMethod, upload } from '../helpers';
import CommonTextField from '../Components/CommonTextField';
import SideMenuBar from '../Components/SideMenuBar';
import toast from 'react-hot-toast';

const EditPostForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(location.state?.data || {});
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (!location.state?.data) {
            fetchPostData();
        }
    }, [id, location.state]);

    const fetchPostData = async () => {
        try {
            const url = `/customers/secure/posts/${id}`;
            const token = localStorage.getItem("@token");
            const response = await getMethod({ url, token });
            if (response.success) {
                setFormData(response.data);
                setFiles(response.data.image || []);
            }
        } catch (error) {
            console.error('Error fetching post data:', error);
            toast.error('Failed to fetch post data');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = async (event) => {
        // ... existing file upload logic ...
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = `/customers/secure/posts/update/${id}`;
            const token = localStorage.getItem("@token");
            const payload = {
                ...formData,
                image: files,
                // ... other fields as needed ...
            };
            const response = await putMethod({ url, token, payload });
            if (response.success) {
                toast.success('Post updated successfully');
                navigate('/my-posts');
            } else {
                toast.error(response.message || 'Failed to update post');
            }
        } catch (error) {
            console.error('Error updating post:', error);
            toast.error('Failed to update post');
        }
    };

    return (
        <SideMenuBar>
            <form onSubmit={handleSubmit} style={formStyle}>
                <h2>Edit Post</h2>
                <div style={cardStyle}>
                    <CommonTextField
                        id='postTitle'
                        labelName='Post Title'
                        name='postTitle'
                        value={formData.postTitle || ''}
                        handleChange={handleChange}
                    />
                    <CommonTextField
                        id='postDescription'
                        labelName='Post Description'
                        name='postDescription'
                        value={formData.postDescription || ''}
                        handleChange={handleChange}
                        multiline={true}
                    />
                    <CommonTextField
                        id='price'
                        labelName='Price'
                        name='price'
                        value={formData.price || ''}
                        handleChange={handleChange}
                    />
                    <CommonTextField
                        id='discountedPrice'
                        labelName='Discounted Price'
                        name='discountedPrice'
                        value={formData.discountedPrice || ''}
                        handleChange={handleChange}
                    />
                    {/* Add more fields as needed */}
                    
                    {/* File upload section */}
                    <div>
                        <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            accept="image/*,video/*"
                        />
                        {/* Display existing and newly uploaded files */}
                    </div>
                </div>
                <div style={buttonContainerStyle}>
                    <button type="submit" style={submitButtonStyle}>
                        Update Post
                    </button>
                </div>
            </form>
        </SideMenuBar>
    );
};

const formStyle = {
    width: '80%',
    margin: 'auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif'
};

const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff'
};

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
};

const submitButtonStyle = {
    padding: '10px 20px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffce00'
};

export default EditPostForm;