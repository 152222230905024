import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { postMethod } from '../helpers';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import NewHeader from '../Components/NewHeaderView';
import NewFooter from '../Components/NewFooter';
import StepperComponent from '../Components/StepperComponent';
import PhoneInput from 'react-phone-input-2';
import CommonTextField from '../Components/CommonTextField';

const useStyles = makeStyles((theme) => ({
    datePickerOverride: {
        width: '100%',
        '& .MuiInputBase-root': {
            height: '40px',
            padding: '0 14px',
            borderRadius: '10px'
        },
        '& .MuiInputLabel-root': {
            top: '0px',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px 14px'
        },
    },
    root: {
        marginBottom: '20px'
    },
    selectField: {
        height: '45px',
        '& .MuiSelect-select': {
            padding: '8px 14px',
            borderRadius: '10px',
        },
        '& .MuiInputBase-root': {
            height: '45px',
            borderRadius: '10px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
        },
    },
}));

const FormComponent = ({ }) => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const classes = useStyles();

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);

    const steps = ['Login Info', 'Address Details', 'Bank Details'];

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        dob: dayjs(''),
        gender: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        district: '',
        pincode: '',
        accountNumber: '',
        accountName: '',
        ifsc: '',
        branch: ''
    });

    useEffect(() => {
        if (User) {
            const { firstName, lastName, email, phoneNumber } = User;
            setFormData({
                ...formData,
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phoneNumber
            })
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateOnChange = (date) => {
        setFormData({
            ...formData,
            dob: dayjs(date.$d).format('YYYY-MM-DD')
        });
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async () => {
        try {
            let url = "customers/secure/affliate/signup";
            let token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload: formData });
            if (response.success) {
                localStorage.setItem("affiliateData", JSON.stringify(response.data));
                navigate('/affiliate', { state: { affiliateType: 'Online' } });
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleNext = () => {
        if (activeStep === steps?.length - 1) {
            handleSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handlePhoneChange = (phone) => {
        setFormData({
            ...formData,
            phone_number: phone
        })
    }

    return (
        <div>
            <NewHeader screenName={'Login'}>
                <Grid container className='my-5'>
                    <Grid item xs={1.5} />
                    <Grid item xs={10}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <div style={{ fontSize: '18px', color: '#5C5B5B' }}>
                                Complete Your UrbanWallah Affiliate Registration
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
                <StepperComponent activeStep={activeStep} stepsData={steps}>
                    <Box component="form">
                        {activeStep === 0 && (
                            <div>
                                <Stack style={{ marginBottom: '1rem' }} spacing={2} direction={'row'} alignItems={'center'}>
                                    <CommonTextField
                                        id='first-name'
                                        labelName='First Name'
                                        name='first_name'
                                        value={formData.first_name}
                                        handleChange={handleChange}
                                        disabled={formData?.first_name}
                                    />
                                    <CommonTextField
                                        id='last-name'
                                        labelName='Last Name'
                                        name='last_name'
                                        value={formData.last_name}
                                        handleChange={handleChange}
                                        disabled={formData?.last_name}
                                    />
                                </Stack>
                                <CommonTextField
                                    id='email'
                                    labelName='Email'
                                    name='email'
                                    value={formData.email}
                                    handleChange={handleChange}
                                    disabled={formData?.email}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                                    <InputLabel
                                        htmlFor="phone-input"
                                        style={{
                                            fontSize: '15px',
                                            position: 'relative',
                                            transform: 'none',
                                            color: '#000'
                                        }}
                                    >
                                        Phone Number
                                    </InputLabel>
                                    <PhoneInput
                                        country={'in'}
                                        preferredCountries={['in']}
                                        value={`91${formData?.phone_number}`}
                                        onChange={handlePhoneChange}
                                        disabled={`91${formData?.phone_number}`}
                                        placeholder="Enter phone number"
                                        inputProps={{
                                            id: 'phone-input',
                                            style: {
                                                height: '40px',
                                                padding: '0 3rem',
                                                borderRadius: '10px',
                                                width: '100%',
                                            },
                                        }}
                                        containerStyle={{ marginTop: '10px' }} // Adjust the margin to position below the label
                                    />
                                </FormControl>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                    <InputLabel
                                        htmlFor="dob"
                                        style={{
                                            fontSize: '15px',
                                            position: 'relative',
                                            transform: 'none',
                                            color: '#000'
                                        }}
                                    >
                                        Date Of Birth
                                    </InputLabel>
                                    <DatePicker
                                        value={formData?.dob}
                                        onChange={handleDateOnChange}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                name="dob"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                        className={clsx('mb-3 mt-2', classes.datePickerOverride)}
                                    />
                                </LocalizationProvider>
                                <FormControl variant="outlined" fullWidth>
                                    <Typography variant="body1" className={classes.selectLabel}>
                                        Gender
                                    </Typography>
                                    <Select
                                        id="demo-simple-select-helper"
                                        value={formData?.gender}
                                        name="gender"
                                        onChange={handleChange}
                                        className={`${classes.selectField} mt-2`}
                                    >
                                        <MenuItem value={"Male"}>Male</MenuItem>
                                        <MenuItem value={"Female"}>Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {activeStep === 1 && (
                            <div>
                                <CommonTextField
                                    id='addressLine1'
                                    labelName='Address Line 1'
                                    name='addressLine1'
                                    value={formData.addressLine1}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='addressLine2'
                                    labelName='Address Line 2'
                                    name='addressLine2'
                                    value={formData.addressLine2}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                    className='my-2'
                                />
                                <Stack className='my-2' spacing={2} direction={'row'} alignItems={'center'}>
                                    <CommonTextField
                                        id='city'
                                        labelName='City'
                                        name='city'
                                        value={formData.city}
                                        handleChange={handleChange}
                                    />
                                    <CommonTextField
                                        id='district'
                                        labelName='District'
                                        name='district'
                                        value={formData.district}
                                        handleChange={handleChange}
                                    />
                                </Stack>
                                <Stack className='my-2' spacing={2} direction={'row'} alignItems={'center'}>
                                    <CommonTextField
                                        id='state'
                                        labelName='State'
                                        name='state'
                                        value={formData.state}
                                        handleChange={handleChange}
                                    />
                                    <CommonTextField
                                        id='pincode'
                                        labelName='Pincode'
                                        name='pincode'
                                        value={formData.pincode}
                                        handleChange={handleChange}
                                    />
                                </Stack>
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div>
                                <CommonTextField
                                    id='accountNumber'
                                    labelName='Account Number'
                                    name='accountNumber'
                                    value={formData.accountNumber}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='accountName'
                                    labelName='Account Name'
                                    name='accountName'
                                    value={formData.accountName}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='ifsc'
                                    labelName='IFSC'
                                    name='ifsc'
                                    value={formData.ifsc}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                                <CommonTextField
                                    id='branch'
                                    labelName='Branch'
                                    name='branch'
                                    value={formData.branch}
                                    handleChange={handleChange}
                                    style={{ marginBottom: '1rem' }}
                                />
                            </div>
                        )}
                    </Box>
                    {activeStep === steps?.length - 1
                        ? <div className='mt-1 d-flex align-items-center justify-content-center'>
                            <Checkbox
                                sx={{
                                    transform: 'scale(1.2)',
                                    '& .MuiSvgIcon-root': { fontSize: 18 },
                                    '&.Mui-checked': {
                                        color: '#2DBDEE',
                                    },
                                    '&.MuiCheckbox-root': {
                                        color: '#2DBDEE',
                                    },
                                }}
                            />
                            <div style={{ fontSize: '14px' }}>Accept Terms and Conditions & Privacy policy</div>
                        </div>
                        : null
                    }
                    <Stack
                        className={activeStep === steps?.length - 1 ? 'mt-3' : 'mt-4'}
                        direction={'row'}
                        spacing={activeStep !== 0 ? 3 : 0}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {activeStep !== 0
                            ? <Button
                                variant="contained"
                                style={{ backgroundColor: '#2DBDEE', width: '150px' }}
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Back
                            </Button>
                            : null
                        }
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#2DBDEE', width: '150px' }}
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={activeStep === steps?.length}
                        >
                            {activeStep === steps?.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Stack>
                </StepperComponent>
            </NewHeader>
            <div style={{ height: '50vh' }} />
            <NewFooter screenName={'Login'} />
        </div>
    )
}

export default FormComponent;