import { Grid, Stack } from "@mui/material";
import React from "react";

const UrbanTicketScreen = () => {
    // Button at the top nearby the main banner
    const renderButtonItem = (backgroundColor, logo, text) => {
        return (
            <div>
                <div
                    style={{
                        backgroundColor,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap:'wrap',
                        height: '100px',
                        borderRadius: "10px",
                    }}
                >
                    <img
                        src={require(`../assets/icons/${logo}.png`)}
                        alt={logo}
                        style={{
                            width: "30px",
                        }}
                    />
                    <div style={{ marginLeft: "10px", fontSize: '22px', fontWeight: 700 }}>{text}</div>
                </div>
            </div>
        );
    };

    // Steps UI (Need to update this with ANTD or MUI steps)
    const renderStepItem = (number, infoText, isLastItem = false) => {
        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1.5rem 1rem",
                    }}
                >
                    <div
                        style={{
                            color: "#fff",
                            backgroundColor: "#312863",
                            borderRadius: "50%",
                            padding: "5px",
                            width: "30px",
                            height: "30px",
                            textAlign: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {number}
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: 400, textAlign: 'center' }} className="mt-3">{infoText}</div>
                </div>
            </div>
        );
    };

    // List UI above the FAQ section
    const renderListContent = (image, text, placeHolder) => {
        return (
            <div
                style={{
                    padding: "20px 20px",
                    width: "250px",
                    backgroundColor: '#F5F5F5',
                    borderRadius: "20px",
                    height: '250px'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: image === 'list_img_1' ? '90px' : '110px', height: image === 'list_img_1' ? '80px' : '110px' }}>
                        <img src={require(`../assets/images/${image}.png`)} style={{ width: '100%', height: image === 'list_img_1' ? '80px' : '110px', objectFit: 'contain' }} />
                    </div>
                    <div style={{ fontWeight: 600, margin: "20px 0px 10px 0px", fontSize: '16px' }}>
                        {text}
                    </div>
                </div>
                <input
                    placeholder={placeHolder}
                    style={{ borderRadius: "10px", width: "100%", padding: '5px 20px', fontSize: '12px', color: '#979797', textAlign: 'center', border: '1px solid #000' }}
                />
            </div>
        );
    };

    return (
        <div className="container py-5">
            <Grid container spacing={10}>
                <Grid item xs={0.5} />
                <Grid item xs={8}>
                    <img
                        src={require('../assets/banner/UrbanTicketBanner.png')}
                        alt="Main-ticket"
                        style={{
                            width: "100%",
                            height: "230px",
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Stack direction={'column'} justifyContent={'space-between'} spacing={3}>
                        {renderButtonItem("#F5DE54", "ut-logo", "Buy Ticket")}
                        {renderButtonItem("#F69776", "ut-logo-1", "Join Pools")}
                    </Stack>
                </Grid>
                <Grid item xs={0.5} />
            </Grid>
            <div>
                <div style={{ fontSize: '24px', fontWeight: 600, marginBottom: "1rem" }}>What is Urban Ticket ?</div>
                <div
                    style={{
                        border: "1px solid skyblue",
                        borderRadius: "20px",
                        padding: "20px 30px",
                        color: '#908C8C',
                        fontSize: '14px'
                    }}
                >
                    Our platform serves as a trusted intermediary, connecting urban consumers with local artisans,
                    craftsmen, and producers, thus revitalizing the urban commerce landscape.Our platform serves as a trusted intermediary,
                    connecting urban consumers with local artisans, craftsmen, and producers, thus revitalizing the urban commerce
                    landscape. Our platform serves as a trusted intermediary, connecting urban consumers with local artisans,
                    craftsmen, and producers, thus revitalizing the urban commerce landscape.Our platform serves as a trusted intermediary,
                    connecting urban consumers with local artisans, craftsmen, and producers.
                </div>
            </div>
            <style>
                
            {`
                    @media (max-width: 768px) {
                        #aduit-trail-text {
                            flex-direction: column;                           
                        }   
                    } 
                    .css-11reh94-MuiGrid-root>.MuiGrid-item {
                        padding-left: 20px!important;
                    } 
                `}
            </style>
            <aside id="info-block" className="mt-5">
                <section class="file-marker" >
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}} >
                        <div class="box-title" id="how-it-work" style={{marginLeft:'0px',marginTop:'-7px'}}>HOW IT WORKS?</div>
                        <div class="box-contents">
                            <div id="audit-trail">
                                <div  id="aduit-trail-text"   
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    {renderStepItem("1", 'BUY "URBAN TICKETS" FROM THE COUNTER.')}
                                    {renderStepItem("2", 'SCAN QR & DOWNLOAD APP.')}
                                    {renderStepItem("3", 'USE "COUPON CODE" TO REGISTER.')}
                                    {renderStepItem("4", 'CHOOSE YOUR PRIZE & ENROLL IN THE CONTEST.')}
                                    {renderStepItem("5", 'WIN A PRIZE OR GET YOUR MONEY BACK IN TO THE WALLET.', true)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </aside>
            {/* section 4 */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 10px",
                    paddingTop: "12px",
                    marginBottom: "30px",
                    flexWrap:'wrap',
                    gap:'20px'
                }}
            >
                {renderListContent(
                    "list_img_3",
                    "Validate Ticket",
                    "Enter ticket number to validate"
                )}
                {renderListContent(
                    "list_img_2",
                    "Raise Query",
                    "Click here to drop us a mail"
                )}
                {renderListContent(
                    "list_img_3",
                    "Suggestion Box",
                    "Share your thought to us"
                )}
            </div>
            {/* section 5 */}
            <div>
                <div style={{ fontWeight: 400, fontSize: '20px' }}>Frequently Asked Questions</div>
                {/* <div style={{ padding: "30px" }}>
                    <div>
                        <textarea style={{ borderRadius: "20px", width: "100%" }} />
                    </div>
                    <textarea style={{ borderRadius: "20px", width: "100%" }} rows={4} />
                </div> */}
            </div>
        </div>
    );
};

export default UrbanTicketScreen;