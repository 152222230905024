import React, { useState, useEffect, Fragment, useRef } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Footer from "../../Components/Footer";
import { getMethod } from "../../helpers";
import HubListView from "../Hub/HubListView";
import { useDispatch, useSelector } from "react-redux";
import HomeList from "./HomeList";
import OnlineAffiliateList from "../Affiliate/OnlineAffiliateList";
import OfflineAffiliateList from "../Affiliate/OfflineAffiliateList";
import NewHomeList from "./NewHomeList";
import NewFooter from "../../Components/NewFooter";
import NewHeader from "../../Components/NewHeaderView";
import ContainerWrapper from "../../Components/HeaderWrapper";
import MenuIcon from '@mui/icons-material/Menu';
import { Badge, IconButton, InputBase, Menu, MenuItem, Paper, Popover, TextField } from "@mui/material";
import CartIcon from '../../assets/icons/cart-bag-border.svg';
import UBticketIcon from '../../assets/icons/UB_ticket.svg';
import CategoryIcon from '../../assets/icons/Categories-large.svg';
import SearchComponent from "../../Components/SearchComponent";
import SearchDropdownComponent from "../../Components/SearchDropDown";
import { makeGetAllListApi } from "../Products/redux/ProductAction";

function Home({ screenName }) {
  const [CategoriesList, setCategoriesList] = useState([]);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [LatestProducts, setLatestProducts] = useState([]);
  const [FeaturedProducts, setFeaturedProducts] = useState([]);
  const [mostViewedProducts, setMostViewedProducts] = useState([])
  const [discountData, setDiscountData] = useState([]);
  const [checked, setChecked] = useState(true);
  const [showSignin, setShowSignin] = useState(false);
  const [searchDataList, setSearchDataList] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const searchRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchData) {
      const fetchSearchResult = async () => {
        try {
          let url = `products/list_products?search=${searchData}`;
          let token = localStorage.getItem("@token");
          let response = await getMethod({ url, token });
          if (response.success) {
            if (response.data.length > 0) {
              setSearchDataList(response.data);
              setDropdownVisible(true);
            } else {
              setSearchDataList([]);
              setDropdownVisible(false);
            }
          } else {
            setSearchDataList([])
          }
        } catch (error) {
          console.log("Error:", error);
        }
      }
      const timeoutId = setTimeout(fetchSearchResult, 100);
      return () => clearTimeout(timeoutId);
    } else {
      setSearchDataList([]);
      setDropdownVisible(false);
    }
  }, [searchData])


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  const loginData = useSelector(state => state.authState.loginData);
  const screenType = useSelector(state => state.dashboardState.screenType);

  const cartData = useSelector(state => state.cartState.cartData);

  let json = localStorage.getItem("@USER");
  let User = JSON.parse(json);

  useEffect(() => {
    makeDiscountsApi();
    makeGetAllListApi(dispatch);
  }, []);

  const makeDiscountsApi = async () => {
    try {
      let url = "common/discounts";
      const token = localStorage.getItem("@token");
      let response = await getMethod({ url, token });
      if (response.success) {
        setDiscountData(response?.data);
      }
    } catch (e) {
      console.log('e', e);
    }
  }


  const handleMenuOnChange = (event) => {
    setSearchData(event.target.value)
  }

  const getCategoriesList = async () => {
    try {
      let url = "common/categories";
      const token = localStorage.getItem("@token");
      let response = await getMethod({ url, token });
      if (response.success) {
        setCategoriesList(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSubCategoryList = async () => {
    try {
      let url = "subcategories/get_subcategories";
      const token = localStorage.getItem("@token");
      let response = await getMethod({ url, token });
      if (response.success) {
        setSubCategoryList(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getProductList = async () => {
    try {
      let url = "products/list_products";
      let response = await getMethod({ url });
      if (response.success) {
        setProductList(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };
  //----
  const getLatestProducts = async () => {
    try {
      let url = "products/latest";
      let response = await getMethod({ url });
      if (response.success) {
        setLatestProducts(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getFeaturedProducts = async () => {
    try {
      let url = "products/featured";
      let response = await getMethod({ url });
      if (response.success) {
        setFeaturedProducts(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getMostViewedProducts = async () => {
    try {
      let url = "products/mostViewed";
      let response = await getMethod({ url });
      if (response.success) {
        setMostViewedProducts(response.data);
      } else {
        console.log("err");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCategoriesList();
    getSubCategoryList();
    getProductList();
    getLatestProducts();
    getFeaturedProducts();
    getMostViewedProducts();
  }, []);

  const naviagteToMyPost = () => {
    navigate('/my-post')
  }

  const handleShow = () => setShowSignin(true);

  const handleClose = () => setShowSignin(false);

  const handleCartClick = () => {
    if (!User) {
      handleShow();
    } else {
      navigate("/cart");
    }
  }

  const getBoxBasedOnScreenName = (boxData) => {
    let filteredArray = [];
    if (screenName === '/') {
      filteredArray = boxData.filter(item => item.title !== 'E-commerce');
    } else if (screenName === 'farmer') {
      filteredArray = boxData.filter(item => item.title !== 'AgriSure');
    } else if (screenName === 'student') {
      filteredArray = boxData.filter(item => item.title !== 'Brain Box');
    } else if (screenName === 'market-place') {
      filteredArray = boxData.filter(item => item.title !== 'XBO');
    } else {
      filteredArray = boxData.filter(item => item.title !== 'Affiliate');
    }
    return filteredArray
  }

  const renderBoxView = () => {
    const boxData = [
      {
        id: '1',
        title: 'Commerce',
        icon: 'logoB.svg',
        color: '#2FBEF0',
        onClick: () => navigate('/')
      },
      {
        id: '2',
        title: 'Brain Box',
        color: '#FAE77D',
        icon: 'logoY.svg',
        onClick: () => navigate('/hub/student')
      },
      {
        id: '3',
        title: 'Agri Sure',
        color: '#20E492',
        icon: 'logoG.svg',
        onClick: () => navigate('/hub/farmer')
      },
      {
        id: '4',
        title: 'XBO',
        color: '#AA68FF',
        icon: 'logoV.svg',
        onClick: () => navigate('/hub/market-place')
      },
      {
        id: '5',
        title: 'Affiliate',
        color: '#FA8A5C',
        icon: 'logoO.svg',
        onClick: () => navigate('/affiliate', { state: { affiliateType: 'Online' } })
      }
    ];

    // let filteredArray = getBoxBasedOnScreenName(boxData);

    return boxData.map(data => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
            fontSize: '16px',
            fontWeight: '600',
            cursor: 'pointer',
            transition: 'transform 0.2s ease'
          }}
          onMouseEnter={() => {
            const container = document.getElementById(data.id);
            const text = document.getElementById(`${data.id}-text`);
            if (container && text) {
              container.style.transform = 'scale(1.1)';
              text.style.transform = 'scale(1.1)';
            }
          }}
          onMouseLeave={() => {
            const container = document.getElementById(data.id);
            const text = document.getElementById(`${data.id}-text`);
            if (container && text) {
              container.style.transform = 'scale(1)';
              text.style.transform = 'scale(1)';
            }
          }}
          onClick={data.onClick}
        >
          <img
            id={data.id}
            src={require(`../../assets/AppLogo/${data.icon}`)}
            style={{ width: '42px', height: '42px' }}
          />
          <div
            className="ms-3"
            id={`${data.id}-text`}
          >
            {data.title}
          </div>
        </div>
      )
    })
  }

  const renderView = () => {
    switch (screenType) {
      case 'Home':
        return (
          <NewHomeList
            discountData={discountData}
            CategoriesList={CategoriesList}
            SubCategoryList={SubCategoryList}
            ProductList={ProductList}
            LatestProducts={LatestProducts}
            FeaturedProducts={FeaturedProducts}
            MostViewedProducts={mostViewedProducts}
          />
        )
      case 'Hub':
        return (
          <HubListView />
        )
      case 'On Affiliate':
        return (
          <OnlineAffiliateList />
        )
      case 'Off Affiliate':
        return (
          <OfflineAffiliateList />
        )
    }
  };

  return (
    <div className="py-5">
      <NewHomeList
        discountData={discountData}
        CategoriesList={CategoriesList}
        SubCategoryList={SubCategoryList}
        ProductList={ProductList}
        LatestProducts={LatestProducts}
        FeaturedProducts={FeaturedProducts}
        MostViewedProducts={mostViewedProducts}
      />
    </div>
  );
}

export default Home;
