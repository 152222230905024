import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import QRCode from 'qrcode';
import PersonIcon from '@mui/icons-material/Person';
import SideMenuBar from '../../Components/SideMenuBar';
import NewHeader from '../../Components/NewHeader';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAPIAction } from './redux/ProfileAction';
import { getMethod } from '../../helpers';
import { Avatar, Button, Card, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';

const ProfileView = () => {
    const [qr, setQR] = useState("");
    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();
    const [userPosts, setUserPosts] = useState([]);
    const dispatch = useDispatch();
    const profileData = useSelector(state => state.profileState.profileData);
   const orderList = useSelector(state => state.orderState.orderDataList);
   const [isLoading, setIsLoading] = useState(true);
   const [error, setError] = useState(null);

    const json = localStorage.getItem("@USER");
    console.log('json',json)
    const User = JSON.parse(json);

    useEffect(() => {
        if (!User) {
            navigate('/login');
        }
        if (copied) {
            toast.success('Copied to clipboard!');
        }
    }, [User, copied]);

    const getTagStyle = (tag) => {
        let tagStyle = {};
        if (tag === 'Placed' || tag === 'Processing' || tag === 'Incart') {
            tagStyle.color = '#0c0e30';
            tagStyle.backgroundColor = '#e8e8ee';
        } else if (tag === 'Completed') {
            tagStyle.color = '#0b7724';
            tagStyle.backgroundColor = '#E7F9ED';
        } else if (tag === 'Cancelled' || tag === 'Rejected') {
            tagStyle.color = '#FF2929';
            tagStyle.backgroundColor = '#FFEAEA';
        }
        return tagStyle;
    }

    

    const GenerateQRCode = (url) => {
        QRCode.toDataURL(url, {
            width: 150, margin: 2, color: {
                dark: "#335383FF",
                light: "#eeeeeeff"
            }
        }, (err, url) => {
            if (err) console.log(err);
            setQR(url);
        });
    }

    const handleCopy = () => {
        const couponLink = profileData?.customer?.affiliate?.couponLink;
        if (couponLink) {
            navigator.clipboard.writeText(couponLink);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };

    const getDateFormat = (date) => {
        const dateObject = new Date(date);
        return dateObject.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, { firstName, lastName }) => (
                <a>{`${firstName} ${lastName}`}</a>
            ),
        },
        {
            title: 'Affiliate Link',
            dataIndex: 'affiliate',
            key: 'affiliate',
            render: (_, { affiliate }) => (
                <a onClick={() => navigate("/coupon/purchase/" + affiliate?.affiliateCode)}>Coupon Link</a>
            ),
        },
        {
            title: 'QR Code',
            dataIndex: 'affiliate',
            key: 'affiliate',
            render: (_, { affiliate }) => (
                <a href={qr} download={"qr.png"}><img src={qr} alt="QR Code" /></a>
            ),
        },
    ];

    const [data, setData] = useState([]);

    useEffect(() => {
        if (profileData?.customer?.firstName) {
            setData([profileData?.customer]);
            if (profileData?.customer?.affiliate?.couponLink) {
                GenerateQRCode(profileData?.customer?.affiliate?.couponLink);
            }
        }
    }, [profileData]);

    useEffect(() => {
        makeGetProfileAPI();
    }, []);

    const makeGetProfileAPI = async () => {
        try {
            setIsLoading(true);
            let url = "/customers/secure/profile";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
            dispatch(getProfileAPIAction(response.data));
        } else {
            setError('Failed to fetch profile data');
        }
    } catch (e) {
        console.error(e);
        setError('An error occurred while fetching profile data');
    } finally {
        setIsLoading(false);
    }
};

    const getFirstLetterFromUserName = (name = 'U') => name?.charAt(0).toLocaleUpperCase();

    const capitalizeName = (fullName) => {
        const parts = fullName?.split(' ');
        return parts?.map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1)?.toLowerCase()).join(' ');
    }

    const renderProfileData = () => {
        let data = [
            { label: 'First Name', value: profileData?.customer?.firstName },
            { label: 'Last Name', value: profileData?.customer?.lastName },
            { label: 'Email', value: profileData?.customer?.email },
            { label: 'Phone', value: profileData?.customer?.phoneNumber },
            { label: 'Birth Date', value: getDateFormat(profileData?.user?.dob) }
        ];
        return (
            <div className="profile-data">
                {data?.map(item => (
                    <div key={item?.label} className="profile-item">
                        <div className="profile-label">{item?.label}</div>
                        <div className="profile-value">{item?.value}</div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <SideMenuBar>
            <NewHeader title={'Profile'} screenName={'Profile'} isShowButton={true} icon={<PersonIcon style={{ width: '1.2em', height: '1.2em', color: '#23c2f2' }} />} />
            <div className='mt-4'>
                <div className='d-flex profile-cards'>
                    <Card className="profile-card">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center' id="user_avatar_name">
                                <Avatar style={{ backgroundColor: '#23C2F2', width: '64px', height: '64px' }}>
                                    {profileData?.customer?.image
                                        ? <img src={profileData?.customer?.image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='profile_img' />
                                        : <Typography component="div" style={{ fontSize: '25px', color: '#4b566b', fontWeight: '700' }}>
                                            {getFirstLetterFromUserName(profileData?.customer?.firstName)}
                                        </Typography>
                                    }
                                </Avatar>
                                <div className='ms-3 profile-info'>
                                    <div className="profile-name">{capitalizeName(profileData?.customer?.userName)}</div>
                                    <div className="profile-ticket">Urban Wallah Ticket: ₹0.00</div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="profile-card">
                        <Typography component="div" className="profile-card-count">
                            {orderList?.length > 0 ? orderList.length : 0}
                        </Typography>
                        <div className="profile-card-label">All Orders</div>
                    </Card>
                    <Card className="profile-card">
                        <Typography component="div" className="profile-card-count">
                            0
                        </Typography>
                        <div className="profile-card-label">Pending Orders</div>
                    </Card>
                </div>
                <div className='mt-4'>
                    <Card className="profile-data-card">
                        {renderProfileData()}
                    </Card>
                </div>
            </div>
            <style jsx>{`
                .profile-cards {
                    justify-content: space-around;
                    flex-wrap: wrap;
                    gap: 10px;
                }
                .profile-card {
                    padding: 1rem 1.5rem;
                    width: max-content;
                    min-width: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .profile-data-card {
                    padding: 1.5rem 2rem;
                }
                .profile-data {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
                .profile-item {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 10px;
                }
                .profile-label {
                    color: #7D879C;
                }
                .profile-value {
                    color: #2B3445;
                    font-size: 14px;
                }
                .profile-info {
                    font-size: 16px;
                }
                .profile-name {
                    font-weight: 600;
                    color: #2B3445;
                }
                .profile-ticket {
                    color: #7D879C;
                    font-size: 14px;
                }
                .profile-card-count {
                    font-weight: 600;
                    font-size: 22px;
                    color: #4B566B;
                }
                .profile-card-label {
                    color: #7D879C;
                    font-size: 14px;
                }
                .profile-logos {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-wrap: wrap;
                }
                .logo-container {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 10px;
                }
                .logo-container img {
                    max-width: 100px;
                    height: auto;
                }

                @media screen and (max-width: 768px) {
                    .profile-cards {
                        flex-direction: column;
                        align-items: center;
                    }
                    .profile-item {
                        flex: 0 0 100%;
                        margin: 0.5rem 0;
                    }
                    #user_avatar_name {
                        flex-direction: column;
                        align-items: center;
                    }
                    .profile-info {
                        text-align: center;
                        margin-top: 0.5rem;
                    }
                    .profile-name {
                        font-size: 16px;
                    }
                    .profile-logos {
                        flex-direction: column;
                    }
                }
            `}</style>
        </SideMenuBar>
    )
}

export default ProfileView;
