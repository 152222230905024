import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { postMethod } from "../helpers";
import Card from '@mui/material/Card';

function OtpVerification() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const verifyOTP = async (e) => {
    e.preventDefault();
    const value = localStorage.getItem("tempid");
    try {
      let url = "users/verify-otp";
      var payload = {
        id: value,
        otp: otp,
      };
      let response = await postMethod({ url, payload });
      if (response.success) {
        toast.success(response.message);
        navigate("/setnewpassword");
        setOtp("");
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.log("api err", e);
      toast.error(e.response.message);
      setOtp("");
    }
  };
  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "#000",
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "#fff",
            },
          }
        }}
      />
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ backgroundColor: '#FFFFFF', width: '500px', height: '100vh' }} className="d-flex align-items-center">
            <Col>
              <div className="login_form_details">
                <div className="log_details">
                  <div>
                    <img
                      style={{ width: '160px', height: '100px', cursor: 'pointer' }}
                      src={require("../assets/AppLogo/UW-Logo.png")}
                      alt="app-logo"
                    />
                  </div>
                  <h4 className="welcome_txt mt-2">OTP Verification</h4>
                  <p className="hint_txt">Reset your password</p>
                  {/* <div className='login_input'>
                  <input type='number' placeholder='Enter OTP' />
                  <i className='fa-solid fa-envelope login_icons'></i>
                </div> */}
                  <div className="common_otp_inputs">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  {/* <Link className='signup_txt' to='/setnewpassword'> */}
                  <button onClick={verifyOTP} className="common_blubtn mt-4">
                    Verify OTP
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </Col>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default OtpVerification;
