import { Button } from '@mui/material';
import React from 'react';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { useNavigate } from 'react-router-dom';

const NewHeader = ({
    title,
    icon,
    isShowButton = false,
    screenName,
    onPress = () => { },
    isAddIcon = false,
    handleAddButtonClick = () => { }
}) => {
    const navigate = useNavigate();
    const onLogout = () => {
        localStorage.removeItem('@token');
        localStorage.removeItem('@USER');
        localStorage.removeItem('affiliateData');
        navigate('/login')
    }
    return (
        <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
                {icon}
                <div style={{ fontSize: '25px', fontWeight: '700', marginLeft: '12px' }}>
                    {title}
                </div>
                {isAddIcon
                    ? <div style={{ marginLeft: '8px' }}>
                        <AddCircleOutlinedIcon
                            style={{ width: '30px', height: '30px', color: '#23c2f2' }}
                            onClick={() => handleAddButtonClick(true)}
                        />
                    </div>
                    : null
                }
            </div>
            {isShowButton && screenName === 'Order' ? null :
                isShowButton && screenName === 'Profile' ?
                    <Button
                        variant="contained"
                        style={{ color: '#fff', backgroundColor: 'red', fontSize: '0.8rem', fontWeight: '600' }}
                        onClick={onLogout}
                    >
                        Logout
                    </Button> :
                    isShowButton && screenName === 'Support Ticket' ?
                        <Button
                            variant="contained"
                            style={{ color: '#23c2f2', backgroundColor: '#fce9ec', fontSize: '0.8rem', fontWeight: '600' }}
                            onClick={onPress}
                        >
                            Back to Support Ticket
                        </Button> :
                        null
            }
        </div>
    )
}

export default NewHeader;