import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../helpers/index";
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import { DEFAULT_FORM_FIELDS } from "./Utils";
import NewFooter from "../Components/NewFooter";
import NewHeader from "../Components/NewHeaderView";
import RegisterForm from "../Components/RegisterForm";

const useStyles = makeStyles((theme) => ({
  datePickerOverride: {
    width: '100%'
  },
  root: {
    marginBottom: '20px'
  }
}));

function Register() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [formData, setFormData] = useState(DEFAULT_FORM_FIELDS);
  const [concernChecked, setConcernChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateOnChange = (date) => {
    setFormData({
      ...formData,
      dob: dayjs(date.$d).format('YYYY-MM-DD')
    });
  }

  const handlePhoneChange = (phone) => {
    setFormData({
      ...formData,
      phone_number: phone
    })
  }

  const resetFormData = () => {
    setFormData(DEFAULT_FORM_FIELDS);
  }

  const onSubmit = async () => {
    try {
      let url = "customers";
      const { first_name, last_name, email, dob, gender, phone_number, password } = formData;
      var payload = {
        firstName: first_name,
        lastName: last_name,
        email: email,
        dob: dob,
        gender: gender,
        phoneNumber: phone_number,
        password: password,
        concern: concernChecked,
        userType: 'Customer'
      };
      let response = await postMethod({ url, payload });
      if (response.success) {
        toast.success(response.message);
        resetFormData();
        navigate('/login');
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error(e.response.message);
    }
  };
  return (
    <>
      <NewHeader screenName={'Register'}>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "#000",
              },
            },
            error: {
              duration: 3000,
              theme: {
                primary: "red",
                secondary: "#fff",
              },
            }
          }}
        />
        <RegisterForm
          handleDateOnChange={handleDateOnChange}
          formData={formData}
          handlePhoneChange={handlePhoneChange}
          handleChange={handleChange}
          onSubmit={onSubmit}
        />
        <div style={{ height: '40vh' }} />
      </NewHeader>
      <NewFooter screenName={'Register'} />
    </>
  );
}

export default Register;
