import { AuthActionTypes } from './AuthActionType';

const INITIAL_STATE = {
  loginData: {},
  isHubUser: false
};

export const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_DATA_SUCCESS:
      return { 
        ...state, 
        loginData: action.payload 
      };
      case AuthActionTypes.SET_HUB_USER:
      return { 
        ...state, 
        isHubUser: action.payload 
      };
    default:
      return state;
  }
};