import React, { useEffect, useState } from "react";
import SideMenuBar from "../../Components/SideMenuBar";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const HubRegisterView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const affiliateIcon = location?.state?.affiliateIcon;

    const [isHubUser, setIsHubUser] = useState(null); // Initial state to null to handle loading state

    useEffect(() => {
        // Function to check if the user is a hub user
        const checkHubUserStatus = async () => {
            try {
                const token = localStorage.getItem("@token");
                const response = await axios.get('/api/v1/customers/secure/profile', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data.success) {
                    const user = response.data.data.customer;
                    setIsHubUser(user.isHub);
                } else {
                    setIsHubUser(false);
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
                setIsHubUser(false);
            }
        };

        checkHubUserStatus();
    }, []);

    if (isHubUser === null) {
        return <div>Loading...</div>; // Optionally, display a loading indicator while checking
    }

    return (
        <SideMenuBar>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 400 }}>
                <img
                    src={require(`../../assets/AppLogo/${affiliateIcon}.svg`)}
                    style={{ width: '65px', height: '65px' }}
                    className='mb-3'
                    alt="Affiliate Icon"
                />
                <div style={{ fontSize: '24px', fontWeight: 600 }}>
                    {isHubUser
                        ? 'You are already registered as a hub user. Please Create Post'
                        : 'Please register to view posts'}
                </div>
                {!isHubUser && (
                    <div className="mt-3">
                        <button
                            className='px-2 py-1'
                            onClick={() => navigate('/hub')}
                            style={{ border: 'none', backgroundColor: '#2ABDF0', fontSize: '14px', borderRadius: '4px', fontWeight: 600 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='ms-1'>
                                    Register
                                </div>
                            </div>
                        </button>
                    </div>
                )}
            </div>
        </SideMenuBar>
    );
};

export default HubRegisterView;
