import { FormControl, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';

const CommonTextField = ({
    id,
    labelName,
    name,
    value,
    handleChange = () => { },
    style = {},
    disabled = false,
    className = 'mt-2',
    type = 'text',
    handleClickShowPassword = () => { },
    handleMouseDownPassword = () => { },
    showPassword = false,
    multiline = false
}) => {
    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor={id}
                style={{
                    fontSize: '15px',
                    position: 'relative',
                    transform: 'none',
                    left: 5,
                    color: '#000'
                }}
            >
                {labelName}
            </InputLabel>
            <TextField
                id={id}
                className={className}
                variant="outlined"
                name={name}
                type={type === 'password' && !showPassword ? 'password' : 'text'}
                fullWidth
                value={value}
                disabled={disabled}
                style={style}
                onChange={handleChange}
                multiline={multiline}
                rows={multiline ? 2 : 0}
                InputProps={{
                    style: {
                        ...(!multiline && { height: '40px' }),
                        borderRadius: '10px',
                    },
                    endAdornment: type === 'password' && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </FormControl>
    )
};

export default CommonTextField;