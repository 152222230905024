import React from "react";

function BannerCard({ discountData }) {
  return (
    <>
      <div className="banner_card">
        <div className="banner_card">
          <div className="bannercard_dtls">
            <img
              className="bannercard_img"
              src={discountData?.image}
              alt='banner'
            />
          </div>
        </div>
        <div className="bannercard_dtls">
          <img
            className="bannercard_img"
            src={require("../assets/images/winerlist.jpg")}
            alt="winner-banner"
          />
        </div>
      </div >
    </>
  );
}

export default BannerCard;
