import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../helpers";

const TodayWinnersList = () => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [poolData, setPoolData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        makePoolListApi()
    }, []);

    const makePoolListApi = async () => {
        try {
            let url = "customers/secure/pools/winnerannounced";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log('response--->', response);
            if (response.success) {
                setPoolData(response.data)
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }

    // let mockObjData = {
    //     "_id": "65808770a06eb7956c7f83fc",
    //     "imageUrl": "https://boxappdocuments.s3.ap-south-1.amazonaws.com/product/1702922023250-kurthis.jpg",
    // }

    const naviagteToDetails = (item) => {
        navigate(`/today-winners/${item?._id}`)
    }

    return (
        <Grid container spacing={{ md: 4 }} className="mt-4 pe-5 ps-5 pb-5" style={{ backgroundColor: '#F6F9FC' }}>
            {poolData?.map((item, index) => {
                return (
                    <Grid item md={3} key={index}>
                        <Card
                            style={{ width: '290px', marginBottom: 0, cursor: 'pointer' }}
                            onClick={() => naviagteToDetails(item)}
                        >
                            <div className='p-4' style={{ textAlign: 'center' }}>
                                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <img src={item?.poolImage} style={{ height: '200px', width: '100%', objectFit: 'contain' }} />
                                </div>
                                <div className="pt-1">
                                    <div style={{ fontSize: '14px', color: '#373F50', fontWeight: 600 }} className='mt-3'>
                                        {item?.poolName}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default TodayWinnersList;