import dayjs from 'dayjs';

export const DEFAULT_FORM_FIELDS = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    dob: dayjs(''),
    password: '',
    confirm_password: '',
    gender: '',
};

export const DEFAULT_HUB_FIELDS = {
    title: '',
    description: '',
    image: '',
    phoneNumber: '',
    email: '',
    price: '',
    otherDetails: ''
}

export const topCategoryData = [
    {
        icon: 'cat-1.png',
        categoryName: 'Home & Living'
    },
    {
        icon: 'cat-2.png',
        categoryName: 'Mens Fashion'
    },
    {
        icon: 'cat-3.png',
        categoryName: 'Electronics'
    },
    {
        icon: 'cat-4.png',
        categoryName: 'Kids Fashion'
    },
    {
        icon: 'cat-1.png',
        categoryName: 'Home & Living'
    },
    {
        icon: 'cat-2.png',
        categoryName: 'Mens Fashion'
    },
    {
        icon: 'cat-3.png',
        categoryName: 'Womens Fashion'
    }
];

export const isVideo = (text) => {
    // Define common video file extensions
    const videoExtensions = [".mp4", ".avi", ".mov", ".wmv", ".mkv", ".flv", ".webm"];
    // Check if the text ends with any of the video extensions
    return videoExtensions.some((extension) => text?.toLowerCase().endsWith(extension));
  };