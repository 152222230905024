import { deleteMethod, getMethod, postMethod } from '../../../helpers';
import { makeRemoveFromWishlistApi } from '../../Wishlist/redux/WishlistAction';
import { CartActionTypes } from './CartActionTypes';
import toast from "react-hot-toast";

export const makeGetCartApi = async (dispatch) => {
    try {
        let url = "customers/secure/cart";
        let token = localStorage.getItem("@token");
        let response = await getMethod({ url, token });
        if (response.success) {
            dispatch({
                type: CartActionTypes.SET_CART_DATA,
                payload: response.data
            });
        } else {
            dispatch({
                type: CartActionTypes.SET_CART_DATA,
                payload: {}
            });
        }
    } catch (error) {
        console.log("Error:", error);
    }
};

export const makeAddToCartApi = async (product, dispatch, isFromWishlist = false, quantity = 1, navigate, isFromProductDetails = false) => {
    try {
        dispatch({
            type: CartActionTypes.CART_LOADING,
            payload: true
        });
        let url = `customers/secure/cart/${product?.productUuid}?quantity=${quantity}`;
        var payload = {
            productId: product?.productUuid,
            quantity
        };
        let token = localStorage.getItem("@token");
        let response = await postMethod({ url, payload, token });
        if (response.success) {
            dispatch({
                type: CartActionTypes.CART_LOADING,
                payload: false
            });
            toast.success(response.message, { duration: 3000 });
            if (isFromWishlist) {
                makeRemoveFromWishlistApi(product, dispatch)
            }
            dispatch({
                type: CartActionTypes.STORE_CART_PRODUCT_IDS,
                payload: product?.productUuid
            });
            makeGetCartApi(dispatch)
            if (isFromProductDetails) {
                navigate('/cart');
            }
        } else {
            dispatch({
                type: CartActionTypes.CART_LOADING,
                payload: false
            });
            toast.error(response.message, { duration: 3000 });
        }
    } catch (e) {
        dispatch({
            type: CartActionTypes.CART_LOADING,
            payload: false
        });
        console.log(e);
    }
}

export const makeRemoveFromCartApi = async (item, dispatch) => {
    try {
        let url = `customers/secure/cart/${item?.productUuid}?`;
        var payload = {
            productId: item?.productUuid,
        };
        let token = localStorage.getItem("@token");
        let response = await deleteMethod({ url, payload, token });
        if (response.success) {
            toast.success(response.message, {
                icon: false,
                style: { color: '#fff', backgroundColor: '#000' }
            });
            dispatch({
                type: CartActionTypes.REMOVE_CART_PRODUCT_ID,
                payload: item?.productUuid
            });
            makeGetCartApi(dispatch);
        } else {
            toast.error(response.message);
        }
    } catch (e) {
        console.log(e);
    }
}

export const updateProductQuantityInCart = async (productUuid, quantity, dispatch) => {
    try {
        let url = `customers/secure/cart/${productUuid}?quantity=${quantity}`;
        let token = localStorage.getItem("@token");
        let response = await postMethod({ url, token, payload: {} });
        if (response.success) {
            makeGetCartApi(dispatch);
        } else {
            toast.error(response.message);
        }
    } catch (e) {
        console.log(e);
    }
}