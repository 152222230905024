import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { makeRemoveFromCartApi } from "../Views/Cart/redux/CartAction";
import { useDispatch } from "react-redux";
import { makeAddToWishlistApi } from "../Views/Wishlist/redux/WishlistAction";

function CartCard({ item, handleQuantityOnchange, key }) {
  const [hoverIndex, setHoverIndex] = useState(null);
  const dispatch = useDispatch();

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          error: {
            duration: 3000,
            theme: {
              primary: "red",
              secondary: "#fff",
            },
          },
        }}
      />
      <div className="cart_cart_whole mb-4">
        <div className="cart_dtls_only">
          <div className="cart_product_img me-4">
            <img className="" src={item?.imageUrl} alt="product-img" />
          </div>
          <div className="cart_prod_details">
            <p className="cart_prodname mb-3">{item?.productName}</p>
            <div className="mb-2" style={{ fontSize: '14px' }}>
              <span className="me-2" style={{ color: '#7D879C' }}>₹{(item.price || 0).toFixed(2)} X {item?.quantity === 0 ? 1 : item?.quantity}</span>
              <span style={{ paddingLeft: '7px', color: '#23c2f2', fontWeight: '600' }}>₹{item?.total}</span>
            </div>
            <div className="cart_incdec_btns">
              <button
                className="cart_dec_btn"
                onClick={() => item?.quantity > 0 ? handleQuantityOnchange(item?.quantity - 1, item?.productUuid) : () => { }}> -
              </button>
              <p className="cart_incdec_count">{item?.quantity === 0 ? 1 : item?.quantity}</p>
              <button className="cart_inc_btn" onClick={() => handleQuantityOnchange(item?.quantity + 1, item?.productUuid)}> + </button>
            </div>
          </div>
        </div>
        <div
          className="cart_closer d-flex"
          style={{ flexDirection: 'column', height: '150px', alignItems: 'flex-end', justifyContent: 'space-between' }}
          onClick={() => makeRemoveFromCartApi(item, dispatch)}
        >
          <i className="fa-solid fa-xmark"></i>
          <button
            className="btn mt-3 text-center"
            style={{
              backgroundColor: hoverIndex === key ? '#23c2f2' : '#fff',
              color: hoverIndex === key ? '#fff' : '#23c2f2',
              border: '1px solid #23c2f2',
              borderRadius: '4px',
              width: 150,
              fontWeight: 600,
              fontSize: '14px'
            }}
            onMouseEnter={() => setHoverIndex(key)}
            onMouseLeave={() => setHoverIndex(null)}
            onClick={() => makeAddToWishlistApi(item, dispatch, true)}
          >
            Move To Wishlist
          </button>
        </div>
      </div>
    </>
  );
}

export default CartCard;
