import React, { useEffect, useState } from 'react';
import NewHeader from '../../Components/NewHeader';
import { Table } from 'antd';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { makeGetOnlineAffiliateApi } from './redux/AffiliateAction';
import moment from 'moment';
import CreateNewCouponsForm from './CreateCouponForm';

const OnlineAffiliateList = () => {
    const [openModal, setModalOpen] = useState(false);

    const dispatch = useDispatch();

    const affiliateData = useSelector(state => state.affiliateState.onlineAffiliateData);

    useEffect(() => {
        makeGetOnlineAffiliateApi(dispatch);
    }, []);

    const columns = [
        {
            title: <div style={{ textAlign: 'center' }}>Coupon Name</div>,
            dataIndex: 'couponName',
            key: 'couponName',
            render: (serialNo) => {
                return (
                    <div style={{ textAlign: 'center' }}>{serialNo}</div>
                )
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>No of Coupons</div>,
            key: 'noOfCoupons',
            dataIndex: 'noOfCoupons',
            render: (noOfCoupons) => {
                return (
                    <div style={{ textAlign: 'center' }}>{noOfCoupons}</div>
                )
            },
        },
        {
            title: <div style={{ textAlign: 'center' }}>Available Coupons</div>,
            dataIndex: 'availableCoupons',
            key: 'availableCoupons',
            render: (availableCoupons) => {
                return (
                    <div style={{ textAlign: 'center' }}>{availableCoupons}</div>
                )
            }
        },
        {
            title: <div style={{ textAlign: 'center' }}>Expire On</div>,
            dataIndex: 'expiryOn',
            key: 'expiryOn',
            render: (expiryOn) => {
                return (
                    <div style={{ textAlign: 'center' }}>{moment(expiryOn).format("DD-MM-yyyy")}</div>
                )
            }
        }
    ];


    return (
        <div style={{ backgroundColor: '#fff', height: 'auto' }}>
            {/* <div className='mt-3'>
                <NewHeader title={'My Coupons'} isAddIcon={true} handleAddButtonClick={() => setModalOpen(true)} />
            </div> */}
            <div className='mt-4'>
                <Table
                    columns={columns}
                    dataSource={affiliateData}
                    className="custom-table"
                />
            </div>
            <CreateNewCouponsForm openModal={openModal} modalOnClose={() => setModalOpen(false)} />
        </div>
    )
}

export default OnlineAffiliateList;