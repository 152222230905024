
import CommonTextField from '../../Components/CommonTextField';
import NewFooter from '../../Components/NewFooter';
import NewHeader from '../../Components/NewHeaderView';
import { IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Box } from '@mui/material';
import { getMethod, postMethod, upload } from '../../helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { setIsHubUser } from '../auth/redux/AuthAction';
import axios from "axios";
import { isVideo } from '../Utils';
import toast from 'react-hot-toast';

const CreatePostForm = ({screenName}) => {
    const initialData = {
        category: '',
        subCategory1: '',
        subCategory2: '',
        postTitle: '',
        postDescription: '',
        price: '',
        discountedPrice: '',
        state: '',
        city: '',
        locality: '',
        moreState1: '',
        moreCity1: '',
        moreLocality1: '',
        moreState2: '',
        moreCity2: '',
        moreLocality2: '',
        photo: '',
        video: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        formatted_address: ''
    }
    const [formData, setFormData] = useState(initialData);
    const [allPostCategories, setAllPostCategories] = useState([])
    const MAX_FILES = 9;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleContactChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };

    const [files, setFiles] = useState([]);
    const handleFileChange = async (event) => {
        console.log('event',event)
        const selectedFiles = Array.from(event.target.files);
        console.log('handleFileChange',selectedFiles)
        if (files.length + selectedFiles.length > MAX_FILES) {
            alert(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }
        const uploadedFiles = [];
        console.log(selectedFiles);
        if (selectedFiles.length < MAX_FILES) {
            for (let i = 0; i < selectedFiles.length; i++) {
              const file = selectedFiles[i];
              const formData = new FormData();
              formData.append("avatar", file);
              console.log(formData);
              const uploadResp = await upload(formData, "postsimg");

              console.log(uploadResp?.data?.data?.Location);
    
              uploadedFiles.push(uploadResp?.data?.data?.Location);              
            }
            
          }
          setFiles([...files, ...uploadedFiles]);
    };

    console.log('files',files)

    const removeFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const json = localStorage.getItem("@USER");
    const User = useMemo(() => JSON.parse(json), [json]);
   
    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData,
            image: files,
            specifications: [...attributes],
            hubType: getHubTypes(),
            contact: {
                name: formData?.contactName,
                email: formData?.contactEmail,
                phone: formData?.contactPhone
            },
            "lat": position?.latitude,
"lng": position?.longitude,
areaName: formData.locality,
title: formData?.postTitle,
description: formData?.postDescription,
subsubcategory: formData?.subCategory2,
subcategory: formData?.subCategory1
        }

        console.log('payload',payload);

        let url = `customers/secure/posts/new`
            const token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload });
        console.log('response',response)
        if (response?.success) {
            toast.success(response?.message);
            setFormData(initialData);
            setFiles([])
        }else {
            toast.error(response?.message);
        }
        
      };
    

    const cardStyle = {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        paddingTop: '30px',
        paddingBottom: '30px'
    };

    const sectionHeaderStyle = {
        marginBottom: '10px',
        fontSize: '16px',
        fontWeight: 'bold'
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    };

    const buttonStyle = {
        padding: '9px 30px',
        fontSize: '1rem',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px'
    };

    const previewButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#f0f0f0'
    };

    const submitButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#ffce00',
        marginLeft: '3rem'
    };

    const formStyle = {
        width: '80%',
        margin: 'auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif'
    };

    const inputContainerStyle = {
        marginBottom: '1rem'
    };

    const inputRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap:'wrap',
        alignItems:'center',
       
    };

    const inputStyle = {
        flex: 1,
        marginRight: '10px'
    };


    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategory1Options, setSubCategory1Options] = useState([]);
    const [subCategory2Options, setSubCategory2Options] = useState([]);

    const getHubTypes = () => {
        let type = ''
        if (screenName === 'farmer') {
            type = 'Farmer'
        } else if (screenName === 'student') {
            type = 'Student'
        } else {
            type = 'MarketPlace'
        }
        return type;
    };

    const [position, setPosition] = useState({ latitude: null, longitude: null });

    useEffect(() => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            setPosition({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          function (error) {
            console.error(error);
          },
          {
            enableHighAccuracy: true,  // Requests high-accuracy results
            timeout: 5000,             // Maximum time to wait for location
            maximumAge: 0              // No caching of location data
          });
        } else {
          console.log("Geolocation is not available in your browser.");
        }
      }, []);

      const getAddress = async () => {
        let url = `third-party/google/geocode?lat=${position?.latitude}&lng=${position?.longitude}`
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log('response',response);
            setFormData({
                ...formData,
                state: response?.data?.[0]?.state,
                city: response?.data?.[0].city,
                locality: response?.data?.[0].areaName,
                formatted_address: response?.data?.[0].formatted_address,
            })
      }

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let url = `customers/posts/categories/${getHubTypes()}`
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log('fetchCategories',response);
            setAllPostCategories(response?.data ?? [])
                
                setCategoryOptions(response?.data ?? []);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const [attributes, setAttributes] = useState([{ title: '', description: '' }]);

    const handleAddAttribute = () => {
        setAttributes([...attributes, { title: '', description: '' }]);
    };

    const handleRemoveAttribute = (index) => {
        const newAttributes = attributes.filter((_, i) => i !== index);
        setAttributes(newAttributes);
    };
    const handleAttributeChange = (index, e) => {
        const { name, value } = e.target;
        const newAttributes = [...attributes];
        newAttributes[index][name] = value;
        setAttributes(newAttributes);
    };

    return (
        <div style={{backgroundColor:'white'}}>
                <form onSubmit={handleSubmit} style={formStyle}>
                    <div className='my-3' style={{ fontSize: '18px' }}>Let's create your post</div>
                    <div style={sectionHeaderStyle}>Basic details</div>
                    <style>
                        {`
                            @media (max-width:600px){
                            #more_attributes,#location_details,#more_attributes1,#contact_details{
                            flex-direction:column;
                            }
                            }
                        `}
                    </style>
                    <div className='mb-5' style={cardStyle}>
                        {/* <div style={inputRowStyle} className='mb-3'>
                            <div style={inputStyle}>
                                <CommonTextField id='category' labelName='Category' name='category' value={formData.category} handleChange={handleChange} />
                            </div>
                            <div style={inputStyle}>
                                <CommonTextField id='subCategory1' labelName='Sub Category 1' name='subCategory1' value={formData.subCategory1} handleChange={handleChange} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <CommonTextField id='subCategory2' labelName='Sub Category 2' name='subCategory2' value={formData.subCategory2} handleChange={handleChange} />
                            </div>
                        </div> */}

                        <div style={inputRowStyle} className='mb-3'>
                            <div style={inputStyle}>
                                <select
                                    id='category'
                                    name='category'
                                    value={formData.category}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setSubCategory2Options([])
                                        setSubCategory1Options(allPostCategories?.find(f => f._id === e.target.value)?.children ?? [])
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                    }}
                                >
                                    <option value="">Select Category</option>
                                    {categoryOptions.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={inputStyle}>
                                <select
                                    id='subCategory1'
                                    name='subCategory1'
                                    value={formData.subCategory1}
                                    onChange={(e) => {
                                        handleChange(e);
                                        console.log('formData?.category',formData?.category)
                                        setSubCategory2Options(allPostCategories?.find(f => f._id === formData?.category)?.children?.find(f => f._id === e.target.value)?.children ?? [])
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                    }}
                                >
                                    <option value="">Select SubCategory</option>
                                    {subCategory1Options.map((subCategory) => (
                                        <option key={subCategory._id} value={subCategory._id}>
                                            {subCategory.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ flex: 1 }}>
                                <select
                                    id='subCategory2'
                                    name='subCategory2'
                                    value={formData.subCategory2}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                    }}
                                >
                                    <option value="">Select Category</option>
                                    {subCategory2Options.map((subCategory) => (
                                        <option key={subCategory._id} value={subCategory._id}>
                                            {subCategory.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div style={inputContainerStyle}>
                            <CommonTextField id='postTitle' labelName='Post Title' name='postTitle' value={formData.postTitle} handleChange={handleChange} />
                        </div>
                        <div style={inputContainerStyle}>
                            <CommonTextField 
                                id='postDescription' 
                                labelName='Post Description' 
                                name='postDescription' 
                                value={formData.postDescription} 
                                handleChange={handleChange}
                                multiline={true}
                            />
                        </div>
                        <div style={inputRowStyle}>
                            <div style={inputStyle}>
                                <CommonTextField id='price' labelName='Price' name='price' value={formData.price} handleChange={handleChange} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <CommonTextField id='discountedPrice' labelName='Discounted Price' name='discountedPrice' value={formData.discountedPrice} handleChange={handleChange} />
                            </div>
                        </div>
                        {/* Add photo/video upload components here */}
                        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                <div style={{ fontSize: '16px', marginRight: '8px' }}>
                    {files.length} / {MAX_FILES}
                </div>
                <IconButton
                    component="label"
                    color="primary"
                    aria-label="upload photos and videos"
                >
                    <AddCircleIcon />
                    <input
                        type="file"
                        multiple
                        hidden
                        onChange={handleFileChange}
                        accept="image/*,video/*"
                    />
                </IconButton>
            </div>
            <Grid container spacing={1}>
                {files.map((file, index) => (
                    <Grid item xs={4} md={2} key={index}>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: '100px',
                                backgroundColor: '#f0f0f0',
                                overflow: 'hidden',
                                borderRadius: '4px',
                            }}
                        >
                            {!isVideo(file) && (
                                <img
                                    src={file}
                                    alt="preview"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                            {isVideo(file) && (
                                <video
                                    src={file}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    controls
                                />
                            )}
                            <button
                                onClick={() => removeFile(index)}
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: '#fff',
                                    width: '10px',
                                    border: 'none',
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    padding: '4px',
                                }}
                                type='button'
                            >
                                &times;
                            </button>
                        </Box>
                    </Grid>
                ))}
                {files.length < MAX_FILES && (
                    <Grid item xs={4} md={2}>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: '100px',
                                backgroundColor: '#e0e0e0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '4px',
                            }}
                        >
                            <IconButton
                                component="label"
                                color="primary"
                                aria-label="add file"
                            >
                                <AddCircleIcon fontSize="large" />
                                <input
                                    type="file"
                                    multiple
                                    hidden
                                    onChange={handleFileChange}
                                    accept="image/*,video/*"
                                />
                            </IconButton>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </div>
                    </div>
                    {/* contact details  start */}
                    <div style={sectionHeaderStyle}>Contact Details</div>
                    <div className='mb-5' style={cardStyle}>
                    <div style={inputRowStyle} className='mb-3'>
                    <div style={inputStyle} id='contact_details'>
                        <CommonTextField 
                        id='contactName' 
                        labelName='Contact Name' 
                        name='contactName' 
                        value={formData.contactName} 
                        handleChange={handleContactChange} 
                        />
                    </div>
                    <div style={inputStyle}>
                        <CommonTextField 
                        id='contactEmail' 
                        labelName='Contact Email' 
                        name='contactEmail' 
                        value={formData.contactEmail} 
                        handleChange={handleContactChange} 
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <CommonTextField 
                        id='contactPhone' 
                        labelName='Contact Phone' 
                        name='contactPhone' 
                        value={formData.contactPhone} 
                        handleChange={handleContactChange} 
                        />
                    </div>
                    </div>
                    </div>


                    <div style={sectionHeaderStyle}>Location details</div>
                    <div className='mb-5' style={cardStyle}>
                        <div style={inputContainerStyle}>
                            <label>
                                <input type="radio" name="location" onClick={() => {
                                    getAddress()
                                }} /> Auto detect my location
                            </label>
                        </div>
                        <div style={inputRowStyle} id='location_details'>
                            <div style={inputStyle}>
                                <CommonTextField id='state' labelName='State' name='state' value={formData.state} handleChange={handleChange} />
                            </div>
                            <div style={inputStyle}>
                                <CommonTextField id='city' labelName='City' name='city' value={formData.city} handleChange={handleChange} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <CommonTextField id='locality' labelName='Locality' name='locality' value={formData.locality} handleChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div style={sectionHeaderStyle}>Specifications</div>
                    <div style={cardStyle}>
                        {attributes.map((attribute, index) => (
                            <div style={inputRowStyle} key={index} className='mb-3'>
                                <div style={inputStyle}>
                                    <CommonTextField
                                        id={`moreState${index}`}
                                        labelName='Title'
                                        name='title'
                                        value={attribute.title}
                                        handleChange={(e) => handleAttributeChange(index, e)}
                                    />
                                </div>
                                <div style={inputStyle}>
                                    <CommonTextField
                                        id={`moreCity${index}`}
                                        labelName='Description'
                                        name='description'
                                        value={attribute.description}
                                        handleChange={(e) => handleAttributeChange(index, e)}
                                    />
                                </div>
                                <button type="button" onClick={handleAddAttribute} style={{ marginRight: '8px', border:'none',backgroundColor:'#FFC100',borderRadius:'25px',marginTop:"25px", width:'30px', height:'30px' }}>+</button>
                                {index > 0 && (
                                    <button type="button" onClick={() => handleRemoveAttribute(index)} style={{border:'none',backgroundColor:'#FFC100',borderRadius:'25px',marginTop:"25px", width:'30px', height:'30px'}}>-</button>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className='mt-5' style={buttonContainerStyle}>
                        <button type="button" style={previewButtonStyle}>Preview</button>
                        <button type="submit" style={submitButtonStyle}>Submit</button>
                    </div>
                </form>
            {/* <NewFooter/> */}
        </div>
    );
};

export default CreatePostForm;