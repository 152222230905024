import {
  Chip,
  Stack,
  Select,
  MenuItem,
  Card,
  Checkbox,
  Button,
  Grid,
  Rating
} from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import StarIcon from "@mui/icons-material/Star";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import FilterClear from "../../assets/icons/filterClearIcon.svg";
import ClearFiltersChip from "../../Components/ChipComponent";
import { makeStyles } from "@mui/styles";
import UWAssuredIcon from "../../assets/icons/UWAssuredIcon.svg";
import PriceFilter from "../../Components/PriceFilter";
import { storeProductSearchData } from "./redux/ProductAction";
import { getMethod } from "../../helpers";
import StarRatingComponent from "../../Components/StartRatingComponent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const useStyles = makeStyles(() => ({
  label: {
    fontSize: "10px",
    fontFamily:'Gabarito',
  },
  radio: {
    "& svg": {
      fontFamily:'Gabarito',
      fontSize: "18px", // Adjust the size of the radio button itself
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      padding: "5", // Adjust the padding to reduce height
      "& .MuiInputBase-input": {
        padding: "12px 14px", // Adjust the input padding
        fontSize: "14px", // Adjust the font size if needed
        fontFamily:'Gabarito',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #00000080",
        borderRadius: "13px",
      },
    },
  },
}));

const ProductList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const productItem = location?.state?.productItem || {};
  const isFromCategoryMenu = location?.state?.isFromCategoryMenu || false;

  const [selectedSortData, setSelectedSortData] = useState("all");
  const [trendingCardhoverIndex, setTrendingCardhoverIndex] = useState(null);
  const [catImgCardhoverIndex, setCatImgCardhoverIndex] = useState(null);
  const [value, setValue] = useState("no");
  const [selectedSubcategoryIds, setSelectedSubcategoryIds] = useState(
    new Set()
  );
  const [showFilters, setShowFilters] = useState(false); // State to control filter visibility

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const [defaultPriceRange, setDefaultPriceRange] = useState([0, 0]);
  const [filterOptions, setFilterOptions] = useState({
    categories: new Set(),
    subcategories: new Set(),
    subcategoryType: new Set(),
    priceRange: {
      start: 0,
      end: 600000,
    },
    ratings: 0,
  });

  console.log("selectedSubcategoryIds", selectedSubcategoryIds);

  const productSearchData = useSelector(
    (state) => state.productState.productSearchData
  );
  const categortList = useSelector((state) => state.productState.categoryData);
  const subCategoryList = useSelector(
    (state) => state.productState.subCategoryData
  );
  const allProductList = useSelector(
    (state) => state.productState.productSearchData
  );
  const nestedCategories = useSelector(
    (state) => state.productState.productAllList?.nestedCategories
  );

  const [appliedFilterArray, setAppliedFilterArray] = useState([]);
  const [checkedState, setCheckedState] = useState({});

  console.log("nestedCategories", nestedCategories);

  const productSearchList = useMemo(() => {
    let filteredProducts = [];
    if (isFromCategoryMenu) {
      filteredProducts = allProductList?.filter(
        (item) => item?.productGroup?.subcategoryType === productItem?._id
      );
    } else {
      filteredProducts = productSearchData;
    }
    return filteredProducts;
  }, [isFromCategoryMenu, productItem]);

  const [filterProductArray, setFilterProductArray] = useState([]);

  // const [hoverIndex, setHoverIndex] = useState(null);
  // const [catImgCardhoverIndex, setCatImgCardhoverIndex] = useState(null);

  const filterAndSortProducts = useCallback(
    (selectedSortData = "all") => {
      const query = {
        cats: filterOptions?.categories.values().toArray(),
        subcats: filterOptions?.subcategories.values().toArray(),
        subcatTypes: filterOptions?.subcategoryType.values().toArray(),
        price: filterOptions?.priceRange,
        ratings: filterOptions.ratings,
      };

      console.log(allProductList);

      let filteredData = allProductList
        .filter((product) => {
          if (query?.cats?.length > 0) {
            return query?.cats?.includes(product?.category?._id);
          } else {
            return true;
          }
        })
        .filter((product) => {
          if (query?.subcats?.length > 0) {
            return query?.subcats?.includes(product?.subcategory?._id);
          } else {
            return true;
          }
        })
        .filter((product) => {
          if (query?.subcatTypes?.length > 0) {
            return query?.subcatTypes?.includes(
              product?.productGroup?.subcategoryType
            );
          } else {
            return true;
          }
        })
        .filter((product) => {
          return (
            product?.price >= query?.price?.start &&
            product?.price <= query?.price?.end
          );
        })
        .filter((product) => {
          if (query?.ratings > 0) {
            return product?.overallRating >= query?.ratings;
          } else {
            return true;
          }
        });

      if (selectedSortData === "priceLTH") {
        filteredData = filteredData.sort(
          (a, b) => Number(a.price) - Number(b.price)
        );
      } else if (selectedSortData === "priceHTL") {
        filteredData = filteredData.sort(
          (a, b) => Number(b.price) - Number(a.price)
        );
      }
      console.log("filteredData", filteredData);
      setFilterProductArray(filteredData);
    },
    [allProductList, filterOptions, selectedSortData]
  );

  useEffect(() => {
    filterAndSortProducts();

    const priceRange = allProductList.reduce(
      (acc, product) => {
        if (product.price < acc.min) acc.min = product.price;
        if (product.price > acc.max) acc.max = product.price;
        return acc;
      },
      { min: Infinity, max: -Infinity }
    );
    setDefaultPriceRange([priceRange?.min, priceRange?.max]);
    console.log("priceRange", priceRange);
  }, [filterOptions, allProductList]);

  const constructProductBySort = useCallback(
    (selectedSortData) => {
      let constructData = [];
      if (selectedSortData === "priceLTH") {
        constructData = [...filterProductArray]?.sort(
          (a, b) => Number(a.price) - Number(b.price)
        );
      } else if (selectedSortData === "priceHTL") {
        constructData = [...filterProductArray]?.sort(
          (a, b) => Number(b.price) - Number(a.price)
        );
      } else if (selectedSortData === "all") {
        constructData = productSearchList;
      }
      //   setFilterProductArray(constructData);
    },
    [productSearchList]
  );

  const handleTrendingCardHover = (index) => {
    setTrendingCardhoverIndex(index);
  };
  const sortData = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Price: Low To High",
      value: "priceLTH",
    },
    
    {
      label: "Price: High To Low",
      value: "priceHTL",
    },
    {
      label: "Popular",
    },
  ];

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleProductOnClick = (item) => {
    if (item) {
      navigate(`/productDetails/${item?.subcategoryUuid}/${item.productUuid}`);
    }
  };

  const handleOnChange = (e) => {
    if (e.target && e?.target?.value) {
      setSelectedSortData(e.target && e?.target?.value ? e.target.value : "");
      filterProducts(selectedSubcategoryIds, e?.target?.value);
      console.log("filteredData", e?.target?.value);
      filterAndSortProducts(e?.target?.value);
    }
  };
  const [reviewDescription, setReviewDescription] = useState("");

  const handleSubCatTypeSelection = (subCatTypeId) => {
    const getFilterOpts = { ...filterOptions };
    if (getFilterOpts.subcategoryType.has(subCatTypeId)) {
      getFilterOpts.subcategoryType.delete(subCatTypeId);
    } else {
      getFilterOpts.subcategoryType.add(subCatTypeId);
    }
    setFilterOptions(getFilterOpts);
  };

  const handleSubCategorySelection = (categoryId, subcategoryId) => {
    const getFilterOpts = { ...filterOptions };
    if (!getFilterOpts.categories.has(categoryId)) {
      getFilterOpts.categories.add(categoryId);
    }

    if (getFilterOpts.subcategories.has(subcategoryId)) {
      getFilterOpts.subcategories.delete(subcategoryId);
    } else {
      getFilterOpts.subcategories.add(subcategoryId);
    }
    setFilterOptions(getFilterOpts);
  };

  const handleCheckBoxChange = (subItemId, subcategoryName) => {
    setCheckedState((prevState) => {
      const newCheckedState = {
        ...prevState,
        [subItemId]: !prevState[subItemId],
      };

      if (newCheckedState[subItemId]) {
        setAppliedFilterArray((prevAppliedFilters) => {
          const newFilter = {
            id: subItemId,
            label: subcategoryName,
          };
          return [...prevAppliedFilters, newFilter];
        });
      } else {
        setAppliedFilterArray((prevAppliedFilters) => {
          const newFilter = prevAppliedFilters?.filter(
            (data) => data?.id !== subItemId
          );
          return newFilter;
        });
      }
      return newCheckedState;
    });

    setSelectedSubcategoryIds((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(subItemId)) {
        newSet.delete(subItemId);
      } else {
        newSet.add(subItemId);
      }
      filterProducts(newSet, selectedSortData);
      return newSet;
    });
  };

  const filterProducts = useCallback(
    (selectedIds, selectedSortData) => {
      let filteredProducts = productSearchList;

      if (selectedIds.size > 0) {
        filteredProducts = filteredProducts?.filter((item) =>
          selectedIds?.has(item?.subcategoryUuid)
        );
      }

      if (selectedSortData === "priceLTH") {
        filteredProducts = filteredProducts.sort(
          (a, b) => Number(a.price) - Number(b.price)
        );
      } else if (selectedSortData === "priceHTL") {
        filteredProducts = filteredProducts.sort(
          (a, b) => Number(b.price) - Number(a.price)
        );
      }

      //   setFilterProductArray(filteredProducts);
    },
    [productSearchList, selectedSortData]
  );

  // useEffect(() => {
  //     filterProducts(selectedSubcategoryIds, selectedSortData);
  // }, [productSearchList, selectedSubcategoryIds, selectedSortData, filterProducts]);

  const handleClearFilter = () => {
    setCheckedState({});
    setAppliedFilterArray([]);
    setSelectedSubcategoryIds(new Set());
    setFilterOptions({
      categories: new Set(),
      subcategories: new Set(),
      subcategoryType: new Set(),
      priceRange: {
        start: 0,
        end: 600000,
      },
      ratings: 0,
    });
  };

  const handleDeleteAppliedFilter = (id) => {
    let filteredArray = appliedFilterArray?.filter((data) => data?.id !== id);
    setAppliedFilterArray(filteredArray);
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    setSelectedSubcategoryIds((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(id)) {
        newSet.delete(id);
      }
      filterProducts(newSet, selectedSortData);
      return newSet;
    });
  };
  return (
    <div className="px-5 py-4">
      <Stack
        direction="row"
        spacing={1}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <div style={{ fontSize: "18px", fontWeight: 400, color: "#000000", fontFamily: 'Gabarito' }}>
          Search results for “
          {isFromCategoryMenu
            ? productItem?.typeName
            : productItem?.productName}
          ”
        </div>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {allProductList && allProductList?.length > 0 ? (
            <div
              style={{ fontSize: "12px", fontWeight: 600, color: "#979797", fontFamily: 'Gabarito' }}
              className="px-3"
            >
              Showing{" "}
              {filterProductArray && filterProductArray?.length > 0 ? 1 : 0}-
              {filterProductArray && filterProductArray?.length} of{" "}
              {allProductList?.length} results
            </div>
          ) : null}
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <div style={{ fontSize: "12px", fontWeight: 400, fontFamily: 'Gabarito' }} className="px-3">
              Sort by
            </div>
            <div>
              <Select
                labelId="select-label"
                id="simple-select"
                value={selectedSortData}
                style={{ width: "150px", height: "25px", fontSize: "12px", fontFamily: 'Gabarito' }}
                onChange={handleOnChange}
              >
                {sortData?.map((item, i) => (
                  <MenuItem
                    id={`${item.label} - ${i}`}
                    value={item.value}
                    style={{ fontSize: "13px", fontFamily: 'Gabarito' }}
                    key={i}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Stack>
        </Stack>
      </Stack>

      {/* Applied Filters Section */}
      {appliedFilterArray && appliedFilterArray?.length > 0 ? (
        <div className="px-5 mt-5">
          <Stack
            direction="row"
            spacing={1}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <Stack direction="row" spacing={1} alignItems={"center"} flexWrap={"wrap"}>
              <div
                style={{ fontSize: "14px", fontWeight: 400, color: "#000" ,fontFamily:'Gabarito'}}
                className="me-2"
              >
                Applied Filters:
              </div>
              {appliedFilterArray?.map((item, i) => {
                return (
                  <Chip
                    key={i}
                    label={item.label}
                    variant="outlined"
                    style={{
                      backgroundColor: "#F3F0F0",
                      color: "#000",
                      fontSize: "13px",
                      fontFamily:'Gabarito',
                      fontWeight: 400,
                    }}
                    onDelete={() => handleDeleteAppliedFilter(item?.id)}
                    deleteIcon={
                      <CloseIcon
                        style={{ fill: "#000", width: "19px", height: "19px" }}
                      />
                    }
                  />
                );
              })}
            </Stack>
            <div>
              <ClearFiltersChip onClick={handleClearFilter} />
            </div>
          </Stack>
        </div>
      ) : null}

      <div className="mt-5" id="desktop_filters">
      <Grid container spacing={3}>
          <Grid item xs={2.5}>
            <div
              style={{
                backgroundColor: "#ffffff",
                color: "#2B3445",
                borderRadius: "8px",
                border: "1px solid #DCDCDC",
              }}
            >
              <div style={{ padding: "1.5rem" }}>
                <div style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                  {nestedCategories?.map((item) => {
                    return (
                      <div className="pb-2 pt-2">
                        {item?.subCategories &&
                          item?.subCategories?.length > 0 ? (
                          <div
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              fontFamily:'Gabarito',
                              color: "#2B3445",
                            }}
                          >
                            {item?.categoryName}
                          </div>
                        ) : null}
                        {item?.subCategories?.length > 0
                          ? item.subCategories?.map((subItem) => {
                            return (
                              <Stack direction={"column"}>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                >
                                  <Checkbox
                                    size="small"
                                    checked={
                                      checkedState[
                                      subItem?.subcategoryUuid
                                      ] || false
                                    }
                                    onChange={() => {
                                      handleCheckBoxChange(
                                        subItem?.subcategoryUuid,
                                        subItem?.subcategoryName
                                      );
                                      handleSubCategorySelection(
                                        item?._id,
                                        subItem?._id
                                      );
                                    }}
                                  />
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      paddingTop: "5px",
                                      paddingBottom: "5px",
                                      cursor: "pointer",
                                      color: "#000",
                                      fontWeight: 400,
                                      fontFamily:'Gabarito',
                                    }}
                                  >
                                    {subItem?.subcategoryName}
                                  </div>
                                </Stack>
                                {subItem?.subcategoryTypes &&
                                  subItem?.subcategoryTypes?.length > 0
                                  ? subItem?.subcategoryTypes?.map(
                                    (subTypeitem, i) => {
                                      return (
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          className="ps-4"
                                          index={i}
                                        >
                                          <Checkbox
                                            size="small"
                                            checked={
                                              checkedState[
                                              subTypeitem?._id
                                              ] || false
                                            }
                                            onChange={() => {
                                              handleCheckBoxChange(
                                                subTypeitem?._id,
                                                subTypeitem?.typeName
                                              );
                                              handleSubCatTypeSelection(
                                                subTypeitem?._id
                                              );
                                            }}
                                          />
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              paddingTop: "5px",
                                              paddingBottom: "5px",
                                              cursor: "pointer",
                                              color: "#000",
                                              fontWeight: 400,
                                              fontFamily:'Gabarito',
                                            }}
                                          >
                                            {subTypeitem?.typeName}
                                          </div>
                                        </Stack>
                                      );
                                    }
                                  )
                                  : null}
                              </Stack>
                            );
                          })
                          : null}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* Price filter */}
              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <PriceFilter
                  min={defaultPriceRange[0]}
                  max={defaultPriceRange[1]}
                  onRangeChange={(val) => {
                    console.log("price Filter", val);
                    setFilterOptions({
                      ...filterOptions,
                      priceRange: {
                        start: val[0],
                        end: val[1],
                      },
                    });
                  }}
                />
              </div>
              {/* Review % Star Rating */}
              <div
                style={{
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    fontFamily: "Gabarito",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Sort by Ratings
                </div>
                <Rating
                  value={filterOptions?.ratings}
                  onChange={(event, newValue) => {
                    setFilterOptions({
                      ...filterOptions,
                      ratings: newValue,
                    });
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={2}>
              {filterProductArray?.map((item, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                  <Card
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      height: "340px",
                      border: trendingCardhoverIndex === i ? "1px solid #D3D3D3" : "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      backgroundColor: "#F5F5F5",
                      position: "relative",
                      overflow: "visible",
                    }}
                    onClick={() => handleProductOnClick(item)}
                    onMouseEnter={() => handleTrendingCardHover(i)}
                    onMouseLeave={() => handleTrendingCardHover(null)}
                  >
                    <div className="py-5">
                      <div
                        style={{
                          transition: "transform 0.2s ease", // Add transition for smooth zoom
                          transform: trendingCardhoverIndex === i ? "scale(1.1)" : "scale(1)", // Zoom effect on hover
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={item?.imageUrl ? item?.imageUrl : require("../../assets/images/placeholder-image.png")}
                            style={{ height: "140px", width: "120px" }}
                          />
                          <div className="mt-3" style={{ textAlign: "center" }}>
                            <div style={{   fontSize: "14px",
                  fontWeight: 600,
                  marginTop: '6px',
                  fontFamily: 'Gabarito',
                  color: 'rgba(144, 140, 140, 1)',
                  lineHeight: '16.8px',
                  textTransform: 'uppercase', }}>
                              {item?.category?.categoryName}
                            </div>
                            <div className="mt-2" style={{  fontSize: '12px',
                  fontWeight: 600,
                  fontFamily: 'Gabarito',
                  color: catImgCardhoverIndex === i ? '#23c2f2' : '#000',
                  textDecoration: catImgCardhoverIndex === i ? 'underline' : 'none', }}>
                              {item?.productName}
                            </div>
                            <div className="mt-2 mb-1">
                              <StarRatingComponent
                                starRating={item?.overallRating}
                                size="medium"
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div style={{ fontSize: '22px', lineHeight: '26.4px', fontFamily: 'Gabarito', fontWeight: 600  }}>
                            ₹ {item?.price}
                          </div>
                        </div>
                      </div>
                    </div>
                    {item?.aaacertified && (
                      <div style={{ position: "absolute", top: -18, right: -12 }}>
                        <img
                          src={UWAssuredIcon}
                          alt="assured"
                          style={{ width: "85px", height: "35px" }}
                        />
                      </div>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <style>
        {`
        @media (min-width:600px){
          #filter_button{
            display:none !important;
          }
        }
          @media(max-width:600px){
            #desktop_filters{
            display:none !important;
            }
          }
        `}
      </style>
      {/* Filter Button for Mobile View */}
      <Button id="filter_button"
        variant="contained"
        color="primary"
        onClick={toggleFilters}
        style={{ display: 'block', marginTop: '1rem', width: '100%'}}
      >
        {showFilters ? 'Hide Filters' : 'Show Filters'}
      </Button>
      <div className="d-block d-sm-none mt-5" id="mobile_product_grid">
  <Grid container spacing={2}>
    {filterProductArray?.map((item, i) => (
      <Grid item xs={6} sm={4} md={3} key={i}>
        <Card
          style={{
            width: '100%',
            height: 'auto',
            border: catImgCardhoverIndex === i ? '1px solid #D3D3D3' : 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            backgroundColor: '#F5F5F5',
            position: 'relative',
            overflow: 'visible',
            padding: '1rem',
          }}
          onClick={() => handleProductOnClick(item)}
          onMouseEnter={() => setCatImgCardhoverIndex(i)}
          onMouseLeave={() => setCatImgCardhoverIndex(null)}
        >
          <div id="search_product_image"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              transition: 'transform 0.2s ease',
              transform: catImgCardhoverIndex === i ? 'scale(1.1)' : 'scale(1)',
            }}
          >
            <img
              src={item?.imageUrl ? item?.imageUrl : require('../../assets/images/placeholder-image.png')}
              style={{ height: '100px', width: '100px' }}
            />
            <div className="ms-3">
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 600,
                  fontFamily: 'Gabarito',
                  color: catImgCardhoverIndex === i ? '#23c2f2' : '#000',
                  textDecoration: catImgCardhoverIndex === i ? 'underline' : 'none',
                }}
                className="mt-3"
              >
                {item?.productName}
              </div>
              <div style={{ fontSize: '22px', lineHeight: '26.4px', fontFamily: 'Gabarito', fontWeight: 600  }} className="mt-2">
                ₹ {item?.price}
              </div>
              <div className="mt-2 mb-1">
                <StarRatingComponent starRating={item?.overallRating} size="small" readOnly={true} />
              </div>
            </div>
          </div>
          {item?.aaacertified && (
            <div style={{ position: 'absolute', top: -10, right: -10 }}>
              <img src={UWAssuredIcon} alt="assured" style={{ width: '70px', height: '30px' }} />
            </div>
          )}
        </Card>
      </Grid>
    ))}
  </Grid>
</div>
      <style>
        {`
        @media (min-width:600px){
          #filter_button{
            display:none !important;
          }

        }
          @media(max-width:600px){
            #desktop_filters{
            display:none !important;
            }
            // #based_on_search{
            // grid-template-columns: auto!important;
            // }
            #search_product_image{
              flex-direction: column!important;
            }
          }
        `}
      </style>

      {/* Mobile View Filter Sidebar */}
      {showFilters && (
        <div className="d-block d-sm-none" style={{ backgroundColor: "#ffffff", color: "#2B3445", borderRadius: "8px", border: "1px solid #DCDCDC", padding: '1.5rem', position: 'absolute', top: 50, right: 0, height: '100%', width: '80%', zIndex: 1000 }}>
          <Button onClick={toggleFilters} style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
            <CloseIcon />
          </Button>
          <div style={{ paddingLeft: "10px", paddingTop: "5px" }}>
            {nestedCategories?.map((item) => (
              <div className="pb-2 pt-2" key={item._id}>
                {item?.subCategories && item?.subCategories.length > 0 && (
                  <div style={{ fontSize: "18px", fontWeight: 400, fontFamily: 'Gabarito', textTransform:'uppercase', lineHeight:'21.6px', color: "#2B3445" }}>
                    {item?.categoryName}
                  </div>
                )}
                {item?.subCategories?.map((subItem) => (
                  <Stack direction={"column"} key={subItem._id}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Checkbox
                        size="small"
                        checked={checkedState[subItem?.subcategoryUuid] || false}
                        onChange={() => {
                          handleCheckBoxChange(subItem?.subcategoryUuid, subItem?.subcategoryName);
                          handleSubCategorySelection(item?._id, subItem?._id);
                        }}
                      />
                      <div style={{ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", color: "#000", fontWeight: 400, fontFamily: 'Gabarito' }}>
                        {subItem?.subcategoryName}
                      </div>
                    </Stack>
                    {subItem?.subcategoryTypes?.map((subTypeitem, i) => (
                      <Stack direction={"row"} alignItems={"center"} className="ps-4" key={subTypeitem._id}>
                        <Checkbox
                          size="small"
                          checked={checkedState[subTypeitem?._id] || false}
                          onChange={() => {
                            handleCheckBoxChange(subTypeitem?._id, subTypeitem?.typeName);
                            handleSubCatTypeSelection(subTypeitem?._id);
                          }}
                        />
                        <div style={{ fontSize: "12px", paddingTop: "5px", paddingBottom: "5px", cursor: "pointer", color: "#000", fontWeight: 400, fontFamily: 'Gabarito' }}>
                          {subTypeitem?.typeName}
                        </div>
                      </Stack>
                    ))}
                  </Stack>
                ))}
              </div>
            ))}
          </div>

          <div style={{ marginLeft: "20px" }}>
            <PriceFilter
              min={defaultPriceRange[0]}
              max={defaultPriceRange[1]}
              onRangeChange={(val) => {
                setFilterOptions({
                  ...filterOptions,
                  priceRange: {
                    start: val[0],
                    end: val[1],
                  },
                });
              }}
            />
          </div>
          <div style={{ marginLeft: "20px", marginTop: "10px" }}>
            <div style={{ fontFamily: "Gabarito", fontWeight: "bold", fontSize: "16px" }}>
              Sort by Ratings
            </div>
            <Rating
              value={filterOptions?.ratings}
              onChange={(event, newValue) => {
                setFilterOptions({
                  ...filterOptions,
                  ratings: newValue,
                });
              }}
            />
          </div>
        </div>
      )}

{/* <div className="mt-5">
        <div style={{ fontSize: "24px", fontWeight: 400, lineHeight:'28.8px', color: "#000" ,fontFamily:'Gabarito'}}>
            Relevant Products
        </div>
        <div
          className="mt-3" id='based_on_search'

          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "16px",
          }}
        >
          {filterProductArray?.map((item, i) => {
            return (
              <Card
                style={{
                  width: "100%",
                  height: "auto",
                  border:
                    catImgCardhoverIndex === i ? "1px solid #D3D3D3" : "none",
                  cursor: "pointer",
                  boxShadow: "none",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ padding: "1.5rem 3rem" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      transition: "transform 0.2s ease", // Add transition for smooth zoom
                      transform:
                        catImgCardhoverIndex === i ? "scale(1.1)" : "scale(1)", // Zoom effect on hover
                    }}
                  >
                    <img
                      src={
                        item?.imageUrl
                          ? item?.imageUrl
                          : require("../../assets/images/placeholder-image.png")
                      }
                      style={{ height: "100px", width: "100px" }}
                    />
                    <div className="ms-3">
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 600,
                          fontFamily:'Gabarito',
                          color:
                            catImgCardhoverIndex === i ? "#23c2f2" : "#000",
                          textDecoration:
                            catImgCardhoverIndex === i ? "underline" : "none",
                        }}
                        className="mt-3"
                      >
                        {item?.productName}
                      </div>
                      <div
                        style={{ fontSize: "14px", fontWeight: 600 ,fontFamily:'Gabarito'}}
                        className="mt-2"
                      >
                        ₹ {item?.price}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </div> */}
<style>
  {`
    .custom-swiper .swiper-button-next::after, .custom-swiper .swiper-button-prev::after {
    font-size: 10px ;
    font-weight: 600;
}
    .custom-swiper .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.custom-swiper .swiper-pagination-bullet-active {
  opacity: 1;
}
  .swiper-button-next:hover,
.swiper-button-prev:hover {
  /* color: #10cbe7 !important; */
  background-color:#2abdf0;
} 


.custom-swiper {
  position: relative;
}

.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev {
  color: #fff;
 
  padding: 15px;
  border-radius: 25px;
  left: auto;
  
}
  `}
</style>

<div className="mt-5">
      <div style={{ fontSize: "24px", fontWeight: 400, lineHeight: '28.8px', color: "#000", fontFamily: 'Gabarito' }}>
        Relevant Products
      </div>
      <div className="mt-3" id='based_on_search'>
        <Swiper
          navigation={true}
          modules={[Navigation, Pagination]}
          className="mySwiper custom-swiper"
          breakpoints={{
            360: { slidesPerView: 1, spaceBetween: 10 },
            576: { slidesPerView: 2, spaceBetween: 10 },
            768: { slidesPerView: 3, spaceBetween: 10 },
            992: { slidesPerView: 4, spaceBetween: 15 },
            1200: { slidesPerView: 5, spaceBetween: 20 },
          }}
          pagination={{ clickable: true }}
        >
          {filterProductArray?.map((item, i) => (
            <SwiperSlide key={i}>
              <Card
                style={{
                  width: "100%",
                  height: "auto",
                  border: catImgCardhoverIndex === i ? "1px solid #D3D3D3" : "none",
                  cursor: "pointer",
                  boxShadow: "none",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                onMouseEnter={() => setCatImgCardhoverIndex(i)}
                onMouseLeave={() => setCatImgCardhoverIndex(null)}
              >
                <div style={{ padding: "1rem 2rem" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "fcenter",
                      transition: "transform 0.2s ease",
                      transform: catImgCardhoverIndex === i ? "scale(1.1)" : "scale(1)",
                    }}
                  >
                    <img
                      src={item?.imageUrl ? item?.imageUrl : require("../../assets/images/placeholder-image.png")}
                      style={{ height: "100px", width: "100px" }}
                      alt={item?.productName}
                    />
                    <div className="ms-3">
                    <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                marginTop: '6px',
                                fontFamily: 'Gabarito',
                                color: 'rgba(144, 140, 140, 1)',
                                lineHeight: '16.8px',
                                textTransform: 'uppercase',
                              }}
                            >
                              {item?.category?.categoryName}
                            </div>
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                color: 'rgba(50, 50, 50, 1)',
                                lineHeight: '19.2px',
                               
                              }}
                              className="mt-3"
                            >
                              {item.productName}
                            </div>
                            <div className="mt-2 mb-1" style={{ display: 'flex', alignItems: 'center',flexWrap:'wrap', }}>
                              <StarRatingComponent
                                starCount={5} /* Replace with your StarRatingComponent props */
                                value={item.overallRating} /* Replace with your StarRatingComponent props */
                                size="medium"
                                readOnly={true}
                              />
                              {item.ratings && item.ratings.length > 0 && (
                                <div
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: 500,
                                    lineHeight: '16.8px',
                                    
                                  }}
                                >
                                  {item.ratings.length > 1 ? `( ${item.ratings.length} Ratings )` : `( ${item.ratings.length} Rating )`}
                                </div>
                              )}
                            </div>
                      <div
                        style={{ fontSize: '22px', lineHeight: '26.4px', fontFamily: 'Gabarito', fontWeight: 600  }}
                        className="mt-2"
                      >
                        ₹ {item?.price}
                      </div>
                    </div>
                  </div>
                </div>
              </Card> 
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>

    </div>
  );
};

export default memo(ProductList);
