import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import BannerCard from "../../Components/BannerCard";
import ProductCard from "../../Components/ProductCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import Product from "../../Components/Products";
import { useNavigate } from "react-router-dom";

const HomeList = ({ discountData, CategoriesList, SubCategoryList, ProductList, LatestProduct, FeaturedProduct }) => {
    const navigate = useNavigate();

    const [buttonActive, setButtonActive] = useState("");

    return (
        <Fragment>
            <Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
                    <div
                        style={{
                            backgroundImage: `url(https://cdn.dribbble.com/users/3906861/screenshots/11079483/02_4x.jpg`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            height: '96%',
                            width: '100%',
                            marginTop: '20px'
                        }}
                    />
                </Col>
                <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12}>
                    <BannerCard discountData={discountData} />
                </Col>
            </Row>
            <div className="deals_content mt-5 mb-1">
                <h5 className="cmn_subhead">Categories</h5>
                <p
                    className="common_link mb-0 cursor"
                    onClick={() => navigate("/products", { state: 'All' })}
                >
                    View All <i className="fa-solid fa-arrow-right ms-2"></i>
                </p>
            </div>
            <Row className="">
                <Swiper
                    loop={true}
                    modules={[Pagination]}
                    className="mySwiper"
                    breakpoints={{
                        360: {
                            slidesPerView: 1,
                            spaceBetween: 15,
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                        },
                        992: {
                            slidesPerView: 5,
                            spaceBetween: 15,
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 15,
                        },
                    }}
                >
                    {CategoriesList.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <div
                                    className="category_swiper"
                                    onClick={() =>
                                        navigate("/subcategory", { state: item.categoryName })
                                    }
                                >
                                    <img className="img-fluid slider_image" src={item.image} />
                                    <button>{item.categoryName}</button>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Row>

            <Row className="mt-5">
                <div className="deals_content">
                    <h5 className="cmn_subhead">Sub Categories</h5>
                    <p
                        className="common_link mb-0 cursor"
                        onClick={() => navigate("/subcategory", { state: "All" })}
                    >
                        More Product <i className="fa-solid fa-arrow-right ms-2"></i>
                    </p>
                </div>
                <Swiper
                    loop={true}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                        360: {
                            slidesPerView: 1,
                            spaceBetween: 15,
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                        992: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                        },
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 15,
                        },
                    }}
                >
                    {SubCategoryList.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <ProductCard data={item} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Row>

            <Row className="mt-5">
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        className="img-fluid promotion_details"
                        src={
                            "https://marketplace.canva.com/EAFYElY5EE4/1/0/1600w/canva-brown-and-white-modern-fashion-banner-landscape-Ap8IU9nEbh8.jpg"
                        }
                    />
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        className="img-fluid promotion_details"
                        src={
                            "https://static.vecteezy.com/system/resources/previews/000/357/905/original/classic-living-room-home-interior-design-banner-vector.jpg"
                        }
                    />
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <img
                        className="img-fluid promotion_details"
                        src={
                            "https://img.freepik.com/free-vector/healthy-food-banner-template_23-2148805673.jpg?w=2000"
                        }
                    />
                </Col>
            </Row>

            <Row className="mt-5 h-100">
                <Col lg={3} md={6} sm={12} xs={12}>
                    <div className="product_listing">
                        <h5 className="product_category mb-4">Fashion</h5>
                        <ul>
                            {SubCategoryList.filter(
                                (item) => item?.categories[0]?.categoryName === "Fashion"
                            ).map((items, i) => {
                                return <li key={i}>{items.subcategoryName}</li>;
                            })}
                        </ul>
                        <p
                            className="common_link mb-0 cursor"
                            onClick={() => navigate("/subcategory", { state: "Fashion" })}
                        >
                            Browse All <i className="fa-solid fa-arrow-right ms-2"></i>
                        </p>
                    </div>
                </Col>

                <Col xl={9} lg={9} md={6} sm={12} xs={12}>
                    <Swiper
                        loop={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                        }}
                    >
                        {SubCategoryList.filter(
                            (item) => item?.categories[0]?.categoryName === "Fashion"
                        ).map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <ProductCard data={item} product="product" />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <img
                        className="img-fluid promotion_details"
                        src={
                            "https://media.istockphoto.com/id/1290991842/vector/natural-food-banner-in-flat-style.jpg?s=612x612&w=0&k=20&c=zq9K5nHSpY0451oog0LBWvzTIeP_fqSAcvFCLG-WCpo="
                        }
                    />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <img
                        className="img-fluid promotion_details"
                        src={
                            "https://img.freepik.com/premium-photo/interior-design-concept-sale-home-decorations-furniture-during-promotions-discounts-it-is-surrounded-by-beds-sofas-armchairs-advertising-spaces-banner-pastel-background-3d-render_156429-3954.jpg"
                        }
                    />
                </Col>
            </Row>

            <Row className="mt-5 h-100">
                <Col lg={3} md={6} sm={12} xs={12}>
                    <div className="product_listing">
                        <h5 className="product_category mb-4">Home&Living</h5>
                        <ul>
                            {SubCategoryList.filter(
                                (item) => item?.categories[0]?.categoryName === "Home&Living"
                            ).map((items, i) => {
                                return <li key={i}>{items.subcategoryName}</li>;
                            })}
                        </ul>
                        <p
                            className="common_link mb-0 cursor"
                            onClick={() =>
                                navigate("/subcategory", { state: "Home&Living" })
                            }
                        >
                            Browse All <i className="fa-solid fa-arrow-right ms-2"></i>
                        </p>
                    </div>
                </Col>

                <Col xl={9} lg={9} md={6} sm={12} xs={12}>
                    <Swiper
                        loop={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                        }}
                    >
                        {SubCategoryList.filter(
                            (item) => item?.categories[0]?.categoryName === "Home&Living"
                        ).map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <ProductCard data={item} product="product" />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col sm={12} xs={12}>
                    <img
                        className="img-fluid promotion_details"
                        src={
                            "https://marketplace.canva.com/EAFYElY5EE4/1/0/1600w/canva-brown-and-white-modern-fashion-banner-landscape-Ap8IU9nEbh8.jpg"
                        }
                    />
                </Col>
            </Row>

            <Row className="mt-5 h-100">
                <Col lg={3} md={6} sm={12} xs={12}>
                    <div className="product_listing">
                        <h5 className="product_category mb-4">Nature Foods</h5>
                        <ul>
                            {SubCategoryList.filter(
                                (item) => item?.categories[0]?.categoryName === "Nature Foods"
                            ).map((items, i) => {
                                return <li key={i}>{items.subcategoryName}</li>;
                            })}
                        </ul>
                        <p
                            className="common_link mb-0 cursor"
                            onClick={() =>
                                navigate("/subcategory", { state: "Nature Foods" })
                            }
                        >
                            Browse All <i className="fa-solid fa-arrow-right ms-2"></i>
                        </p>
                    </div>
                </Col>

                <Col xl={9} lg={9} md={6} sm={12} xs={12}>
                    <Swiper
                        loop={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                        }}
                    >
                        {SubCategoryList.filter(
                            (item) => item?.categories[0]?.categoryName === "Nature Foods"
                        ).map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <ProductCard data={item} product="product" />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <h5 className="cmn_subhead">Trending</h5>
                    <p className="selected_hint">Products</p>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="category_selection">
                        <button
                            className={
                                buttonActive === "newarrivals"
                                    ? "selection_btn active me-2"
                                    : "selection_btn  me-2"
                            }
                            onClick={() => setButtonActive("newarrivals")}
                        >
                            New Arrivals
                        </button>
                        <button
                            className={
                                buttonActive === "bestseller"
                                    ? "selection_btn active me-2"
                                    : "selection_btn  me-2"
                            }
                            onClick={() => setButtonActive("bestseller")}
                        >
                            Best Seller
                        </button>
                        <button
                            className={
                                buttonActive === "mostpopular"
                                    ? "selection_btn active me-2"
                                    : "selection_btn  me-2"
                            }
                            onClick={() => setButtonActive("mostpopular")}
                        >
                            Most Popular
                        </button>
                        <button
                            className={
                                buttonActive === "viewall"
                                    ? "selection_btn active"
                                    : "selection_btn"
                            }
                            onClick={() => setButtonActive("viewall")}
                        >
                            View All
                        </button>
                    </div>
                </Col>
            </Row>

            <Row className="mt-5">
                <Swiper
                    loop={true}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                        360: {
                            slidesPerView: 1,
                            spaceBetween: 15,
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                        },
                    }}
                >
                    {ProductList.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <Product data={item} />
                            </SwiperSlide>
                        );
                    })}
                    {LatestProduct.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <Product data={item} />
                            </SwiperSlide>
                        );
                    })}
                    {FeaturedProduct.map((item, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <Product data={item} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Row>
        </Fragment>
    )
}

export default HomeList;