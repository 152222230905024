import { Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import assureIcon from '../../assets/icons/assure.svg';
import icon1Icon from '../../assets/icons/icon1.svg';
import authenticIcon from '../../assets/icons/authentic.svg';
import adulteration from '../../assets/icons/adulteration.svg';
import { useNavigate } from "react-router-dom";
import './index.css'

const Experience = () => {
    const navigate = useNavigate();
    const [serviceIndex, setServiceIndex] = useState(0);

    const tilesData = [
        {
            icon: authenticIcon,
            title: 'Authentic',
            subTitle: 'Our products are aunthentic'
        },
        {
            icon: assureIcon,
            title: 'Assured',
            subTitle: 'Our products are assured'
        },
        {
            icon: icon1Icon,
            title: 'Adulteration Free',
            subTitle: 'Our products are organic'
        },
        {
            icon: assureIcon,
            title: 'Assured',
            subTitle: 'Our products are assured'
        },
    ];

    const renderTileImages = () => {
        return tilesData.map(item => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={item.icon} style={{ width: '40px', height: '40px' }} />
                    <div style={{ marginLeft: '15px' }}>
                        <div style={{ fontSize: '15px', fontWeight: 600 }}>{item.title}</div>
                        <div style={{ fontSize: '12px', color: '#7D879C' }}>{item.subTitle}</div>
                    </div>
                </div>
            )
        });
    }

    const serviceData = [
        {
            label: 'Ecommerce'
        },
        {
            label: 'BrainBox'
        },
        {
            label: 'Farmers'
        },
        {
            label: 'Marketplace'
        },
        {
            label: 'Affiliation'
        },
        {
            label: 'Ticket'
        }
    ]

    const handleServiceClick = (index) => {
        setServiceIndex(index);
    };

    return (
        // <div className="container">
        //     <div className="mt-4">
        //         <img src={require('../../assets/banner/Frame-1.png')} style={{ width: '100%' }} />
        //     </div>
        //     <div style={{ backgroundColor: '#4A6379', color: '#fff', textAlign: 'center', padding: '50px 200px', fontSize: '1.2em' }} className="my-4">
        //         UrbanWallah is a Reinvented market place, pioneering an e-commerce application dedicated to fostering a vibrant marketplace.
        //     </div>
        //     <Grid container className="my-5">
        //         <Grid item xs={6}>
        //             <img src={require('../../assets/banner/blog1.jpg')} style={{ width: '100%' }} />
        //         </Grid>
        //         <Grid item xs={6} className="ps-3">
        //             <h5 style={{ fontWeight: 600 }}>
        //                 About Us
        //             </h5>
        //             <div>
        //                 We empower small-scale vendors, Bridging Communities, Fostering Talent, and Empowering Agriculture by providing them with the tools, resources,
        //                 and support they need to thrive in the digital marketplace.  Our platform serves as a trusted intermediary,
        //                 connecting urban consumers with local artisans, craftsmen, and producers, thus revitalizing the urban commerce landscape.
        //             </div>
        //         </Grid>
        //     </Grid>
        //     <Grid container className="mb-5">
        //         <Grid item xs={6} className="pe-3">
        //             <h5 style={{ fontWeight: 600 }}>
        //                 Our Services
        //             </h5>
        //             <div>
        //                 We aim to create a thriving ecosystem where authenticity, quality, and trust reign supreme,
        //                 fostering mutually beneficial relationships between vendors and consumers, Even ensuring the availability of Authentic,
        //                 Assured, and Adulteration-free products.
        //             </div>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <img src={require('../../assets/banner/blog2.jpg')} style={{ width: '100%' }} />
        //         </Grid>
        //     </Grid>
        //     <Grid container className="mb-5">
        //         <Grid item xs={6}>
        //             <img src={require('../../assets/banner/blog3.jpg')} style={{ width: '100%' }} />
        //         </Grid>
        //         <Grid item xs={6} className="ps-3">
        //             <h5 style={{ fontWeight: 600 }}>
        //                 Our Motto
        //             </h5>
        //             <div>
        //                 UrbanWallah introduces an innovative concept with Urban Tickets, offering consumers an opportunity to full fill their wishes.
        //                 By entering into exciting gift pools within the Urban Tickets section of the application, users stand a chance to win luxury gifts, tour packages, groceries, and more.
        //             </div>
        //         </Grid>
        //     </Grid>
        //     <div className="px-5 py-3 mt-3 mb-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#F9F8F8', borderRadius: '20px' }}>
        //         {renderTileImages()}
        //     </div>
        //     <div>
        //         <div class="row">
        //             <div class="col-xl-4 col-lg-4 col-md-6">
        //                 <div class="team__two-item">
        //                     <div class="team__two-item-image">
        //                         <img src={require('../../assets/banner/team-p1.jpeg')} style={{ width: '90%' }} alt="" />
        //                     </div>
        //                     <div class="team__two-item-content">
        //                         <h4>Urban Wallah</h4>
        //                         <span class="text-eight">CEO Founder</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="col-xl-4 col-lg-4 col-md-6">
        //                 <div class="team__two-item">
        //                     <div class="team__two-item-image">
        //                         <img src={require('../../assets/banner/team-p1.jpeg')} style={{ width: '90%' }} alt="" />
        //                     </div>
        //                     <div class="team__two-item-content">
        //                         <h4>Urban Wallah</h4>
        //                         <span class="text-eight">Sr. developer</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="col-xl-4 col-lg-4 col-md-6">
        //                 <div class="team__two-item">
        //                     <div class="team__two-item-image">
        //                         <img src={require('../../assets/banner/team-p1.jpeg')} style={{ width: '90%' }} alt="" />
        //                     </div>
        //                     <div class="team__two-item-content">
        //                         <h4>Urban Wallah</h4>
        //                         <span class="text-eight">Content Writer</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="col-xl-4 col-lg-4 col-md-6">
        //                 <div class="team__two-item">
        //                     <div class="team__two-item-image">
        //                         <img src={require('../../assets/banner/team-p1.jpeg')} style={{ width: '90%' }} alt="" />
        //                     </div>
        //                     <div class="team__two-item-content">
        //                         <h4>Urban Wallah</h4>
        //                         <span class="text-eight">Sr. Content Writer</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="col-xl-4 col-lg-4 col-md-6">
        //                 <div class="team__two-item">
        //                     <div class="team__two-item-image">
        //                         <img src={require('../../assets/banner/team-p1.jpeg')} style={{ width: '90%' }} alt="" />
        //                     </div>
        //                     <div class="team__two-item-content">
        //                         <h4>Urban Wallah</h4>
        //                         <span class="text-eight">Sr. Content Writer</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="col-xl-4 col-lg-4 col-md-6">
        //                 <div class="team__two-item">
        //                     <div class="team__two-item-image">
        //                         <img src={require('../../assets/banner/team-p1.jpeg')} style={{ width: '90%' }} alt="" />
        //                     </div>
        //                     <div class="team__two-item-content">
        //                         <h4>Urban Wallah</h4>
        //                         <span class="text-eight">Sr. Content Writer</span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div>
            <div style={{
                    backgroundColor: '#2ABDF0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2rem',   
                    textAlign: 'center'   
                }}>
                    <div style={{ cursor: 'pointer', marginBottom: '1rem' }} onClick={() => navigate("/")}>
                        <img src={require('../../assets/AppLogo/UW-Logo.png')} style={{ width: '180px', maxWidth: '100%' }} alt="Logo" />
                    </div>
                    <div style={{ color: '#000000', fontWeight: 500, fontSize: '14px' }}>
                        A Reinvented market platform
                    </div>
                </div>

            <div className="container">
                <div style={{ display: "flex", width: "100%",  flexWrap:'wrap', justifyContent:'space-around' }} className="mt-5">
                    <div style={{ width: "375px", borderRadius: "30px", border: "1px solid #2DBDEE", height: '318px',marginTop:30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }} >
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", position: "relative" }} >
                            <div style={{ width: "157px", height: "40px", borderRadius: "30px", border: "1px solid #2DBDEE", position: "relative", bottom: '22px', background: "#2DBDEE", alignItems: "center", display: "grid" }} >
                                <p style={{ textAlign: "center", margin: "0", fontSize: "17px", fontWeight: "500" }}>What</p>
                            </div>
                        </div>
                        <img src={require('../../assets/images/idea.png')} style={{ width: '136px', height: '136px' }} />
                        <div className="px-2">
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                textAlign: 'center'
                            }} >
                                We empower small-scale vendors, Bridging Communities, Fostering Talent, and Empowering Agriculture
                                by providing them with the tools, resources, and support they need to thrive in the digital marketplace.
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "375px", borderRadius: "30px", border: "1px solid #2DBDEE", height: '318px', display: 'flex',marginTop:30, flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }} >
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", position: "relative" }} >
                            <div style={{ width: "157px", height: "40px", borderRadius: "30px", border: "1px solid #2DBDEE", position: "relative", bottom: 22, background: "#2DBDEE", alignItems: "center", display: "grid" }} >
                                <p style={{ textAlign: "center", margin: "0", fontSize: "17px", fontWeight: "500" }}>Why</p>
                            </div>
                        </div>
                        <img src={require('../../assets/images/target.png')} style={{ width: '136px', height: '136px' }} />
                        <div className="px-2">
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                textAlign: 'center'
                            }} >
                                We aim to create a thriving ecosystem where authenticity, quality, and trust reign supreme,
                                fostering mutually beneficial relationships between vendors and consumers, Even ensuring the availability of Authentic,
                                Assured, and Adulteration-free products.
                            </p>
                        </div>
                    </div>
                    <div style={{ width: "375px", borderRadius: "30px", border: "1px solid #2DBDEE", height: '318px', display: 'flex', flexDirection: 'column', marginTop:30,alignItems: 'center', justifyContent: 'space-evenly' }} >
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", position: "relative" }} >
                            <div style={{ width: "157px", height: "40px", borderRadius: "30px", border: "1px solid #2DBDEE", position: "absolute", bottom: 12, background: "#2DBDEE", alignItems: "center", display: "grid" }} >
                                <p style={{ textAlign: "center", margin: "0", fontSize: "17px", fontWeight: "500" }}>How</p>
                            </div>
                        </div>
                        <img src={require('../../assets/images/search.png')} style={{ width: '80px', height: '80px' }} />
                        <div className="px-2">
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                textAlign: 'center'
                            }} >
                                Our platform serves as a trusted intermediary, connecting urban consumers with local artisans, craftsmen, and producers,
                                thus revitalizing the urban commerce landscape.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <div style={{ display: "flex", justifyContent: "center", width: "100%" }} >
                        <div style={{ textAlign: "center", fontSize: '20px', fontWeight: 600 }} >All our goods and services are</div>
                    </div>
                    <div className="mt-3" style={{ display: "flex", justifyContent: "center", width: "100%" }} >
                        <div style={{ width: "1004px", height: "162px", background: "#F9F9F9", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <div style={{ display: "flex", width:'100%',flexWrap:'wrap',justifyContent:'space-around' }} >
                                <div style={{ textAlign: 'center' }}>
                                    <img src={authenticIcon} style={{ width: '50px', height: '60px' }} />
                                    <p style={{ fontWeight: 500, fontSize: '16px' }}>Authentic</p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={adulteration} style={{ width: '50px', height: '60px' }} />
                                    <p style={{ fontWeight: 500, fontSize: '16px' }}>Adulteration Free</p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={assureIcon} style={{ width: '50px', height: '60px' }} />
                                    <p style={{ fontWeight: 500, fontSize: '16px' }}>Assured</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <div style={{ fontSize: "24px", fontWeight: 500 }} className="mb-3">Our Services</div>
                    <Stack id="our_services_divs" direction={'row'} alignItems={'center'} justifyContent={'space-between'} 
                     sx={{
                        
                        '@media (max-width: 600px)': {
                          overflow:'scroll',overflowX:'scroll',overflowY:'hidden',gap:'25px'
                        },
                      }}>
                        {serviceData?.map((item, index) => {
                            return (
                                <div 
                                    style={{ 
                                        width: "157px", 
                                        height: "40px", 
                                        background: serviceIndex === index ? "#2DBDEE" : '#fff', 
                                        borderRadius: "20px", 
                                        alignItems: "center", 
                                        display: "flex", 
                                        justifyContent: 'center', 
                                        cursor:'pointer',
                                        alignItems: 'center',
                                        border: serviceIndex === index ? 'none' : '1px solid #2DBDEE'
                                    }} 
                                    onClick={() => handleServiceClick(index)}
                                >
                                    <div style={{ margin: "0", fontSize: "16px", fontWeight: 'bold' }} >{item.label}</div>
                                </div>
                            )
                        })}
                    </Stack>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "4rem" }} >
                    <div id="urban_tickets" style={{ display: "flex",width:'100%',justifyContent:'space-between'}} >
                        <div id="urban_ticket_pic" style={{width:'39%'}}>
                            <img width="100%" height="100%" src={require('../../assets/images/service.png')} />
                        </div>
                        <div id="urban_ticket_text" style={{ width: "59%", border: "1px solid rgba(144, 140, 140, 0.25)", padding: "3rem", borderRadius: "37px" }} >
                            <div style={{ fontSize: "16px", fontWeight: 400, textAlign: "justify", marginTop: "0" }} >
                                UrbanWallah introduces an innovative concept with Urban Tickets, offering consumers an opportunity to full fill
                                their wishes. By entering into exciting gift pools within the Urban Tickets section of the application, users
                                stand a chance to win luxury gifts, tour packages, groceries, and more.
                            </div>
                            <div className="mt-4" style={{ textAlign: "justify", fontSize: "16px", fontWeight: "400", color: "#2DBDEE" }}>Read More</div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <div style={{ marginBottom: "1rem", fontSize: "24px", fontWeight: 500 }} >Meet our team</div>
                    <div style={{ fontSize: "14px", color: '#908C8C' }}>A small description about the team behind UrbanWallah and their leadership qualities</div>
                </div>
                <div className="mt-5" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',flexWrap:'wrap' }}>
                    {[0, 1, 2].map(item => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ width: '180px', height: '180px', borderRadius: '200px', backgroundColor: '#2DBDEE' }} />
                                <div className="mt-3" style={{ textAlign: 'center' }}>
                                    <div style={{ fontSize: '14px' }}>
                                        FirstName LastName
                                    </div>
                                    <div className="mt-2" style={{ fontSize: '14px', color: '#908C8C' }}>
                                        Designation
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Experience