import React, { useEffect } from 'react';
import SecurityIcon from '@mui/icons-material/Security';

const RefundPolicy = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className='container mt-5'>
            <div>
                <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '10px' }}>
                    Refund Policy
                </div>
                <>
                    <p className="p1">1. Introduction</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p3">
                        1.1. This Refund Policy governs the process and conditions for requesting
                        and obtaining refunds for products
                        <span className="Apple-converted-space">&nbsp; </span>purchased from Bharat
                        UrbanWallah Pvt Ltd ("we", "our", or "us") through our website{" "}
                        <span className="s1">www.urbanwallah.com</span>
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        1.2. By purchasing Products from us, you agree to be bound by this Refund
                        Policy. If you do not agree with any part of this Refund Policy, do not
                        purchase Products from us.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">2. Eligibility for Refunds</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        2.1. You may be eligible for a refund if you meet one or more of the
                        following criteria:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        defective or damaged upon receipt.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The wrong
                        Product was delivered.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product
                        received does not match the description on our Website.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- You are not
                        satisfied with the quality or performance of the Product (subject to the
                        conditions outlined in Section 3).
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">2.2. To be eligible for a refund, you must:</p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Notify us of
                        your request for a refund within [number] days of receiving the Product.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Return the
                        Product to us in its original condition and packaging, along with proof of
                        purchase.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">3. Non-Refundable Items</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">3.1. The following items are not eligible for refunds:</p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Products that
                        have been used, altered, or damaged after receipt.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Products that
                        are not in their original condition or packaging.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Products that
                        are personalized or made-to-order.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Digital
                        products, such as downloadable software or electronic gift cards.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Perishable
                        goods, such as food or flowers.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">4. Refund Process</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.1. To request a refund, please contact us at [Your Contact Information]
                        and provide the following information:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Order number
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Reason for the
                        refund request
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Details of the
                        Product to be returned
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.2. Once your refund request is received and approved, we will provide you
                        with instructions for returning the Product to us.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.3. You are responsible for the cost of return shipping, unless the return
                        is due to our error or the Product is defective or damaged upon receipt.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        4.4. Refunds will be processed within [number] days of receiving the
                        returned Product. The refund will be issued to the original payment method
                        used for the purchase, unless otherwise agreed upon.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">5. Partial Refunds</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        5.1. In some cases, we may offer partial refunds or store credit as an
                        alternative to a full refund.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">5.2. Partial refunds may be offered in situations where:</p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- Only part of
                        the Product is returned.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product
                        has been used or damaged after receipt.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned without its original packaging or accessories.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">6. Refund Exceptions</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        6.1. We reserve the right to refuse refunds in the following situations:
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned after the specified return period has expired.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        not returned in its original condition or packaging.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned without proof of purchase.
                    </p>
                    <p className="p1">
                        <span className="Apple-converted-space">&nbsp;&nbsp; </span>- The Product is
                        returned without prior authorization from us.
                    </p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">7. Contact Us</p>
                    <p className="p2">&nbsp;</p>
                    <p className="p1">
                        7.1. If you have any questions or concerns about our Refund Policy, please
                        contact us at info@urbanwallah.com
                    </p>
                </>

            </div>
        </div>
    )
}

export default RefundPolicy;