import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function PriceFilter({ min, max, onRangeChange }) {
  const [value, setValue] = React.useState([min, max]);

  React.useEffect(() => {
    setValue([min, max]);
  }, [min, max]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onRangeChange(newValue);
  };

  return (
    <Box sx={{ maxWidth: 200 }}>
      <div
        style={{
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontFamily: "Gabarito",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Select Price Range
        </span>
        <br></br>

        <span
          style={{
            fontFamily: "Gabarito",
            fontWeight: "regular",
            fontSize: "14px",
          }}
        >
          Current Range: ₹{value[0]} - ₹{value[1]}
        </span>
      </div>
      <Slider
        style={{
          color: "#2ABDF0",
        }}
        getAriaLabel={() => "Price range"}
        value={value}
        min={min}
        max={max}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </Box>
  );
}
