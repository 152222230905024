import { Avatar, Card, Divider, Grid, InputBase, Menu, MenuItem, Paper, Rating, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getMethod } from '../../helpers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '../../assets/icons/Categories.svg';
import CreateIcon from '@mui/icons-material/Create';
import SearchComponent from '../../Components/SearchComponent';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LocationPortal from '../../assets/icons/locationPortal.svg';
import CreatePost from '../../assets/icons/createPost.svg';
import StarIcon from '@mui/icons-material/Star';
import PlaceHolderImage from '../../assets/images/placeholderImage1.svg';
import Item from 'antd/es/list/Item';
import "../../css/fonts.css"


const LandingHubScreen = ({screenName}) => {
    const [trendingCardhoverIndex, setTrendingCardhoverIndex] = useState(null);
    const [myPostList, setMyPostList] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [postCategories, setPostCategories] = useState([]);
    const [categoryIndex, setCatgoryIndex] = useState(0);
    const [position, setPosition] = useState({ latitude: null, longitude: null });


    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [displayCount, setDisplayCount] = useState(10); // Or whatever initial count you want

    const navigate = useNavigate();
    const loginData = useSelector(state => state.authState.loginData);
    const isHubUser = useSelector(state => state.authState.isHubUser);
    const [ProductList, setProductList] = useState([]);
    const handleLoadMore = () => {
        setDisplayCount(prevCount => prevCount + 10); // Increase by 10 or whatever number you prefer
    };

    // Load user data from localStorage
    const json = localStorage.getItem("@USER");
    const User = useMemo(() => JSON.parse(json), [json]);

    const isFromMarketPlaceScreen = useMemo(() => {
        return screenName === 'market-place';
    }, [screenName])

    const handleCardClick = (item) => {
        console.log('Navigating to:', `/hub/${screenName}/hub-details`);
        navigate(`/hub/${screenName}/hub-details`, { state: { isFromMyPost: true, data: item } });
    };
    


 
    useEffect(() => {
        makeHubCategories();
    }, [screenName]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    const getHubTypes = () => {
        let type = ''
        if (screenName === 'farmer') {
            type = 'Farmer'
        } else if (screenName === 'student') {
            type = 'Student'
        } else {
            type = 'MarketPlace'
        }
        return type;
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting geolocation:', error);
                }
            );
        } else {
            console.error('Geolocation not supported by this browser.');
        }
    }, []);
    
    const [categoryMap, setCategoryMap] = useState({});

    const makeHubCategories = async () => {
        try {
            const url = `customers/posts/categories/${getHubTypes()}`;
            const token = localStorage.getItem("@token");
            const response = await getMethod({ url, token });
            console.log('API Response:', response.data);
    
            if (response?.data && response?.data?.length > 0) {
                const categoryMapObj = {};
                response.data.forEach(category => {
                    categoryMapObj[category._id] = category.title;
                    if (category.children) {
                        category.children.forEach(subCategory => {
                            categoryMapObj[subCategory._id] = subCategory.title;
                            if (subCategory.children) {
                                subCategory.children.forEach(subSubCategory => {
                                    categoryMapObj[subSubCategory._id] = subSubCategory.title;
                                });
                            }
                        });
                    }
                });
                setCategoryMap(categoryMapObj);
                setPostCategories(response.data);
            } else {
                console.log("No categories found.");
            }
        } catch (e) {
            console.error(e);
        }
    };
    
    
    useEffect(() => {
        fetchAllPosts();
    }, []);

    const fetchAllPosts = async () => {
        try {
            const url = `customers/posts/all/${getHubTypes()}`;
            const token = localStorage.getItem("@token");
            const response = await getMethod({ url, token });
    
            console.log('API Response:', response.data);
    
            if (response.success) {
                setMyPostList(response.data);
            } else {
                console.log("Error in fetching posts.");
            }
        } catch (e) {
            console.error(e);
        }
    };

    // const fetchCategoryAndLocationDetails = async (posts) => {
    //     const token = localStorage.getItem("@token");
    //     const updatedPosts = await Promise.all(posts.map(async (post) => {
    //         try {
    //             const url = `customers/posts/all/${post.hubType}/${post._id}`;
    //             const response = await getMethod({ url, token });
    //             if (response.success && response.data.length > 0) {
    //                 // Assuming the first item in the response is the matching post
    //                 const detailedPost = response.data[0];
    //                 return {
    //                     ...post,
    //                     category: detailedPost.category,
    //                     city: detailedPost.city // Adjust this if the field name is different
    //                 };
    //             }
    //         } catch (e) {
    //             console.log(`Error fetching details for post ${post._id}:`, e);
    //         }
    //         return post; // Return original post if fetch fails
    //     }));
    
    //     setMyPostList(updatedPosts);
    // }
    
    const handleTrendingCardHover = (index) => {
        setTrendingCardhoverIndex(index);
    };

    const getLoginBoxColor = () => {
        let color = '';
        if (screenName === 'farmer') {
            color = '#C7F8E4'
        } else if (screenName === 'student') {
            color = '#FCF7DC'
        } else if (screenName === 'market-place') {
            color = '#EAD9FF'
        }
        return color;
    }

    const getLoginBoxButtonColor = () => {
        let color = '';
        if (screenName === 'farmer') {
            color = '#20E492'
        } else if (screenName === 'student') {
            color = '#F2DF75'
        } else if (screenName === 'market-place') {
            color = '#AB68FE'
        }
        return color;
    }

    const getImageName = () => {
        let img = '';
        if (screenName === 'farmer') {
            img = 'farmerPortal.png'
        } else if (screenName === 'student') {
            img = 'studentPortal.png'
        } else if (screenName === 'market-place') {
            img = 'market-place.png'
        }
        return img;
    }

    const getTitle = () => {
        let title = '';
        if (screenName === 'farmer') {
            title = 'AgriSure - An agri portal by UrbanWallah'
        } else if (screenName === 'student') {
            title = 'BrainBox - A creative portal by UrbanWallah'
        } else if (screenName === 'market-place') {
            title = 'XBO - A marketplace by UrbanWallah'
        }
        return title;
    }

    // const getProductList = async () => {
    //     try {
    //         let url = "products/list_products";
    //         let response = await getMethod({ url });
    //         if (response.success) {
    //             setProductList(response.data);
    //         } else {
    //             console.log("err");
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };
    const handleRegister = () => {
        // After successful registration
        localStorage.setItem("@USER", JSON.stringify({ ...User, hubUser: { ...User.hubUser, [getHubTypes()]: true } }));
                navigate('/hub');
    };
    

    const renderAuthButtons = () => {
    if (!User) {
        // User is not logged in
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '16px' }} className='my-2'>
                <button
                    onClick={() => navigate('/login')}
                    style={{ 
                        width: '100%', 
                        height: '40px', 
                        border: 'none', 
                        backgroundColor: getLoginBoxButtonColor(), 
                        fontSize: '14px', 
                        borderRadius: '4px' 
                    }}
                >
                    Login
                </button>
            </div>
        )
    } else if (User && (!User.hubUser || !User.hubUser[getHubTypes()])) {
        // User is logged in but not registered for this hub
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '16px' }} className='my-2'>
                <button
                    onClick={handleRegister}
                    style={{ 
                        width: '100%', 
                        height: '40px', 
                        border: 'none', 
                        backgroundColor: getLoginBoxButtonColor(), 
                        fontSize: '14px', 
                        borderRadius: '4px' 
                    }}
                >
                    Register
                </button>
            </div>
        )
    } else {
        // User is logged in and registered for this hub
        return (
            <Stack direction='row' alignItems='center' spacing={2}>
                <img src={CreatePost} style={{ width: '25px', height: '25px' }} alt="Create Post" />
                <Typography
                    component="div"
                    onClick={() => navigate(`/hub/${screenName}/create-post`)}
                    style={{ fontSize: '16px', color: '#000000', cursor: 'pointer', fontWeight: 400 }}
                >
                    Create Post
                </Typography>
            </Stack>
        )
    }
}
    // In the Grid item where the auth/create post button is rendered:
    <Grid item xs={12} sm={4} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '150px' }}>
        {renderAuthButtons()}
    </Grid>



    const getGridSize = () => {
        const postCatLength = postCategories?.length
        if (postCatLength >= 2) {
            return { xs: 12, sm: 6, md: 4 };
        } else if (postCatLength >= 3) {
            return { xs: 12, sm: 6, md: 5 };
        } else if (postCatLength >= 4) {
            return { xs: 12, sm: 6, md: 6 };
        } else if (postCatLength >= 5) {
            return { xs: 12, sm: 6, md: 7 };
        } else {
            return { xs: 12, sm: 6, md: 8 };
        }
    };

    const handlePostCategory = (item) => {
        const filteredData = postCategories?.filter(data => data?._id === item?._id)?.[0];
        if (filteredData?.children && filteredData?.children?.length > 0) {
            return filteredData?.children;
            
        };
    };

   
    const handleMouseHover = (i) => {
        setCatgoryIndex(i)
        
    };

    const handleCategoryClick = async (item) => {
        try {
            let url = `/customers/posts/all/${getHubTypes()}/${item?._id}`
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log('API Response:', response);
            if (response?.success && response?.data && response?.data?.length > 0) {
                setMyPostList(response.data);
                setCatgoryIndex(null);
            } else {
                console.log("No posts found for this category");
                setMyPostList([]); // Clear the list if no posts are found
            }
        } catch (e) {
            console.error("Error fetching category posts:", e);
            setMyPostList([]); // Clear the list on error
        }
    };
    return (
        <div style={{ backgroundColor: '#FFFFFF' }}>
            <Divider sx={{ borderBottom: '1px solid #000', marginBottom: '0' }} />
            <div style={{ margin: '16px 16px' }}>
                <Grid container alignItems="center" spacing={2}>
                    {/* Location on the left */}
                    <Grid item xs={12} sm={4} md={3} style={{ display: 'flex', alignItems: 'center', paddingLeft: '18px', marginTop:'4px' }}>
                        <Stack direction='row' alignItems='center' spacing={2} style={{ margin: 'auto' }}>
                            <img src={LocationPortal} style={{ width: '25px', height: '25px' }} alt="Location" />
                            <div style={{ fontSize: '16px', color: '#323232' }}>Coimbatore</div>
                        </Stack>
                    </Grid>
                    
                    {/* Center content */}
                    <Grid item xs={12} sm={4} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            backgroundColor: '#EDEDED',
                            borderRadius: '50px',
                            padding: '8px'
                        }}>
                            {postCategories?.map((item, i) => (
                                <div
                                    key={i}
                                    onClick={() => handleCategoryClick(item)}
                                    style={{
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '8px 12px',
                                        margin: '4px',
                                        borderRadius: '50px',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#EDEDED'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#EDEDED'}
                                >
                                    {item.title}
                                </div>
                            ))}
                        </div>
                    </Grid>

                    
                    {/* Create Post on the right */}
                    <Grid item xs={12} sm={4} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '150px' }}>
                        {User?.hubUser?.[getHubTypes()]
                            ? <Stack direction='row' alignItems='center' spacing={2}>
                                <img src={CreatePost} style={{ width: '25px', height: '25px' }} alt="Create Post" />
                                <Typography
                                    component="div"
                                    onClick={() => navigate(`/hub/${screenName}/create-post`)}
                                    style={{ fontSize: '16px', color: '#000000', cursor: 'pointer', fontWeight: 400 }}
                                >
                                    Create Post
                                </Typography>
                            </Stack>
                            : renderAuthButtons()
                        }
                    </Grid>
                </Grid>
            </div>
            <div style={{ margin: '16px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <div style={{ margin: '12px' }}>
                    <img src={require(`../../assets/banner/${getImageName()}`)} style={{ width: '150px' }} alt="Banner" />
                </div>
                <div style={{ marginBottom: '12px', fontSize: '20px', fontWeight: 500 }}>
                    {getTitle()}
                </div>
            </div>
            <div style={{ margin: '16px 16px' }}>
                <div style={{ fontWeight: '600',fontSize:'24px', marginBottom: '16px',lineHeight:'28.8px' , marginLeft: '20px' }}>Trending now</div>
                
                {myPostList && myPostList.length > 0 ? (
    <>
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            justifyContent: 'start',
            padding: '20px',
        }}>
            {myPostList.slice(0, displayCount).map((item, i) => (
                <Card
                    key={i}
                    style={{
                        width: '235px',
                        height: '301px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px 0px 0px 0px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        transition: 'transform 0.2s ease',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                    onClick={() => handleCardClick(item)}
                    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
                    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                    <div style={{
                        width: '127px',
                        height: '141px',
                        position: 'absolute',
                        top: '20px',
                        left: '54px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        borderRadius: '10px 0px 0px 0px',
                    }}>
                        <img
                            src={item?.image?.[0] ? item.image[0] : PlaceHolderImage}
                            alt={item?.title}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    <div style={{
                        padding: '16px',
                        textAlign: 'left',
                        marginTop: '161px', // Push content below the image
                    }}>
                        <div style={{
                            fontSize: '12px',
                            color: '#908C8C',
                            fontWeight: 600,
                            marginBottom: '4px',
                            textTransform: 'uppercase',
                        }}>
                            {categoryMap[item.category?._id] || 
                            categoryMap[item.subcategory?._id] || 
                            categoryMap[item.subsubcategory?._id] || 
                            'N/A'}
                        </div>
                        <div style={{
                            fontSize: '18px',
                            fontWeight: 700,
                            color: '#323232',
                            marginBottom: '8px',
                        }}>
                            {item?.title}
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '8px',
                        }}>
                            {item?.price && item?.discountPrice && item?.price !== item?.discountPrice && (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        color: 'gray',
                                        marginRight: '8px',
                                        textDecoration: 'line-through',
                                    }}>
                                        ₹ {item?.price}
                                    </div>
                                    <div style={{
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        color: '#323232',
                                    }}>
                                        ₹ {item?.discountPrice}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{
                            fontSize: '12px',
                            color: '#666',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <LocationOnIcon style={{ width:'18', height:'22', fontSize: '16px', marginRight: '4px', color: '#00b5ff' }} />
                            {item?.location?.address?.city || item?.location?.city || 'N/A'}
                        </div>
                    </div>
                </Card>
            ))}
        </div>

        {myPostList.length > displayCount && (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
            }}>
                <button
                    onClick={handleLoadMore}
                    style={{
                        backgroundColor: '#f0f0f0',
                        border: '1px solid #000000',
                        padding: '10px 20px',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#333',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s'
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                >
                    Load More
                </button>
            </div>
        )}
    </>
) : (
    <div style={{
        fontSize: '18px',
        fontWeight: 600,
        display: 'flex',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        No Data Found
    </div>
)}

            </div>
        </div>
    );
};

export default LandingHubScreen;