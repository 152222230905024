import React from 'react';
import SideMenuBar from '../../Components/SideMenuBar';
import WishlistCard from './WishlistCard';

const ProductWishlist = () => {
    return (
        <SideMenuBar>
            <WishlistCard isFromProfile={true} />
        </SideMenuBar>
    )
};

export default ProductWishlist;