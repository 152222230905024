import { Box, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import toast from 'react-hot-toast';
import { getMethod, postMethod } from '../../helpers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '2rem',
    borderRadius: '10px'
};

const useStyles = makeStyles((theme) => ({
    // Define your custom styles here
}));

const CreateNewCouponsForm = ({ openModal, modalOnClose }) => {
    const [hoverButton, setHoverButton] = useState(false);
    const [value, setValue] = useState('unique');
    const [showButton, setShowButton] = useState(true);
    const [formData, setFormData] = useState({
        couponName: '',
        noOfCoupon: '',
        price: '',
        expiryDate: '',
        couponCode: '',
        couponPrefix: ''
    });

    const dispatch = useDispatch();
    const classes = useStyles();

    const [couponPrices, setCouponPrices] = useState([]);

    const initialCouponPriceId = useMemo(() => {
        return couponPrices && couponPrices.length > 0 && couponPrices[0]._id;
    }, [couponPrices]);

    useEffect(() => {
        getCouponPriceListApi()
    }, []);

    const getCouponPriceListApi = async () => {
        try {
            let url = "common/couponprice";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log(response)
            if (response.success) {
                setCouponPrices(response.data)
                
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const [selectedCouponPrice, setSelectedCouponPrice] = useState(initialCouponPriceId);
    
    const handleCouponPriceChange = (e) => {
        setSelectedCouponPrice(e.target && e?.target?.value ? e.target.value : '');
    }

    const handleOnChange = (e, name) => {
        setFormData({ ...formData, [name]: e.target.value });
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    }

    const onSubmit = async () => {

        let payload = {
            couponName: formData.couponName,
            noOfCoupons: formData.noOfCoupon,
            couponFor: "OnlineAffiliate",
            priceOption: selectedCouponPrice,
            expiryOn: formData.expiryDate,
            couponType: value,
            couponPrefix: formData.couponPrefix,
            couponCode: formData.couponCode
        };
        setShowButton(false);
        try {
            let url = `customers/secure/coupon/poolcoupon`;
            const token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload });
            if (response.success) {
                toast.success(response.message);
                setShowButton(true);
                modalOnClose();
            } else {
                toast.error(response.message);
                setShowButton(true);
            }
        } catch (e) {
            toast.error(e.response.message);
            setShowButton(true);
        }
    }

    const handleDateOnChange = (date) => {
        setFormData({
            ...formData,
            expiryDate: dayjs(date.$d).format('YYYY-MM-DD')
        });
    }

    return (
        <Modal
            open={openModal}
            onClose={modalOnClose}
        >
            <Box sx={style}>
            <div style={{ maxHeight: '80vh', overflowY: 'auto', padding: '20px', scrollbarWidth: 'none' }}>
                <div style={{ fontSize: '20px', color: '#23c2f2', fontWeight: 700 }}>
                    Add New Coupon
                </div>
                <div className='my-4'>
                    <TextField
                        label="Coupon Name"
                        variant="outlined"
                        name='couponName'
                        fullWidth
                        className='mb-4'
                        value={formData.couponName}
                        onChange={(e) => handleOnChange(e, 'couponName')}
                    />
                    <TextField
                        label="No Of Coupon"
                        variant="outlined"
                        name='noOfCoupon'
                        fullWidth
                        className='mb-4'
                        value={formData.noOfCoupon}
                        onChange={(e) => handleOnChange(e, 'noOfCoupon')}
                    />
                    <FormControl fullWidth>
                            <InputLabel>Select Coupon Price</InputLabel>
                            <Select
                                value={selectedCouponPrice}
                                name="selectedCouponPrice"
                                label="Select Coupon Price"
                                onChange={handleCouponPriceChange}
                                className={`${classes.customSelect} mb-4`}
                            >
                                {couponPrices?.map((item, index) => {
                                    return <MenuItem key={`coupon_price_list_${index}`} value={item._id}>{item.priceName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="Expiry Date" className='mb-4' onChange={handleDateOnChange} value={formData.expiryDate} name='expiryDate' />
                        </DemoContainer>
                    </LocalizationProvider>
                    
                    <FormControl>
                        <FormLabel style={{ fontWeight: 600, color: '#23c2f2', fontSize: '18px', marginBottom: '10px' }}>Coupon Types</FormLabel>
                        <RadioGroup
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="unique" control={<Radio />} label="Unique" />
                            <FormControlLabel value="generic" control={<Radio />} label="Generic" />
                        </RadioGroup>
                    </FormControl>
                    {value === 'Generic' && <TextField
                        label="Enter Coupon Code"
                        variant="outlined"
                        name='couponCode'
                        fullWidth
                        className='my-3'
                        value={formData.couponCode}
                        onChange={(e) => handleOnChange(e, 'couponCode')}
                    />}
                    <TextField
                        label="Coupon Prefix"
                        variant="outlined"
                        name='prefix'
                        fullWidth
                        className='my-3'
                        value={formData.couponPrefix}
                        onChange={(e) => handleOnChange(e, 'couponPrefix')}
                    />
                    <div className='text-center'>
                        <button
                            className="btn py-2 mt-4"
                            style={{
                                backgroundColor: hoverButton ? '#23c2f2' : '#fff',
                                color: hoverButton ? '#fff' : '#23c2f2',
                                border: '1px solid #23c2f2',
                                borderRadius: '4px',
                                width: 300,
                                fontWeight: 600
                            }}
                            disabled={!showButton}
                            onMouseEnter={() => setHoverButton(true)}
                            onMouseLeave={() => setHoverButton(false)}
                            onClick={onSubmit}
                        >
                            Add Coupon
                        </button>
                    </div>
                </div>
                </div>
            </Box>
        </Modal>
    )
}

export default CreateNewCouponsForm;