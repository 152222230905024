import React, { useEffect, useMemo, useState } from 'react';
import SideMenuBar from '../../Components/SideMenuBar';
import NewHeader from '../../Components/NewHeader';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, CardHeader, Divider, Grid, Modal, Rating } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getMethod, postMethod } from '../../helpers';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarIcon from '@mui/icons-material/Star';
import { Input } from 'antd';
import toast, { Toaster } from "react-hot-toast";
import ComplaintRaiseModal from '../SupportTicket/ComplaintModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const { TextArea } = Input;

const labels = {
    1: 'Useless',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
};

const ratingLabelColor = {
    0.5: '#f44336',
    1: '#f44336',
    1.5: '#f44336',
    2: '#f44336',
    2.5: '#faaf00',
    3: '#faaf00',
    3.5: '#faaf00',
    4: '#66bb6a',
    4.5: '#66bb6a',
    5: '#66bb6a',
}

const OrderDetails = () => {
    const route = useParams();
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState([]);
    const [openReview, setOpenReview] = useState(false);
    const [complaintModalVisible, setComplaintModalVisible] = useState(false);
    const [selectedOrderItem, setSelectedOrderItem] = useState({});
    const [ratingValue, setRatingValue] = useState(0);
    const [hover, setHover] = useState(-1);
    const [reviewDescription, setReviewDescription] = useState('');

    const chargesDataArray = useMemo(() => {
        if (orderDetails && orderDetails?.charges && orderDetails?.charges?.length > 0) {
            return [
                {
                    chargeLabel: 'Subtotal',
                    value: orderDetails?.subTotal
                },
                ...orderDetails?.charges,
                {
                    chargeLabel: 'Discount',
                    value: 0
                },
                {
                    chargeLabel: 'Total',
                    value: orderDetails?.total
                }
            ]
        }
        return [];
    }, [orderDetails]);

    useEffect(() => {
        if (route?.orderId) {
            getOrderDetailsList();
        }
    }, [route?.orderId]);

    const handleReviewPopup = () => {
        setOpenReview(false)
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: '4rem',
        borderRadius: '15px'
    };

    const getOrderDetailsList = async () => {
        try {
            let url = `customers/secure/myorder/${route?.orderId}`;
            let token = localStorage.getItem('@token');
            let response = await getMethod({ url, token });
            if (response.success) {
                setOrderDetails(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const updateReviewAndRating = async () => {
        try {
            let url = `customers/secure/ratings/${selectedOrderItem?.product}`;
            let token = localStorage.getItem('@token');
            const payload = {
                description: reviewDescription,
                rating: ratingValue
            }
            let response = await postMethod({ url, token, payload });
            if (response.success) {
                toast.success(response.message)
                setOpenReview(false);
            } else {
                toast.error(response.message);
                setOpenReview(false);
                setReviewDescription('');
                setRatingValue(null);
            }
        } catch (e) {
            toast.error(e.message);
            setReviewDescription('');
            setRatingValue(null);
            console.log(e);
        }
    }

    const getDateFormat = (date) => {
        const dateObject = new Date(date);
        const formattedDate = dateObject.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        return formattedDate;
    }

    const getCardTitle = () => {
        return (
            <div className='d-flex align-items-center justify-content-between' style={{ fontSize: '14px', color: '#7D879C' }}>
                <div>Order ID: <span style={{ color: '#2B3445' }}>{orderDetails?._id}</span></div>
                <div>Placed on: <span style={{ color: '#2B3445' }}>{getDateFormat(orderDetails.createdAt)}</span></div>
                <div>Delivered on: <span style={{ color: '#2B3445' }}> Not Available</span></div>
            </div>
        )
    };

    const addToCartFromOrderDetails = async (item) => {
        try {
            let url = `customers/secure/cart/${item?.productUuid}?quantity=1`;
            var payload = {
                productId: item?.productUuid,
                quantity: 1,
            };
            let token = localStorage.getItem("@token");
            let response = await postMethod({ url, payload, token });
            if (response.success) {
                toast.success(response.message);
                navigate("/cart");
            } else {
                toast.error(response.message);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const renderProductDetailsViewCard = () => {
        return (
            orderDetails?.orderItems?.map((item) => {
                return (
                    <div className='d-flex justify-content-between align-items-center py-4'>
                        <div className='d-flex align-items-center'>
                            <Avatar style={{ borderRadius: 0, backgroundColor: '#ffffff', width: '70px', height: '50px' }}>
                                <img src={item?.imageUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='product_image' />
                            </Avatar>
                            <div style={{ marginLeft: '20px', fontSize: '14px', width: '200px' }}>
                                <div style={{ fontWeight: '600' }}>{item.productName}</div>
                                <div style={{ color: '#7D879C' }}>₹{item.price} x {item.quantity}</div>
                            </div>
                        </div>
                        {/* <div style={{ color: '#7D879C', fontSize: '16px' }}>
                            Product properties: {item.productProperties}
                        </div> */}
                        <div
                            style={{ color: '#23c2f2', fontSize: '15px', marginRight: '2rem', cursor: 'pointer' }}
                            onClick={() => {
                                setComplaintModalVisible(true);
                                setSelectedOrderItem(item);
                            }}>
                            Raise A Complaint
                        </div>
                        <div
                            style={{ color: '#23c2f2', fontSize: '15px', marginRight: '2rem', cursor: 'pointer' }}
                            onClick={() => {
                                setOpenReview(true);
                                setSelectedOrderItem(item);
                            }}>
                            Write A Review
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                style={{ fontSize: '12px', color: '#23c2f2', backgroundColor: '#fff', fontWeight: '600', border: '1px solid #23c2f2' }}
                                onClick={() => addToCartFromOrderDetails(item)}
                            >
                                Order Again
                            </Button>
                            <Button
                                variant="contained"
                                className='ms-3'
                                style={{ color: 'red', backgroundColor: '#fff', fontSize: '12px', fontWeight: '600', border: '1px solid red' }}
                                onClick={() => { }}
                            >
                                Cancel Order
                            </Button>
                        </div>
                    </div>
                )
            })
        )
    }

    const getLabelText = (value) => {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    const getRatingTextColor = () => {
        return ratingLabelColor[hover !== -1 ? hover : ratingValue];
    };

    const handleComplaintModalClose = () => {
        setComplaintModalVisible(false);
    }

    return (
        <SideMenuBar>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: "green",
                            secondary: "#000",
                        },
                    },
                    error: {
                        duration: 3000,
                        theme: {
                            primary: "red",
                            secondary: "#fff",
                        },
                    }
                }}
            />

            <NewHeader
                title={'Order Details'}
                icon={<ShoppingBag
                    style={{ width: '1.2em', height: '1.2em', color: '#23c2f2' }}
                />}
                screenName={'Order'}
                isShowButton={true}
            />
            <div className='mt-4'>
                <Card style={{ padding: '4rem 2rem 2rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ position: 'relative' }}>
                            <Avatar style={{ width: '64px', height: '64px', backgroundColor: '#23c2f2' }}>
                                <img src={require('../../assets/images/pack.png')} style={{ width: '40%', height: '40%', objectFit: 'cover' }} alt='pack' />
                            </Avatar>
                            <Avatar style={{ width: '20px', height: '20px', position: 'absolute', right: 0, top: 0, backgroundColor: '#F6F9FC' }}>
                                <DoneIcon style={{ width: '12px', height: '12px', color: '#33d067' }} />
                            </Avatar>
                        </div>
                        <div style={{ minWidth: '50px', height: '4px', backgroundColor: '#23c2f2', flex: 1 }} />
                        <div>
                            <Avatar style={{ width: '64px', height: '64px', backgroundColor: '#23c2f2' }}>
                                <LocalShippingIcon style={{ width: '35px', height: '35px' }} />
                            </Avatar>
                        </div>
                        <div style={{ minWidth: '50px', height: '4px', backgroundColor: '#e3e9ef', flex: 1 }} />
                        <div>
                            <Avatar style={{ width: '64px', height: '64px', backgroundColor: '#e3e9ef' }}>
                                <CheckCircleIcon style={{ width: '35px', height: '35px' }} />
                            </Avatar>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='mt-4'>
                        <div style={{ fontSize: '14px', color: '#23c2f2', padding: '0.5rem 1rem', backgroundColor: '#fff', borderRadius: '20px', borderColor: '#23c2f2', borderWidth: 1, borderStyle: 'solid' }}>
                            Estimated Delivery Date : <span style={{ fontWeight: 'bold' }}>1 Mar 2024</span>
                        </div>
                    </div>
                </Card>
                <Card style={{ backgroundColor: '#f3f5f9', borderRadius: '10px' }} className='mt-4'>
                    <CardHeader title={getCardTitle()} style={{ padding: '1rem' }} />
                    <CardContent style={{ backgroundColor: '#ffffff' }}>
                        <div style={{ padding: '8px 16px' }}>
                            {renderProductDetailsViewCard()}
                        </div>
                    </CardContent>
                </Card>
                <Grid container spacing={3} className='mt-3'>
                    <Grid item xs={6}>
                        <Card style={{ padding: '20px 30px', borderRadius: '10px' }}>
                            <div style={{ marginBottom: '16px', fontSize: '18px', fontWeight: '600' }}>Shipping Address</div>
                            <div style={{ fontSize: '15px' }}>{orderDetails?.customer?.formatted_address_shipping}</div>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ padding: '20px 30px', borderRadius: '10px' }}>
                            <div style={{ marginBottom: '16px', fontSize: '18px', fontWeight: '600' }}>
                                Total Summary
                            </div>
                            <div className='mb-3'>
                                {chargesDataArray?.map(((item) => {
                                    return (
                                        <>
                                            {item.chargeLabel === 'Total' ? <Divider style={{ borderWidth: '1px', marginBottom: '8px' }} /> : null}
                                            <div className='d-flex justify-content-between' style={{ marginBottom: '8px', fontSize: '16px' }}>
                                                <div style={{ color: item.chargeLabel === 'Total' ? '#2B3445' : '#7D879C', fontWeight: item.chargeLabel === 'Total' ? '600' : '400' }}>
                                                    {item?.chargeLabel}
                                                </div>
                                                <div style={{ color: '#2B3445', fontWeight: '600' }}>
                                                    ₹{item?.value || 0}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }))}
                            </div>
                            {orderDetails?.paymentMode ? <div style={{ fontSize: '16px', color: '#2B3445' }}>Paid by {orderDetails?.paymentMode}</div> : null}
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Modal
                open={openReview}
                onClose={handleReviewPopup}
            >
                <Box sx={style}>
                    <div className='d-flex'>
                        <div className='pe-5 me-1 mt-2'>
                            <Avatar style={{ borderRadius: 0, backgroundColor: '#ffffff', width: '175px', height: '300px' }}>
                                <img src={selectedOrderItem?.imageUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='img' />
                            </Avatar>
                        </div>
                        <div style={{ width: '100%' }}>
                            <div style={{ fontSize: '20px', fontWeight: '600' }} className='mb-2'>Ratings</div>
                            <div className='d-flex align-items-center'>
                                <Rating
                                    name="rating"
                                    value={ratingValue}
                                    getLabelText={getLabelText}
                                    onChange={(event, val) => {
                                        setRatingValue(val);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    size='large'
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                                {ratingValue !== null && (
                                    <div className='mb-2 ms-3' style={{ color: getRatingTextColor(), fontWeight: 600 }}>{labels[hover !== -1 ? hover : ratingValue]}</div>
                                )}
                            </div>
                            <div className='mt-3 mb-2' style={{ fontSize: '20px', fontWeight: '600' }}>Review</div>
                            <TextArea rows={4} onChange={(e) => setReviewDescription(e.target.value)} />
                            <button
                                className="checkout_red_btn mt-4"
                                onClick={updateReviewAndRating}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {complaintModalVisible &&
                <ComplaintRaiseModal
                    modalVisible={complaintModalVisible}
                    modalOnClose={handleComplaintModalClose}
                    orderData={orderDetails}
                    isFromOrderDetails={true}
                    selectedOrderItem={selectedOrderItem}
                />}
        </SideMenuBar>
    )
}

export default OrderDetails;