import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ContactModal from './ContactModal';
import { Card, Divider, Grid, Avatar, Button, Rating, Chip, Stack, Paper, InputBase, Typography, Box, Modal } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import toast, { Toaster } from "react-hot-toast";

import 'swiper/swiper-bundle.css';
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import deliveryIcon from '../../assets/icons/delivery.svg';
import LocationIcon  from '../../assets/icons/locationPortal.svg';
import CloseModal from '../../assets/icons/closeModal.svg';
import { getMethod } from '../../helpers';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 250,
  bgcolor: 'background.paper',
  borderRadius: 5,
  p: '0.5rem 1rem',
};

const HubDetails = () => {
    
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    const [showContactModal, setShowContactModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const navigate = useNavigate();
    const location = useLocation();
    const { hubId } = useParams();
    const listDetails = location?.state?.data || {};

    const [myPostList, setMyPostList] = useState([]);

    useEffect(() => {
        makeMyPostApiCall();
    }, []);

    const getHubTypes = () => {
        let type = ''
        if (HubDetails === 'farmer') {
            type = 'Farmer'
        } else if (HubDetails === 'student') {
            type = 'Student'
        } else {
            type = 'MarketPlace'
        }
        return type;
    };

    const makeMyPostApiCall = async () => {
        try {
            let url = `customers/secure/posts/byme/${getHubTypes()}`;   
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            console.log(response.data);
            if (response.success) {
                setMyPostList(response.data);
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const url = window?.location?.href;

    const socialIcon = ['f.svg',  'W.svg', 'TG.svg', 'X1.svg', 'Messenger.svg', ];

    const handleIconClick = (platform, url) => {
        // Implement social media sharing logic here
    };

    const shortUrl = (url) => {
        return url.length > 30 ? url.slice(0, 27) + '...' : url;
    };

    useEffect(() => {
        if (copied) {
            toast.success('Copied to clipboard!');
        }
    }, [copied]);

    const renderDescription = () => {
        return (
            <div style={{ fontSize: '12px', color: '#AEADAD', padding: '1rem', borderRadius: '15px',  backgroundColor:'white',marginBottom: '2rem' }}>
                <h3 style={{ fontSize: '24px', fontWeight: '400',lineHeight:'28.8px', marginBottom: '0.5rem',color:'rgba(0, 0, 0, 1)' }}>Product description</h3>
                <ul>
                    <li style={{color:'rgba(151, 151, 151, 1)',fontSize:'16px',fontWeight:'400',lineHeight:'19.2px'}}>
                    {listDetails?.desc}
                    </li>
                </ul>
                
            </div>
        );
    };

    useEffect(() => {
    console.log('listDetails:', listDetails);
    console.log('listDetails.image:', listDetails?.image);
}, [listDetails]);
const renderSpecifications = () => {
    const specifications = listDetails?.specifications || [];

    return (
        <div style={{ fontSize: '12px', color: '#AEADAD', padding: '1rem', borderRadius: '15px', backgroundColor:'white' }}>
            <h3 style={{fontSize: '24px', fontWeight: '400', lineHeight:'28.8px', marginBottom: '0.5rem' }}>Specifications</h3>
            {specifications.length === 0 ? (
                <div style={{ fontSize: '16px', fontWeight: '400', lineHeight:'19.2px', color: '#7d879c', fontFamily: 'Gabarito' }}>
                    No Specifications Found
                </div>
            ) : (
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {specifications.map((spec, index) => (
                        <li key={spec._id} style={{ fontSize: '16px', fontWeight: '400', lineHeight:'19.2px', color:'rgba(0, 0, 0, 1)', marginBottom: '0.5rem' }}>
                            <strong>{spec.title}:</strong> {spec.description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};


const renderSwiperImage = () => {
    return (
        <div style={{ width: '100%', height: '250px', position: 'relative', overflow: 'hidden' }}>
            <Swiper
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper custom-swiper"
                initialSlide={activeImageIndex}
                onSlideChange={(swiper) => setActiveImageIndex(swiper.activeIndex)}
            >
                {listDetails?.image?.map((item, i) => (
                    <SwiperSlide key={i}>
                        <div 
                            style={{ 
                                width: '100%', 
                                height: '200px', 
                                overflow: 'hidden',
                                cursor: 'zoom-in'
                            }}
                            onMouseMove={(e) => {
                                const { left, top, width, height } = e.target.getBoundingClientRect();
                                const x = (e.clientX - left) / width * 100;
                                const y = (e.clientY - top) / height * 100;
                                e.target.style.transformOrigin = `${x}% ${y}%`;
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.transform = 'scale(1.5)';
                                e.target.style.transition = 'transform 0.3s ease-out';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.transform = 'scale(1)';
                                e.target.style.transition = 'transform 0.3s ease-out';
                            }}
                        >
                            <img
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                src={item}
                                alt={`Image ${i + 1}`}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

const renderThumbnails = () => {
    return (
        <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(4, 1fr)', 
            gap: '10px', 
            marginTop: '10px',
            overflowX: 'auto',
            paddingBottom: '10px'
        }} className="thumbnail-container">
            {listDetails?.image?.map((item, i) => (
                <div 
                    key={i}
                    style={{ 
                        width: '100%', 
                        aspectRatio: '1 / 1',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        border: i === activeImageIndex ? '2px solid #4CAF50' : 'none'
                    }}
                    onClick={() => setActiveImageIndex(i)}
                >
                    <img 
                        src={item} 
                        alt={`Thumbnail ${i + 1}`} 
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
            ))}
        </div>
    );
};

    const renderLocation = () => {
        if (!listDetails?.location) {
            return 'Location not available';
        }

        const { address } = listDetails.location;
        if (address && typeof address === 'object') {
            const { areaName, city, state, formatted_address } = address;
            if (formatted_address) {
                return city;
            }
            const parts = [areaName, city, state].filter(Boolean);
            return parts.join(', ') || 'Address details not available';
        }

        return 'Invalid location data';
    };
    return (
        <div className="px-5 py-4">
            <style jsx>{`
                .thumbnail-container {
                    scrollbar-width: thin;
                    scrollbar-color: #888 #f1f1f1;
                }
                .thumbnail-container::-webkit-scrollbar {
                    height: 5px;
                }
                .thumbnail-container::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                .thumbnail-container::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 5px;
                }
            `}</style>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                }}
            />
             <div className="mt-4">
             <Grid container alignItems={'center'} spacing={3} justifyContent={'space-between'}>
                <Grid item xs={12} md={3.8}>
                    <div style={{
                        backgroundColor:'white',
                        width: '100%',
                        height: 'auto',
                        border: '1px solid #00000033',
                        borderRadius: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        padding: '20px'
                    }}>
                        {renderSwiperImage()}
                    </div>
                    {renderThumbnails()}
                </Grid>
                    <Grid item xs={12} md={8.2}>
                        <div style={{ width: '100%', minHeight: '299px', maxHeight:'300px', border: '1px solid #00000033', backgroundColor:'white', borderRadius: '15px',marginBottom: '105px' }} className="p-4">
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <div style={{ fontSize: '16px', fontWeight: 600, color: '#000', fontFamily:'Gabarito' }} className="mt-2">
                                        {listDetails?.title}
                                    </div>
                                    <div style={{ fontSize: '14px', fontWeight: 500, color: '#000', fontFamily:'Gabarito', maxHeight: '4.5em', overflow: 'auto' }} className="mt-2">
                                        {listDetails?.desc}
                                    </div>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3} className="mt-3">
                                        <div style={{ fontSize: '24px', lineHeight:'28.8px', fontWeight: 500, color: '#000'  }}>
                                            ₹{listDetails?.discountPrice || listDetails?.price}
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item md={1} />
                                <Grid item xs={12} md={3}>
                                    <div>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <img src={LocationIcon} style={{ width: '22px', height: '22px' }} alt="Location Icon" />
                                            <div style={{ fontSize: '12px', fontWeight: 400, color: '#000', fontFamily:'Gabarito' }}>
                                            {renderLocation()}
                                            </div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <hr style={{width:'100%',borderBottom:'1px solid black'}}></hr>
                                <Stack className="mt-3"  display={'flex !important'} flexWrap={'wrap Important'} direction={'row !important'} gap={'10px'} width={'100%'} alignItems={'center !important'} justifyContent={'center !important'} spacing={2}>
                    <Button
                        variant="contained"
                        startIcon={<ShareIcon />}
                        onClick={handleModalOpen}
                        style={{ backgroundColor: '#4CAF50', color: 'white'  ,borderRadius:'25px'}}
                    >
                        Share
                    </Button>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: 'rgba(245, 222, 84, 1)', color: 'rgba(0, 0, 0, 1)' ,borderRadius:'25px',}}
                        onClick={() => { setShowContactModal(true) }}
                    >
                        Call User
                    </Button>
                </Stack>
                            </Grid>
                        </div>
                        
                    </Grid>
                </Grid>
                
            </div>
            <div className="mt-5">
                {renderDescription()}
                {renderSpecifications()}
            </div>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                style={{ marginTop: '2rem' }}
            >
                <Button
                    variant="contained"
                    style={{
                        color: 'White',
                        borderRadius: '10px',
                        padding: '0.75rem 2rem',
                        fontSize: '16px',
                    }}
                    onClick={() => setShowContactModal(true)}
                >
                    Get in Touch
                </Button>
            </Stack>
            <ContactModal
                modalVisible={showContactModal}
                modalOnClose={() => setShowContactModal(false)}
                data={listDetails}
            />
            <Modal
                open={modalOpen}
                onClose={handleModalClose} 
            >
                <Box sx={modalStyle}>
                    <div style={{ textAlign: 'end', cursor: 'pointer' }} onClick={handleModalClose}>
                        <img src={CloseModal} style={{ width: '25px', height: '25px' }} />
                    </div>
                    <div className="px-4 py-2">
                        <div style={{ fontSize: '17px' }}>
                            Share
                        </div>
                        <div className="mt-3 px-3">
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'start'} spacing={1}>
                                {socialIcon.map(icon => (
                                    <img 
                                        key={icon}
                                        src={require(`../../assets/SocialIcons/${icon}`)} 
                                        style={{ width: '30px', height: '30px', cursor:'pointer' }} 
                                        onClick={() => handleIconClick(icon, url)} 
                                        className="me-3" 
                                    />
                                ))}
                            </Stack>
                        </div>
                        <div className="my-4">
                            <div style={{ fontSize: '12px' }} className="mb-3">
                                Copy Link
                            </div>
                            <div className="px-3 py-1" style={{ height: '40px', width: '100%', backgroundColor: '#F3F0F0', borderRadius: '8px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ fontSize: '12px' }}>
                                    {shortUrl(url)}
                                </div>
                                <ContentCopyOutlinedIcon 
                                    style={{ width: '0.7em', height: '0.7em', cursor: 'pointer' }} 
                                    onClick={() => {
                                        navigator.clipboard.writeText(url);
                                        setCopied(true);
                                    }} 
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
};

export default HubDetails;