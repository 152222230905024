import React from 'react';
import { makeStyles } from '@mui/styles';
import FilterClear from '../assets/icons/filterClearIcon.svg';


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F69776BF',
        border: 'none',
        borderRadius: '16px',
        padding: '5px 15px',
        cursor: 'pointer'
    },
    label: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#000'
    },
    icon: {
        marginLeft: '8px',
        width: '16px',
        height: '16px',
    },
}));

const ClearFiltersChip = ({ onClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} onClick={onClick}>
            <span className={classes.label}>Clear filters</span>
            <img src={FilterClear} alt="Clear filters" className={classes.icon} />
        </div>
    );
};

export default ClearFiltersChip;