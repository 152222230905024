import { Card, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { getMethod } from '../../helpers';

const HubListView = () => {
    const [postList, setPostList] = useState([]);

    useEffect(() => {
        makeAllPostsApiCall();
    }, []);

    const makeAllPostsApiCall = async () => {
        try {
            let url = 'customers/secure/posts'
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                setPostList(response.data);
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }
    const navigate = useNavigate();

    return (
        <div>
            <Grid container>
                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', position: 'relative' }}>
                    {postList?.map((item, index) => {
                        return (
                            <Card
                                style={{ marginBottom: '30px', width: '290px', marginRight: '30px', minHeight: '350px' }}
                                onClick={() => navigate('/hub-details', { state: { data: item } })}
                            >
                                <div className='p-4' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={item?.image} style={{ height: '180px', width: '200px', objectFit: 'contain' }} />
                                    <div style={{ textAlign: 'left', marginTop: '10px', width: '100%' }}>
                                        <div style={{ fontSize: '14px', color: '#373F50', fontWeight: 600 }}>
                                            {item?.title}
                                        </div>
                                        {item?.price && (
                                            <div style={{ color: '#23c2f2', fontSize: '14px', fontWeight: 600 }}>
                                                ₹{item?.price}.00
                                            </div>
                                        )}
                                        <div style={{ fontSize: '12px', color: '#373F50', fontWeight: 600, wordWrap: 'break-word', maxHeight: '3em', overflow: 'hidden', marginTop: '5px' }}>
                                            {item?.desc}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        )
                    })}
                </div>
            </Grid>
            <div style={{ position: 'fixed', bottom: 50, right: 30 }} onClick={() => navigate('/create-post')}>
                <AddCircleIcon style={{ width: '50px', height: '50px', fill: '#23c2f2' }} />
            </div>
        </div>
    )
}

export default HubListView;