import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode';

import { getMethod } from '../../helpers';
import { getProfileAPIAction } from '../Profile/redux/ProfileAction';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AffiliateLogo from '../../assets/AppLogo/logoO.svg';


const LandingAffiliate = () => {

    const [qr, setQR] = useState('');
    const [copied, setCopied] = useState(false);

    const profileData = useSelector(state => state.profileState.profileData)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let json = localStorage.getItem("@USER");
    let User = JSON.parse(json);
    let affiliateData = localStorage.getItem("affiliateData");
    let affiliateObj = JSON.parse(affiliateData);

    useEffect(() => {
        if (!User) {
            navigate('/login');
        }
        if (copied) {
            toast.success('Copied to clipboard!');
        }
    }, [User, copied]);

    useEffect(() => {
        makeGetProfileAPI();
    }, []);

    useEffect(() => {
        if (profileData?.customer?.firstName) {
            if (profileData?.customer?.affiliate?.couponLink) {
                GenerateQRCode(profileData?.customer?.affiliate?.couponLink)
            }
        }
    }, [profileData])


    const GenerateQRCode = (url) => {
        QRCode.toDataURL(url, {
            width: 150, margin: 2, color: {
                dark: "#335383FF",
                light: "#eeeeeeff"
            }
        }, (err, url) => {
            if (err) console.log(err)
            console.log(url);
            setQR(url);
        })
    }

    const makeGetProfileAPI = async () => {
        try {
            let url = "/customers/secure/profile";
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                dispatch(getProfileAPIAction(response.data))
            } else {
            }
        } catch (e) {
        }
    }
    const handleCopy = () => {
        const couponLink = profileData?.customer?.affiliate?.couponLink;
        if (couponLink) {
            navigator.clipboard.writeText(couponLink);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }
    };

    return (
        <div>
            {affiliateObj?.isAffiliate || User?.isAffiliate
                ? <div>
                    <div style={{ color: '#AEADAD', fontSize: '15px' }}>
                        Your QR
                    </div>
                    <div style={{ display: 'flex' }} className='mt-3'>
                        <div>
                            <a href={qr} download={"qr.png"}><img src={qr} style={{ width: '100px', height: '100px' }} /></a>
                        </div>
                        <div className='ms-4'>
                            <div style={{ cursor: 'pointer' }} className='mb-2'>
                                <FileDownloadOutlinedIcon style={{ color: '#FA895B', width: '0.8em', height: '0.8em' }} />
                                <span className='ms-2' style={{ color: '#AEADAD', fontSize: '12px', fontWeight: 600 }}>Download QR</span>
                            </div>
                            <div style={{ cursor: 'pointer' }} className='mb-2'>
                                <ContentCopyOutlinedIcon style={{ color: '#FA895B', width: '0.8em', height: '0.8em' }} />
                                <span className='ms-2' style={{ color: '#AEADAD', fontSize: '12px', fontWeight: 600 }}>Copy QR</span>
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={handleCopy}>
                                <LinkIcon style={{ color: '#FA895B', width: '0.8em', height: '0.8em' }} />
                                <span className='ms-2' style={{ color: '#AEADAD', fontSize: '12px', fontWeight: 600 }}>Copy as link</span>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <button style={{ backgroundColor: '#FA895B', color: '#fff', border: 'none', fontSize: '10px', fontWeight: '600' }} className='px-3 py-1'>
                            Refresh QR
                        </button>
                    </div>
                </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <img src={AffiliateLogo} style={{ width: '45px', height: '45px' }} className='mb-3' />
                    <div style={{ fontSize: '18px', fontWeight: 600 }}>
                        Become An Affilate
                    </div>
                    <div className='mt-3'>
                        <button
                            style={{ cursor: 'pointer', backgroundColor: '#FA895B', color: '#fff', border: 'none', fontSize: '12px', fontWeight: '600' }}
                            className='px-3 py-1'
                            onClick={() => navigate('/affiliate-form')}
                        >
                            Register
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default LandingAffiliate;