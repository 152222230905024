import { IconButton, InputBase, MenuItem, Menu, Paper, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getMethod } from "../helpers";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import CategoryIcon from '../assets/icons/catIcon.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { makeGetCategoryApi, makeGetProductApi, makeGetSubCategoryApi } from "../Views/Products/redux/ProductAction";
import { useDispatch, useSelector } from "react-redux";


const SearchComponent = ({ searchData, handleMenuOnChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
    const [submenuTypeAnchorEl, setSubmenuTypeAnchorEl] = useState(null);

    const [currentSubmenuItems, setCurrentSubmenuItems] = useState([]);
    const [currentSubCatTypeItems, setCurrentSubCatTypeItems] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categoryList = useSelector((state) => state.productState.categoryData);
    const subCategoryList = useSelector((state) => state.productState.subCategoryData);
    const allProductList = useSelector((state) => state.productState.productData);
    const nestedCategories = useSelector((state) => state.productState.productAllList?.nestedCategories);
    const categoriesData = useSelector((state) => state.productState.productAllList?.categories);
    const subcategoriesData = useSelector((state) => state.productState.productAllList?.subcategories);
    const subCategoryTypesData = useSelector((state) => state.productState.productAllList?.subCategoryTypes);

    useEffect(() => {
        makeGetCategoryApi(dispatch);
        makeGetSubCategoryApi(dispatch);
        makeGetProductApi(dispatch);
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSubmenuAnchorEl(null);
    };

    const handleSubmenuOpen = useCallback((event, categoryUuid) => {
        setSubmenuAnchorEl(event.currentTarget);
        let filteredSubcategories = subcategoriesData?.filter(item => item?.categoryUuid === categoryUuid);
        setCurrentSubmenuItems(filteredSubcategories);
    }, [subcategoriesData]);

    const handleSubmenuTypeOpen = useCallback((event, subcategoryUuid) => {
        setSubmenuTypeAnchorEl(event.currentTarget);
        let filteredSubCatTypeData = subCategoryTypesData?.filter(item => item?.subcategory === subcategoryUuid);
        setCurrentSubCatTypeItems(filteredSubCatTypeData);
    }, [subCategoryTypesData]);

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
    };

    const handleSubmenuTypeClose = () => {
        setSubmenuTypeAnchorEl(null);
    };


    const handleItemClick = (item) => {
        if (item) {
            navigate('/product-search', { state: { productItem: item, isFromCategoryMenu: true } });
            setAnchorEl(null);
            setSubmenuAnchorEl(null);
            setSubmenuTypeAnchorEl(null);
        }
    };

    return (
        <div>
            <Paper
                style={{ border: 'none', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '20px' }}
            >
                <div
                    style={{
                        backgroundColor: '#F3F0F0',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0.7rem 1.8rem',
                        borderTopLeftRadius: '20px',
                        borderBottomLeftRadius: '20px'
                    }}
                    className="ps-3"
                    onClick={handleMenuOpen}
                >
                    <img src={CategoryIcon} style={{ width: '20px', height: '20px' }} alt="cat-icon" />
                    <Typography
                        component="div"
                        className="ms-2"
                        style={{ fontSize: '14px', color: '#000', cursor: 'pointer', fontWeight: 400 }}>
                        Categories
                    </Typography>
                </div>
                <InputBase
                    value={searchData}
                    sx={{ ml: 1, flex: 1, paddingLeft: '10px', fontSize: '14px', fontWeight: 400, color: '#908C8C' }}
                    placeholder="So, what are you gonna buy today ?"
                    onChange={handleMenuOnChange} // Close menu on input change
                    style={{ color: '#908C8C', fontSize: '14px' }}
                />
                <IconButton
                    id="search-menu-button"
                    type="button"
                    sx={{ p: '5px', marginRight: '1.1rem' }}
                    aria-label="search"
                >
                    <SearchIcon style={{ width: '25px', height: '25px', fill: '#000' }} />
                </IconButton>
            </Paper>
            <div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            width: '250px',
                            marginTop: '0.3rem'
                        },
                    }}
                >
                    <Stack direction={'column'}>
                        {categoriesData?.map((item, i) => (
                            <MenuItem
                                key={i}
                                onMouseEnter={(event) => handleSubmenuOpen(event, item?.categoryUuid)}
                            >
                                <Stack className='p-2' style={{ flex: 1 }} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <div style={{ fontSize: '14px', fontWeight: '600' }}>{item?.categoryName}</div>
                                    <NavigateNextIcon />
                                </Stack>
                            </MenuItem>
                        ))}
                    </Stack>
                </Menu>
                <div>
                    {currentSubmenuItems && currentSubmenuItems?.length > 0
                        ? <Menu
                            anchorEl={submenuAnchorEl}
                            open={Boolean(submenuAnchorEl)}
                            onClose={handleSubmenuClose}
                            PaperProps={{
                                style: {
                                    width: `250px`,
                                    marginLeft: `${20 + 250}px`,
                                    marginTop: '-40px'
                                },
                            }}
                        >
                            <Stack direction={'column'}>
                                {currentSubmenuItems?.map((subItem, i) => {
                                    return <MenuItem
                                        key={i}
                                        onMouseEnter={(event) => handleSubmenuTypeOpen(event, subItem?._id)}
                                    >
                                        <Stack className='p-2' style={{ flex: 1 }} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <div style={{ fontSize: '14px', fontWeight: '600' }}>{subItem?.subcategoryName}</div>
                                            <NavigateNextIcon />
                                        </Stack>
                                    </MenuItem>
                                })}
                            </Stack>
                        </Menu>
                        : null
                    }
                    <div>
                        {currentSubCatTypeItems && currentSubCatTypeItems?.length > 0
                            ? <Menu
                                anchorEl={submenuTypeAnchorEl}
                                open={Boolean(submenuTypeAnchorEl)}
                                onClose={handleSubmenuTypeClose}
                                PaperProps={{
                                    style: {
                                        width: `250px`,
                                        marginLeft: `${20 + 250}px`,
                                        marginTop: '-20px'
                                    },
                                }}
                                MenuListProps={{
                                    onMouseLeave: handleSubmenuTypeClose,
                                }}
                            >
                                <Stack direction={'column'}>
                                    {currentSubCatTypeItems?.map((subItem, i) => {
                                        return <MenuItem
                                            key={i}
                                            onClick={() => handleItemClick(subItem)}
                                        >
                                            <Stack className='p-2'>
                                                <div style={{ fontSize: '14px', fontWeight: '600' }}>{subItem?.typeName}</div>
                                            </Stack>
                                        </MenuItem>
                                    })}
                                </Stack>
                            </Menu>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchComponent;