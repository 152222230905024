import React, { useEffect, useState } from "react";
import CircleTimer from "./PoolTimer";
import { useParams } from "react-router-dom";
import { getMethod } from "../../helpers";
import moment from 'moment';
import JoinPoolForm from "../Coupons/JoinPoolForm";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Card } from "@mui/material";

const WinnersDetails = () => {
    const [poolData, setPoolData] = useState({});
    const { id } = useParams();

    useEffect(() => {
        makePoolSelectedApi()
    }, []);

    const makePoolSelectedApi = async () => {
        try {
            let url = `/customers/secure/pool/${id}`;
            const token = localStorage.getItem("@token");
            let response = await getMethod({ url, token });
            if (response.success) {
                setPoolData(response.data)
            } else {
                console.log("err");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const winnerList = [
        {
            name: 'Winner 1',
            winnerImage: 'https://boxappdocuments.s3.ap-south-1.amazonaws.com/pools/1707376421308-51zJyC37YQL._AC_UY350_.jpg'
        },
        {
            name: 'Winner 2',
            winnerImage: 'https://boxappdocuments.s3.ap-south-1.amazonaws.com/pools/1707376421308-51zJyC37YQL._AC_UY350_.jpg'
        },
        {
            name: 'Winner 3',
            winnerImage: 'https://boxappdocuments.s3.ap-south-1.amazonaws.com/pools/1707376421308-51zJyC37YQL._AC_UY350_.jpg'
        },
        {
            name: 'Winner 4',
            winnerImage: 'https://boxappdocuments.s3.ap-south-1.amazonaws.com/pools/1707376421308-51zJyC37YQL._AC_UY350_.jpg'
        },
        {
            name: 'Winner 5',
            winnerImage: 'https://boxappdocuments.s3.ap-south-1.amazonaws.com/pools/1707376421308-51zJyC37YQL._AC_UY350_.jpg'
        },
        {
            name: 'Winner 6',
            winnerImage: 'https://boxappdocuments.s3.ap-south-1.amazonaws.com/pools/1707376421308-51zJyC37YQL._AC_UY350_.jpg'
        },
    ];

    return (
        <div style={{ backgroundColor: '#F6F9FC' }}>
            <Card style={{ margin: '3rem' }}>
                <div
                    style={{
                        backgroundImage: `url(${poolData?.poolImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '220px',
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative',
                    }}
                >
                    <div style={{ position: 'absolute', top: 30, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Chip
                            label={poolData?.poolName}
                            className="ms-4"
                            style={{ backgroundColor: '#103460', color: '#fff', fontSize: '20px', borderRadius: '4px', padding: '4px 16px', fontWeight: '600' }}
                        />
                        <Chip
                            label={`Participants: ${poolData?.winners?.length > 0 ? poolData?.winners?.length : 0}`}
                            className="me-4"
                            style={{ backgroundColor: '#103460', color: '#fff', fontSize: '20px', borderRadius: '4px', padding: '4px 16px', fontWeight: '600' }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }} className="mt-4">
                    {winnerList?.map((item, index) => (
                        <div key={index} style={{ width: '30%', marginBottom: '40px', textAlign: 'center' }}>
                            <div
                                style={{
                                    width: 110,
                                    height: 110,
                                    border: '10px solid #fff',
                                    borderRadius: 80,
                                    margin: '0 auto 10px',
                                }}>
                                <Avatar alt="Remy Sharp" src={item?.winnerImage} sx={{ width: 100, height: 100 }} />
                            </div>
                            <div style={{ fontSize: '20px', color: '#000', fontWeight: '700' }}>
                                {item?.name}
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
        </div>
    )
};

export default WinnersDetails;