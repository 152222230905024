import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Views/Login";
import Register from "./Views/Register";
import Affiliate from "./Views/Affiliate/Affiliate";
import ForgotPassword from "./Views/ForgotPassword";
import "./css/Style.css";
import OtpVerification from "./Views/OtpVerification";
import SetNewPassword from "./Views/SetNewPassword";
import Home from "./Views/Home/Home";
import SubCategory from "./Views/SubCategory";
import CategoryListing from "./Views/CategoryListing";
import InfoDetails from "./Views/InfoDetails";
import Product from "./Views/Product";
import Cartpage from "./Views/Cart/Cartpage";
import { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";
import OrderList from "./Views/Order/Orders";
import OrderDetails from "./Views/Order/OrderDetails";
import SupportTicket from "./Views/SupportTicket";
import SupportDetails from "./Views/SupportTicket/SupportDetails";
import NewProductsView from "./Views/Products";
import PoolsList from "./Views/Pools/PoolsList";
import PoolsDetails from "./Views/Pools/PoolsDetails";
import WishlistCard from "./Views/Wishlist/WishlistCard";
import HubFormComponent from "./Views/Hub/HubForm";
import CreatePost from "./Views/Hub/CreatePost";
import HubDetails from "./Views/Hub/HubDetails";
import MyPostListView from "./Views/Hub/MyPostView";
import ProductWishlist from "./Views/Wishlist/ProductWishlist";
import TodayWinnersList from "./Views/Pools/TodayWinnersList";
import WinnersDetails from "./Views/Pools/WinnerListDetails";
import ProfileView from "./Views/Profile/Profile";
import AffiliatePurchaseCouponForm from "./Views/Coupons/AffiliatePurchaseCouponForm";
import LandingHubScreen from "./Views/Hub/LandingHub";
import ContainerWrapper from "./Components/HeaderWrapper";
import HubRegisterView from "./Views/Hub/HubRegisterView";
import CommonAffiliate from "./Views/Affiliate/CommonAffiliate";
import Blog from "./Views/Blog";
import BlogDetails from "./Views/Blog/BlogDetails";
import Experience from "./Views/Experience";
import Privacy from "./Views/Policy/Privacy";
import TermsAndCondition from "./Views/Policy/TermsCondition";
import RefundPolicy from "./Views/Policy/RefundPolicy";
import ReturnPolicy from "./Views/Policy/ReturnPolicy";
import ShippingPolicy from "./Views/Policy/ShippingPolicy";
import UrbanTicketScreen from "./Views/UrbanTicket";
import ProductList from "./Views/Products/ProductList";

import Addresses from "./Components/Addresses"
import PaymentMethods from "./Components/PaymentMethods";
import SideMenuBar from './Components/SideMenuBar';
import EditPost from './Components/edit-post';
// import EditPost from './Components/edit-post';


function App() {
  const location = useLocation();
  let screenName = location.pathname.replace('/hub/', '')?.includes('/') ? location.pathname.replace('/hub/', '')?.split("/")[0] : location.pathname.replace('/hub/', '');

  console.log('screenName',screenName)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <ThemeProvider theme={theme}>
        {[
          "/register",
          "/affiliate-form",
          "/hub",
          "/login",
          "/forgotpw",
          "/otpverification",
          "/setnewpassword",
        ].includes(location.pathname)
          ? (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="forgotpw" element={<ForgotPassword />} />
              <Route path="/otpverification" element={<OtpVerification />} />
              <Route path="/setnewpassword" element={<SetNewPassword />} />
              <Route path="/hub" element={<HubFormComponent />} />
              <Route path="/affiliate-form" element={<Affiliate />} />
              
            </Routes>
          )
          : (
            <ContainerWrapper screenName={screenName} >
              <Routes>
                <Route path="/" element={<Home screenName={screenName} />} />
                <Route path="/affiliate" element={<CommonAffiliate />} />
                <Route path="/landing-hub" element={<LandingHubScreen />} />
                <Route path="/subcategory" element={<SubCategory />} />
                <Route path="/products" element={<NewProductsView />} />
                <Route path="/pools" element={<PoolsList />} />
                <Route path="/pools/:id" element={<PoolsDetails />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/productDetails/:subcatId/:productId" element={<InfoDetails />} />
                <Route path="/product-search" element={<ProductList />} />
                <Route path="/categorylisting" element={<CategoryListing />} />
                <Route path="/cart" element={<Cartpage />} />
                <Route path="/wishlist" element={<WishlistCard />} />
                <Route path="/checkout" element={<Cartpage />} />
                <Route path="/payment" element={<Cartpage />} />
                <Route path="/orders" element={<OrderList />} />
                <Route path="/profile" element={<ProfileView />} />
                <Route path="/orders/:orderId" element={<OrderDetails />} />
                <Route path="/support-ticket" element={<SupportTicket />} />
                <Route path="/support-ticket/:ticket" element={<SupportDetails />} />
                <Route path="/my-post" element={<MyPostListView />} />
                <Route path="/hub/:type/hub-details" element={<HubDetails />} />
                <Route path="/coupon/purchase/:affiliateCode" element={<AffiliatePurchaseCouponForm />} />
                <Route path="/profile-wishlist" element={<ProductWishlist />} />
                <Route path="/today-winners" element={<TodayWinnersList />} />
                <Route path="/today-winners/:id" element={<WinnersDetails />} />
                <Route path="/hub-register" element={<HubRegisterView />} />
                <Route path="/hub/:type" element={<LandingHubScreen screenName={screenName} />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:name" element={<BlogDetails />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/terms-and-condition" element={<TermsAndCondition />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/refund" element={<RefundPolicy />} />
                <Route path="/return" element={<ReturnPolicy />} />
                <Route path="/shipping" element={<ShippingPolicy />} />
                <Route path="/urban-ticket" element={<UrbanTicketScreen />} />
                <Route path="/hub/:type/create-post" element={<CreatePost screenName={screenName} />} />

                <Route path="/addresses" element={<SideMenuBar><Addresses /></SideMenuBar>} />
                <Route path="/payment-methods" element={<SideMenuBar><PaymentMethods /></SideMenuBar>} />
                {/* <Route exact path="/my-posts" component={MyPostListView} /> */}
                <Route path="/edit-post/:id" component={EditPost} />

              </Routes>
            </ContainerWrapper>
          )}
      </ThemeProvider>
    </>
  );
}

export default App;
