import { OrderActionTypes } from "./OrderActionType";

const INITIAL_STATE = {
  orderDataList: []
};

export const OrderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderActionTypes.GET_ORDER_LIST:
      return { ...state, orderDataList: action.payload };
    default:
      return state;
  }
};