import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from 'antd';
import { postMethod } from '../../helpers';
import toast, { Toaster } from 'react-hot-toast';

const { TextArea } = Input;

const useStyles = makeStyles((theme) => ({
    // Define your custom styles here
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '2rem',
    borderRadius: '10px'
};


const ComplaintRaiseModal = ({
    modalVisible,
    modalOnClose,
    orderData,
    isFromOrderDetails = false,
    selectedOrderItem = {}
}) => {
    const classes = useStyles();

    const [selectedProductId, setSelectedProductId] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const contructOrderList = useMemo(() => {
        if (isFromOrderDetails) {
            return orderData && Object.keys(orderData).length > 0 ? [orderData] : [];
        } else {
            return orderData && orderData.length > 0 ? orderData : [];
        }
    }, [isFromOrderDetails, orderData]);

    const initialOrderId = useMemo(() => {
        return contructOrderList && contructOrderList.length > 0 && contructOrderList[0].orderNo;
    }, [contructOrderList]);

    const [selectedOrderNumber, setSelectedOrderNumber] = useState(initialOrderId);

    const filterFromAllOrderData = useMemo(() => {
        let filterOrderList = contructOrderList?.find((item) => item?.orderNo === selectedOrderNumber || item?.orderUuid === selectedOrderNumber);
        return { orderId: filterOrderList?._id, orderItems: filterOrderList?.orderItems };
    }, [selectedOrderNumber, contructOrderList]);

    const filterFromSelectedOrderData = useCallback((orderList) => {
        let filterOrderList = orderList?.find((item) => item?._id === selectedOrderItem?._id);
        return [filterOrderList];
    }, [selectedOrderItem])

    const constructProductList = useMemo(() => {
        if (isFromOrderDetails) {
            return contructOrderList && contructOrderList.length > 0 ? filterFromSelectedOrderData(contructOrderList[0]?.orderItems) : [];
        } else {
            return contructOrderList && contructOrderList.length > 0
                ? filterFromAllOrderData?.orderItems
                : [];
        }
    }, [isFromOrderDetails, contructOrderList, filterFromAllOrderData, filterFromSelectedOrderData]);

    const handleOrderSelectChange = (e) => {
        setSelectedOrderNumber(e.target && e?.target?.value ? e.target.value : '');
        setSelectedProductId('');
    }

    const handleProductSelectChange = (e) => {
        setSelectedProductId(e.target && e?.target?.value ? e.target.value : '');
    }

    const handleOnChange = (e) => {
        if (e.target.name === 'subject') {
            setSubject(e.target.value)
        } else {
            setMessage(e.target.value)
        }
    }

    const showSelectedProductImage = () => {
        const selectedProductInfo = constructProductList?.find(
            (item) => item?.product === selectedProductId
        );

        return (
            <img
                src={selectedProductInfo?.imageUrl}
                alt={selectedProductInfo?.productName}
                style={selectedProductId ? { width: '35px', height: '35px', order: -1, marginLeft: '10px' } : { display: 'none' }}
            />
        );
    };

    const onSubmit = async () => {
        let payload = {
            message,
            subject
        };
        try {
            let url = `customers/secure/complaint/raise/${filterFromAllOrderData?.orderId}/${selectedProductId}`;
            const token = localStorage.getItem("@token");
            let response = await postMethod({ url, token, payload });
            if (response.success) {
                toast.success(response.message);
                modalOnClose();
            } else {
                toast.error(response.message);
            }
        } catch (e) {
            toast.error(e.response.message);
        }
    }

    return (
        <>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: "green",
                            secondary: "#000",
                        },
                    },
                    error: {
                        duration: 3000,
                        theme: {
                            primary: "red",
                            secondary: "#fff",
                        },
                    }
                }}
            />
            <Modal
                open={modalVisible}
                onClose={modalOnClose}
            >
                <Box sx={style}>
                    <div style={{ fontSize: '20px', color: '#23c2f2', fontWeight: 700 }}>
                        Create a New Ticket
                    </div>
                    <div className='mx-5 my-4'>
                        <FormControl fullWidth>
                            <InputLabel>Select Order Number</InputLabel>
                            <Select
                                value={selectedOrderNumber}
                                name="orderNumber"
                                label="orderNumber"
                                onChange={handleOrderSelectChange}
                                className={`${classes.customSelect} mb-4`}
                            >
                                {contructOrderList?.map((item, index) => {
                                    let orderNumber = item?.orderNo ? item?.orderNo : item?.orderUuid;
                                    return <MenuItem key={`order_list_${index}`} value={orderNumber}>{orderNumber}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Select Product</InputLabel>
                            <Select
                                name="product"
                                label="product"
                                onChange={handleProductSelectChange}
                                className="mb-4"
                                IconComponent={showSelectedProductImage}
                            >
                                {constructProductList?.map((item, index) => {
                                    return <MenuItem key={`product_list_${index}`} value={item?.product}>{item?.productName}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Subject"
                            variant="outlined"
                            name='subject'
                            fullWidth
                            className='mb-4'
                            value={subject}
                            onChange={handleOnChange}
                        />
                        <TextArea
                            placeholder='Enter Message'
                            rows={5}
                            name='message'
                            onChange={handleOnChange}
                            className='mb-4'
                            value={message}
                        />
                        <Button
                            variant="contained"
                            style={{ fontSize: '14px', backgroundColor: '#23c2f2', width: 150, height: 40, fontWeight: 600, borderRadius: 0 }}
                            onClick={onSubmit}
                        >
                            Create Ticket
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
};

export default ComplaintRaiseModal;