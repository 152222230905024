import React from "react";
import FormComponent from "../Form";

function Affiliate() {
    return (
        <FormComponent />
    );
}

export default Affiliate;
