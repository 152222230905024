import React, { useState } from 'react';
import {
    Button,
    Typography,
    Grid,
    TextField,
    Box,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { makeStyles, styled } from '@mui/styles';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import StepperComponent from './StepperComponent';
import CommonTextField from './CommonTextField';

const steps = ['Login Info', 'Personal Details'];

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: ownerState.completed ? '#2DBDEE' : ownerState.active ? '#2DBDEE' : '#fff',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    border: '1px solid #7F7B7B',
    padding: 0
}));


const useStyles = makeStyles((theme) => ({
    datePickerOverride: {
        width: '100%',
        '& .MuiInputBase-root': {
            height: '40px',
            padding: '0 14px',
            borderRadius: '10px'
        },
        '& .MuiInputLabel-root': {
            top: '0px',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px 14px'
        },
    },
    stepper: {
        backgroundColor: 'transparent'
    },
    root: {
        marginBottom: '20px',
        padding: 0
    },
    selectField: {
        height: '45px',
        '& .MuiSelect-select': {
            padding: '8px 14px',
            borderRadius: '10px',
        },
        '& .MuiInputBase-root': {
            height: '45px',
            borderRadius: '10px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
        },
    },
}));

const RegisterForm = ({ formData, handleChange, handleDateOnChange, onSubmit, handlePhoneChange }) => {

    const [activeStep, setActiveStep] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const classes = useStyles();

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            onSubmit();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <Grid container className='my-5'>
                <Grid item xs={1.5} />
                <Grid item xs={10}>
                    <div style={{ fontSize: '18px', color: '#5C5B5B' }}>
                        Complete your UrbanWallah profile
                    </div>
                </Grid>
            </Grid>
            <StepperComponent activeStep={activeStep} stepsData={steps}>
                <Box component="form">
                    {activeStep === 0 && (
                        <>
                            <Stack style={{ marginBottom: '1rem' }} spacing={2} direction={'row'} alignItems={'center'}>
                                <CommonTextField
                                    id='first-name'
                                    labelName='First Name'
                                    name='first_name'
                                    value={formData.first_name}
                                    handleChange={handleChange}
                                />
                                <CommonTextField
                                    id='last-name'
                                    labelName='Last Name'
                                    name='last_name'
                                    value={formData.last_name}
                                    handleChange={handleChange}
                                />
                            </Stack>
                            <CommonTextField
                                id='email'
                                labelName='Email'
                                name='email'
                                value={formData.email}
                                handleChange={handleChange}
                                style={{ marginBottom: '1rem' }}
                            />
                            <CommonTextField
                                id='password'
                                labelName='Password'
                                name='password'
                                value={formData.password}
                                handleChange={handleChange}
                                style={{ marginBottom: '1rem' }}
                                handleClickShowPassword={handleClickShowPassword}
                                handleMouseDownPassword={handleMouseDownPassword}
                                showPassword={showPassword}
                                type='password'
                            />
                            <CommonTextField
                                id='confirm-password'
                                labelName='Confirm Password'
                                name='confirm_password'
                                value={formData.confirm_password}
                                handleChange={handleChange}
                                handleClickShowPassword={handleClickShowConfirmPassword}
                                handleMouseDownPassword={handleMouseDownConfirmPassword}
                                showPassword={showConfirmPassword}
                                type="password"
                            />
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                                <InputLabel
                                    htmlFor="phone-input"
                                    style={{
                                        fontSize: '15px',
                                        position: 'relative',
                                        transform: 'none',
                                        color: '#000'
                                    }}
                                >
                                    Phone Number
                                </InputLabel>
                                <PhoneInput
                                    country={'in'}
                                    value={formData.phone_number}
                                    onChange={handlePhoneChange}
                                    placeholder="Enter phone number"
                                    inputProps={{
                                        id: 'phone-input',
                                        style: {
                                            height: '40px',
                                            padding: '0 3rem',
                                            borderRadius: '10px',
                                            width: '100%',
                                        },
                                    }}
                                    containerStyle={{ marginTop: '10px' }} // Adjust the margin to position below the label
                                />
                            </FormControl>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                <InputLabel
                                    htmlFor="dob"
                                    style={{
                                        fontSize: '15px',
                                        position: 'relative',
                                        transform: 'none',
                                        color: '#000'
                                    }}
                                >
                                    Date Of Birth
                                </InputLabel>
                                <DatePicker
                                    value={formData.dob}
                                    onChange={handleDateOnChange}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            name="dob"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                    className={clsx('mb-3 mt-2', classes.datePickerOverride)}
                                />
                            </LocalizationProvider>
                            <FormControl variant="outlined" fullWidth>
                                <Typography variant="body1" className={classes.selectLabel}>
                                    Gender
                                </Typography>
                                <Select
                                    id="demo-simple-select-helper"
                                    value={formData.gender}
                                    name="gender"
                                    onChange={handleChange}
                                    className={`${classes.selectField} mt-2`}
                                >
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    )}
                    {activeStep === steps?.length - 1
                        ? <div className='mt-3 d-flex align-items-center justify-content-center'>
                            <Checkbox
                                sx={{
                                    transform: 'scale(1.2)',
                                    '& .MuiSvgIcon-root': { fontSize: 18 },
                                    '&.Mui-checked': {
                                        color: '#2DBDEE',
                                    },
                                    '&.MuiCheckbox-root': {
                                        color: '#2DBDEE',
                                    },
                                }}
                            />
                            <div style={{ fontSize: '14px' }}>Accept Terms and Conditions & Privacy policy</div>
                        </div>
                        : null
                    }
                    <Stack className='mt-4' direction={'row'} spacing={activeStep !== 0 ? 3 : 0} alignItems={'center'} justifyContent={'center'}>
                        {activeStep !== 0
                            ? <Button
                                variant="contained"
                                style={{ backgroundColor: '#2DBDEE', width: '150px' }}
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Back
                            </Button>
                            : null
                        }
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#2DBDEE', width: '150px' }}
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={activeStep === steps?.length}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Stack>
                </Box>
            </StepperComponent>
        </div>
    );
}

export default RegisterForm;
